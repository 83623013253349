import { useTranslate } from '@/i18n/useTranslate';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { permissionsActions } from '@/store/permissions/slice';
import { useCompaniesData } from '@/store/companies/hooks';
import { useInitVivicaLoader } from '@/hooks/useVivicaLoader';
import { useAccountInvitationToModule } from '@/store/profile/hooks';
import { AppRoutes } from '@/routes/appRoutes';

export const useOnLoginInit = () => {
  const { ready } = useTranslate('profile');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isEmailPage = useMatch(AppRoutes.PROFILE_PHONE_EMAILS);
  const permissionsStorage = useSelector(
    (state: RootState) => state.permissions
  );
  const requestInvite = useAccountInvitationToModule();

  useEffect(() => {
    if (searchParams.has('code') && !isEmailPage) {
      requestInvite();
    }
  }, [isEmailPage, requestInvite, searchParams]);
  useEffect(() => {
    if (!permissionsStorage.permissions) {
      dispatch(permissionsActions.requestAllPermissions());
    }
  }, [dispatch, permissionsStorage.permissions]);
  useCompaniesData();
  useInitVivicaLoader(ready);
};
