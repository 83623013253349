import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { RootState } from '@/store/store';
import { accountApi } from '@/api';
import { CompanyNPIState, companyNpiActions as actions } from './slice';
import { getErrors } from '@/store/common/error-handlers';
import { ValidationErrorType } from '@/types/ValidationError';
import { client } from '@/api/client/ApiClient';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';

export function* companyNpiSaga() {
  yield all([
    takeLeading(actions.requestNPIInfo, requestNPIInfo),
    takeLeading(actions.requestConfirm, requestConfirm),
  ]);
}
type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;
function* requestNPIInfo({ payload }: Action<'requestNPIInfo'>): SagaReturn {
  yield put(actions.setIsFetching(true));
  try {
    const {
      data: npi,
    }: Awaited<ReturnType<typeof client.verifyAccountCompanyRegistration>> =
      yield call(
        payload.parent
          ? client.verifyAccountCompanyRegistrationParent
          : client.verifyAccountCompanyRegistration,
        payload
      );
    yield put(actions.setNpiInfo({ npi, parent: payload.parent }));
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common === ValidationErrorType.NPI_TYPE_INCORRECT) {
      errors.npi = ValidationErrorType.NPI_TYPE_INCORRECT_ORG;
      delete errors.common;
    }
    if (errors.common) {
      showError500OrUnknownToast(e);
    }
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}

function* requestConfirm({ payload }: Action<'requestConfirm'>): SagaReturn {
  const { npiNumber, parentNpiNumber }: CompanyNPIState = yield select(
    (rootState: RootState) => rootState.companyNpi
  );
  yield put(actions.setIsFetching(true));
  try {
    yield call(accountApi.confirmCompanyNPIInfo, {
      npi: npiNumber as NonNullable<string>,
      parentNpi: parentNpiNumber || null,
      thDomain: payload.thDomain,
    });
    yield put(actions.reset());
    yield put(actions.setRequestSuccess(true));
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) showError500OrUnknownToast(e);
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}
