import { useAvatarUpload } from '@/store/profile/hooks';
import { useCompanyLogo } from '@/store/companies/hooks/useCompanyLogo';
import { UploadAvatarDialog } from '@/features/profile/UploadAvatarDialog';
import { UploadCompanyAvatarDialog } from '@/features/company/fragments/UploadCompanyAvatarDialog';
import { AboutSystem } from '@/features/layouts/MainLayout/fragments/AboutSystem';

export const Dialogs = () => {
  const { closeDialog, dialogOpened: avatarUploadDialogIsOpened } =
    useAvatarUpload();
  const {
    dialogOpened: companyLogoDialogOpened,
    closeDialog: closeCompanyLogoDialog,
  } = useCompanyLogo();
  return (
    <>
      <UploadAvatarDialog
        open={avatarUploadDialogIsOpened}
        onClose={closeDialog}
      />
      <UploadCompanyAvatarDialog
        open={companyLogoDialogOpened}
        onClose={closeCompanyLogoDialog}
      />
      <AboutSystem />
    </>
  );
};
