import React, { FC, useLayoutEffect } from 'react';
import { PinCodeFragment } from '../common/fragments/PinCodeFragment';
import { PinCodeExpiredFragment } from '../common/fragments/PinCodeExpiredFragment';
import { Forms, Space } from '../common/types';
import { useAccountActivation, useAuthState } from '@/store/auth/hooks';
import { authActions } from '@/store/auth/slice';
import { useDispatch } from 'react-redux';
import { NewPasswordFragment } from './fragments/NewPasswordFragment';
import { WarningFragment } from './fragments/WarningFragment';
import { LoadingBackdrop } from '@/components/LoadingBackdrop';
import { useSearchParams } from 'react-router-dom';
import { PrivacyPolicy } from '../common/fragments/PrivacyPolicy';
import { SuccessFragment } from '../common/fragments/SuccessFragment';
export const INVITE_CODE = 'code';
export const useInviteCode = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(INVITE_CODE) ?? undefined;
};
const ActivationPage: FC = () => {
  const state = useAuthState();
  const dispatch = useDispatch();
  const inviteCode = useInviteCode();
  const { fetching, error, continueRegistration } = useAccountActivation({
    inviteCode,
  });
  let component = <></>;

  useLayoutEffect(() => {
    dispatch(authActions.goToStep('index'));
  }, [dispatch]);

  switch (state.step) {
    case 'init-account-activation':
      component = <LoadingBackdrop open={true} />;
      break;
    case 'error':
      component = <WarningFragment message={error} />;
      break;
    case 'privacy-policy':
      component = <PrivacyPolicy onNext={continueRegistration} />;
      break;
    case 'pin-code':
      component = <PinCodeFragment space={Space.Activation} />;
      break;
    case 'pin-code-attempts-expired':
      component = <PinCodeExpiredFragment space={Space.Activation} />;
      break;
    case 'success':
      component = (
        <SuccessFragment
          space={Space.Activation}
          info={state.forms[Forms.ActivationForm]?.activationSuccessData}
        />
      );
      break;
    default:
      component = <NewPasswordFragment />;
  }

  return (
    <>
      <LoadingBackdrop open={fetching} />
      {component}
    </>
  );
};
export default ActivationPage;
