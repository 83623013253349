/**
 * @deprecated historic version of colors #2. Use paletteColorsV2
 */
export const paletteColors = {
  red: {
    100: '#FCF2F5',
    200: '#EAE0E3',
    300: '#DF546D',
    400: '#A81D42',
    500: '#73001C',
    gradient: ['#A81D42', '#DF546D'],
  },
  blue: {
    100: '#EEF5F8',
    200: '#DEE9ED',
    300: '#60D2E9',
    400: '#19A1B7',
    500: '#007287',
    gradient: ['#014550', '#007287'],
  },
  grey: {
    100: '#FAFAFA',
    200: '#F3F4F6',
    300: '#E2E5EB',
    400: '#C7CDD6',
    500: '#999FAB',
    600: '#858D9C',
    700: '#6B7280',
    800: '#4B5563',
    900: '#374151',
  },
  error: {
    100: '#F4E3E6',
    200: '#EA3D5C',
  },
  success: {
    100: '#DDEFEE',
    200: '#05B7AC',
  },
  warning: {
    100: '#F6EFE0',
    200: '#FAB41C',
  },
  info: '#0E75BF',
  focus: '#FF47F8',
};

// these were existed before designers organized them as palette colors
/**
 * @deprecated
 */
export const initialColors = {
  transparent: 'transparent',
  white: '#fff',
  grey7: paletteColors.grey[100],
  grey6: paletteColors.grey[200],
  grey5: '#D1D5DB',
  grey4: '#9CA3AF',
  grey3: paletteColors.grey[600],
  grey2: paletteColors.grey[700],
  grey1: paletteColors.grey[800],
  secondary5: paletteColors.blue[100],
  secondary10: paletteColors.blue[200],
  secondaryLight: paletteColors.blue[300],
  secondary: paletteColors.blue[400],
  secondaryDark: paletteColors.blue[500],
  errorLegacy: paletteColors.error[200],
  successLegacy: paletteColors.success[200],
  primaryLight: paletteColors.red[300],
  primary: paletteColors.red[400],
  primaryDark: paletteColors.red[500],
} as const;

export const paletteColorsV2 = {
  alertColors: {
    purple: '#846CC8',
    achieved: '#997A8D',
    focus: '#997A8D',
  },
  brandColors: {
    secondaryDark: '#007287',
    secondary: '#19A1B7',
    secondaryLight: '#60D2E9',
    secondary63: '#6EC4D2',
    secondary28: '#BFE5EB',
    secondary21: '#CFEBF0',
    secondary14: '#DFF2F5',
    secondary7: '#EFF8FA',
    secondary1: '#F7FCFD',

    primaryDark: '#73001C',
    primary: '#A81D42',
    primaryLight: '#DF546D',
    primary21: '#F4C3CC',
    primary14: '#F3DFE5',
    primary7: '#F9EFF2',
  },
  grey: {
    100: '#FAFAFA',
    200: '#F3F4F6',
    300: '#E2E5EB',
    400: '#C7CDD6',
    500: '#999FAB',
    600: '#858D9C',
    700: '#6B7280',
    800: '#4B5563',
    900: '#374151',
  },
};
