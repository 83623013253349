import React, { FC } from 'react';
import { User } from '@/types';
import { useTranslate } from '@/i18n/useTranslate';
import { BoxBody, BoxHeader, Nothing, Nowrap, WhiteBox } from '@/components';
import { Box, IconButton, Stack } from '@mui/material';
import { Table, TBody, Td, Th, THead, Tr } from '@/components/Table';
import { UserName } from '@/components/UserName';
import { getName } from '@/utils/getName';
import { ReactComponent as IconSVGCheckmark } from '@/images/icons/icon_checkmark_round.svg';
import { DotAccountStatus } from '@/features/fragments/DotAccountStatus';
import { CompanyAccountStatus } from '@/features/fragments/CompanyAccountStatus';
import { tableFullWidthPaddingsStyle } from '@/components/Table/tableFullWidthPaddingsStyle';
import { SearchControl } from 'ui-kit';
import { PhonesWithTooltip } from '@/components/PhonesDisplayWithTooltip/PhonesWithTooltip';
import { UsersListProps } from '@/features/module/users-list/fragments/TabletList';

const styles = {
  container: { display: 'flex', gap: 24, height: '100%' },
  wBox: { minWidth: 0 },
  search: { marginBottom: 24 },
  boxMarginsX: {
    xs: -24,
    lg: -36,
  },
  table: [
    tableFullWidthPaddingsStyle,
    {
      tableLayout: 'fixed',
      '*': { fontSize: 14 },
    },
  ],
  accountNameAndStatus: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
};
export const DesktopList: FC<
  UsersListProps & { onSearchChange: React.ChangeEventHandler }
> = ({
  moduleUsers,
  companyUsers,
  addUser,
  removeUser,
  loadMoreCompanyUsers,
  loadMoreModuleUsers,
  onSearchChange,
  roleUsersListLabel,
  showRevokeButton,
  showAddButton,
}) => {
  const { t } = useTranslate('companies');

  const tableHead = (
    <THead>
      <Tr>
        <Th width={'40%'} columnId={'name'}>
          {t('NAME')}
        </Th>
        <Th>{t('PHONE')}</Th>
        <Th colSpan={2}>
          <Nowrap>{t('COMPANY_STATUS')}</Nowrap>
        </Th>
      </Tr>
    </THead>
  );

  const renderUserNameAndStatus = (user?: User) => {
    return (
      <Stack sx={styles.accountNameAndStatus}>
        <DotAccountStatus status={user?.accountStatus} />
        <UserName avatarSrc={user?.avatarUrl}>{getName(user)}</UserName>
      </Stack>
    );
  };

  return (
    <Box sx={styles.container}>
      <WhiteBox sx={styles.wBox}>
        <BoxHeader mb={24}>{roleUsersListLabel ?? '...'}</BoxHeader>
        <BoxBody>
          <SearchControl
            placeholder={t('SEARCH_USERS')}
            sx={styles.search}
            name={'module'}
            onChange={onSearchChange}
          />
          {moduleUsers?.allIDs?.length ? (
            <Box marginX={styles.boxMarginsX}>
              <Table sx={styles.table} onLoad={loadMoreModuleUsers}>
                {tableHead}
                <TBody>
                  {moduleUsers?.allIDs?.map((id) => {
                    const user = moduleUsers.byID[id];
                    return (
                      <Tr key={id}>
                        <Td>{renderUserNameAndStatus(user)}</Td>
                        <Td>
                          <PhonesWithTooltip phones={user?.phones} />
                        </Td>
                        <Td>
                          <CompanyAccountStatus
                            status={user?.accountCompanyStatus}
                            inviteExpiredTime={user?.inviteExpiredTime}
                          />
                        </Td>
                        <Td sx={{ textAlign: 'right' }}>
                          {showRevokeButton(user) && (
                            <IconButton onClick={() => removeUser(id)}>
                              <Box
                                component={IconSVGCheckmark}
                                sx={{
                                  path: {
                                    stroke: (t) => t.palette.alert.success,
                                  },
                                }}
                              />
                            </IconButton>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
            </Box>
          ) : (
            <Nothing lh={38}>{t('NO_USERS')}</Nothing>
          )}
        </BoxBody>
      </WhiteBox>
      <WhiteBox sx={styles.wBox}>
        <BoxHeader mb={24}>{t('ALL_AVAILABLE_USERS')}</BoxHeader>
        <BoxBody>
          <SearchControl
            placeholder={t('SEARCH_USERS')}
            sx={styles.search}
            name={'company'}
            onChange={onSearchChange}
          />
          {companyUsers?.allIDs?.length ? (
            <Box marginX={styles.boxMarginsX}>
              <Table sx={styles.table} onLoad={loadMoreCompanyUsers}>
                {tableHead}
                <TBody>
                  {companyUsers?.allIDs?.map((id) => {
                    const user = companyUsers.byID[id];
                    return (
                      <Tr key={id}>
                        <Td>{renderUserNameAndStatus(user)}</Td>
                        <Td>
                          <PhonesWithTooltip phones={user?.phones} />
                        </Td>
                        <Td>
                          <CompanyAccountStatus
                            status={user?.accountCompanyStatus}
                            inviteExpiredTime={user?.inviteExpiredTime}
                          />
                        </Td>
                        <Td sx={{ textAlign: 'right' }}>
                          {showAddButton(user) && (
                            <IconButton onClick={() => addUser(id)}>
                              <Box component={IconSVGCheckmark} />
                            </IconButton>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
            </Box>
          ) : (
            <Nothing lh={38}>{t('NO_USERS')}</Nothing>
          )}
        </BoxBody>
      </WhiteBox>
    </Box>
  );
};
