import React, { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';
import { Button, ButtonProps } from '@/components/Button';
import { ReactComponent as IconSVGArrowRight } from '@/images/icons/icon_arrow_thick_right.svg';
import { useTranslate } from '@/i18n/useTranslate';

const PaginationLayout = styled('div')(() => ({
  display: 'inline-flex',
  gap: 10,
  margin: '0 -12px',
}));

const PgButton = styled(Button)(({ theme: t }) => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  padding: '0 14px',
  height: 30,
  '--icon-fill': t.palette.text.primary,
  minWidth: 'auto',
  '&:disabled': {
    pointerEvents: 'none',
    color: t.palette.grey[300],
    '--icon-fill': t.palette.grey[300],
  },
  '.MuiButton-startIcon': {
    marginRight: 12,
  },
  '.MuiButton-endIcon': {
    marginLeft: 12,
  },
}));
PgButton.defaultProps = {
  type: 'button',
  size: 'sm',
};

export const Prev = ({ children: __, ...restProps }: ButtonProps) => {
  const { t } = useTranslate('common');
  return (
    <PgButton
      {...restProps}
      variant={'text'}
      color={'inherit'}
      startIcon={
        <Box
          component={IconSVGArrowRight}
          sx={{ transform: 'rotate(180deg)' }}
        />
      }
    >
      {t('PREV')}
    </PgButton>
  );
};

export const Next = ({
  children: __,
  ...restProps
}: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>) => {
  const { t } = useTranslate('common');
  return (
    <PgButton
      {...restProps}
      variant={'text'}
      color={'inherit'}
      endIcon={<IconSVGArrowRight />}
    >
      {t('NEXT')}
    </PgButton>
  );
};

export const Pagination = ({
  className,
  children,
  show,
}: PropsWithChildren<{ className?: string; show: boolean }>) => {
  if (!show) {
    return null;
  }
  return <PaginationLayout className={className}>{children}</PaginationLayout>;
};
