import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  styled,
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabTypeMap,
} from '@mui/material';
import { TabsTypeMap } from '@mui/material/Tabs/Tabs';
import { ReactComponent as ArrowRight } from '@/images/icons/icon_arrow_right.svg';
import { createEvent } from '@/utils/createEvent';
import { useBreakpoints } from 'ui-kit';

export const TabPanel: FC<
  PropsWithChildren<{ value: number | string; index: number | string }>
> = ({ value, index, children }) => {
  if (value !== index) {
    return null;
  }
  return <>{children}</>;
};

export const useTabs = (
  tab: number | string,
  cb?: (tab: number | string) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const [selectedTabValue, setSelectedTabValue] = useState(tab);
  const onTabChange = useCallback(
    (e: React.SyntheticEvent | null, v: number | string) => {
      cbRef.current?.(v);
      setSelectedTabValue(v);
    },
    []
  );
  useEffect(() => {
    setSelectedTabValue(tab);
  }, [tab]);
  return { index: selectedTabValue, handleChange: onTabChange };
};

// todo: finish the implementation
export const useRouteTabs = (
  tab: number | string,
  cb?: (tab: number | string) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const [selectedTabValue, setSelectedTabValue] = useState(tab);
  const onTabChange = useCallback(
    (e: React.SyntheticEvent | null, v: number | string) => {
      cbRef.current?.(v);
      setSelectedTabValue(v);
    },
    []
  );
  useEffect(() => {
    setSelectedTabValue(tab);
  }, [tab]);
  return { path: selectedTabValue, handleChange: onTabChange };
};

const MuiTabsExtended: FC<
  TabsTypeMap<{ enableMobileView?: boolean; defaultTab?: string }>['props']
> = ({ enableMobileView, defaultTab, children, ...restProps }) => {
  const { md } = useBreakpoints();

  useLayoutEffect(() => {
    if (md && restProps.value === '') {
      restProps.onChange?.(createEvent({ value: defaultTab }), defaultTab);
    }
  }, [defaultTab, md, restProps]);

  if (enableMobileView && !md && restProps.value !== '') {
    return <></>;
  }

  return (
    <MuiTabs
      {...restProps}
      variant={!md && enableMobileView ? 'fullWidth' : restProps.variant}
    >
      {children}
      <MuiTab value={''} classes={{ root: 'invisible' }} />
    </MuiTabs>
  );
};

export const Tabs = styled(MuiTabsExtended)(({ theme, enableMobileView }) => ({
  '--tab-height': '42px',
  '&.MuiTabs-': {
    '&root': {
      minHeight: 'initial',
      overflow: 'initial',
      button: {
        minHeight: 'var(--tab-height)',
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
  },
  button: {
    textTransform: 'none !important',
    fontSize: '16px',
    fontWeight: 500,
    borderBottom: `2px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[700],
    '&.Mui-selected': {
      '&:disabled': {
        color: theme.palette.secondary.main,
      },
    },
  },
  svg: {
    display: 'none',
  },
  '.invisible': {
    overflow: 'hidden',
    width: '1px!important',
    height: '1px!important',
    minWidth: '0!important',
    minHeight: '0!important',
    borderWidth: '0!important',
    padding: '0!important',
    position: 'absolute',
  },
  [theme.breakpoints.down('md')]: enableMobileView
    ? {
        '--tab-height': '56px',
        '.MuiTabs-flexContainer': { flexDirection: 'column', gap: 24 },
        button: {
          alignItems: 'center',
          paddingLeft: '0!important',
          paddingRight: '0',
          justifyContent: 'space-between',
          borderBottomColor: theme.palette.grey[700],
          fontSize: '16px',
          lineHeight: '20px',
          fontWeight: 500,
        },
        svg: {
          display: 'block',
        },
      }
    : {
        '--tab-height': '38px',
      },
}));

export const Tab: FC<TabTypeMap<{}>['props']> = (props) => {
  return (
    <MuiTab
      {...props}
      iconPosition={'end'}
      icon={
        <Box
          component={ArrowRight}
          width={24}
          height={18}
          sx={{ path: { stroke: (t) => t.palette.grey[700] } }}
        />
      }
    />
  );
};

Tabs.defaultProps = {
  indicatorColor: 'secondary',
  textColor: 'secondary',
};
