import React, { PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/material';
import { Scrollbar } from '@/components/Scrollbar';
import { boxPaddings } from '@/components';

export const ScrollContainer = ({
  children,
  scroll,
}: PropsWithChildren<{
  minHeight?: number;
  className?: string;
  scroll: boolean;
}>) => {
  if (!scroll) {
    return <Box flex={'1 1 auto'}>{children}</Box>;
  }
  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Scrollbar
        sx={(t) => ({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          boxSizing: 'border-box',
          ...boxPaddings(t),
        })}
      >
        {children}
      </Scrollbar>
    </Stack>
  );
};
