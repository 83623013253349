import { MutableRefObject, useEffect, useRef } from 'react';

export const useOnClickOutside = (
  refs: Array<MutableRefObject<Element | null>>,
  handler: (e: MouseEvent) => void,
  active: boolean
) => {
  const ref = useRef<any>();
  ref.current = refs;
  const refHandler = useRef(handler);
  refHandler.current = handler;
  useEffect(() => {
    if (!active) {
      return;
    }
    const listener = (event: Event) => {
      if (
        ref.current.some(
          (ref: any) =>
            !ref.current || ref.current.contains(event.target as Node)
        )
      ) {
        return;
      }
      refHandler.current(event as MouseEvent);
    };

    document.addEventListener('mousedown', listener, { passive: true });
    document.addEventListener('touchstart', listener, { passive: true });
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [active]);
};
