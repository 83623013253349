/** @jsxImportSource @emotion/react */
import { css as _, IconButton as MuiIconButton, styled } from '@mui/material';
import { MENU_COLLAPSED_CLASS } from '@/features/layouts/MainLayout/styles';

export const CollapseButton = styled(MuiIconButton)(
  ({ theme: t }) => _`
      display: none;
      border-radius: 50%;
      width: 44px;
      height: 44px;

      &:hover {
          background: ${t.palette.secondary.light};
          opacity: 1;

          path {
              fill: white !important;
          }
      }

      .${MENU_COLLAPSED_CLASS} & {
          display: flex;
          justify-content: center;
          align-items: center;
      }
  `
);
