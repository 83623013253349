/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useCompany, useUpdateDetails } from '@/store/companies/hooks';
import { ADSection } from './ADSection';
import { DescriptionSection } from './DescriptionSection';
import { useCompanyPermissionsValidator } from '@/store/permissions/PermissionsKeeper';
import { CompanyPermissionName } from '@/types';
import { AddModuleButton } from '../module/AddModuleButton';
import { Tab, TabPanel, Tabs, useRouteTabs } from '@/components/TabPanel';
import { useTranslate } from '@/i18n/useTranslate';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { ParamNames, useParams } from '@/routes/appRoutes';
import { Stack } from '@mui/material';
import { Insurance } from '@/features/company-details/insurance/Insurance';
import { ReactComponent as IconSVGKey } from '@/images/icons/icon_key.svg';

import { Button } from '@/components/Button';
import { useADAuthorize } from '@/store/companies/hooks/useADConnect';
import { useBreakpoints } from 'ui-kit';
import { NPIPracticeAddresses } from '@/features/company-addresses/NPIPracticeAddresses';
import { CompanyNpi } from '@/features/company-npi';
import { BackButton } from '@/features/company-details/BackButton';

export enum TabId {
  CompanyName = 'description',
  PracticeAddresses = 'practice-addresses',
  ExternalAuth = 'external-auth',
  InsuranceCatalog = 'insurance',
  NPI = 'npi',
}

export interface TabsContent {
  title: string;
  id: TabId;
  Component: FC;
}
const useTabsContent = (params: { npi?: number }) => {
  const { t } = useTranslate('companies');

  const has = useCompanyPermissionsValidator(true);

  const tabsContent: TabsContent[] = [];

  tabsContent.push({
    title: t('COMPANY_NAME'),
    id: TabId.CompanyName,
    Component: DescriptionSection,
  });
  tabsContent.push({
    title: t('PRACTICE_ADDRESSES'),
    id: TabId.PracticeAddresses,
    Component: NPIPracticeAddresses,
  });
  if (has(CompanyPermissionName.ExternalAuthenticationConfigure)) {
    tabsContent.push({
      title: t('EXTERNAL_AUTHENTICATION'),
      id: TabId.ExternalAuth,
      Component: ADSection,
    });
  }
  if (has(CompanyPermissionName.CompanyManageInsuranceCarrier)) {
    tabsContent.push({
      title: t('INSURANCE_CATALOG'),
      id: TabId.InsuranceCatalog,
      Component: Insurance,
    });
  }

  if (params.npi)
    tabsContent.push({
      title: t('NPI'),
      id: TabId.NPI,
      Component: CompanyNpi,
    });
  return tabsContent;
};

export const CompanyDetailsPage: FC = () => {
  useUpdateDetails();
  const { md } = useBreakpoints();
  const params = useParams();
  const { company } = useCompany(params.companyId);
  const d = useRouteTabs('');
  const { t, ready } = useTranslate('companies');
  const tabs = useTabsContent({ npi: company?.npi });
  const authorize = useADAuthorize({ companyId: params[ParamNames.CompanyId] });
  const showAuthorizeButton = !!company && company?.locked;
  if (!ready) {
    return null;
  }
  return (
    <>
      <CompanyHeader />
      <Tabs
        value={d.path}
        onChange={d.handleChange}
        enableMobileView
        variant={'fullWidth'}
        defaultTab={TabId.CompanyName}
      >
        {tabs.map((tab) => {
          return <Tab label={tab.title} value={tab.id} key={tab.id} />;
        })}
      </Tabs>
      <Stack sx={{ flexGrow: 1 }}>
        {!md ? (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} index={d.path} key={id}>
                  <BackButton onClick={() => d.handleChange(null, '')} />
                  <Component />
                </TabPanel>
              );
            })}
          </>
        ) : (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} index={d.path} key={id}>
                  <Component />
                </TabPanel>
              );
            })}
          </>
        )}
      </Stack>
      {!md && d.path === '' && (
        <>
          <Stack gap={12}>
            <AddModuleButton variant={'contained'} />
            {showAuthorizeButton && (
              <Button
                color={'secondary'}
                onClick={() => authorize()}
                startIcon={<IconSVGKey width={24} />}
              >
                {t('AUTHORIZE')}
              </Button>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
