import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import {
  companiesSettingsActions,
  companiesSettingsStateName,
  CompanySettingsState,
} from '@/store/companies-settings/slice';
import { RootState } from '@/store/store';
import { companiesActions, CompaniesState } from '@/store/companies/slice';
import { produce } from 'immer';
import { client } from '@/api/client/ApiClient';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { getError, getErrors } from '@/store/common/error-handlers';
import { ValidationErrorType } from '@/types/ValidationError';

const stateSelector = (state: RootState) => state[companiesSettingsStateName];

export function* settingsSaga() {
  yield all([
    takeLeading(companiesSettingsActions.submitName, submitName),
    takeLeading(companiesSettingsActions.submitDescription, submitDescription),
  ]);
}
type Action<T extends keyof typeof companiesSettingsActions> = SagaPayload<
  (typeof companiesSettingsActions)[T]
>;

function* submitDescription({
  payload,
}: Action<'submitDescription'>): SagaReturn {
  const state = (yield select(stateSelector)) as CompanySettingsState;
  const newCompanyDescription =
    state.descriptionForm[payload.companyId]?.data || '';
  const companyState = (yield select(
    (rootState: RootState) => rootState.companies
  )) as CompaniesState;

  const oldCompanyData = companyState.companies?.byID[payload.companyId];
  const updatedCompany = oldCompanyData
    ? produce(oldCompanyData, (draft) => {
        draft.description = newCompanyDescription;
      })
    : null;
  if (updatedCompany) {
    yield put(companiesActions.setCompanies({ companies: [updatedCompany] }));
  }
  try {
    if (oldCompanyData?.description === newCompanyDescription) {
      return;
    }
    yield call(client.updateCompanyDescription, payload.companyId, {
      description: newCompanyDescription,
    });
    yield put(
      companiesSettingsActions.toggleEditDescription({
        companyId: payload.companyId,
      })
    );
  } catch (e) {
    yield put(
      companiesSettingsActions.setDescriptionForm({
        companyId: payload.companyId,
        value: oldCompanyData?.description ?? null,
      })
    );
    if (oldCompanyData) {
      yield put(companiesActions.setCompanies({ companies: [oldCompanyData] }));
    }
    const error = getErrors(e);
    if (error.common) {
      showError500OrUnknownToast(e);
      return;
    }
    yield put(
      companiesSettingsActions.setValidationError({
        companyId: payload.companyId,
        name: 'descriptionForm',
        error: getError(e),
      })
    );
  }
}

function* submitName({ payload }: Action<'submitName'>): SagaReturn {
  const state = (yield select(stateSelector)) as CompanySettingsState;
  const newCompanyName = state.nameForm[payload.companyId]?.data || '';
  const companyState = (yield select(
    (rootState: RootState) => rootState.companies
  )) as CompaniesState;

  const oldCompanyData = companyState.companies?.byID[payload.companyId];
  const updatedCompany = oldCompanyData
    ? produce(oldCompanyData, (draft) => {
        draft.name = newCompanyName;
      })
    : null;
  try {
    if (oldCompanyData?.name === newCompanyName) {
      return;
    }
    yield call(client.updateCompanyName, payload.companyId, {
      name: newCompanyName,
    });
    if (updatedCompany) {
      yield put(companiesActions.setCompanies({ companies: [updatedCompany] }));
    }
  } catch (e) {
    if (oldCompanyData) {
      yield put(companiesActions.setCompanies({ companies: [oldCompanyData] }));
    }
    const error = getErrors(e);
    if (error.common) {
      showError500OrUnknownToast(e);
      return;
    }
    yield put(
      companiesSettingsActions.setValidationError({
        companyId: payload.companyId,
        name: 'nameForm',
        error: getError(e, {
          [ValidationErrorType.NOT_BLANK]: 'NAME_CAN_NOT_BE_EMPTY',
        } as Record<ValidationErrorType, string>),
      })
    );
  }
}
