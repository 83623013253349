import { useAppDispatch, useAppSelector } from '@/store/store';
import { useEffect, useMemo } from 'react';
import { insuranceActions } from '@/store/insurance/slice';
import { useParams } from '@/routes/appRoutes';
import { UUID } from '@/types';
import {
  CompanyInsuranceCarrier,
  InsuranceCarrier,
} from '@/api/__generated__/webApi';
import { pathJoin } from '@/utils/pathJoin';
import { EMPTY, envs } from '@/constants';

export const useInitInsuranceCarriers = () => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  useEffect(() => {
    dispatch(insuranceActions.requestAllCarriers({ filter: '' }));
    if (companyId)
      dispatch(insuranceActions.requestCompanyCarriers({ companyId }));
  }, [dispatch, companyId]);
};

export interface CarrierListItem {
  label: string;
  src: string;
  disabled: boolean;
  id: string;
}
export interface CompanyCarrierListItem {
  label: string;
  id: string;
}
export const useInsuranceCarriersActionsAndData = () => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const state = useAppSelector((s) => s.insurance);
  const callbacks = useMemo(() => {
    return {
      addCarrier({ carrierId }: { carrierId: UUID }) {
        if (companyId)
          dispatch(
            insuranceActions.requestAddCarrierToCompany({
              carrierId,
              companyId,
            })
          );
      },
      removeCarrier({ carrierId }: { carrierId: UUID }) {
        if (companyId)
          dispatch(
            insuranceActions.requestRemoveCarrierFromCompany({
              carrierId,
              companyId,
            })
          );
      },
      removeLastCarrier({ carrierId }: { carrierId: UUID }) {
        if (!companyId) {
          return;
        }
        dispatch(
          insuranceActions.requestRemoveCarrierFromCompany({
            carrierId,
            companyId,
          })
        );
      },
      closeRemoveConfirmation() {
        dispatch(insuranceActions.setRemoveConfirmationDialog(false));
      },
      searchCarrier({ filter }: { filter: string }) {
        dispatch(insuranceActions.requestAllCarriers({ filter }));
      },
      loadMore() {
        dispatch(insuranceActions.requestListLoadMore());
      },
    };
  }, [companyId, dispatch]);
  const companyCarriers = companyId
    ? state.carriers.ofCompanies[companyId]
    : undefined;
  const companyCarriersOriginalIds = useMemo(() => {
    const d =
      companyCarriers?.data.allIDs.reduce<
        Record<string, CompanyInsuranceCarrier>
      >((prev, id) => {
        const companyCarrier = companyCarriers?.data.byID[id];
        if (companyCarrier?.insuranceCarrier?.id) {
          prev[companyCarrier.insuranceCarrier.id] = companyCarrier;
        }
        return prev;
      }, {}) ?? {};
    return Object.keys(d);
  }, [companyCarriers?.data.allIDs, companyCarriers?.data.byID]);

  const carriersItems: CarrierListItem[] = useMemo(() => {
    return state.carriers.listOfAll.data.allIDs.map((id) => {
      const data = state.carriers.listOfAll.data.byID[id] as InsuranceCarrier;
      const disabled = companyCarriersOriginalIds.includes(id);
      const src = data.logo
        ? pathJoin(
            envs.PASSPORT_BACKEND,
            `/api/insurance-carriers/logo/${data.logo}`
          )
        : '';
      return {
        id,
        label: data.name ?? EMPTY,
        disabled,
        src,
      };
    });
  }, [
    companyCarriersOriginalIds,
    state.carriers.listOfAll.data.allIDs,
    state.carriers.listOfAll.data.byID,
  ]);

  const companyCarriersItems: CompanyCarrierListItem[] = useMemo(() => {
    return (
      companyCarriers?.data.allIDs.map((id) => {
        const item = companyCarriers.data.byID[id];
        return {
          id,
          label: item?.insuranceCarrier?.name ?? EMPTY,
        };
      }) ?? []
    );
  }, [companyCarriers?.data.allIDs, companyCarriers?.data.byID]);
  return {
    companyCarriersItems,
    carriersItems,
    isFetchingAll: state.carriers.listOfAll.fetching,
    isFetchingCompany: !!companyCarriers?.fetching,
    filter: state.pageInfo.filter,
    removeConfirmationDialog: state.removeConfirmationDialog,
    ...callbacks,
  };
};

export const useCompanyCarriers = () => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const state = useAppSelector((s) => s.insurance);

  const callbacks = useMemo(() => {
    return {
      updateCarriersList() {
        if (!companyId) {
          return;
        }
        dispatch(
          insuranceActions.requestCompanyCarriers({
            companyId,
          })
        );
      },
    };
  }, [companyId, dispatch]);

  return {
    hasInsuranceCarriers: companyId
      ? !!state.carriers.ofCompanies[companyId]?.data.allIDs.length
      : false,
    ...callbacks,
  };
};
