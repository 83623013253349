import { UUID } from '@/types';

export interface NormalizedState<T, K extends number | string = UUID> {
  byID: Partial<Record<K, T>>;
  allIDs: K[];
}

export const createNormalizedState = <
  K extends string | number = string,
  T extends { id: K } = { id: K },
>(
  items: T[]
): NormalizedState<T, K> => {
  const n: NormalizedState<T, K> = {
    byID: {} as Record<K, T>,
    allIDs: [],
  };

  for (const item of items) {
    n.byID[item.id] = item;
    n.allIDs.push(item.id);
  }

  return n;
};

export const updateNormalizedState = <
  K extends string | number,
  T extends { id: K },
>(
  n: NormalizedState<T, K>,
  data: T[],
  insertionIndex?: number
): void => {
  let i = insertionIndex ?? n.allIDs.length;
  for (const item of data) {
    n.byID[item.id] = item;
    if (!n.allIDs.includes(item.id)) {
      n.allIDs.splice(i, 0, item.id);
    }
    i++;
  }
};

export const deleteByIdFromNormalizedState = <K extends string | number, T>(
  n: NormalizedState<T, K>,
  deleteItems: K[]
): void => {
  for (const id of deleteItems) {
    const index = n.allIDs.indexOf(id);
    if (index !== -1) {
      delete n.byID[id];
      n.allIDs.splice(n.allIDs.indexOf(id), 1);
    }
  }
};
