import { BoxSectionData } from './types';
import {
  CompanyData,
  NPI1Data,
  NPI2Data,
  NPIAddress,
  NPIAddressPurposeType,
  NPITaxonomy,
  NPIType,
} from '@/api/__generated__/webApi';
import { useTranslate } from '@/i18n/useTranslate';
import { useMemo } from 'react';
import { formatNumber } from 'ui-kit';
import { formatDate } from '@/components/DateFormat';
import { BIRTH_SEX_ENUM_MAP } from '@/features/profile/ProfileEdit/enumMaps';
import { getName } from '@/utils/getName';

const dummy: BoxSectionData[] = [];
export const useMappedData = (
  data: NPI1Data | NPI2Data | CompanyData | undefined
): BoxSectionData[] => {
  const { t } = useTranslate('companies');

  const sections: BoxSectionData[] = useMemo(() => {
    if (!data || data.type === NPIType.NON_NPI) {
      return dummy;
    }
    const mailingAddress = data.addresses?.find(
      (a) => a.purposeType === NPIAddressPurposeType.MAILING
    );
    const locationAddress = data.addresses?.find(
      (a) => a.purposeType === NPIAddressPurposeType.LOCATION
    );
    const { primary, secondary } = data.taxonomies?.reduce<{
      primary: NPITaxonomy | undefined;
      secondary: NPITaxonomy[];
    }>(
      (acc, item) => {
        if (item.primary) {
          acc.primary = item;
        } else {
          acc.secondary.push(item);
        }
        return acc;
      },
      {
        primary: undefined,
        secondary: [],
      }
    ) ?? {
      primary: undefined,
      secondary: [],
    };

    const getAddressFields = (address: NPIAddress | undefined) => {
      return [
        {
          label: t('ADDRESS_TYPE'),
          value: address?.type,
        },
        {
          label: t('COUNTRY_CODE'),
          value: address?.countryCode,
        },
        {
          label: t('COUNTRY_NAME'),
          value: address?.countryName,
        },
        {
          label: t('ADDRESS_1'),
          value: address?.firstLine,
        },
        {
          label: t('ADDRESS_2'),
          value: address?.secondLine,
        },
        {
          label: t('CITY'),
          value: address?.city,
        },
        {
          label: t('STATE'),
          value: address?.stateCode,
        },
        {
          label: t('POSTAL_CODE'),
          value: address?.postalCode,
        },
        {
          label: t('PHONE_NUMBER'),
          value: formatNumber(address?.telephoneNumber),
        },
        {
          label: t('FAX_NUMBER'),
          value: formatNumber(address?.faxNumber),
        },
        {
          label: t('UPDATE_DATE'),
          value: formatDate(address?.updatedDate),
        },
      ];
    };
    const getMainDataFieldsOrg = (data: NPI2Data) => {
      return [
        {
          label: t('REPLACEMENT_NPI'),
          value: data.replacementId || null,
        },
        {
          label: t('EIN'),
          value: data.replacementId || null,
        },
        {
          label: t('ORGANIZATIONAL_SUBPART'),
          value: data.organizationalSubpart ? t('YES') : t('NO'),
        },
        ...(data.organizationalSubpart
          ? [
              {
                label: t('PARENT_ORGANIZATION_NAME'),
                value: data.parentOrganizationName || null,
              },
              {
                label: t('PARENT_ORGANIZATION_EIN'),
                value: data.parentOrganizationEin || null,
              },
              {
                label: t('PARENT_ORGANIZATION_NPI'),
                value: null,
              },
            ]
          : []),
        {
          label: t('OWNER'),
          value: getName(data),
        },
        {
          label: t('CREDENTIAL'),
          value: data.credential || null,
        },
        {
          label: t('ENUMERATION_DATE'),
          value: formatDate(data.enumerationDate),
        },
        {
          label: t('LAST_UPDATED'),
          value: formatDate(data.lastUpdated),
        },
        {
          label: t('DEACTIVATION_REASON'),
          value: data.deactivationReason,
        },
        {
          label: t('DEACTIVATION_DATE'),
          value: formatDate(data.deactivationDate),
        },
        {
          label: t('REACTIVATION_DATE'),
          value: formatDate(data.reactivationDate),
        },
      ];
    };
    const getMainDataFieldsIndividual = (data: NPI1Data) => {
      return [
        {
          label: t('REPLACEMENT_NPI'),
          value: data.replacementId || null,
        },
        {
          label: t('FIRST_NAME'),
          value: data.firstName,
        },
        {
          label: t('LAST_NAME'),
          value: data.lastName,
        },
        {
          label: t('MIDDLE_NAME'),
          value: data.middleName,
        },
        {
          label: t('NAME_PREFIX'),
          value: data.namePrefix,
        },
        {
          label: t('NAME_SUFFIX'),
          value: data.nameSuffix,
        },
        {
          label: t('CREDENTIAL'),
          value: data.credential || null,
        },
        {
          label: t('GENDER'),
          value: data.birthSex ? BIRTH_SEX_ENUM_MAP[data.birthSex] : null,
        },
        {
          label: t('ENUMERATION_DATE'),
          value: formatDate(data.enumerationDate),
        },
        {
          label: t('LAST_UPDATED'),
          value: formatDate(data.lastUpdated),
        },
        {
          label: t('DEACTIVATION_REASON'),
          value: data.deactivationReason,
        },
        {
          label: t('DEACTIVATION_DATE'),
          value: formatDate(data.deactivationDate),
        },
        {
          label: t('REACTIVATION_DATE'),
          value: formatDate(data.reactivationDate),
        },
      ];
    };
    const getOtherNamesSection = (data: NPI2Data) => {
      return {
        header: t('OTHER_NAMES'),
        columns: {
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
        },
        items: [
          {
            items: data.otherNames?.map((d) => {
              return {
                header: t(d.type) ?? '',
                items: [
                  { label: t('ORGANIZATION_NAME'), value: d.organizationName },
                ],
              };
            }),
          },
        ],
      } as BoxSectionData;
    };
    const getIdentifiersSection = (data: NPI1Data | NPI2Data) => {
      return {
        header: t('IDENTIFIERS'),
        columns: {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
        },
        items: [
          {
            items:
              data.identifiers?.map((d) => {
                return {
                  items: [
                    {
                      label: t('IDENTIFIER'),
                      value: d.identifier,
                    },
                    {
                      label: t('DESCRIPTION'),
                      value: d.description,
                    },
                    {
                      label: t('STATE'),
                      value: d.stateCode,
                    },
                    {
                      label: t('ISSUER'),
                      value: d.issuer,
                    },
                  ],
                };
              }) ?? [],
          },
        ],
      };
    };
    const getTaxonomyFields = (data: NPITaxonomy | undefined) => {
      return [
        {
          label: t('CODE'),
          value: data?.code,
        },
        {
          label: t('DESCRIPTION'),
          value: data?.description,
        },
        {
          label: t('STATE'),
          value: data?.stateCode,
        },
        {
          label: t('LICENSE'),
          value: data?.license,
        },
        {
          label: t('TAXONOMY_GROUP'),
          value: data?.group,
        },
      ];
    };
    const getTaxonomiesSection = () => {
      return {
        header: t('TAXONOMIES'),
        columns: {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
        },
        items: [
          {
            header: t('PRIMARY'),
            items: [
              {
                items: getTaxonomyFields(primary),
              },
            ],
          },
          ...(secondary.length
            ? [
                {
                  header: t('SECONDARY'),
                  items: secondary.map((d) => {
                    return {
                      items: getTaxonomyFields(d),
                    };
                  }),
                },
              ]
            : []),
        ],
      };
    };
    let sections: BoxSectionData[] = dummy;
    if (data.type === NPIType.NPI2) {
      sections = [
        {
          header: t('MAIN_DATA'),
          columns: {
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
          },
          items: [
            {
              items: [
                {
                  header: t('REGISTRATION'),
                  items: getMainDataFieldsOrg(data),
                },
                {
                  header: t('LOCATION_ADDRESS'),
                  items: getAddressFields(locationAddress),
                },
                {
                  header: t('MAILING_ADDRESS'),
                  items: getAddressFields(mailingAddress),
                },
              ],
            },
          ],
        },
        ...(data.otherNames?.length ? [getOtherNamesSection(data)] : []),
        ...(data.identifiers?.length ? [getIdentifiersSection(data)] : []),
        ...(primary ? [getTaxonomiesSection()] : []),
      ];
    } else {
      sections = [
        {
          header: t('MAIN_DATA'),
          columns: {
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
          },
          items: [
            {
              items: [
                {
                  header: t('REGISTRATION'),
                  items: getMainDataFieldsIndividual(data),
                },
                {
                  header: t('LOCATION_ADDRESS'),
                  items: getAddressFields(locationAddress),
                },
                {
                  header: t('MAILING_ADDRESS'),
                  items: getAddressFields(mailingAddress),
                },
              ],
            },
          ],
        },
        ...(data.identifiers?.length ? [getIdentifiersSection(data)] : []),
        ...(primary ? [getTaxonomiesSection()] : []),
      ];
    }
    return sections;
  }, [data, t]);

  return sections;
};
