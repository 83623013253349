import { styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  mt?: number;
  mb?: number;
  pt?: number;
  pb?: number;
  lh?: number;
  fz?: number;
  weight?: number;
}

export const Link = styled(RouterLink)<LinkProps>(
  ({ theme: t, pb, pt, mb, mt, fz, lh, weight }) => ({
    textDecoration: 'none',
    padding: `${pt ?? 0}px 0 ${pb ?? 0}px`,
    margin: `${mt ?? 0}px 0 ${mb ?? 0}px`,
    fontSize: `${fz ?? ''}px`,
    color: t.palette.secondary.main,
    lineHeight: `${lh ?? ''}px`,
    fontWeight: weight ?? '',
    display: 'inline-flex',
    alignItems: 'center',
    outlineColor: t.colors.all.focus,
    outlineWidth: '1px !important',
    svg: { paddingRight: '7px' },
    '&:visited': {
      color: t.palette.secondary.main,
      textDecoration: 'none',
    },
    '&:active': {
      color: t.palette.secondary.dark ?? '',
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      color: t.palette.secondary.light ?? '',
    },
  })
);
Link.defaultProps = {
  fz: 14,
  lh: 20,
  weight: 500,
};
