import { UpdateAddressDialog } from './UpdateAddressDialog';
import { DeleteDialog } from './DeleteDialog';
import React from 'react';

export const Dialogs = () => {
  return (
    <>
      <UpdateAddressDialog />
      <DeleteDialog />
    </>
  );
};
