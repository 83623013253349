import { Stack, styled } from '@mui/material';

export const ButtonsLayout = styled(Stack)(({ theme: t }) => ({
  flexDirection: 'row',
  gap: 24,
  button: {
    [t.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
}));
ButtonsLayout.defaultProps = {
  mt: { xs: 48, sm: 60 },
};
