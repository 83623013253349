import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createNormalizedState,
  deleteByIdFromNormalizedState,
  NormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import {
  CompanyInsuranceCarrier,
  InsuranceCarrier,
} from '@/api/__generated__/webApi';
import { PartialRecord, UUID } from '@/types';
import { globalStateResetAction } from '@/store/common/actions';
interface Fetchable<T> {
  data: T;
  fetching: boolean;
}
export interface InsuranceState {
  carriers: {
    listOfAll: Fetchable<NormalizedState<InsuranceCarrier>>;
    ofCompanies: PartialRecord<
      UUID,
      Fetchable<NormalizedState<CompanyInsuranceCarrier>>
    >;
  };
  removeConfirmationDialog: { carrierId: UUID } | undefined | false;
  pageInfo: {
    hasNext: boolean;
    pageIndex: number;
    filter: string;
  };
}
const initialState: InsuranceState = {
  carriers: {
    listOfAll: { fetching: false, data: createNormalizedState([]) },
    ofCompanies: {},
  },
  removeConfirmationDialog: false,
  pageInfo: {
    hasNext: true,
    pageIndex: 0,
    filter: '',
  },
};

export const slice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    requestAllCarriers(_state, _action: PayloadAction<{ filter: string }>) {},
    requestListLoadMore() {},
    requestCompanyCarriers(_, _action: PayloadAction<{ companyId: UUID }>) {},
    setRemoveConfirmationDialog(
      state,
      action: PayloadAction<{ carrierId: UUID } | false>
    ) {
      state.removeConfirmationDialog = action.payload;
    },
    requestRemoveCarrierFromCompany(
      _state,
      _action: PayloadAction<{ carrierId: UUID; companyId: UUID }>
    ) {},
    requestAddCarrierToCompany(
      _state,
      _action: PayloadAction<{ carrierId: UUID; companyId: UUID }>
    ) {},
    setListOfCarriers(
      state,
      {
        payload,
      }: PayloadAction<{ carriers: InsuranceCarrier[]; replace: boolean }>
    ) {
      if (payload.replace) {
        state.carriers.listOfAll.data = createNormalizedState(payload.carriers);
        state.carriers.listOfAll.fetching = false;
      } else {
        updateNormalizedState(state.carriers.listOfAll.data, payload.carriers);
        state.carriers.listOfAll.fetching = false;
      }
    },
    setCompanyCarriers(
      state,
      {
        payload,
      }: PayloadAction<{
        carriers: CompanyInsuranceCarrier[];
        companyId: UUID;
        replace: boolean;
      }>
    ) {
      const theCarriers = state.carriers.ofCompanies[payload.companyId];
      if (payload.replace || !theCarriers) {
        state.carriers.ofCompanies[payload.companyId] = {
          fetching: false,
          data: createNormalizedState(payload.carriers),
        };
      } else {
        updateNormalizedState(theCarriers.data, payload.carriers);
        theCarriers.fetching = false;
      }
    },
    removeCarrierFromCompany(
      state,
      { payload }: PayloadAction<{ carrierId: UUID; companyId: UUID }>
    ) {
      const data = state.carriers.ofCompanies[payload.companyId]?.data;
      if (data) {
        deleteByIdFromNormalizedState(data, [payload.carrierId]);
      }
    },
    setPageInfo(
      state,
      action: PayloadAction<{
        hasNext: boolean;
        filter: string;
        pageIndex: number;
      }>
    ) {
      state.pageInfo = action.payload;
    },
    setFetching(
      state,
      {
        payload,
      }: PayloadAction<{
        companyId?: UUID;
        main?: boolean;
        isFetching: boolean;
      }>
    ) {
      if (payload.main) {
        state.carriers.listOfAll.fetching = payload.isFetching;
      }
      if (payload.companyId) {
        const companyData = state.carriers.ofCompanies[payload.companyId];
        if (companyData) {
          companyData.fetching = payload.isFetching;
        } else {
          state.carriers.ofCompanies[payload.companyId] = {
            fetching: payload.isFetching,
            data: createNormalizedState([]),
          };
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const insuranceName = slice.name;
export const insuranceReducer = slice.reducer;
export const insuranceActions = slice.actions;
