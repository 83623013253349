/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { ReactComponent as ProfileIcon } from '@/images/icons/icon_profile.svg';
import { Box, styled, css as _, BoxProps } from '@mui/material';
import { ReactComponent as IconSVGPhoto } from './photoUpload.svg';

import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';

const AvatarLayout = styled(Box, shouldNotForwardProps(['square']))<{
  src?: string;
  size?: number;
  square?: boolean;
}>`
  --size: ${({ size }) => size ?? 34}px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  overflow: hidden;
  border-radius: ${({ square }) => (square ? 0 : '50%')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface AvatarProps extends BoxProps {
  src: string | undefined;
  className?: string;
  size?: number;
  square?: boolean;
  emptyIcon?: JSX.Element;
  text?: string;
  uploadIcon?: boolean;
}
export const Avatar = memo(
  ({
    src,
    className,
    size,
    square,
    emptyIcon,
    text,
    uploadIcon,
    ...restProps
  }: AvatarProps) => {
    return (
      <AvatarLayout
        role={'img'}
        className={className}
        src={src}
        square={square}
        size={size}
        css={
          !uploadIcon
            ? undefined
            : _`
        .hover-icon {
          display: none;
        }
        &:hover {
          .hover-icon {
            display: block;
          }
          .abbr-content {
            display: none;
          }
        }
        
      `
        }
        {...restProps}
      >
        {!uploadIcon ? null : (
          <IconSVGPhoto
            className={'hover-icon'}
            css={_`z-index: 1; path {fill: white}`}
          />
        )}
        {text && (
          <Box
            sx={(t) => ({
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              position: 'absolute',
              color: 'white!important',
              fontSize: '24px',
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: t.palette.secondary[600],
            })}
          >
            <span className={'abbr-content'}>
              {text ? getFirstWordsLettersFrom(text) : ''}
            </span>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            background: `url(${src ?? ''}) center center / cover no-repeat`,
          }}
        />
        {!src && !text && (emptyIcon ?? <ProfileIcon />)}
      </AvatarLayout>
    );
  }
);
Avatar.displayName = 'Avatar';

export const getFirstWordsLettersFrom = (txt: string, amount = 2) => {
  return txt
    .trim()
    .split(' ')
    .filter(Boolean)
    .slice(0, amount)
    .map((w) => w.substring(0, 1))
    .join('')
    .toUpperCase();
};
