/** @jsxImportSource @emotion/react */
import React, { FC, FormEvent, useId, useLayoutEffect, useState } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import {
  Button,
  CheckboxControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/components';
import { css as _, Stack, Typography } from '@mui/material';
import { useCookieAcceptanceActions } from './redux/hooks';
import { TheCookies } from './redux/slice';

const initialFormState: TheCookies = {
  required: true,
  operational: true,
  functional: true,
  advertising: true,
};
export const CookieDialog: FC = () => {
  const { t, ready } = useTranslate('policyAndCookies');
  const formId = useId();
  const {
    getAcceptedCookies,
    dialogOpen: open,
    closeDialog,
    submitCookies,
  } = useCookieAcceptanceActions();
  const [state, setState] = useState(initialFormState);

  useLayoutEffect(() => {
    if (open) {
      if (!getAcceptedCookies()) {
        setState({ ...initialFormState });
      } else {
        setState({ ...getAcceptedCookies(), required: true });
      }
    }
  }, [open, getAcceptedCookies]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setState((d) => {
      return {
        ...d,
        [e.target.name]: e.target.checked,
      };
    });
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    submitCookies(state);
    closeDialog?.();
  };

  if (!ready) {
    return null;
  }

  return (
    <Dialog open={open} size={'lg'}>
      <DialogTitle onClose={handleSubmit}>{t('COOKIE_SETTINGS')}</DialogTitle>
      <DialogContent
        sx={(t) => ({ borderBottom: `1px solid ${t.colors.borderLight}` })}
      >
        <Typography variant={'16_24_700'} component={'h2'} marginBottom={18}>
          {t('WE_USE_THE_FOLLOWING_TYPES_OF_COOKIES_ON_')}
        </Typography>
        <Stack
          gap={20}
          css={_`label {height: 42px;}; p {padding: 12px 0;}`}
          component={'form'}
          onSubmit={handleSubmit}
          id={formId}
        >
          <Stack>
            <CheckboxControl
              label={t('ONLY_REQUIRED_COOKIES')}
              checked
              disabled
              onChange={handleChange}
              name={'required'}
            />
            <Typography variant={'14_20_400'} component={'p'}>
              {t('THESE_COOKIES_ARE_REQUIRED_')}
            </Typography>
          </Stack>
          <Stack>
            <CheckboxControl
              label={t('OPERATIONAL_COOKIES')}
              checked={state.operational ?? ''}
              onChange={handleChange}
              name={'operational'}
            />
            <Typography variant={'14_20_400'} component={'p'}>
              {t('OPERATIONAL_COOKIES_COLLECT_INFORMATION_ABOUT_WEBSITE_')}
            </Typography>
          </Stack>
          <Stack>
            <CheckboxControl
              label={t('FUNCTIONAL_COOKIES')}
              checked={state.functional ?? ''}
              onChange={handleChange}
              name={'functional'}
            />
            <Typography variant={'14_20_400'} component={'p'}>
              {t('FUNCTIONAL_COOKIES_ALLOW_THE_WEBSITE_TO_')}
            </Typography>
          </Stack>
          <Stack>
            <CheckboxControl
              label={t('ADVERTISING_COOKIES')}
              checked={state.advertising ?? ''}
              onChange={handleChange}
              name={'advertising'}
            />
            <Typography variant={'14_20_400'} component={'p'}>
              {t('THESE_ARE_USUALLY_THIRD_PARTY_COOKIES_THAT_ARE_USED_TO_')}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end!important' }}>
        <Button color={'secondary'} type={'submit'} form={formId}>
          {t('SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
