import React, { FC } from 'react';
import { TextareaControl, TextareaControlProps } from 'ui-kit';
import { FastFieldProps, Field } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { useTranslate } from '@/i18n/useTranslate';

export const TextAreaControlF: FC<
  Omit<TextareaControlProps, 'name'> & { name: string }
> = (props) => {
  const { t } = useTranslate('common');
  return (
    <Field {...props}>
      {({ field, form, meta }: FastFieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
          immediate: [t('CHARACTER_LIMIT_EXCEEDED') ?? ''],
        });
        return (
          <TextareaControl
            {...props}
            {...field}
            error={e}
            errorMaxSymbols={t('CHARACTER_LIMIT_EXCEEDED')}
          />
        );
      }}
    </Field>
  );
};
