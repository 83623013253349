import { BoxBody, BoxHeader, WhiteBox } from '@/components/WhiteBox';
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import React from 'react';
import { TextareaControl, InputControl } from 'ui-kit';
import { Box, Stack, Typography } from '@mui/material';
import {
  PermissionsKeeper,
  useCompanyPermissionsValidator,
} from '@/store/permissions/PermissionsKeeper';
import { CompanyPermissionName } from '@/types';
import { useSettings } from '@/store/companies-settings/hooks';
import { expandMaxErr } from '@/hooks/useConnectServerValidationErrors';
const DESCRIPTION_MAX_SYMBOLS = 255;
export const DescriptionSection = () => {
  const { t, ready } = useTranslate('companies');

  const {
    name,
    nameIsDraft,
    descriptionIsDraft,
    description,
    submitName,
    submitDescription,
    toggleDescriptionForm,
    handleChange,
    validationErrors,
    hasName,
    hasDescription,
    descriptionFormIsActive,
  } = useSettings();
  const hasPermission = useCompanyPermissionsValidator(true);

  const hasEditDescriptionPermission = hasPermission(
    CompanyPermissionName.CompanyEditDescription
  );

  const hasEditNamePermission = hasPermission(
    CompanyPermissionName.CompanyEditName
  );

  const validateDescriptionForm = () => {
    if ((description?.length ?? 0) <= DESCRIPTION_MAX_SYMBOLS) {
      return true;
    }
    return false;
  };

  if (!ready) {
    return null;
  }
  const fieldsMaxWidth = 624;
  return (
    <Stack gap={{ xs: 16, sm: 24 }}>
      <WhiteBox sx={{ height: { xs: 'auto', sm: '100%!important' } }}>
        <BoxHeader sx={{ pb: { xs: 18, sm: 24 } }}>
          {t('COMPANY_NAME')}
        </BoxHeader>
        <BoxBody>
          {hasEditNamePermission ? (
            <InputControl
              name={'name'}
              value={name}
              sx={{ maxWidth: fieldsMaxWidth }}
              max={80}
              onChange={handleChange}
              error={t(...expandMaxErr(validationErrors.name))}
            />
          ) : (
            <Typography variant={'14_18_500'}>{name}</Typography>
          )}
        </BoxBody>
        <PermissionsKeeper
          show={(hasPermission) =>
            hasPermission(CompanyPermissionName.CompanyEditName)
          }
        >
          <Stack
            mt={{ xs: 42, sm: 48 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Button
              color="primary"
              onClick={submitName}
              key={'button'}
              variant={'outlined'}
              disabled={!nameIsDraft}
            >
              {hasName ? t('UPDATE') : <>{t('ADD')}</>}
            </Button>
          </Stack>
        </PermissionsKeeper>
      </WhiteBox>
      <WhiteBox sx={{ height: { xs: 'auto', sm: '100%!important' } }}>
        <BoxHeader sx={{ pb: { xs: 18, sm: 24 } }}>
          {t('COMPANY_DESCRIPTION')}
        </BoxHeader>
        <BoxBody>
          {descriptionFormIsActive ? (
            <Box
              component={'form'}
              sx={{ width: '100%', height: '100%', display: 'flex' }}
              id={'company_description_form'}
              onSubmit={(e) => {
                e.preventDefault();
                if (validateDescriptionForm()) submitDescription();
              }}
            >
              <TextareaControl
                max={255}
                errorMaxSymbols={t('CHARACTER_LIMIT_EXCEEDED')}
                value={description}
                placeholder={t('ENTER_DESCRIPTION')}
                onChange={handleChange}
                name={'description'}
                error={t(validationErrors.description)}
                sx={{ maxWidth: fieldsMaxWidth }}
              />
            </Box>
          ) : (
            <>
              {hasDescription ? (
                <Box
                  component={'form'}
                  sx={{ width: '100%', height: '100%', display: 'flex' }}
                >
                  <TextareaControl
                    readOnly
                    value={description}
                    sx={{ maxWidth: fieldsMaxWidth }}
                  />
                </Box>
              ) : (
                <Typography
                  variant={'14_20_500'}
                  sx={(t) => ({
                    border: '1px solid transparent',
                    color: t.palette.text.disabled,
                  })}
                  onClick={() =>
                    hasEditDescriptionPermission && toggleDescriptionForm()
                  }
                >
                  {t('YOU_HAVE_NOT_ADDED_A_DESCRIPTION_OF_YOUR_COMPANY_YET')}
                </Typography>
              )}
            </>
          )}
        </BoxBody>

        <PermissionsKeeper
          show={(hasPermission) =>
            hasPermission(CompanyPermissionName.CompanyEditDescription)
          }
        >
          <Stack
            mt={{ xs: 42, sm: 48 }}
            direction={{ xs: 'row' }}
            gap={{ xs: 24, sm: 12 }}
          >
            {descriptionFormIsActive ? (
              <>
                <Button
                  variant="outlined"
                  onClick={toggleDescriptionForm}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  color="primary"
                  disabled={!descriptionIsDraft}
                  type={'submit'}
                  form={'company_description_form'}
                  key={'submit'}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  {t('SAVE')}
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                onClick={toggleDescriptionForm}
                key={'button'}
                variant={'outlined'}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {hasDescription ? t('EDIT') : <>{t('ADD')}</>}
              </Button>
            )}
          </Stack>
        </PermissionsKeeper>
      </WhiteBox>
    </Stack>
  );
};
