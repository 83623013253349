import { Box, Typography, css as _, SxProps, Theme } from '@mui/material';
import { Trans } from 'react-i18next';
import { Link } from '../../components';
import { useTranslate } from '../../i18n/useTranslate';
import { useCookieAcceptanceActions } from './redux/hooks';
import { spreadSx } from '@/utils/spreadSx';

export const CookiesNote = ({
  btnText,
  sx,
}: {
  btnText: string;
  sx?: SxProps<Theme>;
}) => {
  const { t, ready } = useTranslate('policyAndCookies');
  const { openDialog } = useCookieAcceptanceActions();
  const baseUrl =
    window.env?.REACT_APP_PRIVACY_POLICY_BASE_URL ??
    process.env.REACT_APP_PRIVACY_POLICY_BASE_URL;
  if (!ready) {
    return <>...</>;
  }
  return (
    <Box sx={[{ wordBreak: 'break-word', mb: 20 }, ...spreadSx(sx)]}>
      <Typography variant={'14_20_500'}>
        <Trans t={t} values={{ btnText }}>
          OUR_PLATFORM_USES_VARIOUS_TYPES_OF_COOKIES_BY_CLICKING
          <span css={_`white-space: nowrap;`}>BTN_TEXT</span>
          BUTTON_YOU_AGREE
          <Link to={baseUrl + '#/terms/'}>TERMS_OF_USE</Link>
          <Link to={baseUrl + '#/privacy/'}>PRIVACY_POLICY</Link>
          <Link to={baseUrl + '#/cookies/'}>COOKIE_POLICY</Link>
          _AND_
          <Link
            to={'#'}
            onClick={(e) => {
              e.preventDefault();
              openDialog();
            }}
          >
            COOKIE_SETTINGS
          </Link>
        </Trans>
      </Typography>
    </Box>
  );
};
