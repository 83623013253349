import { ISODateString, UUID } from './types';
import {
  Contact,
  NPIAddressPurposeType,
  StateType,
} from '@/api/__generated__/webApi';

export interface AccountDetails<T = Contact> {
  contact?: T;
  email?: string;
  id?: UUID;
  npi?: number;
  phone?: string;
  avatarUrl?: string;
}

export interface CompanySettings {
  description?: string | null;
}
export interface BaseCompany {
  description?: string | null;
  id: string;
  logo?: string;
  logoUrl?: string;
  npi?: number;
  parentNpi?: number;
  locked: null | boolean;
}

/**
 * Object with error message «Internal Server Error»
 */
export interface BaseError {
  /**
   * Error message
   */
  message?: string;
}

export enum NPIAddressType {
  Dom = 'DOM',
  Fgn = 'FGN',
  Mil = 'MIL',
}
export interface NPIAddressElement {
  purposeType: NPIAddressPurposeType;
  type: NPIAddressType;
  city?: string;
  countryCode?: CountryCode;
  countryName?: string;
  faxNumber?: string;
  firstLine?: string;
  lastLine?: string;
  postalCode?: string;
  secondLine?: string;
  stateCode?: StateCode;
  telephoneNumber?: string;
  updatedDate?: ISODateString;
}
export interface CompanyAddressElement {
  address1: string;
  address2: string;
  state: StateType;
  city: string;
  postalCode: string;
  telephoneNumber: string | undefined;
  faxNumber: string | undefined;
  endDate: ISODateString | null;
  invoicesReceiving: boolean;
  id: UUID;
}

export enum CountryCode {
  AE = 'AE',
  AF = 'AF',
  AI = 'AI',
  Ad = 'AD',
  Ag = 'AG',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Az = 'AZ',
  BI = 'BI',
  BT = 'BT',
  BW = 'BW',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bj = 'BJ',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bv = 'BV',
  By = 'BY',
  Bz = 'BZ',
  CA = 'CA',
  CD = 'CD',
  CF = 'CF',
  CM = 'CM',
  CN = 'CN',
  CR = 'CR',
  CS = 'CS',
  Cc = 'CC',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Co = 'CO',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fo = 'FO',
  Fr = 'FR',
  GB = 'GB',
  Ga = 'GA',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gw = 'GW',
  Gy = 'GY',
  HT = 'HT',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Hu = 'HU',
  ID = 'ID',
  IL = 'IL',
  IM = 'IM',
  IR = 'IR',
  Ie = 'IE',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  KM = 'KM',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  LB = 'LB',
  LV = 'LV',
  La = 'LA',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Ly = 'LY',
  MS = 'MS',
  MT = 'MT',
  MX = 'MX',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Mg = 'MG',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mq = 'MQ',
  Mr = 'MR',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  My = 'MY',
  Mz = 'MZ',
  NI = 'NI',
  NP = 'NP',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Nl = 'NL',
  No = 'NO',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  PE = 'PE',
  PG = 'PG',
  PS = 'PS',
  Pa = 'PA',
  Pf = 'PF',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  QA = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  SD = 'SD',
  SE = 'SE',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  VG = 'VG',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  CA = 'CA',
  CT = 'CT',
  Co = 'CO',
  Dc = 'DC',
  De = 'DE',
  FL = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  ID = 'ID',
  IL = 'IL',
  Ia = 'IA',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  MS = 'MS',
  MT = 'MT',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  PR = 'PR',
  Pa = 'PA',
  Pw = 'PW',
  Ri = 'RI',
  SD = 'SD',
  Sc = 'SC',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  VT = 'VT',
  Va = 'VA',
  Vi = 'VI',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Zz = 'ZZ',
}

export interface NPIIdentifierElement {
  description?: string;
  identifier?: string;
  issuer?: string;
  stateCode?: StateCode;
}

export enum NPIType {
  Npi1 = 'NPI1',
  Npi2 = 'NPI2',
}

export enum CodeType {
  AddMobilePhone = 'ADD_MOBILE_PHONE',
  ChangePassword = 'CHANGE_PASSWORD',
  ConfirmMobilePhone = 'CONFIRM_MOBILE_PHONE',
}

export interface Consent {
  type?: ConsentTypeElement;
  value?: boolean;
}

export enum ConsentTypeElement {
  Edit = 'EDIT',
  Result = 'RESULT',
  View = 'VIEW',
}

export interface NPI1OtherNameElement {
  credential?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  prefix?: string;
  suffix?: string;
  type?: NPI1OtherNameTypeEnum;
}

export enum NPI1OtherNameTypeEnum {
  FormerName = 'FORMER_NAME',
  OtherName = 'OTHER_NAME',
  ProfessionalName = 'PROFESSIONAL_NAME',
}

export interface NPI2OtherNameElement {
  organizationName?: string;
  type?: NPI2OtherNameTypeEnum;
}

export enum NPI2OtherNameTypeEnum {
  DoingBusinessAs = 'DOING_BUSINESS_AS',
  FormerLegalBusinessName = 'FORMER_LEGAL_BUSINESS_NAME',
  OtherName = 'OTHER_NAME',
}

/// /////////////////////////////////////////////////////////

/**
 * Base contact document
 */
export interface BaseDocument {
  deleted?: boolean;
  id?: string;
  type?: DocumentTypeEnum;
}

/**
 * Document type
 */
export enum DocumentTypeEnum {
  Passport = 'PASSPORT',
  UsaBirthCertificate = 'USA_BIRTH_CERTIFICATE',
  UsaDriverLicenceIDCard = 'USA_DRIVER_LICENCE_ID_CARD',
  UsaGreenCard = 'USA_GREEN_CARD',
}

/**
 * USA driver licences or id card option type
 */
export enum OptionType {
  Drl = 'DRL',
  Idc = 'IDC',
}

/**
 * Passport
 *
 * Base contact document
 */
export interface PassportDocument extends BaseDocument {
  /**
   * Country code ISO3166-1 alpha-3
   */
  countryCode?: string;
  /**
   * Expiration date
   */
  expirationDate?: ISODateString | null;
  /**
   * Given names
   */
  givenNames?: string | null;
  /**
   * Issued date
   */
  issueDate?: ISODateString;
  /**
   * Passport number
   */
  number?: string;
  /**
   * Surname
   */
  surname?: string;
}

/**
 * USA birth certificate
 *
 * Base contact document
 */
export interface USABCDocument extends BaseDocument {
  /**
   * Given names
   */
  givenNames?: string | null;
  /**
   * issue Date
   */
  issueDate?: ISODateString | null;
  /**
   * Number
   */
  number?: string;
  /**
   * Surname
   */
  surname?: string;
}

/**
 * USA Driver licence or id card
 *
 * Base contact document
 */
export interface USADLIDDocument extends BaseDocument {
  /**
   * Expiration date
   */
  expirationDate?: ISODateString | null;
  /**
   * Given names
   */
  givenNames?: string | null;
  /**
   * Issue date
   */
  issueDate?: ISODateString;
  /**
   * USA Driver licence number
   */
  number?: string;
  optionType?: OptionType;
  /**
   * Surname
   */
  surname?: string;
}

/**
 * USA Green card
 *
 * Base contact document
 */
export interface USAGCDocument extends BaseDocument {
  /**
   * Expiration date
   */
  expirationDate?: ISODateString | null;
  /**
   * Given names
   */
  givenNames?: string | null;
  /**
   * Number
   */
  number?: string;
  /**
   * Resident since
   */
  residentSince?: ISODateString;
  /**
   * Surname
   */
  surname?: string;
}
