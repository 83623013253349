/** @jsxImportSource @emotion/react */
import React, { FC, useId } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { BoxHeader, Button, WhiteBox } from '@/components';
import { Box, css, Typography } from '@mui/material';
import { InputControl } from '@/components/InputControl';
import { useInternalControlled } from '@/hooks/useInternalControlled';
import { ValidationErrorType } from '@/types/ValidationError';

interface FormProps {
  narrowView: boolean;
  dirty: boolean;
  error: ValidationErrorType | undefined | null;
  fetching: boolean;
  header: string;
  description: string;
  handleSubmit: () => void;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: string;
}

export const NPIFormView: FC<FormProps> = ({
  narrowView,
  handleSubmit,
  handleChange: onChange,
  dirty,
  error,
  fetching,
  header,
  description,
  disabled,
  value,
}) => {
  const { internalValue, handleChange } = useInternalControlled({
    onChange,
    value,
  });
  const formId = useId();
  const { t } = useTranslate('profile');
  return (
    <WhiteBox
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100% !important',
      }}
    >
      <BoxHeader mb={12}>{header}</BoxHeader>
      <Typography mb={35} variant={'14_20_400'}>
        {description}
      </Typography>
      <Box
        display={'flex'}
        alignItems={'end'}
        gap={narrowView ? 36 : 24}
        flexDirection={narrowView ? 'column' : 'row'}
      >
        <form
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          css={css`
            width: 100%;
          `}
        >
          <InputControl
            type="number"
            placeholder={'0000000000'}
            onChange={handleChange}
            error={!disabled && dirty && error && t(error)}
            disabled={disabled}
            max={10}
            value={internalValue}
          />
        </form>
        <Button
          fullWidth={narrowView}
          form={formId}
          variant={'outlined'}
          type={'submit'}
          disabled={fetching || Boolean(error && dirty) || disabled}
        >
          {t('REGISTER')}
        </Button>
      </Box>
    </WhiteBox>
  );
};
