/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import { Box, Stack, css as _, SxProps, Theme } from '@mui/material';
import { Button, CheckboxControl } from '@/components';
import { Scrollbar } from '@/components/Scrollbar';
import { useState, FC, useRef } from 'react';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { Link } from '@/components/Link';
import ScrollBar from 'react-perfect-scrollbar';

const animateScrollToApproxY = async (
  where: number | undefined,
  scrollContainer: HTMLElement | undefined
) => {
  if (where == null || !scrollContainer) {
    return;
  }
  const rafAnimate = (cb: () => boolean, resolved: (val?: unknown) => void) => {
    window.requestAnimationFrame(() => {
      const enough = cb();
      if (enough) {
        resolved();
      } else {
        rafAnimate(cb, resolved);
      }
    });
  };
  const speed = 1000;
  let scrolled = scrollContainer.scrollTop ?? 0;
  const safeWhere: number =
    Math.max(Math.min(scrollContainer.scrollHeight, where), 0) ?? 0;
  const up = scrolled > safeWhere;
  const scroll = () => {
    const scrollIsNotNeeded = up
      ? scrolled <= safeWhere
      : scrolled >= safeWhere;
    if (scrollIsNotNeeded) {
      return true;
    }
    scrolled = up ? scrolled - speed : scrolled + speed;
    scrollContainer.scrollTo(0, scrolled);
    return false;
  };
  await new Promise((resolve) => {
    rafAnimate(scroll, resolve);
  });
};

const privacyPolicyStyles: SxProps<Theme> = (t) => ({
  boxSizing: 'border-box',
  overflow: 'hidden',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: '500',
  marginBottom: 20,
  '*': {
    fontSize: { xs: 12, sm: 14 },
    color: t.palette.grey[800],
    lineHeight: '20px',
  },
  'ol, ul': {
    padding: 20,
  },
  h2: {
    fontSize: { xs: 18, sm: 24 },
    lineHeight: { xs: '28px', sm: '32px' },
    fontWeight: { xs: 500, sm: 700 },
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
  },
  h4: {
    marginBottom: 10,
    '&.pp-header4': {
      fontSize: { xs: 12, sm: 14 },
      margin: 0,
      marginBottom: 10,
      marginLeft: 4,
      marginTop: 20,
      display: 'inline-flex',
      gap: 3,
    },
  },
  'p.c3': {
    margin: 0,
    marginBottom: '10px !important',
    marginBlockEnd: '10px',
  },
  '.c6': {
    textDecoration: 'underline',
  },
  '.asterisk-delimiter': {
    display: 'flex',
    fontSize: 30,
    justifyContent: 'center',
    lineHeight: '17px',
    paddingTop: 16,
  },
  'ul, ol': {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
  },
});
export const PrivacyPolicy: FC<{ onNext?: () => void }> = ({ onNext }) => {
  const { t, ready } = useTranslate('policyAndCookies');
  const [agreed, setAgreed] = useState(false);
  const scrollbarContainerRef = useRef<HTMLElement | null>(null);
  const scrollbarRef = useRef<ScrollBar>(null);
  useRecaptcha();
  if (!ready) {
    return null;
  }

  const scrollToBottom = async () => {
    await animateScrollToApproxY(
      scrollbarContainerRef.current?.scrollHeight,
      scrollbarContainerRef.current ?? undefined
    );
    scrollbarRef.current?.forceUpdate();
  };

  return (
    <Box sx={styles.container}>
      <Stack gap={{ xs: 24, lg: 36 }} sx={styles.subContainer}>
        <Box sx={styles.contentBody}>
          <Scrollbar
            containerRef={(el) => (scrollbarContainerRef.current = el)}
            ref={scrollbarRef}
            options={{ minScrollbarLength: 130 }}
            sx={styles.scrollbar}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={privacyPolicyStyles}
                css={_`p:last-of-type {margin-bottom: 0}`}
                dangerouslySetInnerHTML={{ __html: t('PRIVACY_POLICY_TEXT') }}
              ></Box>
              <CheckboxControl
                value={agreed}
                label={t('I_AGREE_TO_THE_ABOVE_RULES_')}
                sx={{ marginBottom: { xs: 0, sm: 10 } }}
                onChange={(e) => {
                  setAgreed(e.target.checked);
                }}
              />
            </Box>
          </Scrollbar>
        </Box>

        <Stack>
          <Button
            sx={{ mb: { xs: 15, lg: 12 } }}
            color={'secondary'}
            disabled={!agreed}
            onClick={onNext}
          >
            {t('NEXT')}
          </Button>
          <Link
            to={''}
            sx={{ justifyContent: 'center' }}
            onClick={(e) => {
              e.preventDefault();
              void scrollToBottom();
            }}
          >
            {t('SCROLL_DOWN_TO_ACCEPT_THE_RULES_')}
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

const styles = {
  container: { width: '100%', height: '100%', position: 'relative' },
  subContainer: {
    width: '100%',
    height: '100%',
    minHeight: 200,
    justifyContent: 'space-between',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
  },
  contentBody: (t: Theme) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  }),
  scrollbar: { flex: '1 1 auto', maxHeight: '100%', paddingRight: 10 },
};
