import {
  generatePath as generatePathReactRouterDom,
  useParams as useParamsReactRouterDom,
} from 'react-router-dom';
import { UUID } from '@/types';
import { ModuleType } from '@/api/__generated__/webApi';
export enum ParamNames {
  CompanyId = 'companyId',
  RoleId = 'roleId',
  ModuleId = 'moduleId',
  Module = 'module',
  Tab = 'tabName',
  Code = 'code',
  CodeType = 'codeType',
  SettingsType = 'settingsType',
}
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type UrlParams = {
  [ParamNames.CompanyId]: UUID; // company UUID
  [ParamNames.RoleId]: string;
  [ParamNames.Module]: string; // ModuleType -> ModuleTypeToUrlsMap
  [ParamNames.ModuleId]: string;
  [ParamNames.Tab]: string;
  [ParamNames.Code]: string;
  [ParamNames.CodeType]: string;
  [ParamNames.SettingsType]: string;
};

export const AppRoutes = {
  PASS: '/auth/pass',

  CODE: '/auth/code',

  PROFILE: '/profile',

  PROFILE_EDIT: '/profile/edit',

  PROFILE_PHONE_EMAILS: `/profile/:${ParamNames.Tab}`,

  PROFILE_SECURITY: '/profile/security',

  PROFILE_SUPPORT: '/profile/support',

  COMPANY_DETAILS: `/companies/:${ParamNames.CompanyId}/details/:${ParamNames.SettingsType}?`,

  COMPANY_USERS_CONTROL: `/companies/:${ParamNames.CompanyId}/users`,

  COMPANY_USERS_CONTROL_INVITE: `/companies/:${ParamNames.CompanyId}/users/invite`,

  COMPANY_USERS_CONTROL_CREATE: `/companies/:${ParamNames.CompanyId}/users/create`,

  COMPANY_MODULES: `/companies/:${ParamNames.CompanyId}/:${ParamNames.Module}/:${ParamNames.ModuleId}`,

  COMPANY_MODULES_USERS: `/companies/:${ParamNames.CompanyId}/:${ParamNames.Module}/:${ParamNames.ModuleId}/users/:${ParamNames.RoleId}`,

  COMPANY_MODULES_PERMISSIONS: `/companies/:${ParamNames.CompanyId}/:${ParamNames.Module}/:${ParamNames.ModuleId}/permissions/:${ParamNames.RoleId}`,

  COMPANY_MODULES_PERMISSIONS_EDIT: `/companies/:${ParamNames.CompanyId}/:${ParamNames.Module}/:${ParamNames.ModuleId}/permissions/:${ParamNames.RoleId}/editable`,

  AUTH: '/auth/welcome',

  RECOVERY: '/auth/recovery',

  ACTIVATION: '/auth/activation',

  REGISTRATION: '/auth/registration',

  SHORT_URL: `/s/:${ParamNames.CodeType}`,

  EXTERNAL_APP: `/open-external/:${ParamNames.CompanyId}/:${ParamNames.Module}`,
} as const;

export const useParams = () => useParamsReactRouterDom<UrlParams>();
export const generatePath = (
  path: (typeof AppRoutes)[keyof typeof AppRoutes],
  params: Partial<UrlParams>
) => generatePathReactRouterDom(path, params as UrlParams);
export const ModuleTypeToUrlsMap: Record<ModuleType, string> = {
  [ModuleType.PROFESSIONAL]: 'professional',
  [ModuleType.LIS]: 'laboratory',
  [ModuleType.PASSPORT]: 'passport',
};
export const UrlToModuleTypeMap: Record<string, ModuleType> = {
  [ModuleTypeToUrlsMap[ModuleType.PROFESSIONAL]]: ModuleType.PROFESSIONAL,
  [ModuleTypeToUrlsMap[ModuleType.LIS]]: ModuleType.LIS,
  [ModuleTypeToUrlsMap[ModuleType.PASSPORT]]: ModuleType.PASSPORT,
};
