import React, { FC } from 'react';
import { Tooltip } from 'ui-kit';
import { tooltipClasses, TooltipProps } from '@mui/material';
import { ReactComponent as IconSVGWarning } from '@/images/icons/icon_round_info_outlined.svg';
import { IconContainer } from '@/components/IconContainer';
export const AlertSign: FC<{
  title: string;
  className?: string;
  placement?: TooltipProps['placement'];
}> = ({ title, className, placement }) => {
  return (
    <Tooltip
      placement={placement ?? 'bottom'}
      title={title ?? ''}
      sx={styles[tooltipClasses.tooltip]}
    >
      <IconContainer sx={styles.iconContainer} className={className}>
        <IconSVGWarning />
      </IconContainer>
    </Tooltip>
  );
};

const styles = {
  iconContainer: { cursor: 'pointer' },
  [tooltipClasses.tooltip]: { maxWidth: 200 },
};
