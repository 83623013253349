import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalStateResetAction } from '@/store/common/actions';
import { PartialRecord } from '@/types';
import { ValidationErrorType } from '@/types/ValidationError';
import { NPI2ShortData } from '@/api/__generated__/webApi';

export type RequestNPIOrgFlowSteps =
  | 'init'
  | 'enter-main-npi-of-subpart'
  | 'is-that-yours'
  | 'is-that-yours-subpart'
  | 'enter-subdomain'
  | 'enter-subdomain-subpart'
  | 'success';
export interface CompanyNPIState {
  npi: NPI2ShortData | null;
  parentNpiNumber: string | null;
  parentNPI: NPI2ShortData | null;
  npiNumber: string | null;
  fetching: boolean;
  errors: PartialRecord<'common' | string, ValidationErrorType | null> | null;
  requestSuccess: boolean;
  step: RequestNPIOrgFlowSteps;
}
const initialState: CompanyNPIState = {
  npi: null,
  parentNPI: null,
  parentNpiNumber: null,
  npiNumber: null,
  fetching: false,
  errors: null,
  requestSuccess: false,
  step: 'init',
};
export const slice = createSlice({
  name: 'companyNpi',
  initialState,
  reducers: {
    requestAccountNpiData() {},
    requestNPIInfo(
      state,
      action: PayloadAction<{ npi: string; parent?: boolean }>
    ) {
      if (action.payload.parent) {
        state.parentNpiNumber = action.payload.npi;
      } else {
        state.npiNumber = action.payload.npi;
      }
    },
    requestConfirm(state, _action: PayloadAction<{ thDomain: string }>) {
      state.errors = null;
    },
    setStep(
      state,
      { payload }: PayloadAction<{ step: RequestNPIOrgFlowSteps }>
    ) {
      state.step = payload.step;
    },
    setNpiInfo(
      state,
      action: PayloadAction<{ npi: NPI2ShortData | null; parent?: boolean }>
    ) {
      if (action.payload.parent) {
        state.parentNPI = action.payload.npi;
        if (!action.payload.npi) {
          state.parentNpiNumber = null;
        }
        state.step = 'is-that-yours-subpart';
      } else {
        state.npi = action.payload.npi;
        if (!action.payload.npi) {
          state.npiNumber = null;
        }
        state.step = action.payload.npi?.organizationalSubpart
          ? 'enter-main-npi-of-subpart'
          : 'is-that-yours';
      }
    },
    setErrors(
      state,
      action: PayloadAction<PartialRecord<
        'common',
        ValidationErrorType | null
      > | null>
    ) {
      state.errors = action.payload;
    },
    resetErrors(state) {
      state.errors = null;
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setRequestSuccess(state, { payload }: PayloadAction<boolean>) {
      state.requestSuccess = payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const companyNpiStateName = slice.name;
export const companyNpiReducer = slice.reducer;
export const companyNpiActions = slice.actions;
