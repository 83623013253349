import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

export enum Language {
  EN_US = 'en-US',
  EN_GB = 'en-GB',
  ES_US = 'es-US',
}

void i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN_US,
    lng: Language.EN_US,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'validationErrors'],
    defaultNS: ['common', 'validationErrors'],
    fallbackNS: ['common', 'validationErrors'],
    preload: ['en-US'],
    react: {
      useSuspense: false,
    },
  });

export default i18n;
