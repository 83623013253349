export const createEvent = <T, D = never>({
  value,
  name,
}: {
  value?: string | number | boolean | null | D;
  name?: string;
  type?: string;
}): T => {
  return { target: { value, name } } as unknown as T;
};
