import { createSlice } from '@reduxjs/toolkit';
export interface TheCookies {
  required: boolean;
  operational: boolean;
  functional: boolean;
  advertising: boolean;
}
const initialState: { dialog: boolean } = {
  dialog: false,
};
export const cookiesSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    openDialog(state) {
      state.dialog = true;
    },
    closeDialog(state) {
      state.dialog = false;
    },
  },
});

export const cookieActions = cookiesSlice.actions;
export const cookieStateName = cookiesSlice.name;
export const cookieReducer = cookiesSlice.reducer;
