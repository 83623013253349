/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { DateTime, Info } from 'luxon';
import { Box } from '@mui/material';
import { DateButton } from './YearArea';
import { useDatePickerContext } from '@/components/DateControl/DateInput/useDatePickerContext';

export const MonthsArea: FC<{
  onChange: (d: DateTime) => void;
  date: DateTime;
  selected?: DateTime | null;
}> = ({ date, selected, onChange }) => {
  const now = DateTime.now();
  const isCurrentYear = date.hasSame(now, 'year');
  const { disablePastDates, disableFutureDates } = useDatePickerContext();
  const getMonth = (longMonthName: string) =>
    date.set({ month: Info.months('long').indexOf(longMonthName) + 1 });
  const disablePast = (m: string) => {
    if (!disablePastDates) {
      return false;
    }
    const monthDate = getMonth(m).endOf('month');
    return monthDate < now.startOf('month');
  };
  const disableFuture = (m: string) => {
    if (!disableFutureDates) {
      return false;
    }
    const monthDate = getMonth(m).startOf('month');
    return monthDate > now.endOf('month');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: 24,
        margin: '0 -4px',
        alignSelf: 'center',
      }}
    >
      {Info.months('long').map((m) => {
        return (
          <DateButton
            key={m}
            sx={{ width: 'calc(100% / 3)' }}
            active={selected?.monthLong === m && date.year === selected?.year}
            current={isCurrentYear && now.monthLong === m}
            disabled={disableFuture(m) || disablePast(m)}
            onClick={() => {
              onChange(getMonth(m));
            }}
          >
            {m}
          </DateButton>
        );
      })}
    </Box>
  );
};
