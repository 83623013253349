import {
  RegistrationParams,
  SendCodeParams,
  Space,
} from '@/features/authentication/common/types';
import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { activationApi, authApi, ConfirmResponse } from '@/api';
import { identityClient } from '@/api/client/ApiClient';
import { HttpStatusCode, isAxiosError } from 'axios';
import { MFAType } from '@/api/__generated__/identityApi';

type LoginRequestResponse =
  | {
      type: 'simple';
    }
  | {
      type: 'confirmation-required';
      resendTime: number;
    }
  | {
      type: 'mfa-type-required';
      availableMfaTypes: MFAType[];
    };
export const sendLoginRequest = async (params: {
  phone: string;
  password: string;
  mfaType?: MFAType;
}): Promise<LoginRequestResponse> => {
  const recaptcha = await getRecaptchaToken();
  try {
    await identityClient.signInMfa({ ...params, recaptcha });
    return { type: 'simple' };
  } catch (e) {
    if (isAxiosError(e)) {
      switch (e.response?.status) {
        case HttpStatusCode.Conflict: {
          const availableMfaTypes: MFAType[] =
            e.response.data.availableMfaTypes;
          return {
            type: 'mfa-type-required',
            availableMfaTypes,
          };
        }
        case HttpStatusCode.PreconditionRequired:
          return {
            type: 'confirmation-required',
            resendTime: e.response.data.resendTime,
          };
      }
    }
    return await Promise.reject(e);
  }
};

export const sendRegistrationRequest = async (
  params: Omit<RegistrationParams, 'recaptcha'>
) => {
  const recaptcha = await getRecaptchaToken();
  return await authApi.register({ ...params, recaptcha });
};
export const sendCheckAccountRequest = async (
  params:
    | { phone: string; selected: 'phone' }
    | { email: string; selected: 'email' }
) => {
  const recaptcha = await getRecaptchaToken();
  if (params.selected === 'phone') {
    await authApi.checkAccountExists({ phone: params.phone, recaptcha });
  } else {
    await authApi.checkAccountExistsEmail({ email: params.email, recaptcha });
  }
};
export const sendNewPasswordRequest = async (
  params:
    | { selected: 'phone'; phone: string; password: string }
    | { selected: 'email'; email: string; password: string }
) => {
  const recaptcha = await getRecaptchaToken();
  if (params.selected === 'phone') {
    return await authApi.sendNewPassword({ ...params, recaptcha });
  } else {
    return await authApi.sendNewPasswordEmail({ ...params, recaptcha });
  }
};
export const sendCodeRequest = async (
  params: SendCodeParams,
  space: Space
): Promise<undefined | ConfirmResponse> => {
  switch (space) {
    case Space.Mfa:
      await identityClient.confirmSignInMfa({
        code: params.code,
        mfaType: params.mfaType ?? MFAType.SMS,
        phone: params.phone ?? '',
      });
      break;
    case Space.Login:
      await identityClient.confirmSignInMfa({
        code: params.code,
        mfaType: MFAType.SMS,
        phone: params.phone ?? '',
      });
      break;
    case Space.Registration:
      await authApi.sendRegistrationCode(params);
      break;
    case Space.Recovery:
      if (params.phone) {
        await authApi.sendRecoveryCode({
          phone: params.phone,
          code: params.code,
        });
      } else {
        await authApi.sendRecoveryCodeEmail({
          email: params.email as string,
          code: params.code,
        });
      }
      break;
    case Space.Activation:
      if (params.inviteCode) {
        return await activationApi.sendPinCode(
          params.inviteCode ?? '',
          params.code,
          params.phone ?? ''
        );
      } else {
        throw new Error('Invite code not provided');
      }
    default:
      throw new Error(`Unknown space: ${space as string}`);
  }
};

export const logout = () => {
  return authApi.logout();
};
