import { FC, ReactNode } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';
import { spreadSx } from '@/utils/spreadSx';
import { styles } from './styles';

export const Nothing: FC<{
  children: ReactNode;
  lh?: number;
  sx?: SxProps<Theme>;
}> = ({ children, lh, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={[
        styles.text,
        {
          lineHeight: lh ? `${lh}px` : '20px',
        },
        ...spreadSx(sx),
      ]}
    >
      {children}
    </Typography>
  );
};
