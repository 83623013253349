import { Box, Stack, Theme, Typography } from '@mui/material';
import { useTranslate } from '@/i18n/useTranslate';
import { FC, useState } from 'react';
import { Button, Toggle } from 'ui-kit';
import { Img } from './Img';
import qrImage from '@/images/qr_example.png';

export const Card: FC<{
  description: string | undefined;
  url: string | undefined;
  onClick: VoidFunction;
}> = ({ description, url, onClick }) => {
  const { t } = useTranslate('profile');
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  return (
    <Stack
      tabIndex={0}
      className={'card'}
      sx={styles.container}
      onClick={onClick}
    >
      <Stack tabIndex={0} className={'sub-card'} sx={styles.subCard}>
        <Box className={'bg'} sx={styles.bg} />
        {qrCodeVisible ? (
          <Stack sx={{ position: 'relative' }}>
            <Img src={url} />
          </Stack>
        ) : (
          <Stack sx={{ position: 'relative' }}>
            <Typography
              component={'h4'}
              sx={{ typography: { xs: '16_24_700' } }}
              mb={{ xs: 12 }}
            >
              {t('DESCRIPTION')}
            </Typography>
            <Typography sx={{ typography: { xs: '14_18_500' } }}>
              {description}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        sx={styles.footer}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          setQrCodeVisible((b) => !b);
        }}
      >
        <Toggle labelLeft={t('SHOW_QR_CODE')} checked={qrCodeVisible} />
      </Stack>
    </Stack>
  );
};

export const CardButton: FC<{ onClick: VoidFunction }> = ({ onClick }) => {
  const { t } = useTranslate('profile');
  return (
    <Stack
      tabIndex={0}
      className={'card'}
      sx={styles.container}
      onClick={onClick}
    >
      <Stack tabIndex={0} className={'sub-card'} sx={styles.subCard}>
        <Box className={'bg'} sx={styles.bg} />
        <Stack sx={{ position: 'relative', opacity: 0.2 }}>
          <Img src={qrImage} />
        </Stack>
      </Stack>
      <Stack sx={styles.footer} direction={'row'}>
        <Button>{t('MAKE_NEW')}</Button>
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    width: { xs: 240 },
    height: { xs: 318 },
    position: 'relative',
    flexShrink: 0,
    // border: (t: Theme) => `1px solid ${t.palette.grey[400]}`,
    justifyContent: 'space-between',
    backgroundColor: (t: Theme) => t.palette.common.white,
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '.sub-card.sub-card:active &': {
      backgroundColor: (t: Theme) => t.palette.secondary[300],
      border: (t: Theme) => `2px solid ${t.palette.secondary[500] ?? ''}`,
    },
    border: (t: Theme) => `1px solid ${t.palette.grey[400] ?? ''}`,
    '@media(hover: hover)': {
      '.card:hover &': {
        border: (t: Theme) => `1px solid ${t.palette.secondary[400] ?? ''}`,
        backgroundColor: (t: Theme) => t.palette.secondary[200],
        cursor: 'pointer',
      },
    },
  },
  subCard: {
    padding: { xs: 18 },
    minHeight: 100,
    flexShrink: 1,
    overflow: 'hidden',
    height: 'auto',
    flexGrow: 1,
    '@media(hover:hover)': {
      cursor: 'pointer',
    },
  },
  footer: { padding: { xs: 18 }, position: 'relative' },
};
