import { FC, PropsWithChildren } from 'react';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import { Scrollbar } from './Scrollbar';
import { spreadSx } from '@/utils/spreadSx';
import { SxProps, Theme } from '@mui/material';

interface InfiniteScrollContainerProps {
  onLoad?: () => void;
  threshold?: number;
  className?: string;
  maxHeight?: string;
  sx?: SxProps<Theme>;
}

export const InfiniteScrollContainer: FC<
  PropsWithChildren<InfiniteScrollContainerProps>
> = ({ children, maxHeight, onLoad, threshold, className, sx }) => {
  const { setContainer } = useInfiniteScroll({
    onLoad,
    threshold: threshold ?? 2000,
  });
  return (
    <Scrollbar
      className={className}
      sx={[
        { minHeight: 100, height: '100%', maxHeight: maxHeight || 500 },
        ...spreadSx(sx),
      ]}
      containerRef={setContainer}
    >
      {children}
    </Scrollbar>
  );
};
