import { useTranslate } from '@/i18n/useTranslate';
import { useMemo } from 'react';
import { formatDate } from '@/components/DateFormat';
import { formatNumber } from 'ui-kit';
import { BIRTH_SEX_ENUM_MAP } from '@/features/profile/ProfileEdit/enumMaps';
import { NPI1ShortData } from '@/api/__generated__/webApi';
import { DlItem } from '@/types/DlItem';

export const useSectionsOfNpiData = (
  npiData: NPI1ShortData | null | undefined
) => {
  const { t } = useTranslate('profile');

  const data: Record<
    'common' | 'address' | 'additional',
    NPIDataMapped
  > | null = useMemo(() => {
    if (!npiData) {
      return null;
    }
    return {
      common: {
        title: t('NPI'),
        items: [
          { label: t('REPLACEMENT_NPI'), value: npiData.replacementId },
          { label: t('FIRST_NAME'), value: npiData.firstName },
          { label: t('LAST_NAME'), value: npiData.lastName },
          { label: t('MIDDLE_NAME'), value: npiData.middleName },
          { label: t('NAME_PREFIX'), value: npiData.namePrefix },
          { label: t('NAME_SUFFIX'), value: npiData.nameSuffix },
          { label: t('CREDENTIAL'), value: npiData.credential },
          {
            label: t('GENDER'),
            value: npiData.birthSex
              ? BIRTH_SEX_ENUM_MAP[npiData.birthSex]
              : null,
          },
          {
            label: t('ENUMERATION_DATE'),
            value: formatDate(npiData.enumerationDate),
          },
          {
            label: t('LAST_UPDATED'),
            value: formatDate(npiData.lastUpdated),
          },
        ],
      },
      address: {
        title: t('NPI_ADDRESS'),
        items: [
          {
            label: t('COUNTRY_CODE'),
            value: npiData.locationAddress?.countryCode,
          },
          {
            label: t('COUNTRY_NAME'),
            value: npiData.locationAddress?.countryName,
          },
          {
            label: t('ADDRESS_1'),
            value: npiData.locationAddress?.firstLine,
          },
          {
            label: t('ADDRESS_2'),
            value: npiData.locationAddress?.secondLine,
          },
          {
            label: t('CITY'),
            value: npiData.locationAddress?.city,
          },
          {
            label: t('STATE'),
            value: npiData.locationAddress?.stateCode,
          },
          {
            label: t('POSTAL_CODE'),
            value: npiData.locationAddress?.postalCode,
          },
          {
            label: t('PHONE_NUMBER'),
            value: formatNumber(npiData.locationAddress?.telephoneNumber),
          },
          {
            label: t('FAX_NUMBER'),
            value: formatNumber(npiData.locationAddress?.faxNumber),
          },
        ],
      },
      additional: {
        title: t('NPI_TAXONOMY'),
        items: [
          { label: t('CODE'), value: npiData.primaryTaxonomy?.code },
          {
            label: t('DESCRIPTION'),
            value: npiData.primaryTaxonomy?.description,
          },
          { label: t('STATE'), value: npiData.primaryTaxonomy?.stateCode },
          { label: t('LICENSE'), value: npiData.primaryTaxonomy?.license },
        ],
      },
    };
  }, [npiData, t]);
  return data;
};

export interface NPIDataMapped {
  title: string;
  items: DlItem[];
}
