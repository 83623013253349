import { FC, PropsWithChildren } from 'react';
import { css, styled, SxProps } from '@mui/material';

const StyledTd = styled('td')<{ w?: string | number | undefined }>(
  ({ w }) => css`
    padding: 4px 10px;
    min-width: ${w ? `${w}px` : 'initial'};
    max-width: ${w ? `${w}px` : 'initial'};
  `
);
interface TdProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  className?: string;
  width?: number;
}
export const Td: FC<PropsWithChildren<TdProps & SxProps>> = ({
  children,
  className,
  width,
  ...restProps
}) => {
  return (
    <StyledTd className={className} w={width} {...restProps}>
      {children}
    </StyledTd>
  );
};
//
