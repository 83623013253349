import { useCallback, useEffect, useRef, useState } from 'react';

export type ChangeEventHandler<T = string> = (e: {
  target: { name: string; value: T };
}) => void;

export const createEvent = <T, D = never>({
  value,
  name,
  type,
}: {
  value?: string | number | boolean | null | D;
  name?: string;
  type?: string;
}): T => {
  return { target: { value, name } } as unknown as T;
};

// allows to have control over input in a controlled manner but
// don't have echo if the external value does not update fast
export const useInternalControlled = <T = string | number,>({
  onChange,
  value,
}: {
  onChange?: ChangeEventHandler<T> | React.ChangeEventHandler<HTMLInputElement>;
  value?: T | null | undefined;
}) => {
  const [internalValue, setInternalValue] = useState<T | null>(null);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value ?? null);
    }
    // ignore: internalValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const cbRef = useRef(onChange);
  cbRef.current = onChange;
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as T;
    const name = e.target.name;
    cbRef.current?.(createEvent({ name, value }));
    setInternalValue(value);
  }, []);
  return { handleChange, internalValue };
};
