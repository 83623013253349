import { FC, useEffect, useRef } from 'react';
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/auth/slice';
import { Space } from '../../types';
import { useMinutesCountDown } from '@/hooks/useMinutesCountdown';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { AppRoutes } from '@/routes/appRoutes';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';

export const PinCodeExpiredFragment: FC<{ space: Space }> = ({ space }) => {
  const { t } = useTranslate('auth');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToFirstStep = () => dispatch(authActions.goToStep('index'));
  const handleBack = () => {
    if (space === Space.Recovery) {
      navigate({
        pathname: AppRoutes.REGISTRATION,
        search: window.location.search,
      });
    }
    goToFirstStep();
  };
  const timeTenSecondsInFuture = useRef(Date.now() + 10 * 1000);
  const counter = useMinutesCountDown(timeTenSecondsInFuture.current);
  let redirectText = '';
  let buttonText = '';
  let headerText = t('OOPS');
  switch (space) {
    case Space.Registration:
      redirectText = t('YOU_LL_BE_REDIRECTED_TO_REG_PAGE');
      buttonText = t('OK');
      break;
    case Space.Login:
      redirectText = t('YOU_LL_BE_REDIRECTED_TO_LOGIN_PAGE');
      buttonText = t('OK');
      break;
    case Space.Recovery:
      redirectText = t('YOU_LL_BE_REDIRECTED_TO_REG_PAGE');
      buttonText = t('OK');
      headerText = t('OOPS');
      break;
    case Space.Activation:
      redirectText = t('YOU_WILL_BE_REDIRECTED_TO_ACCOUNT_ACTIVATION');
      buttonText = t('OK');
      break;
  }

  const handleBackRef = useRef(handleBack);
  handleBackRef.current = handleBack;
  useEffect(() => {
    if (!counter) {
      handleBackRef.current();
    }
  }, [counter]);

  return (
    <Stack justifyContent={{ xs: 'space-between', sm: 'start' }} width={'100%'}>
      <Stack width={'100%'} sx={{ flex: { sm: '1 1 auto' } }}>
        <AuthHeader
          title={headerText}
          sx={{ mb: { xs: 36, sm: 48, lg: 64 } }}
        />
        <Typography
          sx={{
            mb: { xs: 230, sm: 356, lg: 305 },
            typography: { xs: '14_18_500', sm: '16_20_500' },
          }}
        >
          {t('YOU_HAVE_SPENT_ALL_ATTEMPTS')}&nbsp;
          {redirectText}&nbsp;{counter}
        </Typography>
      </Stack>
      <Button fullWidth color={'secondary'} onClick={handleBack}>
        {buttonText}
      </Button>
    </Stack>
  );
};
