import { FormControlLabel, Radio, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';
import { spreadSx } from '@/utils/spreadSx';

const styles = {
  controlLabel: {
    minWidth: 0,
    maxWidth: '100%',
    marginRight: 0,
    '.MuiFormControlLabel-label': {
      fontSize: '14px',
      lineHeight: '30px',
      paddingLeft: 4,
      minWidth: 30,
    },
    '.MuiRadio-root': {
      marginLeft: -5,
      padding: 5,
      svg: { fill: (t: Theme) => t.palette.secondary.main },
    },
  },
  radio: {
    '&.Mui-disabled': {
      'svg, svg path': {
        fill: (t: Theme) => t.palette.grey[400],
      },
    },
  },
};
interface RadioControlProps {
  label?: React.ReactNode;
  value?: string;
  disabled?: boolean;
  className?: string;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
  name?: string;
  sx?: SxProps<Theme>;
}
export const RadioControl: FC<RadioControlProps> = ({
  label,
  value,
  disabled,
  className,
  checked,
  onClick,
  name,
  sx,
}) => {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      className={className}
      sx={[styles.controlLabel, ...spreadSx(sx)]}
      control={
        <Radio
          color="secondary"
          size="small"
          sx={styles.radio}
          disabled={disabled}
        />
      }
      label={label}
      checked={checked}
      onClick={onClick}
      name={name}
    />
  );
};
