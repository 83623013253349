/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useCompanyNPIForm, useNPIStateOrg } from '@/store/npi-org/hooks';
import { NPIFormView } from '../shared/NPIFormView';
import { useNPIStateIndividual } from '@/store/npi-individual/hooks';

export const NPIFormContainer: FC<{ narrowView: boolean }> = ({
  narrowView,
}) => {
  const { t } = useTranslate('profile');
  const [internalValue, setInternalValue] = useState('');
  const {
    handleChangeNPI: handleChange_,
    handleSubmitNPI: handleSubmit,
    error,
    fetching,
    dirty,
  } = useCompanyNPIForm();
  const { step } = useNPIStateOrg();
  const { step: stepInd } = useNPIStateIndividual();
  const indNpiProcessIsActive = stepInd === 'registration';
  const orgNpiProcessIsActive = step !== 'init';
  useEffect(() => {
    if (step === 'init') {
      setInternalValue('');
    }
  }, [step]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      handleChange_(e);
      setInternalValue(e.target.value);
    },
    [handleChange_]
  );

  return (
    <NPIFormView
      disabled={indNpiProcessIsActive || orgNpiProcessIsActive}
      narrowView={narrowView}
      value={internalValue}
      dirty={dirty}
      error={error}
      fetching={fetching}
      header={t('HEALTHCARE_ORGANIZATION_REGISTRATION')}
      description={t('YOU_CAN_REGISTER_YOUR_ORGANIZATION_IF_')}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
    />
  );
};
