import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { GoogleMap } from '@/components/Google/Map/GoogleMap';
import { GoogleAutocomplete } from '@/components/Google/Autocomplete/GoogleAutocomplete';
import { useGeocoder } from '../shared/useGeocoder';
import { getFullAddress, PlaceResult } from '../shared/utils/getFullAddress';
import { FullAddress } from '../shared/types/FullAddress';
import { SEARCH_COUNTRY } from '@/components/Google/Autocomplete/useGoogleAutocomplete';
export type GeocoderResult = google.maps.GeocoderResult;

const isCountry = (
  result: GeocoderResult | null | undefined,
  country: typeof SEARCH_COUNTRY
) => {
  const isTargetCountry =
    result?.address_components?.some(
      (a) =>
        a.short_name.toLowerCase() === SEARCH_COUNTRY &&
        a.types.includes('country')
    ) ?? false;
  return isTargetCountry;
};

export const AddressLookup: FC<{
  sx?: SxProps<Theme>;
  onChange?: (addr: FullAddress | null, addrText?: string) => void;
  error?: string;
  initialValue?: { addressString?: string; googlePlaceId?: string }; // placeId
  readonly?: boolean;
  placeholder: string;
  name?: string;
}> = ({ placeholder, initialValue, readonly, sx, onChange, error }) => {
  const [place, setMapPlace] = useState<PlaceResult | undefined>();
  const [value, setValue] = useState<null | FullAddress>(null);
  const resetHandlerRef = useRef({ reset: () => {} });
  const getGeocoderResult = useGeocoder();

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const handleAutocomplete = useCallback(
    (result: PlaceResult | null, resultText?: string, setMap = true) => {
      const fullAddress = result ? getFullAddress(result) : null;
      onChangeRef.current?.(fullAddress, resultText);
      setValue(fullAddress);
      if (!result) resetHandlerRef.current.reset();
      if (setMap && result) setMapPlace(result);
    },
    []
  );

  const handleChange = useCallback(
    async (address: string) => {
      let result = address ? await getGeocoderResult?.({ address }) : null;
      if (!isCountry(result, SEARCH_COUNTRY)) {
        result = null;
      }
      handleAutocomplete(result as PlaceResult, address);
    },
    [getGeocoderResult, handleAutocomplete]
  );

  const handleAutocompleteRef = useRef(handleAutocomplete);
  handleAutocompleteRef.current = handleAutocomplete;
  useEffect(() => {
    const hasInitialValue =
      initialValue?.googlePlaceId || initialValue?.addressString;
    const hasHandler = !!getGeocoderResult;
    if (hasInitialValue && hasHandler) {
      void getGeocoderResult({
        placeId: initialValue.googlePlaceId,
        address: initialValue.googlePlaceId
          ? undefined
          : initialValue.addressString,
      }).then((result) => {
        const placeResult = result as google.maps.places.PlaceResult;
        if (!initialValue.googlePlaceId && initialValue.addressString) {
          handleAutocompleteRef.current(
            placeResult,
            initialValue.addressString,
            false
          );
        }
        setMapPlace(placeResult);
        setValue(getFullAddress(placeResult));
      });
    }
  }, [
    getGeocoderResult,
    initialValue?.addressString,
    initialValue?.googlePlaceId,
  ]);

  return (
    <>
      <Stack sx={sx}>
        {!readonly && (
          <Box sx={{ pb: { xs: 24 } }}>
            <GoogleAutocomplete
              onChange={handleAutocomplete}
              onChangeDraft={handleChange}
              error={error}
              placeholder={placeholder}
              value={value?.fullAddress ?? initialValue?.addressString}
            />
          </Box>
        )}
        <GoogleMap place={place} innerRef={resetHandlerRef} />
      </Stack>
    </>
  );
};
