import { createSlice } from '@reduxjs/toolkit';

interface AddressState {
  dialog?: 'delete' | 'edit';
}
const initialState: AddressState = {};
export const contactAddressSlice = createSlice({
  initialState,
  name: 'contactAddress',
  reducers: {
    openConfirmDelete(state) {
      state.dialog = 'delete';
    },
    openEditAddress(state) {
      state.dialog = 'edit';
    },
    closeDialog(state) {
      state.dialog = undefined;
    },
  },
});

export const { actions } = contactAddressSlice;
