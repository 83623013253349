/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import { useParams } from '@/routes/appRoutes';
import React, { FC, lazy, Suspense } from 'react';
import { PermissionsKeeper } from '@/store/permissions/PermissionsKeeper';
import { CompanyPermissionName } from '@/types';
import { Button } from '@/components/Button';
import { useCreateModule } from '@/store/companies/hooks/useCreateModule';
import { DialogAlert } from '@/components';

const ModuleAddDialog = lazy(
  () => import('../company/fragments/ModuleAddDialog')
);
//
export const AddModuleButton: FC<{
  variant: 'outlined' | 'contained';
}> = ({ variant }) => {
  const { t } = useTranslate('companies');
  const params = useParams();
  const d = useCreateModule(params.companyId);
  return (
    <>
      <PermissionsKeeper
        show={(has) => has(CompanyPermissionName.CompanyConnectModule)}
      >
        {!d.allModulesEnabled && (
          <Button
            variant={variant}
            color={'secondary'}
            onClick={() => d.openDialog()}
            sx={{ whiteSpace: 'nowrap', marginTop: { xs: 50, sm: 0 } }}
          >
            {t('ADD_MODULE')}
          </Button>
        )}
      </PermissionsKeeper>
      <Suspense fallback={null}>
        <ModuleAddDialog open={d.dialogOpen} onClose={() => d.closeDialog()} />
      </Suspense>
      <DialogAlert
        key={'alert'}
        open={d.alertOpen}
        title={t('SUCCESS!')}
        type={'success'}
        onClose={d.closeLisNotification}
      >
        {t('YOUR_CONNECTION_REQUEST_HAS_BEEN_SENT')}
      </DialogAlert>
    </>
  );
};
