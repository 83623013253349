/** @jsxImportSource @emotion/react */
import { DotStatus, StatusColor } from '@/components/status';
import { useTranslate } from '@/i18n/useTranslate';
import React from 'react';
import { AccountStatus } from '@/api/__generated__/webApi';
import { SxProps, Theme } from '@mui/material';

export const accountStatusColorMap: Record<AccountStatus, StatusColor> = {
  [AccountStatus.NEW]: StatusColor.Info,
  [AccountStatus.INVITE]: StatusColor.Warning,
  [AccountStatus.ACTIVE]: StatusColor.Success,
  [AccountStatus.BLOCKED]: StatusColor.Blocked,
};
export const accountStatusLabelMap: Record<AccountStatus, any> = {
  [AccountStatus.NEW]: 'TOOLTIP_ACCOUNT_STATUS_NEW',
  [AccountStatus.ACTIVE]: 'TOOLTIP_ACCOUNT_STATUS_ACTIVE',
  [AccountStatus.INVITE]: 'TOOLTIP_ACCOUNT_STATUS_INVITED',
  [AccountStatus.BLOCKED]: 'TOOLTIP_ACCOUNT_STATUS_BLOCKED',
} as const;
export const DotAccountStatus = ({
  status,
  sx,
}: {
  status: AccountStatus | null | undefined;
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslate('companies');
  if (!status) {
    return null;
  }
  return (
    <DotStatus
      sx={sx}
      color={accountStatusColorMap[status]}
      title={t(accountStatusLabelMap[status])}
    />
  );
};
