import { Theme } from '@mui/material';
const pt = {
  xs: 20,
  lg: 0,
} as const;
export const HEADER_HEIGHTS = {
  xs: 48 + pt.xs,
  tablet: 54 + pt.xs,
  desktop: 60 + pt.lg,
} as const;
export const styles = {
  leftLayout: {
    display: 'flex',
    alignItems: 'center',
    gap: {
      xs: 6,
      sm: 6,
      lg: 30,
    },
  },
  header: {
    position: 'fixed',
    width: '100vw',
    zIndex: 101,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '0 1 auto',
    height: {
      xs: HEADER_HEIGHTS.xs,
      sm: HEADER_HEIGHTS.tablet,
      lg: HEADER_HEIGHTS.desktop,
    },
    boxSizing: 'border-box',
    backgroundColor: (t: Theme) => t.colors.background,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: (t: Theme) => t.colors.borderLight,
    display: 'flex',
    pt: { xs: pt.xs, lg: pt.lg },
    paddingBottom: { xs: 0 },
    paddingLeft: { xs: 9, lg: 20 },
    paddingRight: { xs: 18, sm: 21, lg: 18 },
    'svg path': { transition: 'all .3s' },
  },
};
