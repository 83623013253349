// what it does?
// it runs the callback just once, but before executing it, it awaits when loading
// parameter will become false if it is provided
import { useLayoutEffect, useRef } from 'react';

export const useInit = (callback: () => void, loading?: boolean) => {
  const isInitializedRef = useRef(false);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (loading || isInitializedRef.current) {
      return;
    }
    isInitializedRef.current = true;
    callbackRef.current();
  }, [loading]);
};
