import { useProfileData } from '@/store/profile/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { NPIState, npiActions } from './slice';
import * as yup from 'yup';

import { ValidationErrorType } from '@/types/ValidationError';
const {
  requestAccountNpiData,
  requestConfirm,
  requestNPIInfo,
  setErrors,
  setNpiInfo,
  setRequestSuccess,
} = npiActions;

export const useNPIStateIndividual = () =>
  useSelector<RootState, NPIState>((state) => state.npi);

export const useIndividualNPI = (active = true) => {
  const state = useNPIStateIndividual();
  const { details: account } = useProfileData();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!active) {
      return;
    }
    dispatch(requestAccountNpiData());
  }, [dispatch, active]);

  const callbacks = useMemo(() => {
    return {
      requestNPI: (npi: string) => {
        dispatch(requestNPIInfo(npi));
      },
      confirm: () => {
        dispatch(requestConfirm());
      },
      resetNPIInfo: () => {
        dispatch(setNpiInfo({ npi: null, confirmed: false, step: 'init' }));
      },
      resetValidationError: () => {
        dispatch(setErrors(null));
      },
      resetRequestSuccess: () => dispatch(setRequestSuccess(false)),
    };
  }, [dispatch]);

  return {
    id: state.confirmed ? account?.npi : state.npiNumber,
    fetching: state.fetching,
    showRequestForm:
      !account?.npi &&
      state.step !== 'processing' &&
      state.step !== 'processed',
    showNpi: !!state.npi,
    npi: state.npi,
    confirmed: state.confirmed,
    errors: state.errors,
    requestSent: state.requestSuccess,
    ...callbacks,
  };
};

export const npiValidator = yup
  .string()
  .matches(/^[\d]+$/, ValidationErrorType.NOT_DIGIT_10_0)
  .min(10, ValidationErrorType.MIN_10_MAX_10)
  .max(10, ValidationErrorType.MIN_10_MAX_10);
export const useNPIValidate = () => {
  const [error, setError] = useState<null | ValidationErrorType>();
  const [dirty, setDirty] = useState(false);
  const callbacks = useMemo(() => {
    return {
      validate: (value: string) => {
        try {
          npiValidator.validateSync(value);
          setError(null);
        } catch (e) {
          setError(e.message);
        }
      },
      setDirty: () => {
        setDirty(true);
      },
      resetForm: () => {
        setError(null);
        setDirty(false);
      },
      resetError: () => setError(null),
    };
  }, []);
  return {
    dirty,
    error,
    ...callbacks,
  };
};
export const useNPIFormIndividual = () => {
  const { dirty, error, validate, setDirty, resetForm } = useNPIValidate();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const state = useNPIStateIndividual();

  const handleChange = useCallback(
    ({ target: { value } }: any) => {
      if (state.errors) {
        dispatch(setErrors(null));
      }
      validate(value);
      setValue(value);
      if (!value) {
        resetForm();
      } else {
        validate(value);
      }
    },
    [dispatch, resetForm, state.errors, validate]
  );

  const handleSubmit = useCallback(() => {
    if (value) {
      if (!error && !state.errors) {
        dispatch(requestNPIInfo(value));
      }
      setDirty();
    }
  }, [dispatch, error, setDirty, state.errors, value]);

  return {
    handleChange,
    handleSubmit,
    dirty,
    error: state.errors?.npi ?? error,
    fetching: state.fetching,
  };
};
