import {
  FC,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Box,
  GlobalStyles,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useGoogleMap } from '@/components/Google/Map/useGoogleMap';
import { spreadSx } from '@/utils/spreadSx';
import { useGeocoder } from '@/components/Google/shared/useGeocoder';
import { grayMarker } from '@/components/Google/Map/b64Images';

export const GoogleMap: FC<{
  onChange?: (placeId: string) => void;
  innerRef?: MutableRefObject<{ reset: () => void }>;
  sx?: SxProps<Theme>;
  place?: google.maps.places.PlaceResult | undefined;
  placeId?: string;
  hideTooltip?: boolean;
  children?: (ref: MutableRefObject<HTMLElement | null>) => JSX.Element;
}> = ({ innerRef, hideTooltip, onChange, sx, place, placeId, children }) => {
  const [placeFromId, setMapPlaceFromId] = useState<
    google.maps.places.PlaceResult | undefined
  >();

  const { mapRef, tooltip, tooltipRef, reset } = useGoogleMap({
    googlePlace: place || placeFromId,
    onChange,
    hideTooltip,
    marker: grayMarker,
  });
  useImperativeHandle(
    innerRef,
    () => {
      return { reset };
    },
    [reset]
  );

  const geocoder = useGeocoder();

  useEffect(() => {
    if (placeId && geocoder) {
      void geocoder({ placeId }).then((result) => {
        setMapPlaceFromId(result as google.maps.places.PlaceResult);
      });
    }
  }, [geocoder, placeId]);

  return (
    <>
      <GlobalStyles
        styles={{
          '.gm-style-iw-ch': { pt: 20 },
          '.gm-style-iw-chr button': {
            position: 'absolute!important',
            right: 0,
          },
        }}
      />
      {children ? (
        children(mapRef)
      ) : (
        <Box ref={mapRef} sx={[styles.mapBox, ...spreadSx(sx)]} />
      )}
      <Stack className={'tt-body'} ref={tooltipRef} sx={styles.tooltip}>
        <Typography variant={'16_24_500'} sx={{ pr: 32 }}>
          {tooltip.header}
        </Typography>
        <Box>{tooltip.info}</Box>
      </Stack>
    </>
  );
};

const styles = {
  mapBox: {
    width: '100%',
    height: { xs: 200, md: 200 },
    boxSizing: 'border-box',
    border: (t: Theme) => `1px solid ${t.palette.field.border.default}`,
  },
  tooltip: { maxWidth: 200, display: 'none' },
};
