import { useLayoutEffect, useState } from 'react';
import { useGeocoder } from './useGeocoder';
import {
  addrComponentsToRecord,
  AddressComponentMapType,
} from './utils/addrComponentsToRecord';

let locationInfoSt: AddressComponentMapType | null = null;
export const useCurrentLocationInfo = () => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const getPositionInfo = useGeocoder();
  const [locationInfo, setLocationInfo] =
    useState<AddressComponentMapType | null>(locationInfoSt);

  useLayoutEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (l) => {
        setLocation(l);
      },
      (e) => {
        console.error('[navigator geolocation] Error', e);
      }
    );
  }, []);
  useLayoutEffect(() => {
    if (!getPositionInfo || !location) {
      return;
    }
    void getPositionInfo({
      location: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    })
      .then((r) => {
        const result = addrComponentsToRecord(r?.address_components);
        if (result) {
          setLocationInfo(result);
          locationInfoSt = result;
        } else {
          console.error('[Place info not found]');
        }
      })
      .catch((e) => {
        console.error('[Geocoder] Something went wrong', e);
      });
  }, [getPositionInfo, location]);

  return locationInfo;
};
