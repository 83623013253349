import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { FormikProps } from 'formik';
import { UpdateAddressForm } from '@/features/profile-address/types';
import { useUpdateAddress } from '@/features/profile-address/store/hooks';
import { FormikRedux } from '@/components';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { FullAddress } from '@/components/Google/shared/types/FullAddress';
import { cloneDeep } from 'lodash-es';

const useValidationSchema = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return yup.object().shape({
      fullAddress: yup
        .object()
        .nullable()
        .test(
          'required-field',
          t('FIELD_IS_REQUIRED'),
          (addr: FullAddress | null, { parent }) => {
            const { searchFieldText } = parent as UpdateAddressForm;
            if (!searchFieldText && !addr) {
              return false;
            }
            return true;
          }
        )
        .test(
          'invalid-address',
          t('INVALID_ADDRESS'),
          (addr: FullAddress | null, { parent }) => {
            const { searchFieldText } = parent as UpdateAddressForm;
            if (searchFieldText && !addr) {
              return false;
            }
            return true;
          }
        )
        .test(
          'full-address',
          t('PLEASE_CLARIFY_THE_ADDRESS'),
          (addr: FullAddress | null) => {
            let result = true;
            if (
              !addr?.city?.trim() ||
              !addr?.streetAddress?.trim() ||
              !addr?.postalCode?.trim()
            ) {
              console.info(
                '[Address validation Error] Either city, streetAddress, or postalCode are missing:',
                JSON.parse(JSON.stringify(addr))
              );
              result = false;
            }
            return result;
          }
        ),
    });
  }, [t]);
};

export const FormLogic: FC<{
  children: (
    formikProps: FormikProps<UpdateAddressForm>,
    fullAddressError: string | undefined,
    onChangeFullAddress: (addr: FullAddress | null) => void
  ) => ReactNode;
}> = ({ children }) => {
  const d = useUpdateAddress();
  const schema = useValidationSchema();

  return (
    <FormikRedux<UpdateAddressForm>
      noForm
      initialValues={d.values}
      onSubmit={d.submit}
      serverErrors={d.errors}
      resetServerErrors={d.resetServerErrors}
      validationSchema={schema}
      validateOnChange
    >
      {(f) => {
        const fullAddressError = getErrorForDisplay({
          error: f.errors.fullAddress,
          value: f.values.fullAddress?.streetAddress,
          touched: f.touched.fullAddress ?? false,
          submitCount: f.submitCount,
        });
        const cb = (addr: FullAddress | null, searchFieldText?: string) => {
          void f.setValues({
            fullAddress: cloneDeep(addr),
            searchFieldText: searchFieldText ?? '',
            address2: f.values.address2,
          });
        };
        return children(f, fullAddressError, cb);
      }}
    </FormikRedux>
  );
};
