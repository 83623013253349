/** @jsxImportSource @emotion/react */
import React, { FC, HTMLAttributes } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, css as _, styled, Typography } from '@mui/material';
import { ReactComponent as IconSVGCloudUpload } from '@/images/icons/icon_upload.svg';
import { ReactComponent as IconSVGFilePlus } from '@/images/icons/icon_file_plus.svg';
import classNames from 'classnames';
import { useMQuery } from '@/hooks/useMQuery';

export type DropZoneViewComp = FC<{ label?: string; active?: boolean }>;
export const DndDropZoneView: FC<
  { label?: string; active?: boolean } & HTMLAttributes<HTMLLabelElement>
> = ({ label, active, ...restProps }) => {
  const { t } = useTranslate('common');
  const { desktop } = useMQuery();
  return (
    <DnDLabel className={classNames(active && 'dd-active')} {...restProps}>
      <Typography
        variant={'14_20_400'}
        sx={(t) => ({
          a: { color: t.palette.secondary.main },
          '.dd-active &': { display: 'none' },
        })}
        dangerouslySetInnerHTML={{
          __html: desktop ? t('SELECT_FILES') : t('TAP_HERE_TO_ADD_THE_FILE'),
        }}
      />
      <Box
        sx={{
          height: 48,
          width: 48,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '.dd-active &': { display: 'none' },
        }}
      >
        <IconSVGCloudUpload />
      </Box>
      <Typography
        variant={'14_20_400'}
        sx={{
          color: 'grey.600',
          textAlign: 'center',
          '.dd-active &': { display: 'none' },
        }}
      >
        {label}
      </Typography>
      <IconSVGFilePlus css={_`display: none; .dd-active & {display: block}`} />
    </DnDLabel>
  );
};
const DnDLabel = styled('label')(({ theme: th }) => ({
  width: '100%',
  height: '100%',
  minHeight: 204,
  cursor: 'pointer',
  boxSizing: 'border-box',
  padding: 24,
  display: 'flex',
  gap: 24,
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: th.palette.grey[200],
  alignItems: 'center',
  '*': { pointerEvents: 'none' },
  '&.dd-active': {
    background: `${th.palette.grey[300]} !important`,
    justifyContent: 'center',
  },
  '&:hover': {
    background: th.palette.grey[300],
    'svg path': { fill: 'white' },
  },
  'input:focus-visible + &': {
    background: `${th.palette.grey[300]} !important`,
    border: `1px solid ${th.colors.all.focus}`,
    'svg path': { fill: 'white' },
  },
}));
