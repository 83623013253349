// @jsxRuntime classic
// @jsx jsx
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { CircularProgress, Fade } from '@mui/material';
export const LoadingBackdrop: FC<{ open: boolean | undefined }> = ({
  open,
}) => {
  return (
    <Fade
      in={open}
      timeout={{ enter: 500, exit: 250 }}
      style={{ transitionDelay: '500ms' }}
    >
      <div
        css={css`
          z-index: 1;
          background: #ffffffa2;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <CircularProgress color="secondary" />
      </div>
    </Fade>
  );
};
