import { Button, ButtonProps, Tooltip } from 'ui-kit';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { ReactComponent as IconSVGExMarkRound } from '@/images/icons/icon_info_round_outlined.svg';
import { useTranslate } from '@/i18n/useTranslate';
import { Module } from '@/api/__generated__/webApi';
import { useAppDispatch } from '@/store/store';
import { companiesActions } from '@/store/companies/slice';
import { UUID } from '@/types';
const styles = {
  button: {
    height: 26,
    alignItems: 'center',
    backgroundColor: 'secondary.main',
    color: 'common.white',
    paddingY: 0,
    paddingX: 12,
    borderRadius: '26px',
    fontSize: '14px',
    gap: 0,
    '.MuiButton-icon': {
      marginLeft: 10,
    },
    '&.Mui-disabled': {
      pointerEvents: 'all',
      backgroundColor: 'grey.400',
      color: 'common.white',
    },
  },
};

interface GoToModuleButtonProps {
  href?: string;
  children?: ReactNode;
  data?: { companyId: UUID; module: Module };
}

export const GoToModuleButton: FC<GoToModuleButtonProps> = ({
  children,
  href,
  data,
}) => {
  const { module, companyId } = data ?? {};
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!module || !companyId) {
      return;
    }
    if (module.status !== 'SUCCESSFUL') {
      const timerId = setInterval(() => {
        dispatch(
          companiesActions.requestModuleStatus({
            moduleId: module.id,
            companyId,
          })
        );
      }, 1000 * 10);
      return () => clearInterval(timerId);
    }
  }, [companyId, dispatch, module]);
  const disabled = module?.status && module.status !== 'SUCCESSFUL';
  return (
    <ModuleButton href={href} disabled={disabled}>
      {children}
    </ModuleButton>
  );
};

export const ModuleButton: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const { t } = useTranslate('common');
  const renderIcon = () => (
    <Tooltip
      title={t('THIS_COMPONENT_IS_CURRENTLY_BEING_SET_UP_')}
      placement={'bottom'}
      sx={{ maxWidth: 200 }}
    >
      <IconSVGExMarkRound />
    </Tooltip>
  );

  return (
    <Button
      {...props}
      href={props.disabled ? undefined : props.href}
      color={'secondary'}
      variant={'contained'}
      component={'a'}
      sx={styles.button}
      disabled={props.disabled}
      endIcon={props.disabled ? renderIcon() : undefined}
    />
  );
};
