import { useAppDispatch, useAppSelector } from '@/store/store';
import { useEffect, useMemo } from 'react';
import { createQr, deleteQr, getQrs, updateQr } from '@/store/profile-qr/api';
import { qrActions } from '@/store/profile-qr/slice';
import { UUID } from '@/types';

export const useQrsState = () => {
  return useAppSelector((state) => state.qrState);
};

export const useRequestListData = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    void dispatch(getQrs());
  }, [dispatch]);
};

export const useQrData = () => {
  const state = useQrsState();
  return {
    qrs: state.qrs,
    selectedQrId: state.selectedQrId,
    step: state.step,
    errors: state.validationErrors,
    selectedQr: state.selectedQrId ? state.qrs.byID[state.selectedQrId] : null,
  };
};

export const useQrActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => {
    return {
      createNew() {
        dispatch(qrActions.createNew());
      },
      resetErrors() {
        dispatch(qrActions.resetErrors());
      },
      async submitNew({ description }: { description: string }) {
        try {
          await dispatch(createQr({ description })).unwrap();
          dispatch(qrActions.resetStep());
          await wait(300);
        } catch {}
      },
      cancelCreateNew() {
        dispatch(qrActions.resetStep());
      },
      cancelEdit() {
        dispatch(qrActions.resetStep());
      },
      editQr(id: UUID) {
        dispatch(qrActions.editQr({ id }));
      },
      async submitEditQr({ description }: { description: string }) {
        try {
          await dispatch(updateQr({ description })).unwrap();
          dispatch(qrActions.resetStep());
          await wait(300);
        } catch {}
      },
      async deleteQr() {
        try {
          await dispatch(deleteQr()).unwrap();
          dispatch(qrActions.resetStep());
          await wait(300);
        } catch {}
      },
    };
  }, [dispatch]);
};
const wait = async (time: number) => {
  await new Promise((resolve) => window.setTimeout(resolve, time));
};
