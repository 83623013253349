import { Button } from '@/components/Button';
import React, { FC, useMemo } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@/routes/appRoutes';
import { PartialRecord } from '@/types';
import { Stack, Typography } from '@mui/material';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { ValidationErrorType } from '@/types/ValidationError';

export const WarningFragment: FC<{ message: ValidationErrorType }> = ({
  message,
}) => {
  const { t } = useTranslate('auth');
  const navigate = useNavigate();
  const messages: PartialRecord<ValidationErrorType, string> = useMemo(() => {
    return {
      [ValidationErrorType.ACCOUNT_NOT_FOUND]: t('ACCOUNT_NOT_FOUND'),
    };
  }, [t]);
  return (
    <Stack width={'100%'} justifyContent={{ xs: 'space-between', sm: 'start' }}>
      <Stack>
        <AuthHeader
          title={t('WARNING')}
          sx={{ mb: { lg: 64, sm: 48, xs: 36 } }}
        />
        <Typography variant={'16_24_400'} mb={{ xs: 368, sm: 556, lg: 368 }}>
          {messages[message] ?? t('SYSTEM_FAILURE_PLEASE_CONTACT_SUPPORT')}
        </Typography>
      </Stack>
      <Button
        fullWidth
        color={'secondary'}
        sx={{ mb: 6 }}
        onClick={() =>
          navigate({ pathname: AppRoutes.AUTH, search: window.location.search })
        }
      >
        {t('CLOSE')}
      </Button>
    </Stack>
  );
};
