import { identityApiClient } from '@/api/client/ApiClient';
import { InviteUserData, ResendTime } from '@/types';

export const checkAccountInviteExists = async (inviteCode: string) => {
  const response = await identityApiClient.get<InviteUserData>(
    `/api/v1/activation/${inviteCode}`
  );
  return response.data;
};
export const sendNewPasswordForAccountActivationByEmail = async (
  inviteCode: string,
  params: { password: string; phone: string; recaptcha: string }
) => {
  const response = await identityApiClient.post<ResendTime>(
    `/api/v1/activation/${inviteCode}/email`,
    params
  );
  return response.data;
};
export const sendNewPasswordForAccountActivationByPhone = async (
  inviteCode: string,
  params: { password: string; recaptcha: string }
) => {
  await identityApiClient.post(
    `/api/v1/activation/${inviteCode}/phone`,
    params
  );
};

export interface ConfirmResponse {
  companyName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
}
export const sendPinCode = async (
  inviteCode: string,
  pinCode: string,
  phone: string
) => {
  const response = await identityApiClient.post<ConfirmResponse | undefined>(
    `/api/v1/activation/${inviteCode}/confirm`,
    { code: pinCode, phone }
  );
  return response.data;
};

export * as activationApi from './';
