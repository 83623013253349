import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { spreadSx } from '@/utils/spreadSx';

const styles = {
  text: {
    display: 'inline-block',
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: { xs: '10px' },
    color: 'grey.500',
    a: {
      color: 'secondary.main',
      textDecoration: 'none',
      '&:hover': {
        color: 'secondary.light',
      },
      '&:active': {
        color: 'secondary.dark',
      },
    },
  },
};

export const RecaptchaNote: FC<
  TypographyTypeMap['props'] & TypographyProps
> = ({ className, sx, ...restProps }) => {
  const { t } = useTranslate('common');
  return (
    <Typography
      sx={[styles.text, ...spreadSx(sx)]}
      className={className}
      component={'span'}
      dangerouslySetInnerHTML={{ __html: t('RECAPTCHA_TEXT') }}
      {...restProps}
    />
  );
};
