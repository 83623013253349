import { Loader } from '@googlemaps/js-api-loader';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { envs } from '@/constants/envs';

const GoogleLoaderContext = createContext<Loader | undefined>(undefined);
// why?
// 1) The package prohibits creating the loader twice with different settings,
//  so if you want to use it in 2 different modules - you have to deal with it somehow.
// 2) i18n
export const GoogleLoaderProvider: FC<{
  children: ReactNode;
  apiKey?: string;
}> = ({ children, apiKey = envs.GOOGLE_API_KEY }) => {
  const ctx = useContext(GoogleLoaderContext);
  const [loader, setLoader] = useState<Loader | undefined>(ctx);
  const { i18n } = useTranslate('common');
  const [language, region] = i18n.language?.split('-') ?? [];
  useEffect(() => {
    if (language && region) {
      const loader = new Loader({
        apiKey,
        version: 'weekly',
        region,
        language,
      });
      setLoader(loader);
    }
  }, [apiKey, language, loader, region]);
  return (
    <GoogleLoaderContext.Provider value={loader}>
      {children}
    </GoogleLoaderContext.Provider>
  );
};

export const useGoogleLoader = () => {
  return useContext(GoogleLoaderContext);
};
