import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { baseErrorHandler } from './error-handlers';
import { tryCatchSaga } from '@/store/sagas';
import { RootState } from '@/store/store';
import { makeDataQuery } from './request-handlers';
import {
  QueryActionNames,
  QueryPayloadActions,
  QueryState,
  _querySuccess,
  _setProgress,
} from './slice';
import { WebApiEndpoints } from '@/store/profile/apiHooks';

const tryCatchSagaOptions = {
  handleError: baseErrorHandler,
};

export function* querySaga() {
  yield all([
    takeEvery(
      QueryActionNames.query,
      tryCatchSaga(queryHandler, tryCatchSagaOptions)
    ),
    ...Object.entries(WebApiEndpoints).map((endpoint) => {
      return takeLeading(endpoint, tryCatchSaga(query, tryCatchSagaOptions));
    }),
    takeLatest(
      QueryActionNames.mutationQuery,
      tryCatchSaga(mutationQuery, tryCatchSagaOptions)
    ),
  ]);
}

function* mutationQuery(action: QueryPayloadActions['mutationQuery']): any {
  const state: QueryState = yield select(
    (state: RootState) => state.queryState
  );
  yield put(_setProgress({ path: action.payload.path, fetching: true }));
  const data = yield call(makeDataQuery, {
    method: action.payload.method,
    data: action.payload.requestData,
    url: action.payload.path,
  });
  yield put(_querySuccess({ path: action.payload.path, data }));
  yield put(
    _setProgress({ path: action.payload.path, fetching: false, success: true })
  );

  if (action.payload.invalidateTags) {
    for (const tag of action.payload.invalidateTags) {
      const payload = state.tags[tag];
      yield put({ type: payload.path, payload });
    }
  }
}

function* queryHandler(action: QueryPayloadActions['query']): any {
  yield put({ type: action.payload.path, payload: action.payload });
}

function* query(action: QueryPayloadActions['query']): any {
  const data = yield call(makeDataQuery, {
    method: action.payload.method,
    url: action.payload.path,
  });
  yield put(_querySuccess({ path: action.payload.path, data }));
}
