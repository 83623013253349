import { applyMask } from './applyMask';
const onlyNonDigitsGlobal = /[^0-9]/g;
const removeAllNonDigits = (val: string) => val.replace(onlyNonDigitsGlobal, '');
export const calcInsert = (opt: {
  maskedValueAfter: string;
  maskedValueBefore: string;
  selectionStartBefore: number | null | undefined;
  selectionEndBefore: number | null | undefined;
  cursorPositionAfter: number | null | undefined;
  initialMask: string;
}) => {
  const {
    maskedValueAfter,
    maskedValueBefore,
    selectionStartBefore,
    selectionEndBefore,
    initialMask,
    cursorPositionAfter,
  } = opt;
  if (
    selectionEndBefore == null ||
    selectionStartBefore == null ||
    cursorPositionAfter == null
  ) {
    return;
  }

  /// /////////////////////////////////
  /// BACKSPACE CASE - if user wants to erase value with backend
  /// there are few cases
  /// 1. Simple erasing of the data by just hitting backspace, one symbol at a time
  /// 2. Erasing using selection - if some symbols selected and user will press backspace -
  ///     then selected users will be replaced with mask values
  /// 3. Also, user can erase all date with just ctrl-a + backspace.
  /// /////////////////////////////////
  if (
    (selectionEndBefore > selectionStartBefore &&
      cursorPositionAfter === selectionStartBefore) ||
    selectionStartBefore > cursorPositionAfter
  ) {
    const value = initialMask
      .slice(cursorPositionAfter, selectionEndBefore)
      .replace(/[^0-9ymd]/gi, '');
    const { maskedValue } = applyMask({
      prevMaskedValue: maskedValueBefore,
      insertedValue: value,
      applyFromIndex: cursorPositionAfter
    });
    return {
      cursorPosition: cursorPositionAfter,
      maskedValue,
    };
  }
  /// //////////////////////////////////
  /// backspace case end
  /// //////////////////////////////////

  // we got clear value what was inserted
  const insertedValue = maskedValueAfter.slice(
    selectionStartBefore,
    cursorPositionAfter
  );
  const stripedNonDigitsSymbolsInsertedValue = removeAllNonDigits(insertedValue);

  const { maskedValue, cursorPosition } = applyMask({
    prevMaskedValue: maskedValueBefore,
    insertedValue: stripedNonDigitsSymbolsInsertedValue,
    applyFromIndex: selectionStartBefore
  });
  return {
    cursorPosition,
    maskedValue,
  };
};
