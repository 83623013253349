import { useCallback, useLayoutEffect, useState } from 'react';

export const useMenuPersistentState = (
  d: boolean
): [boolean, (d: boolean) => void] => {
  const stateKey = 'menuCollapsed';
  const [state, setState] = useState(d);

  const setPersistentState = useCallback((d: boolean) => {
    setState(d);
    localStorage.setItem(stateKey, JSON.stringify(d));
  }, []);

  useLayoutEffect(() => {
    const data = JSON.parse(localStorage.getItem(stateKey) ?? 'false');
    setState(data);
  }, []);

  return [state, setPersistentState];
};
