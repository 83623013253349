import { companiesApi } from '@/api/web/companies';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { permissionsActions as actions } from './slice';
import { SagaPayload, SagaReturn } from '../common/types';
export function* permissionSagas() {
  yield all([
    takeLeading(actions.requestAllPermissions, requestAllPermissions),
    takeLeading(
      actions.requestCompanyPermissions,
      requestPermissionsForCompany
    ),
  ]);
}

function* requestAllPermissions(): SagaReturn {
  const permissions: Awaited<
    ReturnType<typeof companiesApi.getAllAccountCompanyPermissions>
  > = yield call(companiesApi.getAllAccountCompanyPermissions);
  yield put(actions.updatePermissions(permissions));
}

function* requestPermissionsForCompany({
  payload,
}: SagaPayload<typeof actions.requestCompanyPermissions>) {
  const permissions: Awaited<
    ReturnType<typeof companiesApi.getAccountCompanyPermissions>
  > = yield call(companiesApi.getAccountCompanyPermissions, payload);
  yield put(actions.updatePermissions([permissions]));
}
