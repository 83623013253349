/** @jsxImportSource @emotion/react */
import { css, GlobalStyles } from '@mui/material';
import React from 'react';
import {
  ToastContainer as ToastifyToastContanier,
  toast,
} from 'react-toastify';
import { useMQuery } from '@/hooks/useMQuery';

const toastifyOverrideStyles = css`
  .Toastify {
    &__toast {
      border-radius: 0;
      padding: 12px;
      font-family: inherit;
      min-height: 76px;
      margin-top: 46px;
    }
    &__toast-container {
      min-width: 332px;
    }
    &__toast-body {
      align-items: start;
      padding: 0;
      margin-top: 0;
    }
    &__toast-icon {
      width: 24px;
      margin-left: -1px;
      margin-inline-end: 11px;
    }
    .mobile-layout & {
      &__toast {
        margin: 16px;
        margin-top: 24px;
      }
    }
  }
`;
export const ToastContainer = () => {
  const { mobile } = useMQuery();
  return (
    <>
      <GlobalStyles styles={toastifyOverrideStyles} />
      <ToastifyToastContanier
        position={mobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};
