import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createNormalizedState,
  NormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import {
  UUID,
  PartialRecord,
  ModuleRoles,
  CompanyUUID,
  ISODateString,
  AccountSearchResponse,
} from '@/types';
import { globalStateResetAction } from '@/store/common/actions';
import { AccountCompanyStatus, CompanyUser } from '@/api/__generated__/webApi';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { ValidationError, ValidationErrorType } from '@/types/ValidationError';

export enum ErrorTypes {
  Common = 'common',
  UserLoading = 'userLoading',
  AddRolesToAccount = 'addRolesToAccount',
  DeleteRolesFromAccount = 'deleteRolesFromAccount',
}
export enum Fetching {
  Common = 'common',
}
interface Form {
  success?: boolean;
  validationErrors?: Partial<Record<string, ValidationErrorType>> | null;
}

export type UserFormsType = 'reInviteUser' | 'inviteUser';
export type InviteProcessStep = 'search' | 'list' | 'invite' | 'roles';
export type SearchType = 'email' | 'npi' | 'phone';

interface InviteForm extends Form {
  accounts?: AccountSearchResponse[] | null;
  search?: { type: SearchType; value: string };
  step?: InviteProcessStep;
  inviteSentOn?: string | null;
  selectedAccount?: CompanyUser;
}
interface ReinviteUserForm extends Form {
  selectedAccount?: CompanyUser;
  inviteSentTo?: string | null;
  step?: 'init' | 'inviteDialog' | 'success';
}

export interface UserFilters {
  withRoleId: UUID | null;
  search?: string | null;
  accountStatuses?: AccountCompanyStatus[] | null;
}
export interface UserDataType {
  items?: NormalizedState<CompanyUser>;
  hasMore: boolean | null;
  filters?: UserFilters;
}
export interface UsersState {
  users: PartialRecord<CompanyUUID, UserDataType>;
  validationErrors: PartialRecord<ErrorTypes, ValidationErrorType> | null;
  fetching: PartialRecord<Fetching, boolean>;
  selectedUserId: UUID | null;
  rolesDialog: boolean;
  forms: {
    reInviteUser?: ReinviteUserForm;
    inviteUser?: InviteForm;
  } | null;
}
const initialState: UsersState = {
  fetching: {},
  users: {},
  validationErrors: null,
  selectedUserId: null,
  rolesDialog: false,
  forms: {},
};

export interface RequestUsersPayload {
  companyId: UUID;
  pageIndex: number;
  perPage?: number;
  replace: boolean;
  quiet?: boolean;
  filters?: UserFilters;
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    requestDeleteUser(
      _state,
      _action: PayloadAction<{ companyId: UUID; accountId: UUID }>
    ) {},
    requestSearchUsers(
      _state,
      _action: PayloadAction<{
        data: {
          email: string | null;
          npi: string | null;
          phone: string | null;
        };
        companyId: UUID;
      }>
    ) {},
    requestChangeEmailOfCompanyAccount(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        accountId: UUID;
        email: string;
      }>
    ) {},
    updateInviteExpiredTime(
      state,
      {
        payload,
      }: PayloadAction<{
        accountId: string;
        companyId: string;
        inviteExpiredTime: ISODateString;
      }>
    ) {
      const user =
        state.users[payload.companyId]?.items?.byID[payload.accountId];
      if (user) {
        user.inviteExpiredTime = payload.inviteExpiredTime;
      } else {
        console.error('[updateInviteExpiredTime] User not found', payload);
      }
    },
    updateUserEmail(
      state,
      {
        payload,
      }: PayloadAction<{
        companyId: UUID;
        accountId: UUID;
        email: string | null | undefined;
      }>
    ) {
      const user =
        state.users[payload.companyId]?.items?.byID[payload.accountId];
      if (user) {
        user.domainEmail = payload.email ?? undefined;
      }
    },
    setFilters(
      state,
      { payload }: PayloadAction<{ filters?: UserFilters; companyId: UUID }>
    ) {
      if (!state.users[payload.companyId]) {
        state.users[payload.companyId] = { hasMore: true };
      }
      const usersData = state.users[payload.companyId] as UserDataType;
      usersData.filters = {
        search: payload.filters?.search ?? usersData.filters?.search ?? null,
        withRoleId:
          payload.filters?.withRoleId ?? usersData.filters?.withRoleId ?? null,
        accountStatuses:
          payload.filters?.accountStatuses ??
          usersData.filters?.accountStatuses ??
          null,
      };
    },
    setUsers(
      state,
      {
        payload,
      }: PayloadAction<{
        users: CompanyUser[];
        companyId: UUID;
        hasMore: boolean;
        replace: boolean;
        filters?: UserFilters;
      }>
    ) {
      const usersData = state.users[payload.companyId];
      if (usersData?.items && !payload.replace) {
        updateNormalizedState(usersData.items, payload.users);
        usersData.hasMore = payload.hasMore;
      } else {
        state.users[payload.companyId] = {
          items: createNormalizedState(payload.users),
          hasMore: payload.hasMore,
        };
      }
      const usersData2 = state.users[payload.companyId];
      if (usersData2)
        usersData2.filters = {
          search: payload.filters?.search ?? usersData2.filters?.search ?? null,
          withRoleId:
            payload.filters?.withRoleId ??
            usersData2.filters?.withRoleId ??
            null,
          accountStatuses:
            payload.filters?.accountStatuses ??
            usersData2.filters?.accountStatuses ??
            null,
        };
    },
    deleteUser(
      state,
      { payload }: PayloadAction<{ companyId: UUID; accountId: UUID }>
    ) {
      const user =
        state?.users[payload.companyId]?.items?.byID[payload.accountId];
      if (user) {
        user.accountCompanyStatus = AccountCompanyStatus.DELETED;
      }
    },
    inviteUser(
      state,
      { payload }: PayloadAction<{ companyId: UUID; accountId: UUID }>
    ) {
      const user =
        state?.users[payload.companyId]?.items?.byID[payload.accountId];
      if (user) {
        user.accountCompanyStatus = AccountCompanyStatus.INVITED;
      }
    },
    insertUsers(
      state,
      {
        payload,
      }: PayloadAction<{
        companyId: UUID;
        users: CompanyUser[];
        insertionIndex: number;
      }>
    ) {
      const users = state?.users[payload.companyId]?.items;
      if (users) {
        updateNormalizedState(users, payload.users, payload.insertionIndex);
      }
    },
    setErrors(
      state,
      { payload }: PayloadAction<{ common: Array<ValidationError<ErrorTypes>> }>
    ) {
      if (!state.validationErrors) {
        state.validationErrors = {};
      }
      for (const error of payload.common) {
        state.validationErrors[error.field] = error.error;
      }
    },
    deleteRolesFromUser(
      state,
      {
        payload,
      }: PayloadAction<{
        roles: ModuleRoles[];
        companyId: UUID;
        accountId: UUID;
      }>
    ) {
      const user =
        state.users[payload.companyId]?.items?.byID[payload.accountId];
      for (const { module, roles } of payload.roles) {
        const userModuleAndItsRoles = user?.moduleRoles?.find(
          (d) => d.module?.id === module.id
        );
        const userRoles = userModuleAndItsRoles?.roles;

        if (!userRoles) return;
        for (const role of roles ?? []) {
          const indexToDelete = userRoles?.findIndex((r) => r.id === role.id);
          if (indexToDelete !== -1) userRoles?.splice(indexToDelete, 1);
        }
      }
    },
    addRolesToUser(
      state,
      {
        payload,
      }: PayloadAction<{
        roles: ModuleRoles[];
        companyId: UUID;
        accountId: UUID;
      }>
    ) {
      const user =
        state.users[payload.companyId]?.items?.byID[payload.accountId];
      for (const payloadModuleRoles of payload.roles) {
        const { module, roles } = payloadModuleRoles;
        for (const role of roles ?? []) {
          const moduleRoles = user?.moduleRoles?.find(
            (d) => d.module?.id === module.id
          );
          if (moduleRoles) {
            const hasRole = moduleRoles.roles?.find((r) => r.id === role.id);
            if (hasRole) {
              return;
            } else {
              moduleRoles.roles?.push(role);
            }
          } else {
            user?.moduleRoles?.push(payloadModuleRoles);
          }
        }
      }
    },
    setFormErrors(
      state,
      {
        payload,
      }: PayloadAction<{
        errors: PartialRecord<string | 'common', ValidationErrorType>;
        form: UserFormsType;
      }>
    ) {
      // @ts-expect-error
      ((state.forms = state.forms ?? {})[payload.form] =
        state.forms[payload.form] ?? {}).validationErrors = payload.errors;
    },
    resetErrors(state) {
      state.validationErrors = null;
      if (state.forms?.inviteUser?.validationErrors) {
        state.forms.inviteUser.validationErrors = null;
      }
    },
    resetForm(state, { payload }: PayloadAction<{ type: UserFormsType }>) {
      delete state.forms?.[payload.type];
    },
    setSuccessForm(state, { payload }: PayloadAction<{ type: UserFormsType }>) {
      // @ts-expect-error
      ((state.forms = state.forms ?? {})[payload.type] =
        state.forms?.[payload.type] ?? {}).success = true;
    },
    setIsFetching(
      state,
      { payload }: PayloadAction<{ type?: Fetching; fetching: boolean }>
    ) {
      state.fetching[payload.type ?? Fetching.Common] = payload.fetching;
    },
    setRoleHandling(
      state,
      { payload }: PayloadAction<{ userId?: UUID; isOpen: boolean }>
    ) {
      if (payload.userId) {
        state.selectedUserId = payload.userId;
      }
      state.rolesDialog = payload.isOpen;
    },
    setAccountSearchResponses(
      state,
      {
        payload,
      }: PayloadAction<{
        accounts: AccountSearchResponse[];
        foundBy: { type: SearchType; value: string };
      }>
    ) {
      const acc = payload.accounts[0];
      if (acc) {
        acc.emails = [...acc.emails];
      }
      if (state.forms?.inviteUser) {
        state.forms.inviteUser.accounts = payload.accounts;
        state.forms.inviteUser.search = payload.foundBy;
      } else {
        console.error(
          '[usersSlice#setAccountResponses] Invite user form does not exists'
        );
      }
    },
    deleteSearchResponses(state) {
      if (state.forms?.inviteUser) {
        state.forms.inviteUser.accounts = null;
      }
    },
    setForm(
      state,
      { payload }: PayloadAction<{ forms: UsersState['forms']; merge: boolean }>
    ) {
      if (!payload.forms) {
        state.forms = null;
        return;
      }
      for (const formName of Object.keys(payload.forms)) {
        if (state.forms?.[formName as UserFormsType] && payload?.merge) {
          const newState = {
            ...state.forms[formName as UserFormsType],
            ...payload.forms[formName as UserFormsType],
          };
          state.forms[formName as UserFormsType] = newState;
        } else {
          (state.forms = state.forms ?? {})[formName as UserFormsType] = payload
            .forms[formName as UserFormsType] as Form;
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, (state) => {
      return { ...initialState, users: filtersOnly(state.users) };
    });
  },
});
const filtersOnly = (users: UsersState['users']): UsersState['users'] => {
  return Object.entries(users).reduce<
    Record<string, { filters: UserFilters | undefined }>
  >((acc, [companyId, userData]) => {
    acc[companyId] = { filters: userData?.filters };
    return acc;
  }, {}) as UsersState['users'];
};
const persistConfig = {
  key: 'users',
  version: 1,
  storage,
  whitelist: ['users'],
};

export const usersStateName = usersSlice.name;
export const _usersStateReducer = usersSlice.reducer;
export const usersStateActions = usersSlice.actions;
export const usersStateReducer = persistReducer(
  persistConfig,
  _usersStateReducer
);
