import { FC, useCallback, useEffect, useState } from 'react';
import { useGoogleAutocomplete } from '@/components/Google/Autocomplete/useGoogleAutocomplete';
import { AutocompleteInputView } from '@/components/Google/Autocomplete/AutocompleteInputView';

export const GoogleAutocomplete: FC<{
  onChange?: (
    placeResult: google.maps.places.PlaceResult | null,
    placeText?: string
  ) => void;
  onChangeDraft?: (value: string) => Promise<void>;
  placeholder?: string;
  placeId?: string;
  error?: string;
  value?: string;
}> = ({ onChange, onChangeDraft, placeholder, error, value }) => {
  const { ref } = useGoogleAutocomplete({
    onChange,
  });

  const [draftValue, setDraftValue] = useState<null | string>(null);
  const handleOnBlur = useCallback(() => {
    setDraftValue(null);
  }, []);

  useEffect(() => {
    if (!draftValue && value && ref.current) {
      ref.current.value = value ?? '';
    }
  }, [ref, value, draftValue]);

  return (
    <AutocompleteInputView
      ref={ref}
      error={error}
      value={draftValue || value}
      placeholder={placeholder}
      onBlur={handleOnBlur}
      onChange={(e) => {
        setDraftValue(e.target.value?.trim() ?? '');
        void onChangeDraft?.(e.target.value?.trim());
      }}
    />
  );
};
