import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AddressChangeRequest,
  ContactAddress,
} from '@/api/__generated__/webApi';
import { client } from '@/api/client/ApiClient';
import { UUID } from '@/types';

export const addressApi = createApi({
  reducerPath: 'addressApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    // eslint-disable-next-line
    getAddress: builder.query<ContactAddress | null, void>({
      queryFn: async () => {
        try {
          const response = await client.getAccountContactAddresses();
          return { data: response.data[0] };
        } catch (error) {
          return { error };
        }
      },
      providesTags: () => ['ADDRESS'],
    }),
    updateAddress: builder.mutation<ContactAddress, AddressChangeRequest>({
      queryFn: async (data) => {
        try {
          const { data: address } = await client.changeAccountAddress(data);
          return { data: address };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['ADDRESS'],
    }),
    deleteAddress: builder.mutation<undefined, UUID>({
      queryFn: async (accountAddressId) => {
        try {
          const { data: address } = await client.removeAccountContactAddress(
            accountAddressId
          );
          return { data: address };
        } catch (error) {
          return { error };
        }
      },
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          addressApi.util.updateQueryData('getAddress', undefined, (draft) => {
            return null;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
      invalidatesTags: ['ADDRESS'],
    }),
  }),
  tagTypes: ['ADDRESS'],
});
export const {
  useGetAddressQuery,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
} = addressApi;
