import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { DateTime } from 'luxon';
import {
  CalendarPicker,
  DateRange,
} from '@/components/DateControl/DateInput/CalendarPicker';
import { usePopper } from 'react-popper';
import usePortal from '@/hooks/usePortal';
import { DEFAULT_DATE_FORMAT } from '@/components/DateFormat';
import { DatePickerContext } from '@/components/DateControl/DateInput/useDatePickerContext';
import { createPortal } from 'react-dom';
import {
  DateInputProps,
  PARSING_FORMAT,
} from '@/components/DateControl/DateInput/DatePickerInput';
import { Box } from '@mui/material';
import { createEvent } from '@/utils/createEvent';
import { ChangeEventHandler } from '@/hooks/useInternalControlled';

export type PickerProps = DateInputProps & {
  children: (val: {
    value: string | undefined;
    active: boolean;
  }) => JSX.Element;
  className?: string;
  onOpen?: (shown: boolean) => void;
  autofocus?: boolean;
  type?: 'calendar' | 'range';
  range?: [string | null, string | null];
  onChange: ChangeEventHandler<[string, string] | string>;
};
export const HeadlessDatePicker = forwardRef<HTMLDivElement, PickerProps>(
  (props, ref) => {
    const {
      children,
      name,
      value,
      onChange,
      format = PARSING_FORMAT,
      onOpen,
      type,
      range,
      disablePastDates,
      disableFutureDates,
      autofocus = true,
      disabled,
      ...restProps
    } = props;
    const [inputEl, setInputEl] = useState<HTMLDivElement | null>(null);
    const [popperEl, setPopperEl] = useState<HTMLDivElement | null>();
    const [show, setShowHidePicker] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const popperRef = useRef<any>();
    popperRef.current = popperEl;
    useOnClickOutside(
      [containerRef, popperRef],
      () => {
        setShowHidePicker(false);
      },
      true
    );

    const handleChange = useCallback(
      (date: DateTime | DateRange) => {
        if (type === 'range') {
          if (date instanceof Array) {
            const [start, end] = date as [DateTime, DateTime];
            const value = [start.toFormat(format), end.toFormat(format)] as [
              string,
              string,
            ];
            onChange?.({
              target: {
                name,
                value,
              },
            });
            setShowHidePicker(false);
          }
        } else {
          if (date instanceof DateTime) {
            const value = date.toFormat(format);
            onChange?.(createEvent({ name, value }));
            setShowHidePicker(false);
          }
        }
      },
      [format, name, onChange, type]
    );

    const { styles: pStyles, attributes } = usePopper(inputEl, popperEl, {
      placement: 'auto-end',
      modifiers: [
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['top-start', 'bottom-start'],
          },
        },
      ],
    });
    const target = usePortal('modal-root', 'calendar-picker-root');

    const handleKeyUp: React.KeyboardEventHandler = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      switch (e.key) {
        case 'Enter':
          setShowHidePicker(true);
          break;
        case 'Escape':
          setShowHidePicker(false);
          break;
      }
    }, []);

    const onOpenRef = useRef(onOpen);
    onOpenRef.current = onOpen;
    useEffect(() => {
      onOpenRef.current?.(show);
    }, [onOpen, show]);

    useEffect(() => {
      autofocus && popperEl?.getElementsByTagName('button')[0].focus();
    }, [popperEl, autofocus]);

    const dateTimeRange = useMemo(() => {
      if (type !== 'range') {
        return;
      }
      return [
        range?.[0] ? DateTime.fromFormat(range[0], PARSING_FORMAT) : null,
        range?.[1] ? DateTime.fromFormat(range[1], PARSING_FORMAT) : null,
      ] as DateRange;
    }, [range, type]);

    const parsed = DateTime.fromFormat(value ?? '', PARSING_FORMAT);

    const [rangeStart, rangeEnd] = dateTimeRange || [];
    const valueToDisplay =
      type !== 'range'
        ? parsed.isValid
          ? parsed.toFormat(DEFAULT_DATE_FORMAT)
          : ''
        : rangeStart && rangeStart?.isValid && rangeEnd && rangeEnd?.isValid
        ? `${rangeStart.toFormat(DEFAULT_DATE_FORMAT)} - ${rangeEnd.toFormat(
            DEFAULT_DATE_FORMAT
          )}`
        : '';
    const ctxData = useMemo(() => {
      return { disablePastDates, disableFutureDates };
    }, [disableFutureDates, disablePastDates]);
    return (
      <DatePickerContext.Provider value={ctxData}>
        <Box
          sx={disabled ? { pointerEvents: 'none' } : undefined}
          ref={(node: HTMLDivElement) => {
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
            containerRef.current = node;
          }}
          onKeyUp={handleKeyUp}
          {...restProps}
        >
          <Box
            sx={styles.container}
            ref={setInputEl}
            onClick={() => setShowHidePicker(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
              e.stopPropagation();
            }}
          >
            {children({
              value: valueToDisplay,
              active: show,
            })}
          </Box>
          {show &&
            (createPortal(
              <CalendarPicker
                key={value}
                mode={type}
                date={parsed.isValid ? parsed : undefined}
                range={dateTimeRange}
                ref={setPopperEl}
                style={{
                  ...pStyles.popper,
                  zIndex: 1301,
                }}
                onChange={handleChange}
                onClose={() => {
                  setShowHidePicker(false);
                }}
                attrs={attributes.popper}
              />,
              target
            ) as ReactNode)}
        </Box>
      </DatePickerContext.Provider>
    );
  }
);

HeadlessDatePicker.displayName = 'HeadlessDatePicker';

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
};
