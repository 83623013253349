import { ActivationForm } from '@/features/authentication/Activation/fragments/NewPasswordFragment';
import { InviteUserData, PartialRecord } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoginParams,
  RegistrationParams,
  Space,
  FieldValidationError,
  Step,
  Forms,
} from '@/features/authentication/common/types';
import { RegistrationFormState } from '@/features/authentication/Registration/fragments/FormFragment';
import { RecoveryCheckAccountFormState } from '@/features/authentication/Recovery/fragments/CheckAccountFragment';
import { LoginFormState } from '@/features/authentication/Login/fragments/LoginFragment';
import { RecoveryNewPwdFormState } from '@/features/authentication/Recovery/fragments/NewPasswordFragment';
import { globalStateResetAction } from '@/store/common/actions';
import { ConfirmResponse } from '@/api';
import { current } from 'immer';
import { ValidationErrorType } from '@/types/ValidationError';
import { MFAType } from '@/api/__generated__/identityApi';
export enum Fetching {
  Common = 'common',
  CheckInvite = 'check-invite',
}
export interface AuthState {
  step: Step;
  fetchingDeprecated: boolean;
  fetching: PartialRecord<Fetching, boolean> | null;
  forms: {
    [Forms.LoginForm]?: LoginFormState;
    [Forms.RegistrationForm]?: RegistrationFormState;
    [Forms.RecoveryCheckAccountForm]?: RecoveryCheckAccountFormState;
    [Forms.RecoveryNewPasswordForm]?: RecoveryNewPwdFormState;
    [Forms.ActivationForm]?: Omit<ActivationForm, 'confirmPassword'>;
  };
  error: { status: number; statusText: string } | null | undefined;
  code: string;
  pinCodeResendTime: number;
  serverValidation: {
    fieldErrors: PartialRecord<string, ValidationErrorType>;
    errors: PartialRecord<ValidationErrorType, boolean | null | undefined>;
    attempts: PartialRecord<ValidationErrorType, number>;
  };
  userToInvite: InviteUserData | null;
  mfaOptions: MFAType[];
}
const initialState: AuthState = {
  step: 'index',
  fetchingDeprecated: false,
  fetching: {},
  error: null,
  code: '',
  pinCodeResendTime: 0,
  serverValidation: {
    errors: {},
    attempts: {},
    fieldErrors: {},
  },
  forms: {},
  userToInvite: null,
  mfaOptions: [],
};
const regSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMfaOptions(state, action: PayloadAction<MFAType[]>) {
      state.mfaOptions = action.payload;
    },
    goToStep(state, action: PayloadAction<Step>) {
      state.serverValidation.errors = {};
      if (action.payload === 'pin-code-attempts-expired') {
        state.serverValidation.attempts.CODE_VALIDATION_ERROR = 0;
      }
      state.step = action.payload;
    },
    setIsFetchingDeprecated(state, action: PayloadAction<boolean>) {
      state.fetchingDeprecated = action.payload;
      (state.fetching = state.fetching ?? {})[Fetching.Common] = action.payload;
    },
    setIsFetching(
      state,
      action: PayloadAction<{ type?: Fetching; fetching: boolean }>
    ) {
      (state.fetching = state.fetching ?? {})[
        action.payload.type ?? Fetching.Common
      ] = action.payload.fetching;
      state.fetchingDeprecated = action.payload.fetching;
    },
    setBaseError(
      state,
      action: PayloadAction<{ status: number; statusText: string }>
    ) {
      state.error = action.payload;
      state.step = 'error';
    },
    sendCode(
      state,
      action: PayloadAction<{
        code: string;
        activationCode?: string;
        space: Space;
      }>
    ) {
      state.code = action.payload.code;
    },
    requestNewPinCode(state, _action: PayloadAction<Space>) {},
    setResendTime(state, action: PayloadAction<number>) {
      state.pinCodeResendTime = action.payload;
      state.serverValidation.errors.CODE_VALIDATION_ERROR = null;
    },
    setUserToInvite(state, { payload }: PayloadAction<InviteUserData>) {
      state.userToInvite = payload;
    },
    setValidationErrors(
      state,
      action: PayloadAction<
        | {
            error?: ValidationErrorType;
            fieldErrors?: FieldValidationError[];
          }
        | undefined
      >
    ) {
      if (action.payload?.error) {
        state.serverValidation.errors[action.payload.error] = true;
        state.serverValidation.attempts[action.payload.error] =
          (state.serverValidation.attempts[action.payload.error] ?? 0) + 1;
      }
      if (action.payload?.fieldErrors) {
        state.serverValidation.fieldErrors = action.payload.fieldErrors.reduce<
          PartialRecord<string, ValidationErrorType>
        >((acc, item) => {
          acc[item.field] = item.error;
          return acc;
        }, {});
      }
    },
    resetValidationError(state, action: PayloadAction<ValidationErrorType>) {
      state.serverValidation.errors[action.payload] = null;
    },
    resetFieldValidationError(state, action: PayloadAction<string>) {
      delete state.serverValidation.fieldErrors[action.payload];
    },
    resetErrors(state) {
      state.serverValidation.errors = {};
      state.serverValidation.fieldErrors = {};
      state.error = null;
    },

    /// registration
    register(
      state,
      action: PayloadAction<Omit<RegistrationParams, 'recaptcha'>>
    ) {
      state.forms[Forms.RegistrationForm] = action.payload;
      state.serverValidation.errors.CODE_VALIDATION_ERROR = null;
      state.serverValidation.attempts.CODE_VALIDATION_ERROR = 0;
    },
    /// login
    login(state, action: PayloadAction<Omit<LoginParams, 'recaptcha'>>) {
      state.forms[Forms.LoginForm] = action.payload;
      state.serverValidation.errors.CODE_VALIDATION_ERROR = null;
      state.serverValidation.attempts.CODE_VALIDATION_ERROR = 0;
    },
    requestLoginWithMfa(state, action: PayloadAction<{ type: MFAType }>) {
      if (state.forms[Forms.LoginForm])
        state.forms[Forms.LoginForm].mfaType = action.payload.type;
    },
    /// recovery
    recoveryCheckAccount(
      state,
      action: PayloadAction<RecoveryCheckAccountFormState>
    ) {
      state.forms[Forms.RecoveryCheckAccountForm] = action.payload;
    },
    recoverySetNewPassword(
      state,
      action: PayloadAction<RecoveryNewPwdFormState>
    ) {
      state.forms[Forms.RecoveryNewPasswordForm] = action.payload;
    },
    logout() {},
    // activation
    requestInviteExists(
      _state,
      _action: PayloadAction<{ inviteCode: string }>
    ) {},
    requestActivationSetNewPassword(
      _state,
      _action: PayloadAction<{
        inviteCode: string;
        password: string;
        phone: string;
      }>
    ) {},
    storeDataAndDisplayPrivacyPolicy(
      state,
      { payload }: PayloadAction<{ password: string; phone: string }>
    ) {
      state.forms[Forms.ActivationForm] = {
        phone: payload.phone,
        password: payload.password,
      };
      state.step = 'privacy-policy';
    },
    setActivationSuccessInfo(
      state,
      { payload }: PayloadAction<ConfirmResponse | undefined>
    ) {
      const form = state.forms[Forms.ActivationForm];
      if (form) {
        form.activationSuccessData = payload;
      } else {
        console.error(
          '[setActivationSuccessInfo] No activation form data',
          current(state.forms)
        );
      }
    },
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const authReducer = regSlice.reducer;
export const authStateName = regSlice.name;
export const authActions = regSlice.actions;
