import {
  Namespace,
  useTranslation,
  UseTranslationResponse,
} from 'react-i18next';
import { TranslationKeys, TranslationNS } from './TranslationKeys';

export type TKeys<
  T extends TranslationNS = 'validationErrors' | 'common' | 'profile',
> =
  | keyof TranslationKeys[T]
  | keyof TranslationKeys['common']
  | keyof TranslationKeys['validationErrors'];

export const useTranslate = <T extends TranslationNS>(ns: T) => {
  const { t, ready, i18n } = useTranslation(ns);
  return { t, ready, i18n } as {
    t: (
      str: TKeys<T> | undefined | null,
      params?:
        | Record<string, string | number | null | undefined>
        | string
        | undefined
    ) => string;
    ready: boolean;
    i18n: UseTranslationResponse<Namespace>['i18n'];
  };
};
