import { errorToast, successToast } from '@/components/Toast';
import { NotificationType, notifyActions } from './slice';
import { all, takeEvery } from 'redux-saga/effects';
import { SagaPayload } from '../common/types';
import { ValidationErrorType } from '@/types/ValidationError';

export function* errorNotificationsSaga() {
  yield all([takeEvery(notifyActions.setNotification, showNotifications)]);
}

type Action<T extends keyof typeof notifyActions> = SagaPayload<
  (typeof notifyActions)[T]
>;

function* showNotifications({ payload }: Action<'setNotification'>) {
  for (const n of payload) {
    if (n.type === NotificationType.Error) {
      errorToast({ text: n.text as ValidationErrorType }, { autoClose: 10000 });
    }
    switch (n.type) {
      case NotificationType.Error:
        errorToast(
          { text: n.text as ValidationErrorType },
          { autoClose: 10000 }
        );
        break;
      case NotificationType.Success:
        successToast({ text: n.text }, { autoClose: 10000 });
        break;
    }
  }
}
