import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiType } from '@/store/common/types/ThunkApiType';
import { client } from '@/api/client/ApiClient';
import { getErrors } from '@/store/common/error-handlers';

export const confirmChangeAccountPassword = createAsyncThunk<
  void,
  { code: string; recaptcha: string },
  ThunkApiType
>('profile/confirm-change-password', async ({ code, recaptcha }, thunkAPI) => {
  try {
    await client.confirmChangeAccountPassword({ code, recaptcha });
  } catch (e) {
    return thunkAPI.rejectWithValue(getErrors(e));
  }
});
