/** @jsxImportSource @emotion/react */
import { styled } from '@mui/material';

export const IconContainer = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
