/** @jsxImportSource @emotion/react */
import React, { FC, HTMLAttributes, useId } from 'react';
import { styled } from '@mui/material';
interface FileInputProps
  extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  multiple?: boolean;
  accept?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  children: JSX.Element;
}

export const FileInput: FC<FileInputProps> = ({
  multiple,
  accept,
  onChange,
  children,
  ...restProps
}) => {
  const id = useId();
  const childrenClone = React.cloneElement(children, {
    htmlFor: id,
    ...restProps,
  });
  return (
    <>
      <FileInputStyled
        id={id}
        multiple={multiple}
        accept={accept}
        onChange={onChange}
      />
      {childrenClone}
    </>
  );
};
export const FileInputStyled = styled('input')`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
FileInputStyled.defaultProps = {
  type: 'file',
};
