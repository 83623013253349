import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Header, HeaderActionType } from './fragments/Header';
import { DatesArea } from './fragments/DatesArea';
import { MonthsArea } from './fragments/MonthsArea';
import { YearArea } from './fragments/YearArea';
import { DateRange } from '../../CalendarPicker';
import { BodyContainer } from './fragments/BodyContainer';
import { useDatePickerContext } from '@/components/DateControl/DateInput/useDatePickerContext';

export const MAX_LIFE_EXPECTANCY = 150;
export type SelectorType = 'day' | 'month' | 'year';

interface CalendarProps {
  onChange?: (d: DateTime, order: 0 | 1) => void;
  onChangeMonthDate: (d: DateTime, order: 0 | 1) => void;
  date?: DateTime;
  range?: DateRange;
  order?: 0 | 1;
  className?: string;
  mode?: 'range' | 'calendar';
  monthDate: DateTime;
  minYear?: number;
}

export const Calendar: FC<CalendarProps> = ({
  onChange,
  date,
  range,
  order = 0,
  className,
  mode,
  monthDate,
  onChangeMonthDate,
  minYear = 1900,
}) => {
  const [selectorType, setSelectorType] = useState<'day' | 'month' | 'year'>(
    'day'
  );
  const { disablePastDates, disableFutureDates } = useDatePickerContext();

  const handleClick = useCallback(
    (d: DateTime) => {
      if (mode === 'range') {
        onChange?.(d, order);
      } else {
        window.setTimeout(() => {
          onChange?.(d, order);
        }, 100);
      }
    },
    [mode, onChange, order]
  );
  const handleForward = () => {
    const date = monthDate.plus(
      selectorType === 'day' ? { month: 1 } : { year: 1 }
    );
    onChangeMonthDate(date, order);
  };
  const handleBack = () => {
    const date = monthDate.minus(
      selectorType === 'day' ? { month: 1 } : { year: 1 }
    );
    onChangeMonthDate(date, order);
  };

  const handleHeaderActions = ({ type }: { type: HeaderActionType }) => {
    switch (type) {
      case 'year':
      case 'month':
      case 'day':
        setSelectorType(type);
        break;
      case 'back':
        handleBack();
        break;
      case 'forward':
        handleForward();
        break;
    }
  };

  const dateNow = DateTime.now();
  let yearsRange: [number, number] = [
    minYear,
    dateNow.set({ year: minYear }).year + MAX_LIFE_EXPECTANCY,
  ];
  if (disablePastDates && disableFutureDates) {
    console.error(
      '[calendar] You can not disable past and future dates simultaneously. It does not make any sense.'
    );
  } else if (disablePastDates) {
    yearsRange = [dateNow.year, dateNow.year + MAX_LIFE_EXPECTANCY];
  } else if (disableFutureDates) {
    yearsRange = [dateNow.year - MAX_LIFE_EXPECTANCY, dateNow.year];
  }

  return (
    <BodyContainer className={className}>
      <Header
        type={selectorType}
        date={monthDate}
        yearsRange={yearsRange}
        onClick={handleHeaderActions}
      />
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          position: 'relative',
          padding: '0 4px',
        }}
      >
        {selectorType === 'day' && (
          <DatesArea
            onChange={handleClick}
            month={monthDate}
            selected={mode === 'range' ? undefined : date}
            range={range}
          />
        )}
        {selectorType === 'month' && (
          <MonthsArea
            onChange={(d) => {
              onChangeMonthDate(d, order);
              setSelectorType('day');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
          />
        )}
        {selectorType === 'year' && (
          <YearArea
            onChange={(d) => {
              onChangeMonthDate(d, order);
              setSelectorType('day');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
            range={yearsRange}
          />
        )}
      </Box>
    </BodyContainer>
  );
};
