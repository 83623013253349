export const last = (arr: any[]) => arr[arr.length - 1];
export const set = (
  obj: any,
  path: Array<string | number>,
  value: any,
  onlyIfEmpty: boolean
) => {
  let cursor = obj;
  for (const p of path.slice(0, -1)) {
    if (!cursor[p]) {
      cursor[p] = {};
    }
    cursor = cursor[p];
  }
  if (onlyIfEmpty) {
    if (!cursor[path[path.length - 1]]) {
      cursor[path[path.length - 1]] = value;
    }
  } else {
    cursor[path[path.length - 1]] = value;
  }
};
