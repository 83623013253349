/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ADFSOpenidSettings,
  AccountDetails,
  AccountSessionDTO,
  AccountTOTP,
  AccountTOTPCreateRequest,
  AccountTOTPDescriptionUpdateRequest,
  AddAccountContactPhonePayload,
  AddressChangeRequest,
  BaseError,
  BatchIdRequest,
  ChangePassword,
  Company,
  CompanyAddress,
  CompanyAddressCreateRequest,
  CompanyAddressUpdateRequest,
  CompanyConfirmRequest,
  CompanyData,
  CompanyInsuranceCarrier,
  CompanyInsuranceCarrierCreateRequest,
  CompanyPermission,
  CompanyVerifyRequest,
  ConfirmCodeRequest,
  Contact,
  ContactAddress,
  ContactEdit,
  Email,
  EmailCreateRequest,
  GetCompanyModuleRoleUsersParams,
  GetCompanyUsersParams,
  HomePhone,
  InvitationResultResponse,
  KeycloakOpenidSettings,
  MedicareProviderRequest,
  MobilePhone,
  Module,
  ModuleCreateRequest,
  ModuleStatusResponse,
  NPI1Data,
  NPI1ShortData,
  NPI2Data,
  NPI2ShortData,
  PageCompanyUser,
  PageInsuranceCarrier,
  ParentCompanyVerifyRequest,
  PassportDocument,
  Permission,
  RecaptchaRequest,
  RemoveAccountSessionRequest,
  ResendCodeRequest,
  ResendTimeEmail,
  ResendTimeResponse,
  ResolveAccountUrlParams,
  Role,
  RoleCreateRequest,
  RoleUpdateRequest,
  SearchInsuranceCarriersParams,
  URLCompanyModule,
  URLRedirect,
  USABCDocument,
  USADLIDDocument,
  USAGCDocument,
  UpdateCompanyLogoPayload,
  UpdateCompanyOpenidPayload,
  UpdateDescriptionRequest,
  UpdateNameRequest,
  UploadAccountAvatarPayload,
  WorkPhone,
} from './data-contracts';

import { ContentType, HttpClient, RequestParams } from './http-client';

import { ValidationError } from '@/types/ValidationError';

export class Api<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Getting account details
   *
   * @tags Account
   * @name GetAccountDetails
   * @summary Account details
   * @request GET:/api/account
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/getAccountDetails
   * @response `200` `AccountDetails` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountDetails = (params: RequestParams = {}) =>
    this.request<AccountDetails, ValidationError | undefined | BaseError>({
      path: `/api/account`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Upload account avatar
   *
   * @tags Account
   * @name UploadAccountAvatar
   * @summary Upload account avatar
   * @request PATCH:/api/account/avatar
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/uploadAccountAvatar
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  uploadAccountAvatar = (
    data: UploadAccountAvatarPayload,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/avatar`,
      method: 'PATCH',
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Changing account password
   *
   * @tags Account
   * @name ChangeAccountPassword
   * @summary Change account password
   * @request PATCH:/api/account/change-password
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/changeAccountPassword
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  changeAccountPassword = (data: ChangePassword, params: RequestParams = {}) =>
    this.request<ResendTimeResponse, ValidationError | undefined | BaseError>({
      path: `/api/account/change-password`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account companies
   *
   * @tags Account companies
   * @name GetAccountCompanies
   * @summary Account companies
   * @request GET:/api/account/companies
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20companies/getAccountCompanies
   * @response `200` `(Company)[]` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountCompanies = (params: RequestParams = {}) =>
    this.request<Company[], undefined | BaseError>({
      path: `/api/account/companies`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account companies permissions
   *
   * @tags Account companies
   * @name GetAccountCompaniesPermissions
   * @summary Account companies permissions
   * @request GET:/api/account/companies/permissions
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20companies/getAccountCompaniesPermissions
   * @response `200` `(CompanyPermission)[]` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountCompaniesPermissions = (params: RequestParams = {}) =>
    this.request<CompanyPermission[], undefined | BaseError>({
      path: `/api/account/companies/permissions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account company permissions
   *
   * @tags Account companies
   * @name GetAccountCompanyPermissions
   * @summary Account company permissions
   * @request GET:/api/account/companies/{companyId}/permissions
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20companies/getAccountCompanyPermissions
   * @response `200` `CompanyPermission` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountCompanyPermissions = (
    companyId: string,
    params: RequestParams = {}
  ) =>
    this.request<CompanyPermission, undefined | BaseError>({
      path: `/api/account/companies/${companyId}/permissions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Confirming change account password
   *
   * @tags Account
   * @name ConfirmChangeAccountPassword
   * @summary Confirm change account password
   * @request POST:/api/account/confirm/change-password
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/confirmChangeAccountPassword
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmChangeAccountPassword = (
    data: ConfirmCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/confirm/change-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Confirming invite
   *
   * @tags Account
   * @name ConfirmAccountInvite
   * @summary Confirm invite
   * @request POST:/api/account/confirm/invite/{invite}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/confirmAccountInvite
   * @response `200` `InvitationResultResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmAccountInvite = (invite: string, params: RequestParams = {}) =>
    this.request<
      InvitationResultResponse,
      ValidationError | undefined | BaseError
    >({
      path: `/api/account/confirm/invite/${invite}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * @description Editing account contact
   *
   * @tags Account contact
   * @name EditAccountContact
   * @summary Edit account contact
   * @request PUT:/api/account/contact
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/editAccountContact
   * @response `200` `Contact` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  editAccountContact = (data: ContactEdit, params: RequestParams = {}) =>
    this.request<Contact, ValidationError | undefined | BaseError>({
      path: `/api/account/contact`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account contact addresses
   *
   * @tags Account contact
   * @name GetAccountContactAddresses
   * @summary Get account contact addresses
   * @request GET:/api/account/contact/addresses
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/getAccountContactAddresses
   * @response `200` `(ContactAddress)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountContactAddresses = (params: RequestParams = {}) =>
    this.request<ContactAddress[], ValidationError | undefined | BaseError>({
      path: `/api/account/contact/addresses`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Changing account address
   *
   * @tags Account contact
   * @name ChangeAccountAddress
   * @summary Change account address
   * @request POST:/api/account/contact/addresses
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/changeAccountAddress
   * @response `200` `ContactAddress` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  changeAccountAddress = (
    data: AddressChangeRequest,
    params: RequestParams = {}
  ) =>
    this.request<ContactAddress, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/addresses`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Removing account contact address
   *
   * @tags Account contact
   * @name RemoveAccountContactAddress
   * @summary Remove account contact address
   * @request DELETE:/api/account/contact/addresses/{id}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/removeAccountContactAddress
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  removeAccountContactAddress = (id: string, params: RequestParams = {}) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/addresses/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Getting account contact documents
   *
   * @tags Account contact
   * @name GetAccountContactDocuments
   * @summary Get account contact documents
   * @request GET:/api/account/contact/documents
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/getAccountContactDocuments
   * @response `200` `((PassportDocument | USABCDocument | USADLIDDocument | USAGCDocument))[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountContactDocuments = (params: RequestParams = {}) =>
    this.request<
      (PassportDocument | USABCDocument | USADLIDDocument | USAGCDocument)[],
      ValidationError | undefined | BaseError
    >({
      path: `/api/account/contact/documents`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account contact emails
   *
   * @tags Account contact
   * @name GetAccountContactEmails
   * @summary Get account contact emails
   * @request GET:/api/account/contact/emails
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/getAccountContactEmails
   * @response `200` `(ResendTimeEmail)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountContactEmails = (params: RequestParams = {}) =>
    this.request<ResendTimeEmail[], ValidationError | undefined | BaseError>({
      path: `/api/account/contact/emails`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Adding account contact email
   *
   * @tags Account contact
   * @name AddAccountContactEmail
   * @summary Add account contact email
   * @request POST:/api/account/contact/emails
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/addAccountContactEmail
   * @response `200` `ResendTimeEmail` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `428` `ResendTimeResponse` Precondition Required
   * @response `500` `BaseError` Internal Server Error
   */
  addAccountContactEmail = (
    data: EmailCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResendTimeEmail,
      ValidationError | undefined | ResendTimeResponse | BaseError
    >({
      path: `/api/account/contact/emails`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Removing account contact email
   *
   * @tags Account contact
   * @name RemoveAccountContactEmail
   * @summary Remove account contact email
   * @request DELETE:/api/account/contact/emails/{id}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/removeAccountContactEmail
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  removeAccountContactEmail = (id: string, params: RequestParams = {}) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/emails/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Allowed account contact email results delivery
   *
   * @tags Account contact
   * @name AllowAccountContactEmailResultsDelivery
   * @summary Allow account contact email results delivery
   * @request PATCH:/api/account/contact/emails/{id}/allow-results-delivery/{value}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/allowAccountContactEmailResultsDelivery
   * @response `200` `Email` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  allowAccountContactEmailResultsDelivery = (
    id: string,
    value: boolean,
    params: RequestParams = {}
  ) =>
    this.request<Email, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/emails/${id}/allow-results-delivery/${value}`,
      method: 'PATCH',
      format: 'json',
      ...params,
    });
  /**
   * @description Making main account contact email
   *
   * @tags Account contact
   * @name MakeMainAccountContactEmail
   * @summary Make main account contact email
   * @request PATCH:/api/account/contact/emails/{id}/make-main
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/makeMainAccountContactEmail
   * @response `200` `Email` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  makeMainAccountContactEmail = (id: string, params: RequestParams = {}) =>
    this.request<Email, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/emails/${id}/make-main`,
      method: 'PATCH',
      format: 'json',
      ...params,
    });
  /**
   * @description Sending confirmation account contact email
   *
   * @tags Account contact
   * @name SendConfirmationAccountContactEmail
   * @summary Send confirmation account contact email
   * @request POST:/api/account/contact/emails/{id}/send-confirmation
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/sendConfirmationAccountContactEmail
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  sendConfirmationAccountContactEmail = (
    id: string,
    data: RecaptchaRequest,
    params: RequestParams = {}
  ) =>
    this.request<ResendTimeResponse, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/emails/${id}/send-confirmation`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account contact phones
   *
   * @tags Account contact
   * @name GetAccountContactPhones
   * @summary Get account contact phones
   * @request GET:/api/account/contact/phones
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/getAccountContactPhones
   * @response `200` `((HomePhone | MobilePhone | WorkPhone))[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountContactPhones = (params: RequestParams = {}) =>
    this.request<
      (HomePhone | MobilePhone | WorkPhone)[],
      ValidationError | undefined | BaseError
    >({
      path: `/api/account/contact/phones`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Adding account contact phone
   *
   * @tags Account contact
   * @name AddAccountContactPhone
   * @summary Add account contact phone
   * @request POST:/api/account/contact/phones
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/addAccountContactPhone
   * @response `200` `(HomePhone | MobilePhone | WorkPhone)` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `428` `ResendTimeResponse` Precondition Required
   * @response `500` `BaseError` Internal Server Error
   */
  addAccountContactPhone = (
    data: AddAccountContactPhonePayload,
    params: RequestParams = {}
  ) =>
    this.request<
      HomePhone | MobilePhone | WorkPhone,
      ValidationError | undefined | ResendTimeResponse | BaseError
    >({
      path: `/api/account/contact/phones`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Confirmed account contact mobile phone
   *
   * @tags Account contact
   * @name ConfirmAccountContactMobilePhone
   * @summary Confirm account contact mobile phone
   * @request POST:/api/account/contact/phones/confirm/mobile-phone
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/confirmAccountContactMobilePhone
   * @response `200` `MobilePhone` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmAccountContactMobilePhone = (
    data: ConfirmCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<MobilePhone, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/confirm/mobile-phone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Removing account contact phone
   *
   * @tags Account contact
   * @name RemoveAccountContactPhone
   * @summary Remove account contact phone
   * @request DELETE:/api/account/contact/phones/{id}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/removeAccountContactPhone
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  removeAccountContactPhone = (id: string, params: RequestParams = {}) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Allowed account contact phone results delivery
   *
   * @tags Account contact
   * @name AllowAccountContactPhoneResultsDelivery
   * @summary Allow account contact phone results delivery
   * @request PATCH:/api/account/contact/phones/{id}/allow-results-delivery/{value}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/allowAccountContactPhoneResultsDelivery
   * @response `200` `MobilePhone` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  allowAccountContactPhoneResultsDelivery = (
    id: string,
    value: boolean,
    params: RequestParams = {}
  ) =>
    this.request<MobilePhone, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/${id}/allow-results-delivery/${value}`,
      method: 'PATCH',
      format: 'json',
      ...params,
    });
  /**
   * @description Making main account contact mobile phone
   *
   * @tags Account contact
   * @name MakeMainAccountContactMobilePhone
   * @summary Make main account contact mobile phone
   * @request PATCH:/api/account/contact/phones/{id}/make-main
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/makeMainAccountContactMobilePhone
   * @response `200` `MobilePhone` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  makeMainAccountContactMobilePhone = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.request<MobilePhone, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/${id}/make-main`,
      method: 'PATCH',
      format: 'json',
      ...params,
    });
  /**
   * @description Confirms ownership account contact mobile phone
   *
   * @tags Account contact
   * @name ConfirmOwnershipMobileAccountContactPhone
   * @summary Confirm ownership account contact mobile phone
   * @request PATCH:/api/account/contact/phones/{id}/mobile/confirm-ownership
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/confirmOwnershipMobileAccountContactPhone
   * @response `200` `MobilePhone` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmOwnershipMobileAccountContactPhone = (
    id: string,
    data: ConfirmCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<MobilePhone, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/${id}/mobile/confirm-ownership`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Sending confirmation on account contact mobile phone
   *
   * @tags Account contact
   * @name SendConfirmationAccountContactMobilePhone
   * @summary Send confirmation on account contact mobile phone
   * @request POST:/api/account/contact/phones/{id}/mobile/send-confirmation
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20contact/sendConfirmationAccountContactMobilePhone
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  sendConfirmationAccountContactMobilePhone = (
    id: string,
    data: RecaptchaRequest,
    params: RequestParams = {}
  ) =>
    this.request<ResendTimeResponse, ValidationError | undefined | BaseError>({
      path: `/api/account/contact/phones/${id}/mobile/send-confirmation`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting account NPI
   *
   * @tags Account NPI
   * @name GetAccountNpi
   * @summary Get account NPI
   * @request GET:/api/account/npi
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/getAccountNPI
   * @response `200` `NPI1ShortData` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountNpi = (params: RequestParams = {}) =>
    this.request<NPI1ShortData, ValidationError | undefined | BaseError>({
      path: `/api/account/npi`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Confirming account company registration
   *
   * @tags Account NPI
   * @name ConfirmCompanyRegistration
   * @summary Confirm account company registration
   * @request POST:/api/account/npi/company/confirm/registration
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/confirmCompanyRegistration
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmCompanyRegistration = (
    data: CompanyConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/company/confirm/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Verify account company registration
   *
   * @tags Account NPI
   * @name VerifyAccountCompanyRegistration
   * @summary Verified account company registration
   * @request POST:/api/account/npi/company/verify/registration
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/verifyAccountCompanyRegistration
   * @response `200` `NPI2ShortData` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  verifyAccountCompanyRegistration = (
    data: CompanyVerifyRequest,
    params: RequestParams = {}
  ) =>
    this.request<NPI2ShortData, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/company/verify/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Verify parent account company registration
   *
   * @tags Account NPI
   * @name VerifyAccountCompanyRegistrationParent
   * @summary Verified parent account company registration
   * @request POST:/api/account/npi/company/verify/registration/parent
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/verifyAccountCompanyRegistrationParent
   * @response `200` `NPI2ShortData` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  verifyAccountCompanyRegistrationParent = (
    data: ParentCompanyVerifyRequest,
    params: RequestParams = {}
  ) =>
    this.request<NPI2ShortData, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/company/verify/registration/parent`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Confirming account medicare provider registration
   *
   * @tags Account NPI
   * @name ConfirmAccountMedicareProviderRegistration
   * @summary Confirm account medicare provider registration
   * @request POST:/api/account/npi/medicare-provider/confirm/registration
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/confirmAccountMedicareProviderRegistration
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmAccountMedicareProviderRegistration = (
    data: MedicareProviderRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/medicare-provider/confirm/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Verify account medicare provider registration
   *
   * @tags Account NPI
   * @name VerifyAccountMedicareProviderRegistration
   * @summary Verified account medicare provider registration
   * @request POST:/api/account/npi/medicare-provider/verify/registration
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/verifyAccountMedicareProviderRegistration
   * @response `200` `NPI1ShortData` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  verifyAccountMedicareProviderRegistration = (
    data: MedicareProviderRequest,
    params: RequestParams = {}
  ) =>
    this.request<NPI1ShortData, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/medicare-provider/verify/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Verified account medicare provider registration available
   *
   * @tags Account NPI
   * @name ConfirmAccountMedicareProviderRegistration1
   * @summary Verify account medicare provider registration available
   * @request GET:/api/account/npi/medicare-provider/verify/registration/available
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/confirmAccountMedicareProviderRegistration_1
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `409` `undefined` Conflict
   * @response `500` `BaseError` Internal Server Error
   */
  confirmAccountMedicareProviderRegistration1 = (params: RequestParams = {}) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/npi/medicare-provider/verify/registration/available`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Getting account NPI phones
   *
   * @tags Account NPI
   * @name GetAccountNpiPhones
   * @summary Get account NPI phones
   * @request GET:/api/account/npi/phones
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20NPI/getAccountNPIPhones
   * @response `200` `(string)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountNpiPhones = (params: RequestParams = {}) =>
    this.request<string[], ValidationError | undefined | BaseError>({
      path: `/api/account/npi/phones`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Resolving account url
   *
   * @tags Account
   * @name ResolveAccountUrl
   * @summary Resolve account url
   * @request GET:/api/account/resolve-url
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/resolveAccountUrl
   * @response `200` `(URLCompanyModule | URLRedirect)` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  resolveAccountUrl = (
    query: ResolveAccountUrlParams,
    params: RequestParams = {}
  ) =>
    this.request<
      URLCompanyModule | URLRedirect,
      ValidationError | undefined | BaseError
    >({
      path: `/api/account/resolve-url`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting all active account sessions
   *
   * @tags Account sessions
   * @name GetAllActiveSessions
   * @summary Get account sessions
   * @request GET:/api/account/sessions
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20sessions/getAllActiveSessions
   * @response `200` `(AccountSessionDTO)[]` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAllActiveSessions = (params: RequestParams = {}) =>
    this.request<AccountSessionDTO[], undefined | BaseError>({
      path: `/api/account/sessions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Confirmed removing account sessions
   *
   * @tags Account sessions
   * @name ConfirmRemoveSessions
   * @summary Confirm remove account sessions
   * @request POST:/api/account/sessions/confirm/remove
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20sessions/confirmRemoveSessions
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  confirmRemoveSessions = (
    data: ConfirmCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/sessions/confirm/remove`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Removing account sessions
   *
   * @tags Account sessions
   * @name RemoveSessions
   * @summary Remove account sessions
   * @request POST:/api/account/sessions/remove
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account%20sessions/removeSessions
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  removeSessions = (
    data: RemoveAccountSessionRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/sessions/remove`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting account TOTP
   *
   * @tags Account
   * @name GetAccountTotp
   * @summary Account TOTP
   * @request GET:/api/account/totp
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/getAccountTOTP
   * @response `200` `(AccountTOTP)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getAccountTotp = (params: RequestParams = {}) =>
    this.request<AccountTOTP[], ValidationError | undefined | BaseError>({
      path: `/api/account/totp`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Creating account TOTP
   *
   * @tags Account
   * @name CreateAccountTotp
   * @summary Create account TOTP
   * @request POST:/api/account/totp
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/createAccountTOTP
   * @response `200` `AccountTOTP` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  createAccountTotp = (
    data: AccountTOTPCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<AccountTOTP, ValidationError | undefined | BaseError>({
      path: `/api/account/totp`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Updating account TOTP description
   *
   * @tags Account
   * @name UpdateAccountTotpDescription
   * @summary Update account TOTP description
   * @request POST:/api/account/totp/fn/update-description
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/updateAccountTOTPDescription
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  updateAccountTotpDescription = (
    data: AccountTOTPDescriptionUpdateRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/totp/fn/update-description`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Deleting account TOTP
   *
   * @tags Account
   * @name DeleteAccountTotp
   * @summary Delete account TOTP
   * @request DELETE:/api/account/totp/{id}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Account/deleteAccountTOTP
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  deleteAccountTotp = (id: string, params: RequestParams = {}) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/account/totp/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Sending confirmation code
   *
   * @tags Code
   * @name SendCode
   * @summary Send code
   * @request POST:/api/code/send
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Code/sendCode
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  sendCode = (data: ResendCodeRequest, params: RequestParams = {}) =>
    this.request<ResendTimeResponse, ValidationError | BaseError>({
      path: `/api/code/send`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting company addresses
   *
   * @tags Company
   * @name GetCompanyAddresses
   * @summary Get company addresses
   * @request GET:/api/companies/{companyId}/addresses
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/getCompanyAddresses
   * @response `200` `(CompanyAddress)[]` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyAddresses = (companyId: string, params: RequestParams = {}) =>
    this.request<CompanyAddress[], undefined | BaseError>({
      path: `/api/companies/${companyId}/addresses`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Create company address
   *
   * @tags Company
   * @name CreateCompanyAddress
   * @summary Create company address
   * @request POST:/api/companies/{companyId}/addresses
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/createCompanyAddress
   * @response `200` `CompanyAddress` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  createCompanyAddress = (
    companyId: string,
    data: CompanyAddressCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<CompanyAddress, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/addresses`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Update company address
   *
   * @tags Company
   * @name UpdateCompanyAddress
   * @summary Update company address
   * @request PUT:/api/companies/{companyId}/addresses/{addressId}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/updateCompanyAddress
   * @response `200` `CompanyAddress` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyAddress = (
    companyId: string,
    addressId: string,
    data: CompanyAddressUpdateRequest,
    params: RequestParams = {}
  ) =>
    this.request<CompanyAddress, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/addresses/${addressId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting company module available permissions
   *
   * @tags CompanyModule
   * @name GetCompanyModuleAvailablePermissions
   * @summary Get company module available permissions
   * @request GET:/api/companies/{companyId}/company-modules/{companyModuleId}/available-permissions
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/getCompanyModuleAvailablePermissions
   * @response `200` `(Permission)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyModuleAvailablePermissions = (
    companyId: string,
    companyModuleId: string,
    params: RequestParams = {}
  ) =>
    this.request<Permission[], ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/available-permissions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting company module roles
   *
   * @tags CompanyModule
   * @name GetCompanyModuleRoles
   * @summary Get company module roles
   * @request GET:/api/companies/{companyId}/company-modules/{companyModuleId}/roles
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/getCompanyModuleRoles
   * @response `200` `(Role)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyModuleRoles = (
    companyId: string,
    companyModuleId: string,
    params: RequestParams = {}
  ) =>
    this.request<Role[], ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Created custom role
   *
   * @tags CompanyModule
   * @name CreateCustomRole
   * @summary Create custom role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/createCustomRole
   * @response `200` `Role` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  createCustomRole = (
    companyId: string,
    companyModuleId: string,
    data: RoleCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<Role, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Update company module role
   *
   * @tags CompanyModule
   * @name UpdateCompanyModuleRole
   * @summary Update company module role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/fn/update
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/updateCompanyModuleRole
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyModuleRole = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    data: RoleUpdateRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/fn/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting company module role permissions
   *
   * @tags CompanyModule
   * @name GetCompanyModuleRolePermissions
   * @summary Get company module role permissions
   * @request GET:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/permissions
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/getCompanyModuleRolePermissions
   * @response `200` `(Permission)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyModuleRolePermissions = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    params: RequestParams = {}
  ) =>
    this.request<Permission[], ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/permissions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Adding permissions company module to role
   *
   * @tags CompanyModule
   * @name AddCompanyModuleRolePermissions
   * @summary Add company module permissions to role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/permissions/fn/add
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/addCompanyModuleRolePermissions
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  addCompanyModuleRolePermissions = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    data: BatchIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/permissions/fn/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Remove permissions company module from role
   *
   * @tags CompanyModule
   * @name RemoveCompanyModuleRolePermissions
   * @summary Remove company module permissions from role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/permissions/fn/remove
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/removeCompanyModuleRolePermissions
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  removeCompanyModuleRolePermissions = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    data: BatchIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/permissions/fn/remove`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting company module role users
   *
   * @tags CompanyModule
   * @name GetCompanyModuleRoleUsers
   * @summary Get company module role users
   * @request GET:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/users
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/getCompanyModuleRoleUsers
   * @response `200` `PageCompanyUser` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyModuleRoleUsers = (
    {
      companyId,
      companyModuleId,
      roleId,
      ...query
    }: GetCompanyModuleRoleUsersParams,
    params: RequestParams = {}
  ) =>
    this.request<PageCompanyUser, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/users`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Adding users company module to role
   *
   * @tags CompanyModule
   * @name AddCompanyModuleRoleUsers
   * @summary Add company module users to role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/users/fn/add
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/addCompanyModuleRoleUsers
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  addCompanyModuleRoleUsers = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    data: BatchIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/users/fn/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Remove users company module from role
   *
   * @tags CompanyModule
   * @name RemoveCompanyModuleRoleUsers
   * @summary Remove company module users from role
   * @request POST:/api/companies/{companyId}/company-modules/{companyModuleId}/roles/{roleId}/users/fn/remove
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/removeCompanyModuleRoleUsers
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  removeCompanyModuleRoleUsers = (
    companyId: string,
    companyModuleId: string,
    roleId: string,
    data: BatchIdRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/company-modules/${companyModuleId}/roles/${roleId}/users/fn/remove`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting company module status
   *
   * @tags CompanyModule
   * @name GetCompanyModuleStatus
   * @summary Get company module status
   * @request GET:/api/companies/{companyId}/company-modules/{companyModuleId}/status
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#CompanyModule/getCompanyModuleStatus
   * @response `200` `ModuleStatusResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyModuleStatus = (
    companyId: string,
    companyModuleId: string,
    params: RequestParams = {}
  ) =>
    this.request<ModuleStatusResponse, ValidationError | undefined | BaseError>(
      {
        path: `/api/companies/${companyId}/company-modules/${companyModuleId}/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }
    );
  /**
   * @description Updating company description
   *
   * @tags Company
   * @name UpdateCompanyDescription
   * @summary Update company description
   * @request PATCH:/api/companies/{companyId}/description
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/updateCompanyDescription
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyDescription = (
    companyId: string,
    data: UpdateDescriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/description`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting company insurance carriers
   *
   * @tags Company
   * @name GetCompanyInsuranceCarriers
   * @summary Get company insurance carriers
   * @request GET:/api/companies/{companyId}/insurance-carriers
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/getCompanyInsuranceCarriers
   * @response `200` `(CompanyInsuranceCarrier)[]` OK
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyInsuranceCarriers = (
    companyId: string,
    params: RequestParams = {}
  ) =>
    this.request<CompanyInsuranceCarrier[], undefined | BaseError>({
      path: `/api/companies/${companyId}/insurance-carriers`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Create company insurance carrier
   *
   * @tags Company
   * @name CreateCompanyInsuranceCarrier
   * @summary Create company insurance carrier
   * @request POST:/api/companies/{companyId}/insurance-carriers
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/createCompanyInsuranceCarrier
   * @response `200` `CompanyInsuranceCarrier` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  createCompanyInsuranceCarrier = (
    companyId: string,
    data: CompanyInsuranceCarrierCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      CompanyInsuranceCarrier,
      ValidationError | undefined | BaseError
    >({
      path: `/api/companies/${companyId}/insurance-carriers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Removing company insurance carrier
   *
   * @tags Company
   * @name RemoveCompanyInsuranceCarrier
   * @summary Remove company insurance carrier
   * @request DELETE:/api/companies/{companyId}/insurance-carriers/{companyInsuranceCarrierId}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/removeCompanyInsuranceCarrier
   * @response `200` `undefined` OK
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  removeCompanyInsuranceCarrier = (
    companyId: string,
    companyInsuranceCarrierId: string,
    params: RequestParams = {}
  ) =>
    this.request<undefined, undefined | BaseError>({
      path: `/api/companies/${companyId}/insurance-carriers/${companyInsuranceCarrierId}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Updating company logo
   *
   * @tags Company
   * @name UpdateCompanyLogo
   * @summary Update company logo
   * @request PATCH:/api/companies/{companyId}/logo
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/updateCompanyLogo
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyLogo = (
    companyId: string,
    data: UpdateCompanyLogoPayload,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/logo`,
      method: 'PATCH',
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Created company module
   *
   * @tags Company
   * @name CreateCompanyModule
   * @summary Create company module
   * @request POST:/api/companies/{companyId}/modules
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/createCompanyModule
   * @response `200` `Module` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  createCompanyModule = (
    companyId: string,
    data: ModuleCreateRequest,
    params: RequestParams = {}
  ) =>
    this.request<Module, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/modules`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Updating company name
   *
   * @tags Company
   * @name UpdateCompanyName
   * @summary Update company name
   * @request PATCH:/api/companies/{companyId}/name
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/updateCompanyName
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyName = (
    companyId: string,
    data: UpdateNameRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/name`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting NPI
   *
   * @tags NPI
   * @name GetNpi
   * @summary Get NPI
   * @request GET:/api/companies/{companyId}/npi
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#NPI/getNPI
   * @response `200` `(CompanyData | NPI1Data | NPI2Data)` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getNpi = (companyId: string, params: RequestParams = {}) =>
    this.request<
      CompanyData | NPI1Data | NPI2Data,
      ValidationError | undefined | BaseError
    >({
      path: `/api/companies/${companyId}/npi`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting company openid
   *
   * @tags Company
   * @name GetCompanyOpenid
   * @summary Get company openid
   * @request GET:/api/companies/{companyId}/openid
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/getCompanyOpenid
   * @response `200` `(ADFSOpenidSettings | KeycloakOpenidSettings)` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyOpenid = (companyId: string, params: RequestParams = {}) =>
    this.request<
      ADFSOpenidSettings | KeycloakOpenidSettings,
      ValidationError | undefined | BaseError
    >({
      path: `/api/companies/${companyId}/openid`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Updating company openid
   *
   * @tags Company
   * @name UpdateCompanyOpenid
   * @summary Update company openid
   * @request PATCH:/api/companies/{companyId}/openid
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/updateCompanyOpenid
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  updateCompanyOpenid = (
    companyId: string,
    data: UpdateCompanyOpenidPayload,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/openid`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Getting company users
   *
   * @tags Company
   * @name GetCompanyUsers
   * @summary Get company users
   * @request GET:/api/companies/{companyId}/users
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Company/getCompanyUsers
   * @response `200` `PageCompanyUser` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  getCompanyUsers = (
    { companyId, ...query }: GetCompanyUsersParams,
    params: RequestParams = {}
  ) =>
    this.request<PageCompanyUser, ValidationError | undefined | BaseError>({
      path: `/api/companies/${companyId}/users`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Getting users template file
   *
   * @tags Files
   * @name GetUsersTemplateFile
   * @summary Get users template file
   * @request GET:/api/files/users/template
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Files/getUsersTemplateFile
   * @response `200` `string` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  getUsersTemplateFile = (params: RequestParams = {}) =>
    this.request<string, ValidationError | undefined | BaseError>({
      path: `/api/files/users/template`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Getting insurance carrier logo
   *
   * @tags Insurance carrier
   * @name GetInsuranceCarrierLogo
   * @summary Get insurance carrier logo
   * @request GET:/api/insurance-carriers/logo/{id}
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Insurance%20carrier/getInsuranceCarrierLogo
   * @response `200` `string` OK
   * @response `401` `undefined` Unauthorized
   * @response `404` `undefined` Not Found
   * @response `500` `BaseError` Internal Server Error
   */
  getInsuranceCarrierLogo = (id: string, params: RequestParams = {}) =>
    this.request<string, undefined | BaseError>({
      path: `/api/insurance-carriers/logo/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Searching insurance carriers
   *
   * @tags Insurance carrier
   * @name SearchInsuranceCarriers
   * @summary Search insurance carriers
   * @request GET:/api/insurance-carriers/search
   * @docs https://passport-web.test.private.lifedl.net/swagger-ui/index.html#Insurance%20carrier/searchInsuranceCarriers
   * @response `200` `PageInsuranceCarrier` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `500` `BaseError` Internal Server Error
   */
  searchInsuranceCarriers = (
    query: SearchInsuranceCarriersParams,
    params: RequestParams = {}
  ) =>
    this.request<PageInsuranceCarrier, ValidationError | undefined | BaseError>(
      {
        path: `/api/insurance-carriers/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }
    );
}
