import { RoleFormView } from '@/features/module/ModulePage/fragments/RoleFormView';
import { FC } from 'react';
import { useEditRoleForm } from '@/store/companies/hooks/useEditRoleForm';
import { RoleFormType } from '@/features/module/ModulePage/fragments/CreateNewRoleForm';
import { UUID } from '@/types';

export const EditRoleForm: FC<{ back: () => void; className?: string }> = ({
  back,
  className,
}) => {
  const d = useEditRoleForm();
  return (
    <RoleFormView
      edit
      className={className}
      values={d.role as RoleFormType}
      cancel={back}
      onSubmit={(roleForm) => {
        d.submitTheRole({ ...roleForm, id: d.role?.id as UUID });
      }}
      resetErrors={() => {}}
    />
  );
};
