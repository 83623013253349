import { apiClient, identityApiClient } from '@/api/client/ApiClient';
import {
  AccountSearchResponse,
  CompanyPermissions,
  CompanyUUID,
  ModuleRoles,
  OpenIdSettings,
  Page,
  Role,
  RoleUUID,
  User,
  UUID,
} from '@/types';
import { CompanyAddressElement } from '@/types/fromSchema';
import {
  ImportAccountData,
  ImportAccountResponse,
} from '@/features/users-control/import-users/types';
import { AxiosProgressEvent } from 'axios';
import {
  BirthSex,
  InsuranceCarrier,
  Module,
  ModuleType,
  SearchInsuranceCarriersParams,
} from '@/api/__generated__/webApi';
import { ValidationErrorType } from '@/types/ValidationError';

export const getCompanyRoles = async ({ companyId }: { companyId: UUID }) => {
  const response = await apiClient.get<ModuleRoles[]>(
    `/api/companies/${companyId}/roles`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};
export const addCompanyModule = async ({
  id,
  type,
}: {
  id: UUID;
  type: ModuleType;
}): Promise<Module> => {
  const response = await apiClient.post<Module>(
    `/api/companies/${id}/modules`,
    { module: type }
  );
  return response.data;
};

export const saveDescription = async ({
  id,
  description,
}: {
  id: UUID;
  description: string;
}): Promise<void> => {
  const response = await apiClient.patch(`/api/companies/${id}/description`, {
    description,
  });
  return response.data;
};

interface GetAllUsersParams {
  companyId: CompanyUUID;
  excludeRoleId?: UUID | null;
  includeRoleId?: UUID | null;
  includeRoles?: boolean | null;
  filter: string;
  startPage: number;
  perPage: number;
}
export const getAllUsersByPage = async ({
  companyId,
  ...params
}: GetAllUsersParams) => {
  if (!params.excludeRoleId) {
    params.excludeRoleId = null;
  }
  if (!params.includeRoleId) {
    params.includeRoleId = null;
  }
  if (!params.includeRoles) {
    params.includeRoles = null;
  }
  const response = await apiClient.get<Page<User>>(
    `/api/companies/${companyId}/users`,
    { params: { ...params, permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const deleteAccount = async ({
  companyId,
  accountId,
}: {
  companyId: CompanyUUID;
  accountId: UUID;
}) => {
  await apiClient.delete(`/api/companies/${companyId}/accounts/${accountId}`, {
    params: { permissionsCompanyId: companyId },
  });
};

export const addRolesToAccount = async ({
  companyId,
  accountId,
  ids,
}: {
  companyId: UUID;
  accountId: UUID;
  ids: RoleUUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/accounts/${accountId}/roles/fn/add`,
    { ids },
    { params: { permissionsCompanyId: companyId } }
  );
};

export const deleteRolesFromAccount = async ({
  companyId,
  accountId,
  ids,
}: {
  companyId: UUID;
  accountId: UUID;
  ids: RoleUUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/accounts/${accountId}/roles/fn/remove`,
    { ids },
    { params: { permissionsCompanyId: companyId } }
  );
};

export const editRole = async ({
  companyId,
  moduleId,
  role,
}: {
  companyId: UUID;
  moduleId: UUID;
  role: Role;
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${role.id}/fn/update`,
    role,
    { params: { permissionsCompanyId: companyId } }
  );
};
export const accountsInviteSearch = async ({
  companyId,
  data,
}: {
  companyId: UUID;
  data: { email: string | null; phone: string | null; npi: string | null };
}) => {
  const response = await apiClient.post<AccountSearchResponse[]>(
    `/api/companies/${companyId}/accounts/invite/search`,
    data,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};
export const reInviteUser = async ({
  companyId,
  accountId,
}: {
  companyId: UUID;
  accountId: UUID;
}) => {
  const response = await apiClient.post<{ inviteExpiredTime: string }>(
    `/api/companies/${companyId}/accounts/${accountId}/invite`,
    undefined,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};
export interface UserData {
  firstName: string;
  lastName: string;
  middleName?: string | null;
  email: string;
  domainEmail?: string | null;
  birthDate: string;
  birthSex: BirthSex;
}
export const createUser = async ({
  companyId,
  user,
}: {
  companyId: UUID;
  user: UserData;
}) => {
  await apiClient.post(`/api/companies/${companyId}/accounts`, user, {
    params: { permissionsCompanyId: companyId },
  });
};

export const getCompanyAddresses = async ({
  companyId,
}: {
  companyId: UUID;
}) => {
  return await apiClient.get<CompanyAddressElement[]>(
    `/api/companies/${companyId}/addresses`,
    { params: { permissionsCompanyId: companyId } }
  );
};

export const createCompanyAddress = async ({
  companyId,
  address,
}: {
  companyId: UUID;
  address: Required<Omit<CompanyAddressElement, 'id'>>;
}) => {
  const response = await apiClient.post<
    Required<CompanyAddressElement> & { id: UUID }
  >(`/api/companies/${companyId}/addresses`, address, {
    params: { permissionsCompanyId: companyId },
  });
  return response.data;
};
export const editCompanyAddress = async ({
  companyId,
  addressId,
  partialAddress,
}: {
  companyId: UUID;
  addressId: UUID;
  partialAddress: Omit<
    Required<CompanyAddressElement>,
    'address1' | 'address2' | 'state' | 'city' | 'postalCode' | 'id'
  >;
}) => {
  const response = await apiClient.put<
    Required<CompanyAddressElement> & { id: UUID }
  >(`/api/companies/${companyId}/addresses/${addressId}`, partialAddress, {
    params: { permissionsCompanyId: companyId },
  });
  return response.data;
};
export const setOpenIdConnection = async ({
  companyId,
  openIdSettings,
}: {
  companyId: UUID;
  openIdSettings: OpenIdSettings;
}) => {
  await apiClient.patch(`/api/companies/${companyId}/openid`, openIdSettings, {
    params: { permissionsCompanyId: companyId },
  });
};
export const getOpenIdSettings = async ({ companyId }: { companyId: UUID }) => {
  const response = await apiClient.get<OpenIdSettings>(
    `/api/companies/${companyId}/openid`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};
export const authorizeWithActiveDirectory = async ({
  companyId,
  url,
}: {
  companyId: UUID;
  url: string;
}) => {
  const response = await identityApiClient.post<{ redirectUrl: string }>(
    `/api/v1/openid/company/${companyId}`,
    null,
    { params: { webUrl: url, permissionsCompanyId: companyId } }
  );
  return response.data;
};
export const changeCompanyAccountEmail = async ({
  companyId,
  accountId,
  domainEmail,
}: {
  companyId: UUID;
  accountId: UUID;
  domainEmail: string;
}) => {
  const response = await apiClient.put(
    `/api/companies/${companyId}/accounts/${accountId}/domain-email`,
    { domainEmail },
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};
export const sendImportUsersFile = async (
  { companyId, file }: { companyId: UUID; file: FormData },
  onProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  const data = await apiClient
    .post<{ error: ValidationErrorType; responses: ImportAccountResponse[] }>(
      `/api/companies/${companyId}/accounts/import/validate`,
      file,
      {
        onUploadProgress: onProgress,
        params: { permissionsCompanyId: companyId },
      }
    )
    .then((response) => {
      return response.data;
    });
  return data;
};
export const sendImportUsersFixedData = async ({
  companyId,
  requests,
}: {
  companyId: UUID;
  requests: ImportAccountData[];
}) => {
  const response = await apiClient
    .post<
      | { error: ValidationErrorType; responses: ImportAccountResponse[] }
      | undefined
    >(
      `/api/companies/${companyId}/accounts/import/apply`,
      { requests },
      { params: { permissionsCompanyId: companyId } }
    )
    .then((response) => {
      if (!response.data) {
        return;
      }
      return response.data;
    })
    .catch((e) => {
      if (e.response.status === 422) {
        return e.response.data as {
          error: ValidationErrorType;
          responses: ImportAccountResponse[];
        };
      } else {
        return Promise.reject(e);
      }
    });
  return response;
};
export const getAllAccountCompanyPermissions = async () => {
  const response = await apiClient.get<CompanyPermissions[]>(
    'api/account/companies/permissions'
  );
  return response.data;
};
export const getAccountCompanyPermissions = async ({
  companyId,
}: {
  companyId: UUID;
}) => {
  const response = await apiClient.get<CompanyPermissions>(
    `api/account/companies/${companyId}/permissions`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const getCompanyLogo = async ({ companyId }: { companyId: UUID }) => {
  const response = await apiClient.get<string>(
    `api/companies/${companyId}/logo`
  );
  return response.data;
};
export const uploadCompanyLogo = async ({
  companyId,
  data,
}: {
  companyId: UUID;
  data: FormData;
}) => {
  await apiClient.patch(`api/companies/${companyId}/logo`, data);
};

/**
 * @description Getting insurance carrier logo
 *
 * @tags Insurance carrier
 * @name GetInsuranceCarrierLogo
 * @summary Get insurance carrier logo
 * @request GET:/api/insurance-carriers/logo/{id}
 * @docs https://passport-web.dev.private.lifedl.net/swagger-ui/index.html#Insurance%20carrier/getInsuranceCarrierLogo
 * @response `200` `(string)[]` OK
 * @response `401` `void` Unauthorized
 * @response `404` `void` Not Found
 * @response `500` `BaseError` Internal Server Error
 */
export const getInsuranceCarrierLogo = async (id: string) => {
  const response = await apiClient.get<string[]>(
    `/api/insurance-carriers/logo/${id}`
  );
  return response.data;
};

/**
 * @description Searching insurance carriers
 *
 * @tags Insurance carrier
 * @name SearchInsuranceCarriers
 * @summary Search insurance carriers
 * @request GET:/api/insurance-carriers/search
 * @docs https://passport-web.dev.private.lifedl.net/swagger-ui/index.html#Insurance%20carrier/searchInsuranceCarriers
 * @response `200` `Page` OK
 * @response `400` `ValidationError` Bad request
 * @response `401` `void` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const searchInsuranceCarriers = async (
  query: SearchInsuranceCarriersParams
) => {
  const response = await apiClient.get<Page<InsuranceCarrier>>(
    '/api/insurance-carriers/search',
    { params: query }
  );
  return response.data;
};

/**
 * @description Removing company insurance carrier
 *
 * @tags Company
 * @name RemoveCompanyInsuranceCarrier
 * @summary Remove company insurance carrier
 * @request DELETE:/api/companies/{companyId}/insurance-carriers/{companyInsuranceCarrierId}
 * @docs https://passport-web.dev.private.lifedl.net/swagger-ui/index.html#Company/removeCompanyInsuranceCarrier
 * @response `200` `void` OK
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `500` `BaseError` Internal Server Error
 */
export const removeCompanyInsuranceCarrier = async (
  companyId: string,
  companyInsuranceCarrierId: string
) => {
  await apiClient.delete(
    `/api/companies/${companyId}/insurance-carriers/${companyInsuranceCarrierId}`
  );
};

export * as companiesApi from './';
