import React, { FC, useId } from 'react';
import { DatePickerInput } from './DateInput';
import { SxProps } from '@mui/material';
import { InputSize } from '@/components/common/Common';
import { ChangeEventHandler } from '@/hooks/useInternalControlled';
import { ControlBody } from '@/components/common/ControlBody';
import { US_DATE_PLACEHOLDER_SLASHED } from '@/components/DateFormat';

export interface DateControlProps {
  value?: string | undefined;
  name?: string;
  onChange?: ChangeEventHandler;
  error?: string | boolean;
  success?: string | boolean;
  placeholder?: string;
  label: string;
  range?: [string | null, string | null];
  className?: string;
  disabled?: boolean;
  optional?: boolean;
  sx?: SxProps;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  size?: InputSize;
  compact?: boolean;
  hideTipText?: boolean;
  tip?: string;
  ignoreLabelHeight?: boolean;
}
export const DateControl: FC<DateControlProps> = ({
  disabled,
  tip,
  placeholder = US_DATE_PLACEHOLDER_SLASHED,
  name,
  value,
  onChange,
  error,
  label,
  className,
  optional,
  sx,
  disablePastDates,
  disableFutureDates,
  size = 'md',
  compact = true,
  hideTipText,
  ignoreLabelHeight,
}) => {
  const id = useId();
  return (
    <ControlBody
      sx={sx}
      label={label}
      error={error}
      tip={tip}
      optional={optional}
      className={className}
      disabled={disabled}
      hideTipText={hideTipText}
      compact={compact}
      size={size}
      ignoreLabelHeight={ignoreLabelHeight}
    >
      <DatePickerInput
        disabled={disabled}
        disablePastDates={disablePastDates}
        disableFutureDates={disableFutureDates}
        name={name ?? ''}
        error={!!error}
        id={id}
        value={value ?? ''}
        onChange={onChange}
        placeholder={placeholder}
        size={size}
      />
    </ControlBody>
  );
};
