import { useLayoutEffect, useState } from 'react';

export const getRestTime = (timeInMs: number) => {
  const seconds = Math.floor((timeInMs - Date.now()) / 1000);
  if (seconds < 0) {
    return '';
  }
  const pad = (seconds: number | string) => `0${seconds}`.slice(-2);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;

  return `${pad(hours)}:${pad(minutes)}:${pad(sec)}`;
};

export const useMinutesCountDown = (timeInMs: number | undefined) => {
  const [time, setTime] = useState('...');
  useLayoutEffect(() => {
    if (timeInMs === undefined) {
      setTime(() => {
        return '...';
      });
      return;
    }
    setTime(getRestTime(timeInMs));
    const tmt = window.setInterval(() => {
      const time = getRestTime(timeInMs);
      if (!time) {
        window.clearInterval(tmt);
      }
      setTime(time);
    }, 1000);
    return () => {
      window.clearInterval(tmt);
    };
  }, [timeInMs]);

  return time;
};
