import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { useAuthState } from '@/store/auth/hooks';
import { Formik, FormikConfig, FormikProps, useFormikContext } from 'formik';
import { authActions } from '@/store/auth/slice';
import { Grid, Link } from '@/components';
import { Button } from '@/components/Button';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import * as yup from 'yup';
import { passwordValidator } from '../../../common/validators';
import { Forms } from '../../../common/types';
import { Stack, Typography } from '@mui/material';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { AppRoutes } from '@/routes/appRoutes';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { InputControl } from '@/components/InputControl';
import { useMQuery } from '@/hooks/useMQuery';
import { Notification } from '@/components/Notification';

export interface RecoveryNewPwdFormState {
  password?: string;
  confirmPassword?: string;
}
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  const { t: tr } = useTranslate('password');
  return useMemo(() => {
    return yup.object().shape({
      password: passwordValidator(tr),
      confirmPassword: yup
        .string()
        .required(t('PLEASE_CONFIRM_PASSWORD'))
        .oneOf([yup.ref('password')], t('PASSWORDS_DO_NOT_MATCH')),
    });
  }, [t, tr]);
};
export const NewPasswordFragment: FC = () => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<RecoveryNewPwdFormState>>(null);
  const { fetchingDeprecated: fetching } = useAuthState();
  useRecaptcha();
  const schema = useValidationSchema();
  const submitForm: FormikConfig<RecoveryNewPwdFormState>['onSubmit'] =
    useCallback(
      async (formData, formikHelpers) => {
        dispatch(authActions.recoverySetNewPassword(formData));
        formikHelpers.setSubmitting(true);
      },
      [dispatch]
    );

  useEffect(() => {
    window.setTimeout(() => {
      formikRef.current?.setSubmitting(fetching);
    });
  }, [fetching]);
  return (
    <Formik<RecoveryNewPwdFormState>
      innerRef={formikRef}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      onSubmit={submitForm}
      validateOnChange={true}
      validationSchema={schema}
    >
      <FormView />
    </Formik>
  );
};
export const FormView: FC = () => {
  const { t } = useTranslate('auth');
  const dispatch = useDispatch();
  const { serverValidation, forms } = useAuthState();
  const q = useMQuery();
  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
    useFormikContext<RecoveryNewPwdFormState>();
  const handleChangeWithValidationReset = useCallback(
    (e: React.ChangeEvent<any>) => {
      const name = e.target.name;
      if (serverValidation.fieldErrors[name]) {
        dispatch(authActions.resetFieldValidationError(name));
      }
      handleChange(e);
    },
    [dispatch, handleChange, serverValidation.fieldErrors]
  );

  const byEmail = forms[Forms.RecoveryCheckAccountForm]?.selected === 'email';

  return (
    <Stack width={'100%'} justifyContent={{ xs: 'space-between' }}>
      <Stack width={'100%'} height={'100%'}>
        <AuthHeader
          title={t('ACCOUNT_RECOVERY')}
          sx={{ mb: { xs: 24, sm: 66, lg: 48 } }}
        >
          <Link
            to={{ pathname: AppRoutes.AUTH, search: window.location.search }}
          >
            {t('SIGN_IN')}
          </Link>
        </AuthHeader>
        <Typography
          variant={q.mobile ? '14_18_500' : '16_20_500'}
          mb={{ xs: 48, sm: 120, lg: 136 }}
        >
          {byEmail ? t('PIN_SEND_BY_EMAIL') : t('PIN_SEND_BY_PHONE_NUMBER')}
        </Typography>
        <Stack
          sx={{ flex: '1 1 auto' }}
          component={'form'}
          id={'new_password_form'}
          onSubmit={handleSubmit}
        >
          <Grid
            container
            rowSpacing={{
              xs: 48,
            }}
          >
            <Grid xs={12} sm={6} lg={12}>
              <InputControl
                label={t('NEW_PASSWORD')}
                type={'password'}
                name={'password'}
                placeholder={t('YOUR_PASSWORD')}
                value={values.password}
                onChange={handleChangeWithValidationReset}
                error={touched.password && errors.password}
                ignoreLabelHeight
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              sx={{ display: { xs: 'none', sm: 'flex', lg: 'none' } }}
            />
            <Grid xs={12} sm={6} lg={12}>
              <InputControl
                label={t('CONFIRM_NEW_PASSWORD')}
                type={'password'}
                name={'confirmPassword'}
                placeholder={t('YOUR_PASSWORD')}
                value={values.confirmPassword ?? ''}
                onChange={handleChangeWithValidationReset}
                error={touched.confirmPassword && errors.confirmPassword}
                ignoreLabelHeight
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: { xs: 36 }, mb: { xs: 48 } }}>
            <Grid xs={12} sm={6} lg={12}>
              <Notification type={'warning'}>
                {t('PLEASE_CREATE_A_STRONG_PASSWORD_')}
              </Notification>
            </Grid>
          </Grid>
        </Stack>
        <Button
          fullWidth
          form={'new_password_form'}
          type={'submit'}
          disabled={isSubmitting}
          color={'secondary'}
          sx={{ mb: { xs: 48, sm: 36, lg: 24 } }}
        >
          {t('CONFIRM')}
        </Button>
      </Stack>
      <RecaptchaNote />
    </Stack>
  );
};
