import { Theme } from '@mui/material';

export const tableFullWidthPaddingsStyle = (t: Theme) => ({
  [t.breakpoints.up('xs')]: {
    thead: {
      tr: {
        borderTop: `1px solid ${t.colors.borderLight}`,
      },
      th: {
        '&:first-of-type': {
          paddingLeft: 24,
        },
        '&:last-of-type': {
          paddingRight: 24,
        },
      },
    },
    tbody: {
      td: {
        '&:first-of-type': {
          paddingLeft: 24,
        },
        '&:last-of-type': {
          paddingRight: 24,
        },
      },
    },
  },
  [t.breakpoints.up('lg')]: {
    thead: {
      'tr th': {
        '&:first-of-type': {
          paddingLeft: 36,
        },
        '&:last-of-type': {
          paddingRight: 36,
        },
      },
    },
    tbody: {
      'tr td': {
        '&:first-of-type': {
          paddingLeft: 36,
        },
        '&:last-of-type': {
          paddingRight: 36,
        },
      },
    },
  },
});
