/** @jsxImportSource @emotion/react */
import { Box, css as _, Stack, styled, Theme } from '@mui/material';
import { FC, MutableRefObject, useRef, useState } from 'react';
import { useDataUrl } from '@/hooks/useDataUrl';
import { CropRotateHandler } from './fragments/CropRotateHandler';
import { rotateAndCrop } from './utils';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { BoxTypeMap } from '@mui/system';

const calcCenterOffset = (
  nContainer: { size: number; offset: number },
  wContainer: { size: number; offset: number }
) => {
  return (
    nContainer.offset -
    wContainer.offset -
    (wContainer.size - nContainer.size) / 2
  );
};

interface PhotoEditorProps {
  file: File | undefined;
  formId: string;
  onSubmit: (file: File) => void;
  round?: boolean;
  resetRef?: MutableRefObject<() => void>;
}
export const PhotoEditor: FC<PhotoEditorProps> = ({
  file,
  formId,
  onSubmit,
  round = true,
  resetRef,
}) => {
  const src = useDataUrl(file);
  const [imageLoaded, setImageLoaded] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const transformContainerRef = useRef<HTMLDivElement>(null);
  const formContainerRef = useRef<HTMLFormElement>(null);

  const transformOriginRef = useRef({ x: 0, y: 0 });
  const panRef = useRef({ x: 0, y: 0 });
  const imgParamsRef = useRef({ deg: 0, translate: { x: 0, y: 0 } });
  const transformContainerParamsRef = useRef({
    deg: 0,
    translate: { x: 0, y: 0 },
  });

  const cropResetRef = useRef(() => {});

  const setTransformContainerParams = ({
    x,
    y,
    deg,
    transformOrigin,
  }: {
    x?: number;
    y?: number;
    deg?: number;
    transformOrigin?: { x: number; y: number };
  }) => {
    const ref = transformContainerRef.current as HTMLDivElement;
    if (x != null) {
      transformContainerParamsRef.current.translate.x =
        x ?? transformContainerParamsRef.current.translate.x;
      ref.style.left = `${transformContainerParamsRef.current.translate.x}px`;
    }
    if (y != null) {
      transformContainerParamsRef.current.translate.y =
        y ?? transformContainerParamsRef.current.translate.y;
      ref.style.top = `${transformContainerParamsRef.current.translate.y}px`;
    }
    if (deg != null) {
      transformContainerParamsRef.current.deg =
        deg ?? transformContainerParamsRef.current.deg;
      ref.style.transform = `rotate(${transformContainerParamsRef.current.deg}deg)`;
    }
    if (transformOrigin != null) {
      transformOriginRef.current = transformOrigin;
      ref.style.transformOrigin = `${transformOriginRef.current.x}px ${transformOriginRef.current.y}px`;
    }
  };

  const setRotateData = ({
    angle,
    transformOrigin: { x, y },
  }: {
    angle: number;
    transformOrigin: { x: number; y: number };
  }) => {
    const prevTransformOrigin = transformOriginRef.current;
    if (
      prevTransformOrigin.x !== x ||
      prevTransformOrigin.y !== y ||
      transformContainerParamsRef.current.translate.x !== 0 ||
      transformContainerParamsRef.current.translate.y !== 0
    ) {
      const formContainer = formContainerRef.current as HTMLFormElement;
      const img = imgRef.current as HTMLImageElement;
      const containerRect = formContainer.getBoundingClientRect();
      const imgRect = img.getBoundingClientRect();
      const x1 = calcCenterOffset(
        { offset: containerRect.x, size: containerRect.width },
        { offset: imgRect.x, size: imgRect.width }
      );
      const y2 = calcCenterOffset(
        { offset: containerRect.y, size: containerRect.height },
        { offset: imgRect.y, size: imgRect.height }
      );
      imgParamsRef.current = {
        deg: imgParamsRef.current.deg + transformContainerParamsRef.current.deg,
        translate: {
          x: x1,
          y: y2,
        },
      };
      img.style.transform = `rotate(${imgParamsRef.current.deg}deg)`;
      img.style.marginTop = `${-imgParamsRef.current.translate.y}px`;
      img.style.marginLeft = `${-imgParamsRef.current.translate.x}px`;
      setTransformContainerParams({ x: 0, y: 0, transformOrigin: { x, y } });
    }
    const rotate = angle - imgParamsRef.current.deg;
    setTransformContainerParams({ deg: rotate });
  };

  const reset = () => {
    imgParamsRef.current = { deg: 0, translate: { x: 0, y: 0 } };
    const img = imgRef.current as HTMLImageElement;
    img.style.transform = '';
    img.style.marginTop = '';
    img.style.marginLeft = '';
    setTransformContainerParams({
      x: 0,
      y: 0,
      deg: 0,
      transformOrigin: { x: 0, y: 0 },
    });
    cropResetRef.current();
  };
  if (resetRef) {
    resetRef.current = reset;
  }
  const submitHandler: React.FormEventHandler = (e) => {
    e.preventDefault();
    const cropArea = window.document.getElementById('crop_area') as HTMLElement;
    const cW = parseFloat(cropArea.style.width);
    const cX = parseFloat(cropArea.style.left);
    const cY = parseFloat(cropArea.style.top);
    const degree =
      imgParamsRef.current.deg + transformContainerParamsRef.current.deg;
    const img = imgRef.current as HTMLImageElement;
    const container = formContainerRef.current as HTMLFormElement;
    const imgRect = img.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const imgOffset = {
      x: -calcCenterOffset(
        { size: containerRect.width, offset: containerRect.x },
        { size: imgRect.width, offset: imgRect.x }
      ),
      y: -calcCenterOffset(
        { size: containerRect.height, offset: containerRect.y },
        { size: imgRect.height, offset: imgRect.y }
      ),
    };

    const transform = {
      rotate: degree,
      offset: imgOffset,
    };

    void rotateAndCrop(imgRef.current as HTMLImageElement, {
      cropArea: {
        size: cW,
        x: cX,
        y: cY,
      },
      rotate: { deg: transform.rotate, offset: transform.offset },
    }).then((file) => {
      onSubmit(file);
    });
  };

  const panImage = ({
    x,
    y,
    type,
  }: {
    x: number;
    y: number;
    type: 'start' | 'stop' | 'move';
  }) => {
    const container = transformContainerRef.current as HTMLImageElement;
    switch (type) {
      case 'start': {
        const initX = parseFloat(container.style.left || '0');
        const initY = parseFloat(container.style.top || '0');
        panRef.current = {
          x: initX,
          y: initY,
        };
        break;
      }
      case 'stop':
        break;
      case 'move':
        setTransformContainerParams({
          x: panRef.current.x + x,
          y: panRef.current.y + y,
        });
        break;
    }
  };

  const imageOnLoad = () => {
    setImageLoaded(true);
    const img = imgRef.current as HTMLImageElement;
    const form = formContainerRef.current as HTMLFormElement;
    const container = transformContainerRef.current as HTMLDivElement;
    form.style.width = `${img.offsetWidth}px`;
    form.style.height = `${img.offsetHeight}px`;
    container.style.width = `${img.offsetWidth}px`;
    container.style.height = `${img.offsetHeight}px`;
  };

  return (
    <Stack sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <EditorContainer
        id={formId}
        onSubmit={submitHandler}
        ref={formContainerRef}
      >
        <TransformContainer ref={transformContainerRef}>
          <Image src={src} onLoad={imageOnLoad} ref={imgRef} />
        </TransformContainer>
        {imageLoaded && (
          <CropRotateHandler
            round={round}
            rotate={setRotateData}
            pan={panImage}
            resetRef={cropResetRef}
          />
        )}
      </EditorContainer>
    </Stack>
  );
};

const EditorContainer = styled(Box)(
  ({ theme: t }) => _`
  --checker-color: ${t.palette.grey[300]}; 
  --checker-size: 16px; background-color: white;
`
);
EditorContainer.defaultProps = {
  sx: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    backgroundImage:
      'linear-gradient(45deg, var(--checker-color) 25%, transparent 25%), linear-gradient(-45deg, var(--checker-color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--checker-color) 75%), linear-gradient(-45deg, transparent 75%, var(--checker-color) 75%)',
    backgroundSize:
      'calc(2 * var(--checker-size)) calc(2 * var(--checker-size))',
    backgroundPosition:
      '0 0, 0 var(--checker-size), var(--checker-size) calc(-1 * var(--checker-size)), calc(-1 * var(--checker-size)) 0px',
    backgroundColor: 'white',
  },
  component: 'form',
};
const TransformContainer = styled(Box)(
  () => _`
`
);
TransformContainer.defaultProps = {
  sx: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
};
const Image = styled(
  Box as OverridableComponent<BoxTypeMap<{}, 'img', Theme>>
)``;
Image.defaultProps = {
  component: 'img',
  sx: {
    pointerEvents: 'none',
    width: '100%',
    height: 'auto',
    display: 'block',
  },
};
