/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from 'react';
import { ReactComponent as IconSVGWarning } from '@/images/icons/icon_round_warning.svg';
import { ReactComponent as IconSVGSuccess } from '@/images/icons/icon_round_success.svg';
import { ReactComponent as IconSVGInfo } from '@/images/icons/icon_round_info.svg';
import { ReactComponent as IconSVGError } from '@/images/icons/icon_round_error.svg';
import {
  Box,
  css,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@/theme/utils';
interface Props {
  children: ReactNode;
  type: 'warning' | 'success' | 'info' | 'error';
  variant?: 'contained' | 'text';
  className?: string;
  sx?: SxProps<Theme>;
}

/**
 * @deprecated consider use Notification component or just typography with icon
 */
export const NotificationText: FC<Props> = ({
  type,
  variant = 'text',
  children,
  className,
  sx,
}) => {
  const theme = useTheme();
  let icon;
  let color = '';
  switch (type) {
    case 'success':
      icon = (
        <IconSVGSuccess
          css={css`
            min-width: 20px;
          `}
        />
      );
      color = theme.palette.success.main;
      break;
    case 'warning':
      icon = (
        <IconSVGWarning
          css={css`
            min-width: 20px;
          `}
        />
      );
      color = theme.palette.warning.main;
      break;
    case 'info':
      icon = (
        <IconSVGInfo
          css={css`
            min-width: 20px;
          `}
        />
      );
      break;
    case 'error':
      icon = (
        <IconSVGError
          css={css`
            min-width: 20px;
          `}
        />
      );
      color = theme.palette.error.main;
      break;
    default:
      icon = <></>;
  }

  if (variant === 'contained') {
    return (
      <Box
        display={'flex'}
        alignItems={'stretch'}
        paddingX={14}
        paddingY={12}
        minHeight={48}
        boxSizing={'border-box'}
        gap={{ xs: 12, sm: 14 }}
        sx={[
          (t) => ({ backgroundColor: alpha(color, 10) }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Stack justifyContent={'center'}>{icon}</Stack>
        <Stack justifyContent={'center'}>
          <Typography variant={'14_20_700'} lineHeight={'18px'} minWidth={60}>
            {children}
          </Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Typography
      className={className}
      sx={[
        {
          display: 'inline-flex',
          textAlign: 'left',
          alignItems: 'center',
          svg: { padding: '0 10px 0 0' },
        },
        { fontSize: 16, lineHeight: '22px', fontWeight: 500 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component={'p'}
    >
      {icon}
      {children}
    </Typography>
  );
};
