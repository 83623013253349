import { createSlice } from '@reduxjs/toolkit';
import { ValidationErrorType } from '@/types/ValidationError';
import { confirmChangeAccountPassword } from './api';

interface PwdChangeState {
  validationErrors: Record<string, ValidationErrorType>;
}
const initialState: PwdChangeState = {
  validationErrors: {},
};

export const profilePwdChangeSlice = createSlice({
  initialState,
  name: 'pwdChange',
  reducers: {
    resetValidationErrors(state) {
      state.validationErrors = {};
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      confirmChangeAccountPassword.rejected,
      (state, { payload }) => {
        if (payload?.common === ValidationErrorType.CODE_VALIDATION_ERROR) {
          state.validationErrors = {
            code: ValidationErrorType.CODE_VALIDATION_ERROR,
          };
          return;
        }
        state.validationErrors = payload ?? {};
      }
    );
  },
});

export const profilePwdChangeActions = profilePwdChangeSlice.actions;
