import { useRef, useState } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { QuestionMarkButton } from './QuestionMarkButton';
import Menu from '@mui/material/Menu';
import { MenuItem, Typography } from '@mui/material';
import { envs } from '@/constants';

export const QuestionMarkButtonMenu = () => {
  const btRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslate('profile');

  return (
    <>
      <QuestionMarkButton
        ref={btRef}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : (event.currentTarget as HTMLElement));
        }}
      />
      <Menu
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        sx={{
          filter:
            'drop-shadow(0px 8px 12px rgba(77, 77, 77, 0.07)) drop-shadow(0px 0px 6px rgba(77, 77, 77, 0.07))',
        }}
        MenuListProps={{
          sx: (t) => ({
            minWidth: 223,
            padding: 0,
            border: `1px solid ${t.colors.borderLight}!important`,
          }),
        }}
      >
        <MenuItem component={'a'} href={envs.HELP_URL}>
          <Typography>{t('USER_GUIDE')}</Typography>
        </MenuItem>
        <MenuItem component={'a'} href={envs.CONTACT_SUPPORT_URL}>
          <Typography>{t('CONTACT_SUPPORT')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
