export const applyMask = ({ prevMaskedValue, insertedValue, applyFromIndex = 0 }: {prevMaskedValue: string, insertedValue: string, applyFromIndex: number}) => {
  let pointer = applyFromIndex;
  let newValue = prevMaskedValue.slice(0, applyFromIndex);
  let cursorPosition = prevMaskedValue.length;
  while (pointer < prevMaskedValue.length && insertedValue) {
    const symbol = prevMaskedValue[pointer];
    pointer++;
    if (/[^ymd0-9]/i.test(symbol)) {
      newValue = newValue + symbol;
      continue;
    }
    newValue = newValue + insertedValue[0];
    insertedValue = insertedValue.slice(1);
  }
  if (newValue.length < prevMaskedValue.length) {
    cursorPosition = newValue.length;
    newValue = newValue + prevMaskedValue.slice(newValue.length);
  }
  return { maskedValue: newValue, cursorPosition };
};
