/** @jsxImportSource @emotion/react */
import { styled, css as _ } from '@mui/material';

export const DocsTable = styled('table')(
  ({ theme: t }) => _`
  border-collapse: collapse;
  line-height: 28px;
  width: 100%;
  tr {
    
    &:last-of-type {
      border-bottom: none;
    }
    ${t.breakpoints.up('lg')} {
      border-bottom: 1px solid ${t.colors.borderLight};
    }
  }
  th, td {
    text-align: left;
    padding: 18px 10px;
    vertical-align: top;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
  th {
    padding-left: 0;
  }
  td {
    font-size: 16px;
  }
    ${t.breakpoints.up('sm')} {
        tr {
            width: 48%
        }
    }
  .tablet-layout &, .mobile-layout & {
    tr {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 6px;
    }
    tbody {
      display: flex;
      row-gap: 36px;
      gap: 36px;
      flex-wrap: wrap;
    }
    th, td {
      padding: 0;
    }
  }
`
);
