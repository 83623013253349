/** @jsxImportSource @emotion/react */
import { WhiteBox, BoxBody, BoxHeaderDeprecated } from '@/components/WhiteBox';
import { Grid, GridContainer } from '@/components';
import { InputControl, InputControlProps } from '@/components/InputControl';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useEffect, useId, useRef } from 'react';
import { useOrgNPI, useCompanyNPIForm } from '@/store/npi-org/hooks';
import { Text } from '@/components/Text';
import { Box, ButtonProps, css as _, Stack, styled } from '@mui/material';
import { Button } from '@/components/Button';
import { useMQuery } from '@/hooks/useMQuery';
import { Dl } from '@/components/Dl';
import { SubHeader } from '../shared/SubHeader';
import {
  RequestNPIOrgFlowSteps,
  companyNpiActions,
} from '@/store/npi-org/slice';
import { useDispatch } from 'react-redux';
import { ValidationErrorType } from '@/types/ValidationError';

import { EMPTY } from '@/constants';
import { NPI2ShortData } from '@/api/__generated__/webApi';
import { useSectionsOfNpiOrgData } from '@/features/profile/NPISearchForms/NPIOrg/useSectionsOfNpiOrgData';

// TODO [tech-debt]: split file to components etc.
const YesNoLayout = styled(Box)`
  display: flex;
  gap: 24px;
  row-gap: 36px;
  .mobile-layout & {
    flex-direction: column;
  }
`;

export const NPIDataConfirmationOrg: FC = () => {
  const { step, id, npi, parentNpiId, parentNPI, resetNPIInfo, fetching } =
    useOrgNPI();
  const {
    handleChangeDomain,
    handleSubmitDomain,
    handleChangeNPI,
    handleSubmitNPI,
    domainValue,
    error,
    dirty,
  } = useCompanyNPIForm(true);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslate('profile');

  const showNpi = step !== 'init';
  const showParentNpi =
    step === 'enter-subdomain-subpart' || step === 'is-that-yours-subpart';

  const showConfirmForm =
    step === 'is-that-yours' || step === 'is-that-yours-subpart';

  const showEnterDomainForm =
    step === 'enter-subdomain' || step === 'enter-subdomain-subpart';

  const showRequestParentNPIForm = step === 'enter-main-npi-of-subpart';

  useEffect(() => {
    if (showNpi) {
      window.setTimeout(() => {
        boxRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [showNpi]);

  if (!showNpi) {
    return null;
  }
  return (
    <WhiteBox ref={boxRef}>
      <BoxBody>
        <NPIOrgInfo
          npi={npi}
          title={t('INFORMATION_ABOUT_NPI', { number: id })}
          mb={showParentNpi ? 50 : 0}
        />
        {showParentNpi && (
          <NPIOrgInfo
            title={t('INFORMATION_ABOUT_PARENT_NPI', { number: parentNpiId })}
            npi={parentNPI}
          />
        )}
      </BoxBody>
      {showRequestParentNPIForm && (
        <RequestParentNPIForm
          error={error ?? undefined}
          dirty={dirty}
          onChange={handleChangeNPI}
          onSubmit={handleSubmitNPI}
        />
      )}
      {showConfirmForm && (
        <YesNoForm onCancel={resetNPIInfo} fetching={fetching} step={step} />
      )}
      {showEnterDomainForm && (
        <EnterSubdomainForm
          onSubmit={handleSubmitDomain}
          fetching={fetching}
          domainValue={domainValue}
          domainOnChange={handleChangeDomain}
          error={error}
        />
      )}
    </WhiteBox>
  );
};

const YesNoForm: FC<{
  onCancel: () => void;
  fetching: boolean;
  step: RequestNPIOrgFlowSteps;
}> = ({ step, onCancel, fetching }) => {
  const { mobile } = useMQuery();
  const { t } = useTranslate('profile');
  const dispatch = useDispatch();
  return (
    <YesNoLayout>
      <Box
        justifyContent={{ xs: 'center', sm: 'start', lg: 'end' }}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        fontSize={{ xs: '18px', lg: '14px' }}
        lineHeight={'24px'}
      >
        {t('IS_THIS_REALLY_YOUR_DATA')}
      </Box>
      <Stack
        flexDirection={'row'}
        gap={24}
        width={{ xs: '100%', sm: 'fit-content' }}
      >
        <Button
          variant={'outlined'}
          onClick={onCancel}
          disabled={fetching}
          fullWidth={mobile}
        >
          {t('NO')}
        </Button>
        <Button
          variant={'contained'}
          disabled={fetching}
          fullWidth={mobile}
          onClick={() =>
            dispatch(
              companyNpiActions.setStep({
                step:
                  step === 'is-that-yours-subpart'
                    ? 'enter-subdomain-subpart'
                    : 'enter-subdomain',
              })
            )
          }
        >
          {t('YES')}
        </Button>
      </Stack>
    </YesNoLayout>
  );
};

const EnterSubdomainForm: FC<{
  onSubmit: () => void;
  fetching: boolean;
  domainValue: string | null;
  domainOnChange: React.ChangeEventHandler;
  error: ValidationErrorType | null | undefined;
}> = ({ domainValue, domainOnChange, error, fetching, onSubmit }) => {
  const { t } = useTranslate('profile');

  return (
    <FormView
      enforceSmSize
      input={
        <InputControl
          type="text"
          label={t('SUBDOMAIN_NAME')}
          placeholder={t('ENTER_HERE')}
          name="thDomain"
          value={domainValue}
          onChange={domainOnChange}
          error={error && t(error)}
          simpleTextInput
          max={10}
        />
      }
      submitButton={
        <Button variant={'contained'} disabled={fetching}>
          {t('COMPLETE_REGISTRATION')}
        </Button>
      }
      text={t('YOU_CAN_EDIT_THIS_FIELD_TO_GET_A_RECOGNIZABLE_')}
      onSubmit={onSubmit}
    />
  );
};

const RequestParentNPIForm: FC<{
  error: ValidationErrorType | undefined;
  dirty: boolean | undefined;
  onChange: React.ChangeEventHandler;
  onSubmit: () => void;
  className?: string;
}> = ({ onChange, onSubmit, error, dirty, className }) => {
  const { t } = useTranslate('profile');

  return (
    <>
      <FormView
        className={className}
        submitButton={
          <Button variant={'contained'} disabled={dirty && !!error}>
            {t('CHECK')}
          </Button>
        }
        input={
          <InputControl
            max={10}
            type="number"
            name="npi"
            placeholder={t('ENTER_NPI_NUMBER')}
            onChange={onChange}
            error={dirty && error && t(error)}
          />
        }
        onSubmit={onSubmit}
        text={t('WE_FOUND_YOUR_COMPANY_IS_A_SUBPART_ORGANIZATION')}
      />
    </>
  );
};
const FormView: FC<{
  submitButton: JSX.Element;
  input: JSX.Element;
  text: string;
  className?: string;
  onSubmit: () => void;
  enforceSmSize?: boolean; // have to enforce view type for some cases
}> = ({ submitButton, input, onSubmit, text, className, enforceSmSize }) => {
  const { mobile } = useMQuery();
  const id = useId();
  const button = React.cloneElement<ButtonProps>(submitButton, {
    fullWidth: mobile,
    form: id,
    type: 'submit',
  });
  const inputC = React.cloneElement<InputControlProps>(input, {
    sx: {
      maxWidth: enforceSmSize ? '100%' : { xs: '100%', lg: 350 },
      minWidth: 206,
    },
  });
  return (
    <Box
      sx={{
        gap: 24,
        display: 'flex',
        flexDirection: enforceSmSize ? 'column' : { xs: 'column', lg: 'row' },
        alignItems: enforceSmSize ? 'start' : { xs: 'start', lg: 'end' },
      }}
      className={className}
    >
      <Text fz={16} css={_`width: 100%`}>
        {text}
      </Text>
      <Box
        display={'flex'}
        gap={24}
        alignItems={'flex-end'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        width={{ xs: '100%', lg: 'auto' }}
      >
        <Box
          component={'form'}
          id={id}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {inputC}
        </Box>
        {button}
      </Box>
    </Box>
  );
};

const NPIOrgInfo: FC<{
  npi: NPI2ShortData | null;
  title: string | null;
  mb?: number;
}> = ({ npi, title, mb }) => {
  const sections = useSectionsOfNpiOrgData(npi);
  return (
    <>
      <BoxHeaderDeprecated>{title}</BoxHeaderDeprecated>
      <GridContainer mb={mb}>
        <Grid xs={12} sm={6} md={4}>
          <SubHeader>{sections?.common.title}</SubHeader>
          {sections?.common.items?.map((item) => {
            return (
              <Dl key={item.label}>
                <dt>{item.label}</dt>
                <dd>{item.value || EMPTY}</dd>
              </Dl>
            );
          })}
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <SubHeader>{sections?.address.title}</SubHeader>
          {sections?.address.items?.map((item) => {
            return (
              <Dl key={item.label}>
                <dt>{item.label}</dt>
                <dd>{item.value || EMPTY}</dd>
              </Dl>
            );
          })}
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <SubHeader>{sections?.additional.title}</SubHeader>
          {sections?.additional.items?.map((item) => {
            return (
              <Dl key={item.label}>
                <dt>{item.label}</dt>
                <dd>{item.value || EMPTY}</dd>
              </Dl>
            );
          })}
        </Grid>
      </GridContainer>
    </>
  );
};
