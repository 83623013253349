/** @jsxImportSource @emotion/react */
import { useDispatch } from 'react-redux';
import { useCompaniesState } from '@/store/companies/hooks';
import { UUID } from '@/types';
import { companiesActions } from '@/store/companies/slice';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { useADAuthorize } from '@/store/companies/hooks/useADConnect';
import { ModuleType } from '@/api/__generated__/webApi';

export const useEnterToModule = () => {
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const [searchParams, setSearchParams] = useSearchParams();
  const authorize = useADAuthorize();

  const handleClick = useCallback(
    ({
      moduleType,
      companyId,
      afterRedirect,
      openInNewTab,
    }: {
      moduleType: ModuleType;
      companyId: UUID;
      afterRedirect?: boolean;
      openInNewTab?: boolean;
    }) => {
      const company = state.companies.byID[companyId];

      if (!company?.locked) {
        dispatch(
          companiesActions.requestEnterModule({
            moduleType,
            companyId,
            openInNewTab,
          })
        );
      } else {
        if (afterRedirect) {
          console.error(
            '[useEnterModule] Authorization already been requested, though the company is still locked'
          );
          return;
        }
        authorize({
          companyId,
          url: currentUrlWithSearchParams({
            companyId,
            moduleType,
            queuedTask: 'moduleRedirect',
          }),
        });
      }
    },
    [authorize, dispatch, state.companies.byID]
  );

  const clickCallbackRef = useRef(handleClick);
  clickCallbackRef.current = handleClick;

  useEffect(() => {
    if (searchParams.get('queuedTask') === 'moduleRedirect') {
      const moduleType = searchParams.get('moduleType') as ModuleType;
      const companyId = searchParams.get('companyId');
      if (moduleType && companyId) {
        clickCallbackRef.current({
          moduleType,
          companyId,
          afterRedirect: true,
        });
      }
      setSearchParams('');
    }
  }, [searchParams, setSearchParams]);

  return {
    enter: handleClick,
    fetching: state.fetching,
  };
};

const toSearchParams = (searchParamsObj: Record<string, string | number>) => {
  return Object.entries(searchParamsObj)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
};
const currentUrlWithSearchParams = (searchParams: Record<string, string>) => {
  return `${window.location.href}${
    window.location.href.includes('?') ? '&' : '?'
  }${toSearchParams(searchParams)}`;
};
