/** @jsxImportSource @emotion/react */
import { DateTime } from 'luxon';
import { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { CalendarContainer } from '../styles';
import { useMQuery } from '@/hooks/useMQuery';
import { css as _, styled } from '@mui/material';
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import { Calendar } from './components/Calendar';

const insertByOrder = (
  r: [start?: DateTime | null, end?: DateTime | null] = [],
  date: DateTime,
  order: 0 | 1
) => {
  let range = [...r];
  if (
    !range.length ||
    (range.length === 1 && range[0] == null) ||
    (!range[0] && !range[1])
  ) {
    range = [null, null];
    range.splice(order, 1, date);
    return range as DateRange;
  }
  if (range.length === 1) {
    range.splice(order, 0, date);
  }
  if (range[0] && range[1]) {
    range[order] = date;
  }

  if (range[order] && !range[1 - order]) {
    range[1 - order] = date;
  } else {
    range[order] = date;
  }

  if (range[0] && range[1] && range[0] > range[1]) {
    return range.reverse() as DateRange;
  }

  return range as DateRange;
};

export type DateRange = [start: DateTime | null, end: DateTime | null];
interface CalendarPickerProps {
  mode?: 'range' | 'calendar';
  style: CSSProperties;
  date?: DateTime;
  arrow?: JSX.Element;
  attrs: any;
  onClose: () => void;
  range?: DateRange;
  onChange?: (date: DateTime | [DateTime, DateTime]) => void;
}
export const CalendarPicker = forwardRef<HTMLDivElement, CalendarPickerProps>(
  (props, ref) => {
    const { mobile } = useMQuery();
    const { date, style, arrow, attrs, range, mode, onChange, onClose } = props;
    const [internalRange, setInternalRange] = useState(
      mode === 'range' ? props.range : undefined
    );
    const isRangeMode = mode === 'range';
    const { t } = useTranslate('common');
    const initialStartMonth = isRangeMode
      ? range?.[0]?.isValid
        ? range[0]
        : range?.[1]?.isValid
        ? range[1].minus({ month: 1 })
        : DateTime.now()
      : date ?? DateTime.now();
    const initialEndMonth = range?.[1]?.isValid
      ? range[1]
      : initialStartMonth.plus({ month: 1 });
    const [[start, end], setMonthRange] = useState<[DateTime, DateTime]>([
      initialStartMonth,
      initialEndMonth,
    ]);
    useEffect(() => {
      if (mode === 'range') {
        setInternalRange(range);
        const externalStartMonth = range?.[0]?.isValid
          ? range[0]
          : range?.[1]?.isValid
          ? range[1].minus({ month: 1 })
          : DateTime.now();
        const externalEndMonth = range?.[1]?.isValid
          ? range[1]
          : externalStartMonth.plus({ month: 1 });
        setMonthRange([externalStartMonth, externalEndMonth]);
      }
    }, [range, mode]);

    useEffect(() => {
      setMonthRange((d) => {
        const [rangeStart, rangeEnd] = internalRange ?? [];
        if (rangeStart && rangeEnd && !rangeStart.hasSame(rangeEnd, 'month')) {
          return [rangeStart, rangeEnd];
        }
        return d;
      });
    }, [internalRange]);
    const handleChange = (d: DateTime, order: 0 | 1) => {
      if (isRangeMode) {
        setInternalRange((range) => {
          const [start, end] = range ?? [];
          if (start && end) {
            const r: DateRange = [null, null];
            r.splice(order, 1, d);
            return r;
          }
          return insertByOrder(range, d, order);
        });
      } else {
        onChange?.(d);
        onClose();
      }
    };
    const handleChangeMonth = (d: DateTime, order: 0 | 1) => {
      setMonthRange((dt) => {
        const [start, end] = dt;
        const newRange = [...dt];
        newRange[order] = d;
        if (order === 0 && d > end) {
          newRange[1] = d.plus({ month: 1 });
        }
        if (order === 1 && d < start) {
          newRange[0] = d.minus({ month: 1 });
        }
        if (newRange[order].hasSame(newRange[1 - order], 'month')) {
          newRange[1 - order] = newRange[1 - order][order ? 'minus' : 'plus']({
            month: 1,
          });
        }
        return newRange as [DateTime, DateTime];
      });
    };

    const handleSubmit = () => {
      if (internalRange?.[0]?.isValid && internalRange?.[1]?.isValid) {
        onChange?.(internalRange as [DateTime, DateTime]);
        onClose();
      } else {
        console.error('[Range selector]. Range is invalid', internalRange);
      }
    };

    const calendar = (
      <CalendarContainer
        twoColumns={isRangeMode}
        style={style}
        ref={ref}
        {...attrs}
      >
        {!mobile && arrow}
        <Calendar
          onChange={handleChange}
          date={date}
          range={internalRange}
          css={
            isRangeMode
              ? _`width: 50%; .mobile-layout & {width: 100%}; padding-bottom: 0`
              : undefined
          }
          order={0}
          mode={mode}
          monthDate={start}
          onChangeMonthDate={handleChangeMonth}
        />
        {isRangeMode && (
          <>
            <Calendar
              onChange={handleChange}
              date={date}
              range={internalRange}
              order={1}
              css={
                isRangeMode
                  ? _`width: 50%; .mobile-layout & {width: 100%}; padding-bottom: 0`
                  : undefined
              }
              mode={'range'}
              monthDate={end}
              onChangeMonthDate={handleChangeMonth}
            />
            <div
              css={_`width: 100%; display: flex; justify-content: space-between; padding: 16px;`}
            >
              <Button
                color={'secondary'}
                variant={'outlined'}
                size={'sm'}
                sx={(t) => ({
                  borderColor: 'white',
                  color: 'white',
                  '&:hover': { borderColor: t.palette.grey[200] },
                })}
                onClick={onClose}
              >
                {t('CANCEL')}
              </Button>
              <Button
                size={'sm'}
                color={'secondary'}
                variant={'contained'}
                sx={(t) => ({
                  borderColor: 'white',
                  color: t.palette.grey[800],
                  '&:hover': { borderColor: t.palette.grey[200] },
                })}
                onClick={handleSubmit}
              >
                {t('APPLY')}
              </Button>
            </div>
          </>
        )}
      </CalendarContainer>
    );

    return mobile ? <MobileContainer>{calendar}</MobileContainer> : calendar;
  }
);
CalendarPicker.displayName = 'CalendarPicker';

export const MobileContainer = styled('div')`
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1301;
`;
