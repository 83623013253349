import React, { memo, ReactNode, useId } from 'react';
import { InputTip } from '@/components/common/InputTip';
import { Stack, SxProps, Theme } from '@mui/material';
import {
  InputSize,
  inputStyles,
  sizeStylesControl,
} from '@/components/common/Common';
import { Nowrap } from '@/components';
import { LabelOptional } from '@/components/common/LabelOptional';
import { Label } from '@/components/common/Label';
import { spreadSx } from '@/utils/spreadSx';

interface ControlBodyProps {
  sx?: SxProps<Theme>;
  label?: string | null;
  error?: string | boolean | null | undefined;
  tip?: string | null | undefined;
  optional?: boolean;
  className?: string;
  disabled?: boolean;
  hideTipText?: boolean;
  compact?: boolean;
  size: InputSize;
  children: ReactNode;
  ignoreLabelHeight?: boolean;
}

export const ControlBody = memo(
  ({
    sx,
    label,
    error,
    tip,
    optional,
    className,
    disabled,
    hideTipText,
    compact = true,
    size = 'md',
    children,
    ignoreLabelHeight,
  }: ControlBodyProps) => {
    const id = useId();
    const showCompactLabel = label && compact;
    const showFullLabel = label && !compact;
    const hint = (
      <InputTip
        size={size}
        hide={hideTipText}
        disabled={disabled}
        color={error ? 'error' : 'default'}
      >
        {error || tip}
      </InputTip>
    );
    const ifOnlyOptionalLabelOrNothing = !label || !compact;
    return (
      <Stack className={className} sx={[{ flex: '1 1 auto' }, ...spreadSx(sx)]}>
        <Stack
          sx={[
            {
              flexDirection: 'row',
              width: '100%',
              flexGrow: 0,
              flexShrink: 1,
            },
            sizeStylesControl.labelContainer[size],
          ]}
        >
          {showFullLabel && (
            <Stack
              justifyContent={'center'}
              sx={[sizeStylesControl.fullLabel[size]]}
            >
              <Label htmlFor={id} size={size} disabled={disabled}>
                <Nowrap>{label}</Nowrap>
              </Label>
            </Stack>
          )}
          <Stack
            sx={{
              flexGrow: 1,
              minWidth: 200,
              position: 'relative',
            }}
          >
            <Stack
              sx={[
                inputStyles.labelCompact,
                sizeStylesControl.labelCompact[size],
                ifOnlyOptionalLabelOrNothing
                  ? sizeStylesControl.labelNegativeCompensation[size]
                  : {},
                ignoreLabelHeight
                  ? { position: 'absolute', left: 0, bottom: '100%' }
                  : {},
              ]}
            >
              {showCompactLabel && (
                <Label htmlFor={id} size={size} disabled={disabled}>
                  <Nowrap>{label}</Nowrap>
                </Label>
              )}
              <LabelOptional optional={optional} disabled={!!disabled} />
            </Stack>
            {children}
            {!compact && hint}
          </Stack>
        </Stack>
        {compact && hint}
      </Stack>
    );
  }
);

ControlBody.displayName = 'ControlBody';
