/** @jsxImportSource @emotion/react */
import { styled } from '@mui/material';
import { Grid } from './Grid';

const GridContainer_ = styled(Grid)``;
GridContainer_.defaultProps = {
  container: true,
  columnSpacing: 24,
  rowSpacing: { xs: 36, sm: 20 },
};

export const GridContainer = GridContainer_;
