import { fieldSizeMap } from '@/components/common/FieldSizeMap';

export type InputSize = 'sm' | 'md' | 'lg';

export const sizeStyles = {
  inputStyles: {
    sm: {},
    md: {},
    lg: {
      fontSize: '16px',
      lineHeight: '20',
      fontWeight: 500,
    },
  },
  inputFilledStyles: {
    sm: { paddingRight: 30 },
    md: { paddingRight: 30 },
    lg: { paddingRight: 46 },
  },
  countryIconStyles: {
    sm: {},
    md: {},
    lg: {},
  },
};
export const inputStyles = {
  labelCompact: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'end',
    minWidth: 100,
    height: 20,
  },
};

export const sizeStylesControl = {
  labelCompact: {
    sm: {
      paddingBottom: 0,
    },
    md: {
      paddingBottom: 3,
    },
    lg: {
      paddingBottom: 4,
    },
  },
  labelNegativeCompensation: {
    sm: {
      marginTop: -20,
    },
    md: {
      marginTop: -(20 + 3),
    },
    lg: {
      marginTop: -(20 + 4),
    },
  },
  fullLabel: {
    sm: {
      height: fieldSizeMap.sm,
    },
    md: {
      height: fieldSizeMap.md,
    },
    lg: {
      height: fieldSizeMap.lg,
    },
  },
  labelContainer: {
    sm: {
      gap: 6,
    },
    md: {
      gap: 12,
    },
    lg: {
      gap: 12,
    },
  },
};
export const CONTROL_CONTAINER = 'control-container';
export const INPUT_TIP_HEIGHT = 0;
