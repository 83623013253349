import { BoxHeader, boxXPaddings, WhiteBox } from '@/components';
import { Stack, Typography } from '@mui/material';
import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import { Cards } from './Cards';
import { useQrActions } from '@/store/profile-qr/hooks';
import { Scrollbar } from '@/components/Scrollbar';

export const Authentication = () => {
  const { t } = useTranslate('profile');
  const { xsOnly } = useBreakpoints();
  const a = useQrActions();
  return (
    <>
      <WhiteBox
        noXPaddings
        sx={{ flex: '1 1 auto', position: 'relative', minHeight: 100 }}
      >
        <BoxHeader sx={{ mb: { xs: 12 }, paddingX: boxXPaddings }}>
          {t('CONNECTING_TWO_FACTOR_AUTHENTICATION_APPS')}
        </BoxHeader>
        <Scrollbar shadow permanent={false}>
          <Stack sx={{ paddingX: boxXPaddings }}>
            <Typography sx={styles.text}>
              {t('MULTIPLE_CONNECTIONS_CAN_BE_ESTABLISHED_FOR_')}
            </Typography>
          </Stack>
          <Stack mb={xsOnly ? 24 : 0} sx={{ flex: '1 1 auto' }}>
            <Cards />
          </Stack>
        </Scrollbar>
        {xsOnly && (
          <Stack sx={{ paddingX: boxXPaddings, pt: boxXPaddings }}>
            <Button onClick={a.createNew}>{t('MAKE_NEW')}</Button>
          </Stack>
        )}
      </WhiteBox>
    </>
  );
};

const styles = {
  text: {
    typography: { xs: '12_18_500', sm: '14_20_500' },
    pb: { xs: 24, sm: 36 },
  },
};
