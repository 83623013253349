/** @jsxImportSource @emotion/react */
import { WhiteBox } from '@/components/WhiteBox';
import { Breadcrumbs, BreadcrumbsText } from '@/components/Breadcrumbs';
import { Tag } from '@/components/Tag';
import { Link } from '@/components/Text';
import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, Fragment } from 'react';
import { usePermissions, useRoles } from '@/store/companies/hooks';
import { css } from '@emotion/react';
import { CompanyHeader } from '../../company/fragments/CompanyHeader';
import { useModuleName } from '../ModulePage/fragments/ModuleHeader';
//
export const PermissionsPage: FC = () => {
  const { companyId, moduleId, module } = useParams();
  const { t, ready } = useTranslate('companies');
  const { role } = useRoles();
  const { permissions } = usePermissions();
  const { name } = useModuleName();

  if (!ready) {
    return <>...</>;
  }

  return (
    <>
      <CompanyHeader />
      <Breadcrumbs>
        <Link
          to={{
            pathname: generatePath(AppRoutes.COMPANY_MODULES, {
              companyId,
              moduleId,
              module,
            }),
            search: window.location.search,
          }}
        >
          {name}
        </Link>
        <BreadcrumbsText>
          {t('PERMISSIONS_FOR', { roleName: role?.name ?? '...' })}
        </BreadcrumbsText>
      </Breadcrumbs>
      <WhiteBox>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          `}
        >
          {permissions?.allIDs.map((id) => {
            return <Tag key={id}>{permissions.byID?.[id]?.description}</Tag>;
          })}
        </div>
      </WhiteBox>
    </>
  );
};
