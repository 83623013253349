import { MutableRefObject, FC, Fragment, useState } from 'react';
import { ProfileMenuItem } from '../ProfileMenu';
import { UlContainer, MenuLink, MenuIcon, ListItem } from './styles';
import { Button, Nowrap, Text } from '@/components';
import { ReactComponent as IconSVGUSFlag } from '../../us_flag.svg';
import { ReactComponent as IconSVGArrowDown } from '@/images/icons/icon_arrow_down.svg';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, Collapse, Stack, Theme, Typography } from '@mui/material';
import { UUID } from '@/types';
import { useBreakpoints, Tooltip } from 'ui-kit';

const styles = {
  langButton: {
    paddingX: '16px',
    marginX: -16,
    minWidth: 'initial',
    '.MuiButton-startIcon': { width: 'auto', mr: 12 },
  },
  listItem: (t: Theme) => ({
    minHeight: 48,
    paddingX: 16,
    paddingY: 0,
    borderBottom: `1px solid ${t.colors.borderLight}`,
  }),
  expandableItemContainer: (t: Theme) => ({
    backgroundColor: t.palette.grey[100],
  }),
  menuItem: { alignItems: 'center', justifyContent: 'center' },
  labelContainer: { flex: '1 1 auto' },
  arrowIcon: { minWidth: 12, width: 12, height: 'auto' },
  iconItemExpanded: {
    transform: 'rotate(180deg)',
  },
};

interface DropdownMenuProps {
  open: boolean;
  menu: ProfileMenuItem[];
  onClose: () => void;
  parentRef: MutableRefObject<HTMLElement | null>;
  containerRef: MutableRefObject<HTMLUListElement | null>;
}

export const MenuList: FC<DropdownMenuProps> = ({
  open,
  menu,
  onClose,
  parentRef,
  containerRef,
}) => {
  const rect = parentRef.current?.getBoundingClientRect();
  const { t } = useTranslate('common');
  const b = useBreakpoints();
  const coords = {
    top: (rect?.bottom ?? 0) + 10,
    right: b.xsOnly
      ? 0
      : window.document.body.offsetWidth - (rect?.right ?? 0) - 20,
  };
  const { xsOnly: mobile } = useBreakpoints();
  const [expandedState, setExpanded] = useState<Record<UUID, boolean>>({});
  if (!open) {
    return null;
  }
  return (
    <UlContainer
      style={coords}
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={containerRef}
    >
      <ListItem sx={styles.listItem}>
        <Button
          startIcon={<IconSVGUSFlag />}
          color={'inherit'}
          variant={'text'}
          cancelIconFilling
          sx={styles.langButton}
        >
          {t('ENGLISH')}
        </Button>
      </ListItem>
      {menu.map((item, i) => {
        const { to, onClick, label, icon, title, items, href } = item;
        const clickHandler: React.MouseEventHandler = (e) => {
          e.preventDefault();
          if (items?.length) {
            setExpanded((s) => {
              return {
                ...s,
                [item.id]: !s[item.id],
              };
            });
          } else {
            onClick?.();
            window.setTimeout(() => {
              onClose();
            }, 300);
          }
        };
        return (
          <Fragment key={i}>
            <ListItem key={i}>
              <MenuLink
                href={href}
                to={to ? { pathname: to, search: window.location.search } : ''}
                onClick={to || href ? () => {} : clickHandler}
                onAuxClick={to || href ? () => {} : clickHandler}
                withIcon={!!icon}
                sx={items?.length ? styles.expandableItemContainer : undefined}
              >
                {items?.length ? (
                  <Stack direction={'row'} sx={styles.menuItem} gap={6}>
                    <Tooltip title={label} placement={mobile ? 'top' : 'right'}>
                      <Nowrap sx={styles.labelContainer}>
                        <Typography variant={'14_20_700'} display={'inline'}>
                          {label}
                        </Typography>
                      </Nowrap>
                    </Tooltip>

                    <Box
                      component={IconSVGArrowDown}
                      sx={[
                        styles.arrowIcon,
                        expandedState[item.id] && styles.iconItemExpanded,
                      ]}
                    />
                  </Stack>
                ) : (
                  <Typography variant={'14_20_400'} pb={2}>
                    {label}
                  </Typography>
                )}
                {title && (
                  <Typography variant={'12_18_500'}>{title}</Typography>
                )}
                {icon && <MenuIcon>{icon}</MenuIcon>}
              </MenuLink>
            </ListItem>
            <Collapse in={expandedState[item.id]}>
              {items?.map(({ to, href, title, label, onClick }, i) => {
                return (
                  <ListItem key={i}>
                    <MenuLink
                      sx={{ paddingX: 36 }}
                      href={href}
                      to={
                        to
                          ? { pathname: to, search: window.location.search }
                          : ''
                      }
                      onClick={
                        to || href
                          ? () => {}
                          : (e) => {
                              e.preventDefault();
                              onClick?.();
                              window.setTimeout(() => {
                                onClose();
                              }, 300);
                            }
                      }
                    >
                      <Text weight={title ? 700 : 400} fz={14}>
                        {label}
                      </Text>
                    </MenuLink>
                  </ListItem>
                );
              })}
            </Collapse>
          </Fragment>
        );
      })}
    </UlContainer>
  );
};
