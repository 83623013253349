import React, { FC } from 'react';

import { Field } from 'formik';
import {
  PhoneInputControl,
  PhoneInputControlProps,
} from '@/components/PhoneInputControl';
import { useFormikError } from '@/components/FormikRedux/useFormikError';

export const PhoneInputControlF: FC<
  Omit<PhoneInputControlProps, 'name'> & {
    name: string;
    submitCount: number;
  }
> = ({ submitCount, ...restProps }) => {
  const error = useFormikError(restProps.name, submitCount);
  return <Field as={PhoneInputControl} {...restProps} error={error} />;
};
