import { Button } from '@/components/Button';
import { Link, Text } from '@/components/Text';
import { FormikProps } from 'formik';
import { useTranslate } from '@/i18n/useTranslate';
import React, { useMemo, FC, useRef, useCallback, useEffect } from 'react';
import {
  useChangePasswordRequest,
  usePinResend,
} from '@/store/profile/apiHooks';
import * as yup from 'yup';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { useMinutesCountDown } from '@/hooks/useMinutesCountdown';
import { useInit } from '@/hooks/useInit';
import { BoxBody } from '@/components/WhiteBox';
import { useMQuery } from '@/hooks/useMQuery';
import { Box, Stack, Typography } from '@mui/material';
import { AppRoutes } from '@/routes/appRoutes';
import { useNavigate } from 'react-router-dom';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { FormikRedux } from '@/components';
import { PinCodeControlF } from '@/components/FormikRedux/PinCodeControlF';
import { usePinCodeRequest } from '@/store/profile-new-password/hooks';
import { ValidationErrorType } from '@/types/ValidationError';

const useValidationSchema = () => {
  const { t } = useTranslate('common');
  return useMemo(() => {
    return yup.object().shape({
      code: yup.array().test('Pin validation', function (this: any, value) {
        const { path, createError } = this;
        if (!value || value.join('').length === 0) {
          return createError({ path, message: t('FIELD_IS_REQUIRED') });
        }
        if (value.join('').length < 6) {
          return createError({ path, message: t('CODE_VALIDATION_ERROR') });
        }
        return true;
      }),
    });
  }, [t]);
};
interface PinCodeFormState {
  code: string[];
}
const pinInitialState = {
  code: [],
};

export const PinCodeForm: FC<{ next: (failed?: boolean) => void }> = ({
  next,
}) => {
  const { t } = useTranslate('profile');
  const schema = useValidationSchema();
  const getCaptcha = useRecaptcha();
  const { responseData: passwdResponseData, reset: resetPasswordData } =
    useChangePasswordRequest();
  const {
    sendData: resendPin,
    responseData: resendResponseData,
    reset: resetPinData,
  } = usePinResend();
  const resendTime =
    resendResponseData?.resendTime ?? passwdResponseData?.resendTime ?? 0;
  const time = useMinutesCountDown(resendTime);
  const { send, validationErrors, success, reset, resetValidationErrors } =
    usePinCodeRequest();
  const formikRef = useRef<FormikProps<PinCodeFormState> | null>(null);
  const { mobile, tablet, desktop } = useMQuery();
  const navigate = useNavigate();
  const handleResendPin = () => {
    resendPin();
    void formikRef.current?.resetForm();
  };
  const handleSubmit = useCallback(
    async (values: PinCodeFormState) => {
      const recaptcha = await getCaptcha();
      await send({ code: values.code.join(''), recaptcha });
    },
    [getCaptcha, send]
  );

  const resetAll = useCallback(() => {
    reset();
    resetPinData();
    resetPasswordData();
    resetValidationErrors();
  }, [reset, resetPasswordData, resetPinData, resetValidationErrors]);

  useInit(() => {
    reset();
    resetPinData();
  });

  useEffect(() => {
    if (success) {
      next();
      resetAll();
    }
  }, [next, resetAll, success]);

  const handleMaxAttempts = (attempts: number) => {
    if (attempts < 3) return;
    window.setTimeout(() => {
      next(true);
      resetAll();
    });
  };
  const adjustedValidationErrors = useMemo(() => {
    const copyOfValidationErrors = { ...validationErrors };
    if (
      copyOfValidationErrors.code === ValidationErrorType.CODE_VALIDATION_ERROR
    ) {
      copyOfValidationErrors.code = t(
        'PIN_CODE_IS_INCORRECT_YOU_HAVE_ATTEMPTS',
        {
          attempts: 3 - (formikRef.current?.submitCount ?? 0),
        }
      ) as ValidationErrorType;
    }
    return copyOfValidationErrors;
  }, [t, validationErrors]);
  return (
    <>
      <FormikRedux<PinCodeFormState>
        innerRef={(r) => (formikRef.current = r)}
        initialValues={pinInitialState}
        onSubmit={handleSubmit}
        validationSchema={schema}
        serverErrors={adjustedValidationErrors}
        resetServerErrors={resetValidationErrors}
        noForm
      >
        {(f) => {
          handleMaxAttempts(f.submitCount);
          return (
            <>
              <BoxBody>
                <Typography component={'p'} sx={styles.subTitle}>
                  {t(
                    'A_6-DIGIT_PIN_CODE_HAS_BEEN_SENT_TO_YOUR_PHONE_PLEASE_ENTER_IT_IN_THE_FIELD_BELOW'
                  )}
                </Typography>

                <Stack
                  component={'form'}
                  id={'password_change_form'}
                  sx={styles.form}
                  onSubmit={f.handleSubmit}
                >
                  <PinCodeControlF
                    sx={styles.pinCodeField}
                    length={6}
                    name="code"
                    fullWidth
                    label={t('PIN_CODE')}
                    compact={mobile}
                  />
                  {!mobile &&
                    (time ? (
                      <Text lh={20} fz={14}>
                        {t('THE_CODE_CAN_BE_RESENT_AFTER')}&nbsp;{time}
                      </Text>
                    ) : (
                      <Link to="" onClick={handleResendPin} fz={14} lh={20}>
                        {t('RESEND_PIN_CODE')}
                      </Link>
                    ))}
                </Stack>
                <RecaptchaNote mt={styles.recaptcha} />
              </BoxBody>
              <Stack sx={styles.buttonsLayout}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={f.isSubmitting || !f.isValid || !f.dirty}
                  form="password_change_form"
                >
                  {t('SEND')}
                </Button>
                {desktop && (
                  <Typography variant={'16_20_500'}>
                    {t('AFTER_CHANGING_THE_PASSWORD_THE_ACCOUNT_WILL_BE_')}
                  </Typography>
                )}
                {tablet && (
                  <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() => navigate(AppRoutes.PROFILE)}
                  >
                    {t('CANCEL')}
                  </Button>
                )}
                {mobile &&
                  (time ? (
                    <Box sx={styles.resendBlock}>
                      {t('THE_CODE_CAN_BE_RESENT_AFTER')}&nbsp;{time}
                    </Box>
                  ) : (
                    <Button variant="outlined" onClick={handleResendPin}>
                      {t('RESEND_PIN_CODE')}
                    </Button>
                  ))}
              </Stack>
            </>
          );
        }}
      </FormikRedux>
    </>
  );
};

const styles = {
  resendBlock: {
    height: 42,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  subTitle: {
    fontSize: { xs: 14 },
    lineHeight: { xs: '20px' },
    fontWeight: 400,
    mb: { xs: 36, sm: 48, lg: 48 },
  },
  form: { maxWidth: 340, gap: { xs: 30, sm: 0, lg: 36 } },
  pinCodeField: {
    mb: { xs: 0, sm: 48, lg: 0 },
  },
  recaptcha: { xs: 36, sm: 130, lg: 72 },
  buttonsLayout: {
    gap: { xs: 10 },
    width: '100%',
    flexDirection: { xs: 'column', sm: 'row-reverse' },
    justifyContent: 'space-between',
    pt: { xs: 0, sm: 36 },
    alignItems: { xs: 'initial', sm: 'center' },
  },
};
