import { ParamNames, useParams } from '@/routes/appRoutes';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { companiesActions as actions } from '../slice';
import { useCompaniesState } from './useCompaniesState';
import { UUID } from '@/types/types';
import { CompanyAddress } from '@/api/__generated__/webApi';
import { DateTime } from 'luxon';

export const useCompanyAddresses = () => {
  const params = useParams();
  const state = useCompaniesState();
  const dispatch = useDispatch();
  const companyId = params[ParamNames.CompanyId] ?? '';
  const npi = state.companyDetails?.[companyId];

  const { expired, actual } = useMemo(() => {
    return (
      (npi?.practiceAddresses ?? []).reduce<{
        expired: CompanyAddress[];
        actual: CompanyAddress[];
      }>(
        (acc, addr) => {
          const endDate = DateTime.fromISO(addr.endDate ?? '');
          if (
            !endDate.isValid ||
            endDate.startOf('day') > DateTime.now().startOf('day')
          ) {
            acc.actual.push(addr);
          } else {
            acc.expired.push(addr);
          }
          return acc;
        },
        { expired: [], actual: [] }
      ) ?? {}
    );
  }, [npi?.practiceAddresses]);
  const callbacks = useMemo(() => {
    return {
      requestAddresses: () => {
        dispatch(actions.requestCompanyDetails({ companyId }));
      },
    };
  }, [companyId, dispatch]);

  return {
    addresses: npi?.practiceAddresses,
    expired,
    actual,
    ...callbacks,
  };
};
export const useSelectedCompanyDetails = (passive?: boolean) => {
  const params = useParams();
  const state = useCompaniesState();
  const dispatch = useDispatch();
  const companyId = params[ParamNames.CompanyId] ?? '';
  const details = state.companyDetails?.[companyId];

  useEffect(() => {
    if (!companyId || passive) {
      return;
    }
    dispatch(actions.requestCompanyDetails({ companyId }));
  }, [companyId, dispatch, passive]);

  const callbacks = useMemo(() => {
    return {
      resetError: () => {
        dispatch(actions.setError(null));
      },
    };
  }, [dispatch]);

  return {
    details,
    ...callbacks,
  };
};
export const useCompanyDetailsGetter = () => {
  const state = useCompaniesState();
  const dispatch = useDispatch();

  const callbacks = useMemo(() => {
    return {
      getDetails: (companyId: UUID | undefined | null) => {
        const details = companyId ? state.companyDetails[companyId] : undefined;
        if (!details && companyId) {
          dispatch(actions.requestCompanyDetails({ companyId, quiet: true }));
        }
      },
    };
  }, [dispatch, state.companyDetails]);

  return {
    ...callbacks,
    details: state.companyDetails,
  };
};
export const useUpdateDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const companyId = params[ParamNames.CompanyId] ?? '';
  useEffect(() => {
    if (!companyId) {
      return;
    }
    dispatch(actions.requestCompanyDetails({ companyId }));
  }, [companyId, dispatch]);
};
