import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const StyledHead = styled('thead')`
  tr {
    height: 52px;
  }
`;
export const THead: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <StyledHead className={className}>{children}</StyledHead>;
};
