import { useMemo, useState } from 'react';

export const usePagination = <T = unknown>({
  perPage,
  items,
}: {
  perPage: number;
  items: T[] | undefined;
}) => {
  const [pageIndex, setPageIndex] = useState(0);

  const paginatedItems = useMemo(() => {
    const start = perPage * pageIndex;
    const end = start + perPage;
    return items?.slice(start, end);
  }, [items, pageIndex, perPage]);
  const totalPages = Math.ceil((items?.length ?? 0) / perPage);
  const currentPage = pageIndex + 1;
  return {
    items: paginatedItems,
    next: () => setPageIndex((i) => i + 1),
    prev: () => setPageIndex((i) => i - 1),
    nextDisabled: totalPages <= currentPage,
    prevDisabled: !pageIndex,
    show: totalPages > 1,
    reset: () => setPageIndex(0),
  };
};
