/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { useMenuItems } from './useMenuItems';
import { useMQuery } from '@/hooks/useMQuery';
import { SidebarMenu } from './SidebarMenu';
import { SidebarMenuStripe } from './SidebarMenuStripe';
import { UUID } from '@/types';
import { Module } from '@/api/__generated__/webApi';

type ChildMenuItem = Omit<SideMenuItem, 'children' | 'buttons' | 'collapsible'>;
interface LinkParams {
  pathname?: string;
  search?: string;
  exact?: boolean;
}
interface ButtonMenuItem<T = undefined> {
  title: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  link?: LinkParams;
  icon?: JSX.Element;
  data?: T;
}
export interface SideMenuItem {
  disabled?: boolean;
  link?: LinkParams;
  selected?: boolean;
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  iconStripeOnly?: boolean;
  items?: ChildMenuItem[];
  buttons: Array<ButtonMenuItem<{ module: Module; companyId: UUID }>>;
  collapsible?: boolean;
  id?: UUID;
}

export const SidebarContainer: FC<{
  className?: string;
  collapsed?: boolean;
}> = ({ className, collapsed }) => {
  const menuItems = useMenuItems();
  const { mobile, tablet } = useMQuery();
  if (collapsed && !mobile && !tablet) {
    return <SidebarMenuStripe menuItems={menuItems} className={className} />;
  }
  return <SidebarMenu menuItems={menuItems} className={className} />;
};
