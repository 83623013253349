import { useProfileState } from '@/store/profile/hooks';
import { profileActions } from '@/store/profile/slice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const useBackUrl = () => {
  const searchParams = useSearchParamsCustom();
  const state = useProfileState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.redirect) {
      navigate({ pathname: state.redirect });
      dispatch(profileActions.redirect(null));
    }
  }, [dispatch, navigate, state.redirect]);
  useEffect(() => {
    for (const [name, param] of searchParams) {
      if (name.toLowerCase() === 'backurl') {
        dispatch(profileActions.requestCheckBackUrl({ backUrl: param }));
        break;
      }
    }
  }, [dispatch, searchParams]);
};

/**
 * Why?
 * Seems like react-router-dom hook works not quite well for cases when it is required to extract urls from the params.
 * the problem is it tries to decode search params before returning it, i.e. it does not
 * return the params as is but can slightly change them.
 * So in our case we had got
 * /something/something/["paramone","paramtwo"] - invalid url, instead of original
 * /something/something/%5D"paramone","paramtwo"%5B - which is more or less valid
 */
const useSearchParamsCustom = (): Array<[string, string]> => {
  const [searchParams] = useSearchParams();
  const getSearchParamsNative = (): Array<[string, string]> => {
    return window.location.search
      .slice(1)
      .split('&')
      .map((p) => p?.split('='))
      .filter((p) => p?.length === 2) as any;
  };

  return useMemo(() => {
    return getSearchParamsNative();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
};
