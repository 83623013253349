import React, { FC, useMemo } from 'react';
import { ChangeEventHandler } from '@/hooks/useInternalControlled';
import { createEvent } from '@/utils/createEvent';
import {
  DateInputProps,
  PARSING_FORMAT,
} from '@/components/DateControl/DateInput/DatePickerInput';
import { InputView } from 'ui-kit';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { useDateInputMask } from '@/components/DateControl/DateInput/useDateInputMask';
import { Validator } from './utils/dateValidator';
import { DateTime } from 'luxon';

export const DateInput: FC<
  Omit<DateInputProps, 'onChange'> & { onChange: ChangeEventHandler }
> = ({
  disablePastDates,
  disableFutureDates,
  id,
  disabled,
  size,
  onChange,
  error,
  name,
  value,
  placeholder,
}) => {
  const validator = useMemo(() => {
    const validator = new Validator();
    if (typeof disableFutureDates !== 'undefined')
      validator.disableFuture = disableFutureDates;
    if (typeof disablePastDates !== 'undefined')
      validator.disablePast = disablePastDates;
    return validator;
  }, [disableFutureDates, disablePastDates]);

  const displayFormat = 'MM/dd/yyyy';
  const mask = displayFormat.toLowerCase();
  const date = value ? DateTime.fromFormat(value, PARSING_FORMAT) : undefined;
  const { clearDate, ...props } = useDateInputMask({
    mask,
    onChange: (value) => {
      const date = DateTime.fromFormat(value, displayFormat);
      onChange(
        createEvent({
          name,
          value: date.isValid ? date.toFormat(PARSING_FORMAT) : '',
        })
      );
    },
    value: date?.isValid ? date.toFormat(displayFormat) : undefined,
    validate: validator.fn,
  });

  return (
    <InputView
      disabled={disabled}
      size={size}
      error={error}
      id={id}
      name={`prevent-suggestions ${id ?? ''}`}
      placeholder={placeholder}
      startAdornment={<CalendarIcon />}
      noClearHandler={props.value === mask}
      onChange={(e) => {
        if (!e.target.value) {
          clearDate();
        }
      }}
      {...props}
    />
  );
};
