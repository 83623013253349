import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect, useMemo } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { cookieActions, TheCookies } from './slice';

const ACCEPT_COOKIES_NAME = 'accept-cookies' as const;
export const useCookiesState = () =>
  useSelector((state: RootState) => state.cookies);

export const useCookieAcceptanceActions = () => {
  const state = useCookiesState();
  const [_cookies, setCookies] = useCookies<
    typeof ACCEPT_COOKIES_NAME,
    { 'accept-cookies': TheCookies }
  >([ACCEPT_COOKIES_NAME]);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      closeDialog: () => {
        dispatch(cookieActions.closeDialog());
      },
      checkAcceptedCookies: () => {
        if (!new Cookies().get(ACCEPT_COOKIES_NAME)?.required) {
          dispatch(cookieActions.openDialog());
        }
      },
      getAcceptedCookies: () => {
        return new Cookies().get(ACCEPT_COOKIES_NAME);
      },
      submitCookies: (cookies: TheCookies) => {
        dispatch(cookieActions.closeDialog());
        setCookies(ACCEPT_COOKIES_NAME, cookies, {
          domain: window.location.hostname.substring(
            window.location.hostname.indexOf('.')
          ),
          maxAge: 2147483647, // can not be more than 400 days in future
          secure: true,
          path: '/',
        });
      },
      openDialog: () => {
        dispatch(cookieActions.openDialog());
      },
    };
  }, [dispatch, setCookies]);

  return {
    dialogOpen: state.dialog,
    ...callbacks,
  };
};

export const useCheckAcceptedCookies = () => {
  const { getAcceptedCookies, openDialog } = useCookieAcceptanceActions();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!getAcceptedCookies()?.required) {
      dispatch(cookieActions.openDialog());
    }
  });
  useEffect(() => {
    const cb = () => {
      if (!getAcceptedCookies()?.required) {
        openDialog();
      }
    };
    window.document.addEventListener('click', cb, true);
    return () => {
      window.document.removeEventListener('click', cb, true);
    };
  }, [getAcceptedCookies, openDialog]);
};
