export const styles = {
  mapLayout: {
    flexShrink: 0,
    width: {
      xs: '100%',
      sm: '50%',
    },
    maxWidth: {
      xs: '100%',
      sm: 335,
      md: 422,
    },
  },
  mapContentContainer: {
    width: { xs: '100%' },
    height: { xs: '100%' },
    minHeight: {
      xs: 280,
      sm: 250,
    },
    backgroundColor: 'grey.100',
  },
  locationContainer: {
    '&:last-of-type': { marginBottom: 0 },
    mb: 36,
    gap: 20,
    rowGap: 36,
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  noLocationContainer: {
    flexDirection: 'row',
    gap: 20,
    width: '100%',
  },
  col: {
    flexGrow: 1,
    gap: {
      xs: 24,
      md: 36,
    },
    dl: {
      marginBottom: 0,
      '*': {
        lineHeight: '18px',
      },
    },
  },
  alertContainer: {
    flexGrow: 1,
    justifyContent: 'end',
  },
  column: {
    flex: '1 1 auto',
    dl: { mb: { lg: 24 } },
  },
};
