import {
  BirthSex,
  ContactEthnicityEnum,
  ContactGenderEnum,
  ContactRaceEnum,
  ContactSexualOrientationEnum,
} from '@/api/__generated__/webApi';
import { TranslationKeys } from '@/i18n/TranslationKeys';

export const BIRTH_SEX_ENUM_MAP: Record<
  BirthSex,
  keyof TranslationKeys['common']
> = {
  [BirthSex.FEMALE]: 'FEMALE',
  [BirthSex.MALE]: 'MALE',
  [BirthSex.UNKNOWN]: 'UNK_SEX',
} as const;
export const ETHNICITY_ENUM_MAP: Record<
  ContactEthnicityEnum,
  keyof TranslationKeys['common']
> = {
  [ContactEthnicityEnum.HISPANIC_OR_LATINO]: 'HISPANIC_OR_LATINO',
  [ContactEthnicityEnum.NOT_HISPANIC_OR_LATINO]: 'NOT_HISPANIC_OR_LATINO',
  [ContactEthnicityEnum.ASKU]: 'ASKU_ETH',
  [ContactEthnicityEnum.OTH]: 'OTH_ETH',
  [ContactEthnicityEnum.UNK]: 'UNK_ETH',
} as const;
export const GENDER_ENUM_MAP: Record<
  ContactGenderEnum,
  keyof TranslationKeys['common']
> = {
  [ContactGenderEnum.ASKU]: 'ASKU_GENDER',
  [ContactGenderEnum.OTH]: 'OTH_GENDER',
  [ContactGenderEnum.FEMALE]: ContactGenderEnum.FEMALE,
  [ContactGenderEnum.MALE]: ContactGenderEnum.MALE,
  [ContactGenderEnum.MALE_TO_FEMALE]: ContactGenderEnum.MALE_TO_FEMALE,
  [ContactGenderEnum.FEMALE_TO_MALE]: ContactGenderEnum.FEMALE_TO_MALE,
  [ContactGenderEnum.GENDERQUEER]: ContactGenderEnum.GENDERQUEER,
} as const;
export const RACE_ENUM_MAP: Record<
  ContactRaceEnum,
  keyof TranslationKeys['common']
> = {
  [ContactRaceEnum.AMERICAN_INDIAN_OR_ALASKA_NATIVE]:
    ContactRaceEnum.AMERICAN_INDIAN_OR_ALASKA_NATIVE,
  [ContactRaceEnum.ASIAN]: ContactRaceEnum.ASIAN,
  [ContactRaceEnum.BLACK_OR_AFRICAN_AMERICAN]:
    ContactRaceEnum.BLACK_OR_AFRICAN_AMERICAN,

  [ContactRaceEnum.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER]:
    ContactRaceEnum.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER,
  [ContactRaceEnum.WHITE]: ContactRaceEnum.WHITE,
  [ContactRaceEnum.OTHER]: 'OTH_RACE',
  [ContactRaceEnum.ASKU]: 'ASKU_RACE',
  [ContactRaceEnum.UNKNOWN]: 'UNK_RACE',
} as const;
export const SEXUAL_ORIENTATION_ENUM_MAP: Record<
  ContactSexualOrientationEnum,
  keyof TranslationKeys['common']
> = {
  [ContactSexualOrientationEnum.LESBIAN_GAY_HOMOSEXUAL]:
    ContactSexualOrientationEnum.LESBIAN_GAY_HOMOSEXUAL,
  [ContactSexualOrientationEnum.STRAIGHT_HETEROSEXUAL]:
    ContactSexualOrientationEnum.STRAIGHT_HETEROSEXUAL,
  [ContactSexualOrientationEnum.BISEXUAL]:
    ContactSexualOrientationEnum.BISEXUAL,
  [ContactSexualOrientationEnum.ASKU]: 'ASKU_SEXUAL_ORIENTATION',
  [ContactSexualOrientationEnum.UNK]: 'UNK_SEXUAL_ORIENTATION',
  [ContactSexualOrientationEnum.OTH]: 'OTH_SEXUAL_ORIENTATION',
} as const;
