import { apiClient } from '@/api/client/ApiClient';
import { Permission, Role, User, UUID, Page, ModuleUUID } from '@/types';

export const getRoles = async ({
  companyId,
  moduleId,
}: {
  companyId: UUID;
  moduleId: UUID;
}) => {
  const response = await apiClient.get<Role[]>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const createRole = async ({
  companyId,
  moduleId,
  data,
}: {
  companyId: UUID;
  moduleId: UUID;
  data: { description: string; name: string; permissions: UUID[] };
}) => {
  const response = await apiClient.post<Role>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles`,
    data,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const getPermissions = async ({
  companyId,
  moduleId,
  roleId,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
}) => {
  const response = await apiClient.get<Permission[]>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/permissions`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const addPermissions = async ({
  companyId,
  moduleId,
  roleId,
  ids,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  ids: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/permissions/fn/add`,
    { ids },
    { params: { permissionsCompanyId: companyId } }
  );
};

export const removePermissions = async ({
  companyId,
  moduleId,
  roleId,
  ids,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  ids: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/permissions/fn/remove`,
    { ids },
    { params: { permissionsCompanyId: companyId } }
  );
};

export const getAvailablePermissions = async ({
  companyId,
  moduleId,
}: {
  companyId: UUID;
  moduleId: UUID;
}) => {
  const response = await apiClient.get<Permission[]>(
    `/api/companies/${companyId}/company-modules/${moduleId}/available-permissions`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

interface GetUsersParams {
  moduleId: ModuleUUID;
  companyId: UUID;
  roleId: UUID;
  filter: string;
  startPage: number;
  perPage: number;
}
export const getUsersByPage = async ({
  moduleId,
  roleId,
  companyId,
  ...params
}: GetUsersParams) => {
  const response = await apiClient.get<Page<User>>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users`,
    { params: { ...params, permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const removeUser = async ({
  companyId,
  moduleId,
  roleId,
  users,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  users: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users/fn/remove`,
    { ids: users },
    { params: { permissionsCompanyId: companyId } }
  );
};
export const addUser = async ({
  companyId,
  moduleId,
  roleId,
  users,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  users: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users/fn/add`,
    { ids: users },
    { params: { permissionsCompanyId: companyId } }
  );
};

export * as companyModulesApi from './';
