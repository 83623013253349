/** @jsxImportSource @emotion/react */
import { css as _ } from '@emotion/react';
import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ProfileMenuButtonStyled } from './styles';
import { createPortal } from 'react-dom';
import { Box, ButtonBaseProps, Stack, Theme } from '@mui/material';
import { MenuList } from './MenuList/MenuList';

import { ProfileAvatar } from '../../profile/ProfileAvatar';
import { UUID } from '@/types';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

const styles = {
  buttonLabel: (t: Theme) => ({
    lineHeight: 1,
    [t.breakpoints.between('xs', 'lg')]: { display: 'none' },
  }),
};

export interface ProfileMenuItem {
  id: UUID;
  to?: string;
  href?: string;
  onClick?: () => void;
  label: string;
  items?: ProfileMenuItem[];
  icon?: JSX.Element;
  title?: string;
}
interface ProfileMenuProps {
  name: string;
  src?: string | undefined;
  menu: ProfileMenuItem[];
  className?: string;
}
export const ProfileMenuView: FC<ProfileMenuProps> = ({
  name,
  src,
  menu,
  className,
}) => {
  const [opened, setOpened] = useState(false);
  const elRef = useRef(window.document.createElement('div'));
  const [container, setContainerEl] = useState<HTMLElement | null>(null);
  const tmtRef = useRef<number>();
  const btRef = useRef<HTMLElement | null>(null);
  const containerRef = useRef<HTMLUListElement | null>(null);
  const handleClick = useCallback(() => {
    setOpened((s) => !s);
  }, []);
  const handleMouseLeave = useCallback(() => {
    tmtRef.current = window.setTimeout(() => {
      setOpened(false);
    }, 1000);
  }, []);
  const handleMouseOver = useCallback(() => {
    window.clearTimeout(tmtRef.current);
  }, []);
  useOnClickOutside(
    [btRef, containerRef],
    () => {
      window.setTimeout(() => {
        setOpened(false);
      });
    },
    true
  );

  useEffect(() => {
    container?.appendChild(elRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      container?.removeChild(elRef.current);
    };
  }, [container]);

  return (
    <Stack
      ref={setContainerEl}
      className={className}
      direction={'row'}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ProfileMenuButton
        ref={(el) => {
          btRef.current = el;
        }}
        type={'button'}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        name={name}
        src={src}
      >
        <>
          {createPortal(
            <MenuList
              containerRef={containerRef}
              parentRef={btRef}
              open={opened}
              menu={menu}
              onClose={handleClick}
            />,
            elRef.current
          )}
        </>
      </ProfileMenuButton>
    </Stack>
  );
};
interface Props extends ButtonBaseProps {
  src?: string;
  name?: string;
}
export const ProfileMenuButton = forwardRef<any, Props>(
  ({ name, children, src, ...baseBtProps }, ref) => {
    return (
      <ProfileMenuButtonStyled ref={ref} {...baseBtProps}>
        <Box sx={styles.buttonLabel}>{name || '...'}</Box>
        <ProfileAvatar
          size={24}
          css={(theme) =>
            _`background: transparent; svg path {fill: ${theme.palette.grey[800]}}`
          }
          noLetters
          noUpload
        />
        {children}
      </ProfileMenuButtonStyled>
    );
  }
);
ProfileMenuButton.displayName = 'ProfileMenuButton';
