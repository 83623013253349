import { PinCodeControl } from '@/components';
import React, { FC, memo } from 'react';
import { FastFieldProps, Field } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';

type PinCodeControlProps = Parameters<typeof PinCodeControl>[0];
export const PinCodeControlF: FC<
  Omit<PinCodeControlProps, 'name'> & { name: string }
> = memo((props) => {
  return (
    <Field {...props}>
      {({ field, form, meta }: FastFieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return <PinCodeControl {...props} {...field} error={e} />;
      }}
    </Field>
  );
});
PinCodeControlF.displayName = 'PinCodeControlF';
