import { PartialRecord } from '@/types';
import { AddressComponentType } from '@/components/Google/shared/types/addressComponentType';

export type AddressComponentMapType = PartialRecord<
  AddressComponentType,
  google.maps.GeocoderAddressComponent
>;
export const addrComponentsToRecord = (
  addressComponents: google.maps.GeocoderAddressComponent[] | undefined
): AddressComponentMapType => {
  const ac =
    addressComponents?.reduce<
      PartialRecord<AddressComponentType, google.maps.GeocoderAddressComponent>
    >((acc, item) => {
      for (const cType of item.types) {
        acc[cType as AddressComponentType] = item;
      }
      return acc;
    }, {}) ?? {};
  return ac;
};
