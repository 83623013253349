/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, css as _, styled, Typography } from '@mui/material';
import { ReactComponent as IconSVGCloudUpload } from '@/images/icons/icon_upload.svg';
import { ReactComponent as IconSVGFilePlus } from '@/images/icons/icon_file_plus.svg';
import classNames from 'classnames';

export const DnDZoneViewPhotoUpload: FC<{
  label?: string;
  active?: boolean;
  src?: string;
  round?: boolean;
}> = ({ round = true, src, label, active, ...restProps }) => {
  const { t } = useTranslate('common');
  const validSrc = src || undefined;
  return (
    <DnDLabel
      className={classNames(
        active && 'dd-active',
        validSrc && 'dd-image',
        'dd-label'
      )}
      {...restProps}
      css={(th) => _`
        border: ${validSrc ? 'none' : `1px dashed ${th.palette.grey[300]}`};
      `}
      round={round}
    >
      <Img src={validSrc} />
      {!!validSrc && (
        <Backdrop
          css={_`
            .dd-label:hover &, .dd-active & {
              opacity: .4;
            }
          `}
        />
      )}
      <Box
        css={_`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 17px;
          z-index: 101;
        `}
      >
        {!active && (
          <>
            <IconSVGCloudUpload
              css={_`
                .dd-label:hover & {
                  display: block;
                }
                .dd-image & {
                  display: none;
                  path {
                    fill: #fff;
                  }
                }
              `}
            />
            <Typography
              variant={'14_20_400'}
              lineHeight={'20px'}
              textAlign={'center'}
              whiteSpace={'pre-wrap'}
              css={_`
                strong {font-weight: 500}
                .dd-label:hover & {
                  display: block;
                }
                .dd-image & {
                  color: #fff;
                  display: none;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: t('DROP_PHOTO_TO_UPLOAD_OR_BROWSE'),
              }}
            />
          </>
        )}
        {active && <IconSVGFilePlus />}
      </Box>
    </DnDLabel>
  );
};
const DnDLabel = styled('label')<{ round?: boolean }>(
  ({ theme: th, round }) => _`
  position: relative;
  width: 196px;
  height: 196px;
  overflow: hidden;
  border-radius: ${round ? '50%' : ''};
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 17px;
  background-color: ${th.palette.grey[200]};
  align-items: center;
  * {
    pointer-events: none;
  }
  &.dd-active {
    background: ${th.palette.grey[300]} !important;
    justify-content: center;
  } 
  &:hover {
    background: ${th.palette.grey[300]};
    svg path {
      fill: white;
    }
  }
  input:focus-visible + & {
    background: ${th.palette.grey[300]} !important;
    border: 1px solid ${th.colors.all.focus};
    svg path {
      fill: white;
    }
  }
`
);

const Backdrop = styled(Box)(
  () => _`
  opacity: 0;
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
`
);
const Img = styled(Box)<{ src: string | undefined }>(
  ({ src, theme: t }) => _`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
);
