export const getName = (
  data:
    | {
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      }
    | null
    | undefined,
  short?: boolean
) =>
  (data ? [data.firstName, !short && data.middleName, data.lastName] : [])
    .filter(Boolean)
    .join(' ');
