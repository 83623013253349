import { useEffect, useState } from 'react';

export const useDataUrl = (file: File | undefined) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setUrl(reader.result as string);
        },
        false
      );
      reader.readAsDataURL(file);
    } else {
      setUrl('');
    }
  }, [file]);
  return url;
};
