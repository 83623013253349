/** @jsxImportSource @emotion/react */
import {
  BoxFooter,
  BoxHeader,
  BoxHeaderDeprecated,
  boxPaddings,
  WhiteBox,
} from '@/components/WhiteBox';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, Fragment, useRef } from 'react';
import {
  useNewRoleForm,
  useRoles,
  useUpdateDetails,
} from '@/store/companies/hooks';
import { Button } from '@/components/Button';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes';
import { CompanyPermissionName, Role, UUID } from '@/types';
import { CreateNewRoleForm } from './fragments/CreateNewRoleForm';
import { Box, css as _, Typography } from '@mui/material';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { RoleItem } from './fragments/RoleItem';
import { ReactComponent as IconSVGPlus } from '@/images/icons/icon_plus.svg';
import { useMQuery } from '@/hooks/useMQuery';
import { Grid } from '@/components';
import {
  PermissionsKeeper,
  useCompanyPermissionsValidator,
} from '@/store/permissions/PermissionsKeeper';
import { ScrollContainer } from '@/features/module/ModulePage/fragments/ScrollContainer';
import { useEditRoleForm } from '@/store/companies/hooks/useEditRoleForm';
import { NormalizedState } from '@/store/common/normalized';
import { EditRoleForm } from '@/features/module/ModulePage/fragments/EditRoleForm';
import { ModuleType } from '@/api/__generated__/webApi';
import { isEqual } from 'lodash-es';

const useValuesChanged = (data: Record<string, string | undefined | null>) => {
  const ref = useRef(data);
  if (!isEqual(ref.current, data)) {
    ref.current = data;
    return true;
  }
  return false;
};

export const ModulePage: FC = () => {
  const { t, ready } = useTranslate('companies');
  useUpdateDetails();
  const { createNewRoleFormIsShown, showCreateNewRoleForm } = useNewRoleForm();
  const { editRoleFormShown, resetEditRoleForm } = useEditRoleForm();
  const { roles, customRolesIds, fetching } = useRoles();
  const { module, companyId, moduleId } = useParams();
  const { xl } = useMQuery(); // todo [tech-debt]: replace with useBreakpoints
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  const anyOfFormsIsShown = editRoleFormShown || createNewRoleFormIsShown;
  const forAllXLScreensAndLarger = xl;
  const forAllScreensSmallerThanXl = !xl;

  const changed = useValuesChanged({ module, companyId, moduleId });
  if (changed) {
    showCreateNewRoleForm(false);
    resetEditRoleForm();
  }

  if (!ready) {
    return <>...</>;
  }

  let content = <>...</>;

  const systemRolesHeadersMap: Record<ModuleType, string> = {
    [ModuleType.LIS]: t('LIS_SYSTEM_ROLES'),
    [ModuleType.PASSPORT]: t('PASSPORT_SYSTEM_ROLES'),
    [ModuleType.PROFESSIONAL]: t('HEALTHCARE_PROFESSIONAL_SYSTEM_ROLES'),
  };

  const canHaveCustomRoles = moduleType !== ModuleType.PASSPORT;

  if (fetching && !roles?.allIDs.length) {
    content = <>{t('LOADING')}</>;
  } else if (!roles?.allIDs.length) {
    content = <>{t('EMPTY')}</>;
  } else {
    content = (
      <>
        <PermissionsKeeper
          show={(has) => has(CompanyPermissionName.CompanyViewAllRoles)}
        >
          <ScrollContainer scroll={xl}>
            <BoxHeaderDeprecated>
              {moduleType && systemRolesHeadersMap[moduleType]}
            </BoxHeaderDeprecated>
            <Box
              display={'flex'}
              flexDirection={'column'}
              sx={{ gap: { xs: 48, lg: 24 } }}
            >
              {roles.allIDs
                .filter((id) => !roles.byID[id]?.editable)
                .map((id) => {
                  return <RoleItem key={id} role={roles.byID[id]} />;
                })}
            </Box>
            <CustomRolesList
              roles={roles}
              customRolesIds={customRolesIds}
              formShown={anyOfFormsIsShown}
            />
          </ScrollContainer>
        </PermissionsKeeper>
        <PermissionsKeeper
          show={(has) => has(CompanyPermissionName.ModuleCreateCustomRole)}
        >
          {canHaveCustomRoles && (
            <>
              {!anyOfFormsIsShown && (
                <BoxFooter
                  sx={
                    xl
                      ? (t) => ({
                          ...boxPaddings(t),
                          paddingTop: '0!important',
                          marginTop: 0,
                        })
                      : undefined
                  }
                >
                  <Button
                    color="primary"
                    onClick={() => showCreateNewRoleForm(true)}
                    startIcon={<IconSVGPlus />}
                  >
                    {t('ADD_ROLE')}
                  </Button>
                </BoxFooter>
              )}
              {forAllScreensSmallerThanXl && (
                <>
                  {createNewRoleFormIsShown && (
                    <CreateNewRoleForm
                      back={() => showCreateNewRoleForm(false)}
                    />
                  )}
                  {editRoleFormShown && (
                    <EditRoleForm back={resetEditRoleForm} />
                  )}
                </>
              )}
            </>
          )}
        </PermissionsKeeper>
      </>
    );
  }

  return (
    <Fragment key={module}>
      <CompanyHeader />
      <Grid container spacing={{ xs: 24, lg: 36 }} height={'100%'}>
        <Grid xs>
          <WhiteBox
            sx={
              xl
                ? { height: '100%!important', padding: '0!important' }
                : undefined
            }
          >
            {content}
          </WhiteBox>
        </Grid>
        {forAllXLScreensAndLarger && (
          <>
            {createNewRoleFormIsShown && (
              <Grid xs={4}>
                <WhiteBox>
                  <BoxHeader mb={30}>{t('CUSTOM_ROLES')}</BoxHeader>
                  <CreateNewRoleForm
                    back={() => showCreateNewRoleForm(false)}
                    css={_`height: 100%`}
                  />
                </WhiteBox>
              </Grid>
            )}
            {editRoleFormShown && (
              <Grid xs={4}>
                <WhiteBox>
                  <BoxHeader mb={30}>{t('EDIT_ROLE')}</BoxHeader>
                  <EditRoleForm
                    back={resetEditRoleForm}
                    css={_`height: 100%`}
                  />
                </WhiteBox>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Fragment>
  );
};

const CustomRolesList: FC<{
  roles: NormalizedState<Role, string>;
  customRolesIds: UUID[] | null | undefined;
  formShown: boolean;
}> = ({ formShown, roles, customRolesIds }) => {
  const { module, moduleId, companyId } = useParams();
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  const canHaveCustomRoles = moduleType !== ModuleType.PASSPORT;
  const { t } = useTranslate('companies');
  const { showEditRoleForm } = useEditRoleForm();
  const customRolesHeadersMap: Record<ModuleType, string> = {
    [ModuleType.LIS]: t('LIS_CUSTOM_ROLES'),
    [ModuleType.PASSPORT]: t('PASSPORT_CUSTOM_ROLES'),
    [ModuleType.PROFESSIONAL]: t('HEALTHCARE_PROFESSIONAL_CUSTOM_ROLES'),
  };

  const hasPerm = useCompanyPermissionsValidator(true);

  if (!canHaveCustomRoles) {
    return null;
  }

  return (
    <>
      <BoxHeader
        sx={{
          mt: { xs: 48, sm: 64, lg: 50 },
          mb: { xs: 48, sm: 48, lg: 24 },
        }}
      >
        {moduleType && customRolesHeadersMap[moduleType]}
      </BoxHeader>
      {customRolesIds?.length ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{ gap: { xs: 48, lg: 36 }, mb: { xs: 0, sm: 18 } }}
        >
          {customRolesIds.map((id: UUID) => {
            return (
              <RoleItem
                key={id}
                role={roles.byID[id]}
                disableEdit={formShown}
                onEdit={
                  hasPerm(CompanyPermissionName.ModuleRenameCustomRole) ||
                  hasPerm(CompanyPermissionName.ModuleEditCustomRole)
                    ? () => {
                        showEditRoleForm({ roleId: id, moduleId, companyId });
                      }
                    : () => {}
                }
              />
            );
          })}
        </Box>
      ) : (
        !formShown && (
          <Typography variant={'14_20_400'} component={'p'} color={'grey.400'}>
            {t('YOU_HAVE_NOT_ADDED_ANY_CUSTOM_ROLES_YET')}
          </Typography>
        )
      )}
    </>
  );
};
