import { useAppDispatch, useAppSelector } from '@/store/store';
import {
  useDeleteAddressMutation,
  useGetAddressQuery,
  useUpdateAddressMutation,
} from '@/features/profile-address/store/api';
import { useCallback, useMemo } from 'react';
import { contactAddressSlice } from '@/features/profile-address/store/slice';
import { UpdateAddressForm } from '@/features/profile-address/types';
import { getErrors } from '@/store/common/error-handlers';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { AddressChangeRequest, StateType } from '@/api/__generated__/webApi';
import { FullAddress } from '@/components/Google/shared/types/FullAddress';
import { ValidationErrorType } from '@/types/ValidationError';

export const useDelete = () => {
  const { dialog } = useAppSelector((state) => state.contactAddress);
  const { data } = useGetAddressQuery();
  const [deleteAddr] = useDeleteAddressMutation();
  const dispatch = useAppDispatch();

  const deleteAddress = useCallback(async () => {
    if (data?.id) void deleteAddr(data.id);
    dispatch(contactAddressSlice.actions.closeDialog());
  }, [data?.id, deleteAddr, dispatch]);

  return {
    deleteAddress,
    dismiss: () => dispatch(contactAddressSlice.actions.closeDialog()),
    isOpen: dialog === 'delete',
  };
};

export const useAddress = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAddressQuery();

  const callbacks = useMemo(() => {
    return {
      onEdit() {
        dispatch(contactAddressSlice.actions.openEditAddress());
      },
      onDelete() {
        dispatch(contactAddressSlice.actions.openConfirmDelete());
      },
    };
  }, [dispatch]);

  return {
    ...callbacks,
    address: data,
    isLoading,
  };
};
const initValues: UpdateAddressForm = {
  fullAddress: null,
  address2: '',
  searchFieldText: '',
};
const formToAddressChangeRequest = ({
  fullAddress: address,
  address2,
}: UpdateAddressForm): AddressChangeRequest | undefined => {
  if (!address) {
    console.error('[formToAddressChangeRequest] FullAddress is undefined');
    return;
  }
  return {
    address1: address?.streetAddress ?? '',
    address2,
    city: address?.city ?? '',
    location: {
      latitude: address.latitude as number,
      longitude: address.longitude as number,
      googlePlaceId: address.googlePlaceId as string,
    },
    postalCode: address.postalCode as string,
    state: address.state as StateType,
  };
};
export const useUpdateAddress = () => {
  const { data, isFetching } = useGetAddressQuery();
  const [update, { error, isLoading }] = useUpdateAddressMutation();
  const { dialog } = useAppSelector((state) => state.contactAddress);
  const dispatch = useAppDispatch();
  const callbacks = useMemo(() => {
    return {
      dismiss: () => {
        dispatch(contactAddressSlice.actions.closeDialog());
      },
      submit: async (formData: UpdateAddressForm) => {
        try {
          const requestData = formToAddressChangeRequest(formData);
          if (requestData) await update(requestData).unwrap();
          dispatch(contactAddressSlice.actions.closeDialog());
        } catch (e) {
          console.error('error!', e);
        }
      },
      resetServerErrors: () => {},
    };
  }, [dispatch, update]);

  const formData: UpdateAddressForm = useMemo(() => {
    return data
      ? {
          fullAddress: {
            fullAddress: data.main ?? '',
            streetAddress: data.main ?? '',
            city: data.city ?? '',
            state: data.state ?? '',
            postalCode: data.postalCode ?? '',
            latitude: data.latitude,
            longitude: data.longitude,
            googlePlaceId: data.googlePlaceId ?? '',
          },
          searchFieldText: '',
          address2: data.additional ?? '',
        }
      : initValues;
  }, [data]);

  const errors = useMemo(() => {
    if (!error) {
      return {};
    }
    const errors = getErrors(error) as Record<
      keyof FullAddress | 'common' | 'additional',
      ValidationErrorType
    >;
    if (errors?.common) {
      showError500OrUnknownToast(error);
      return {};
    }
    if (Object.keys(errors).length) {
      return { fullAddress: 'INVALID_ADDRESS' as ValidationErrorType };
    }
    return errors;
  }, [error]);

  return {
    edit: !!data,
    isOpen: dialog === 'edit',
    isLoading: isLoading || isFetching,
    values: formData,
    errors,
    ...callbacks,
  };
};
