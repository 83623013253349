/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, Typography } from '@mui/material';
import { MobilePhone } from '@/api/__generated__/webApi';
import { EMPTY } from '@/constants';
import { groupPhones } from '@/components/PhonesDisplayWithTooltip';
import { FormattedPhoneNumber, Tooltip } from 'ui-kit';

export const PopupPhone: FC<{
  children: JSX.Element;
  data: MobilePhone[] | undefined;
  selected?: string;
}> = ({ children, data, selected }) => {
  const { t } = useTranslate('companies');

  const { confirmed, unconfirmed, main } = groupPhones(data);

  const content = (
    <Box display={'flex'} flexDirection={'column'} gap={12}>
      <Box display={'inline-flex'} gap={8}>
        <Typography
          component={'h4'}
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={700}
        >
          {t('MAIN')}
        </Typography>
        <Typography
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={500}
          color={
            selected && selected === main?.phone
              ? (t) => t.palette.secondary.main
              : undefined
          }
        >
          {main?.phone ? (
            <FormattedPhoneNumber>{main.phone}</FormattedPhoneNumber>
          ) : (
            EMPTY
          )}
        </Typography>
      </Box>
      <Box
        display={'inline-flex'}
        flexWrap={'wrap'}
        gap={8}
        rowGap={3}
        flexDirection={confirmed.length ? 'column' : 'row'}
      >
        <Typography
          component={'h4'}
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={700}
        >
          {t('CONFIRMED')}
        </Typography>

        {!!confirmed.length && (
          <Box>
            {confirmed.map((d, i) => {
              if (!d) {
                return null;
              }
              return (
                <Typography
                  key={i}
                  component={'p'}
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={500}
                  color={
                    selected && selected === d.phone
                      ? (t) => t.palette.secondary.main
                      : undefined
                  }
                >
                  <FormattedPhoneNumber>{d.phone}</FormattedPhoneNumber>
                </Typography>
              );
            })}
          </Box>
        )}
        {!confirmed.length && (
          <Typography fontSize={'12px'} lineHeight={'18px'} fontWeight={500}>
            {EMPTY}
          </Typography>
        )}
      </Box>
      <Box
        display={'inline-flex'}
        flexWrap={'wrap'}
        gap={8}
        rowGap={3}
        flexDirection={unconfirmed.length ? 'column' : 'row'}
      >
        <Typography
          component={'h4'}
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={700}
        >
          {t('UNCONFIRMED')}
        </Typography>

        {!!unconfirmed.length && (
          <Box>
            {unconfirmed.map((d, i) => {
              if (!d) {
                return null;
              }
              return (
                <Typography
                  key={i}
                  component={'p'}
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={500}
                  color={
                    selected && selected === d.phone
                      ? (t) => t.palette.secondary.main
                      : undefined
                  }
                >
                  <FormattedPhoneNumber>{d.phone}</FormattedPhoneNumber>
                </Typography>
              );
            })}
          </Box>
        )}
        {!unconfirmed.length && (
          <Typography fontSize={'12px'} lineHeight={'18px'} fontWeight={500}>
            {EMPTY}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Tooltip title={content} placement={'bottom'}>
      {children}
    </Tooltip>
  );
};
