import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalStateResetAction } from '@/store/common/actions';
import { PartialRecord } from '@/types';
import { ValidationErrorType } from '@/types/ValidationError';
import { NPI1ShortData } from '@/api/__generated__/webApi';

type NpiStep = 'init' | 'registration' | 'processing' | 'processed';
export interface NPIState {
  npi: NPI1ShortData | null;
  npiNumber: string | null;
  confirmed: boolean;
  fetching: boolean;
  errors: PartialRecord<'common' | string, ValidationErrorType> | null;
  requestSuccess: boolean;
  step: NpiStep;
}
const initialState: NPIState = {
  npi: null,
  npiNumber: null,
  confirmed: false,
  fetching: true,
  errors: null,
  requestSuccess: false,
  step: 'processed',
};
export const slice = createSlice({
  name: 'npi',
  initialState,
  reducers: {
    requestAccountNpiData() {},
    requestNPIInfo(state, action: PayloadAction<string>) {
      state.npiNumber = action.payload;
    },
    requestConfirm(state) {
      state.errors = null;
    },
    setNpiInfo(
      state,
      action: PayloadAction<{
        npi: NPI1ShortData | null;
        confirmed: boolean;
        step: NpiStep;
      }>
    ) {
      state.confirmed = action.payload.confirmed;
      state.npi = action.payload.npi;
      if (!action.payload.npi) {
        state.npiNumber = null;
      }
      state.step = action.payload.step;
    },
    setErrors(
      state,
      action: PayloadAction<PartialRecord<
        'common' | string,
        ValidationErrorType
      > | null>
    ) {
      state.errors = action.payload;
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setRequestSuccess(state, { payload }: PayloadAction<boolean>) {
      state.requestSuccess = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const npiStateName = slice.name;
export const npiReducer = slice.reducer;
export const npiActions = slice.actions;
