import React, { ReactNode } from 'react';
import { useAddress } from './store/hooks';
import { GeoValidatedAddressView } from './fragments/GeoValidatedAddressView';
import { NoAddressView } from './fragments/NoAddressView/NoAddressView';
import { NotGeoValidatedAddressView } from './fragments/NotGeoValidatedAddressView';
import { Dialogs } from './fragments/Dialogs';

export const Addresses = () => {
  const { isLoading, address, ...d } = useAddress();
  let content: ReactNode = null;
  if (isLoading) {
    content = null;
  } else if (!address) {
    content = <NoAddressView onAdd={d.onEdit} />;
  } else if (address?.googlePlaceId) {
    content = (
      <GeoValidatedAddressView
        address={address}
        onChange={d.onEdit}
        onDelete={d.onDelete}
      />
    );
  } else {
    content = (
      <NotGeoValidatedAddressView
        address={address}
        onChange={d.onEdit}
        onDelete={d.onDelete}
      />
    );
  }
  return (
    <>
      {content}
      <Dialogs />
    </>
  );
};
