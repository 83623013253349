import { identityApiClient } from '@/api/client/ApiClient';
import { AuthCodeType, ResendTime } from '@/types';

export const getResendTime = async (
  params:
    | {
        type: Omit<AuthCodeType, 'RecoveryEmail'>;
        phone: string;
        recaptcha: string;
      }
    | { type: AuthCodeType.RecoveryEmail; email: string; recaptcha: string }
) => {
  const resonse = await identityApiClient.post<ResendTime>(
    '/api/v1/code/send',
    params
  );
  return resonse.data;
};
export * as authCodeApi from './';
