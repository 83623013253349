import { apiClient } from '@/api/client/ApiClient';
import { CodeType, ResendTime } from '@/types';

export const resendCode = async (data: {
  recaptcha: string;
  codeType: CodeType;
}): Promise<ResendTime> => {
  const response = await apiClient.post<ResendTime>('/api/code/send', data);
  return response.data;
};
export * as codeApi from '.';
