import { useTranslate } from '@/i18n/useTranslate';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { AppRoutes, generatePath } from '@/routes/appRoutes';
import { SideMenuItem } from '../SidebarContainer';
import { ReactComponent as IconProfile } from '@/images/icons/icon_profile.svg';
import { ReactComponent as IconSVGNote } from '@/images/icons/icon_notes.svg';
import { ReactComponent as IconSVGEmails } from '@/images/icons/icon_email.svg';
import { ReactComponent as IconSVGLock } from '@/images/icons/icon_lock.svg';
import { envs } from '@/constants/envs';
import { useBreakpoints } from 'ui-kit';
import { useCompaniesMenuItems } from '@/features/sidebar/useMenuItems/useCompaniesMenuItems';

export const useMenuItems = () => {
  const b = useBreakpoints();
  const mobile = b.xsOnly;
  const { t } = useTranslate('profile');
  const location = useLocation();
  const companyItems = useCompaniesMenuItems();

  return useMemo<SideMenuItem[]>(() => {
    const items: SideMenuItem[] = [
      {
        link: {
          pathname: AppRoutes.PROFILE,
          exact: true,
        },
        title: t('PROFILE'),
        selected: location.pathname === AppRoutes.PROFILE_EDIT,
        icon: <IconProfile />,
        items: [
          ...(mobile
            ? [
                {
                  link: {
                    pathname: generatePath(AppRoutes.PROFILE_PHONE_EMAILS, {
                      tabName: 'phones',
                    }),
                  },
                  title: t('PHONES'),
                  icon: <IconSVGNote />,
                },
                {
                  link: {
                    pathname: generatePath(AppRoutes.PROFILE_PHONE_EMAILS, {
                      tabName: 'emails',
                    }),
                  },
                  title: t('EMAILS'),
                  icon: <IconSVGEmails />,
                },
              ]
            : [
                {
                  link: {
                    pathname: generatePath(AppRoutes.PROFILE_PHONE_EMAILS, {
                      tabName: 'phones',
                    }),
                  },
                  title: t('PHONES_EMAILS'),
                  selected:
                    location.pathname ===
                    generatePath(AppRoutes.PROFILE_PHONE_EMAILS, {
                      tabName: 'emails',
                    }),
                  icon: <IconSVGNote />,
                },
              ]),
          {
            link: {
              pathname: AppRoutes.PROFILE_SECURITY,
              exact: true,
            },
            title: t('SECURITY_SETTINGS'),
            icon: <IconSVGLock />,
          },
        ],
        buttons: [
          {
            title: t('GO_TO_VIVICA_PATIENT'),
            link: { pathname: envs.PATIENT_PORTAL_URL },
          },
        ],
      },
      ...companyItems,
    ];
    return items;
  }, [companyItems, location.pathname, mobile, t]);
};
