import { ReactComponent as IconSVGHamburger } from '@/images/icons/icon_hamburger.svg';
import { ReactComponent as IconSVGTimesMenu } from '@/images/icons/icon_times_menu.svg';
import { IconButton } from '@/components/IconButton';
export const HamburgerButton = ({
  toggle,
  open,
}: {
  toggle: () => void;
  open: boolean;
}) => {
  return (
    <IconButton onClick={toggle} size={'lg'} color={'quaternary'}>
      {open ? <IconSVGTimesMenu /> : <IconSVGHamburger />}
    </IconButton>
  );
};
