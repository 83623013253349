import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';
import { spreadSx } from '@/utils/spreadSx';
const sx = {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  rowGap: 8,
  flexWrap: 'wrap',
  lineHeight: { xs: '20px', sm: '24px' },
  margin: 0,
  'dt, dd': {
    display: 'inline-block',
    wordBreak: 'break-word',
    fontSize: { xs: '14px', lg: '16px' },
    fontFamily: 'inherit',
  },
  dt: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '0 1 auto',
    fontWeight: 700,
  },
  dd: {
    marginLeft: 0,
    fontWeight: { xs: 500 },
  },
};
export const Dl = memo(
  ({ sx: extSx, mb, marginBottom, ...restProps }: BoxProps) => {
    const mbExternal = (mb ?? marginBottom) as number;

    return (
      <Box
        sx={[
          sx,
          {
            mb: mbExternal ?? { xs: 12 },
            '&:last-of-type': {
              marginBottom: mbExternal ?? 0,
            },
          },
          ...spreadSx(extSx),
        ]}
        component={'dl'}
        {...restProps}
      />
    );
  }
);
Dl.displayName = 'Dl';
