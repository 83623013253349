import React, { FC, ReactNode } from 'react';
import { ReactComponent as IconSVGWarning } from '@/images/icons/icon_triangle_warning.svg';
import { ReactComponent as IconSVGSuccess } from '@/images/icons/icon_round_success.svg';
import { ReactComponent as IconSVGInfo } from '@/images/icons/icon_round_info.svg';
import { ReactComponent as IconSVGError } from '@/images/icons/icon_round_error.svg';
import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@/theme/utils';
import { spreadSx } from '@/utils/spreadSx';
const styles = {
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      flexShrink: 0,
      width: { xs: 18, sm: 24, lg: 18 },
      height: { xs: 18, sm: 24, lg: 18 },
    },
  },
  contained: {
    display: 'flex',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    gap: { xs: 6, sm: 12 },
    paddingX: { xs: 9, sm: 12 },
    paddingY: { xs: 9, sm: 12 },
  },
  typography: { typography: { xs: '12_16_500' }, minWidth: 60 },
};
interface Props {
  children: ReactNode;
  type: 'warning' | 'success' | 'info' | 'error';
  className?: string;
  sx?: SxProps<Theme>;
}
export const Notification: FC<Props> = ({ type, children, className, sx }) => {
  const theme = useTheme();
  let icon;
  let color = '';

  switch (type) {
    case 'success':
      icon = (
        <Stack sx={styles.icon}>
          <Box component={IconSVGSuccess} />
        </Stack>
      );
      color = theme.palette.success.main;
      break;
    case 'warning':
      icon = (
        <Stack sx={styles.icon}>
          <Box component={IconSVGWarning} />
        </Stack>
      );
      color = theme.palette.warning.main;
      break;
    case 'info':
      icon = (
        <Stack sx={styles.icon}>
          <Box component={IconSVGInfo} />
        </Stack>
      );
      color = theme.palette.info.main;
      break;
    case 'error':
      icon = (
        <Stack sx={styles.icon}>
          <Box component={IconSVGError} />
        </Stack>
      );
      color = theme.palette.error.main;
      break;
    default:
      icon = <></>;
  }

  return (
    <Box
      className={className}
      sx={[
        { backgroundColor: alpha(color, 10) },
        styles.contained,
        ...spreadSx(sx),
      ]}
    >
      <Stack>{icon}</Stack>
      <Stack>
        <Typography sx={styles.typography}>{children}</Typography>
      </Stack>
    </Box>
  );
};
