/** @jsxImportSource @emotion/react */
import React, { FC, useMemo, useEffect } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Formik, useFormikContext } from 'formik';
import { H5, Link } from '@/components/Text';
import { Grid } from '@/components';
import { Button } from '@/components/Button';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import * as yup from 'yup';
import { passwordValidator, phoneValidator } from '../../../common/validators';
import { AppRoutes } from '@/routes/appRoutes';
import { useConnectServerValidationErrors } from '@/hooks/useConnectServerValidationErrors';
import { useActivationNewPasswordForm, useAuthState } from '@/store/auth/hooks';
import { getName } from '@/utils/getName';
import { formatDate } from '@/components/DateFormat';
import { CookiesNote } from '@/features/cookies/CookiesNote';
import { Stack, Typography } from '@mui/material';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { InputControl } from '@/components/InputControl';
import { ConfirmResponse } from '@/api';
import { PhoneInputControl } from '@/components/PhoneInputControl';
import { Notification } from '@/components/Notification';

export interface ActivationForm {
  password?: string;
  confirmPassword?: string;
  phone?: string;
  activationSuccessData?: ConfirmResponse;
}
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  const { t: tr } = useTranslate('password');
  return useMemo(() => {
    return yup.object().shape({
      phone: phoneValidator(t),
      password: passwordValidator(tr),
      confirmPassword: yup
        .string()
        .required(t('PLEASE_CONFIRM_PASSWORD'))
        .oneOf([yup.ref('password')], t('PASSWORDS_DO_NOT_MATCH')),
    });
  }, [t, tr]);
};
const initialValues = {
  password: '',
  confirmPassword: '',
  phone: '',
};
export const NewPasswordFragment: FC = () => {
  useRecaptcha();
  const schema = useValidationSchema();
  const { handleSubmit, resetErrors, errors } = useActivationNewPasswordForm();
  const connect = useConnectServerValidationErrors<ActivationForm>(
    errors,
    resetErrors
  );
  return (
    <>
      <Formik<ActivationForm>
        {...connect}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={schema}
      >
        <FormView />
      </Formik>
    </>
  );
};
export const FormView: FC = () => {
  const { t } = useTranslate('auth');
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<ActivationForm>();
  const { userToInvite } = useAuthState();
  const byPhone = userToInvite?.phone;
  useEffect(() => {
    if (byPhone) {
      void setFieldValue('phone', userToInvite.phone);
    }
  }, [byPhone, setFieldValue, userToInvite?.phone]);
  return (
    <Stack width={'100%'} justifyContent={{ xs: 'space-between' }}>
      <Stack component={'form'} id={'activation_form'} onSubmit={handleSubmit}>
        <AuthHeader
          title={t('REGISTER')}
          sx={{ mb: { xs: 36, sm: 200, lg: 64 } }}
        >
          <Link
            to={{ pathname: AppRoutes.AUTH, search: window.location.search }}
          >
            {t('SIGN_IN')}
          </Link>
        </AuthHeader>
        <H5 lh={24}>
          {userToInvite
            ? userToInvite.contact.birthDate
              ? t('WELCOME_INVITED_USER', {
                  name: getName(userToInvite.contact),
                  date: formatDate(userToInvite.contact.birthDate),
                })
              : t('WELCOME_INVITED_USER_NO_DOB', {
                  name: getName(userToInvite.contact),
                })
            : '...'}
        </H5>

        <Typography
          mb={{ xs: 36, lg: 32 }}
          sx={{ typography: { xs: '16_20_500', md: '14_18_500' } }}
        >
          {byPhone
            ? t('PLEASE_CREATE_YOUR_PASSWORD_TO_FINISH_')
            : t('FILL_IN_VALID_DATA_FOR_MEDICAL_PURPOSES_AFTER_SENDING_IT')}
        </Typography>

        <Grid container columnSpacing={{ xs: 36 }} rowSpacing={{ xs: 48 }}>
          <Grid xs={12} sm={6} lg={12}>
            <PhoneInputControl
              label={t('MOBILE_PHONE')}
              name={'phone'}
              disabled={!!userToInvite?.phone}
              value={values.phone ?? ''}
              onChange={handleChange}
              error={touched.phone ? errors.phone : undefined}
              ignoreLabelHeight
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            display={{ xs: 'none', sm: 'flex', lg: 'none' }}
          />
          <Grid xs={12} sm={6} lg={12}>
            <InputControl
              label={t('PASSWORD')}
              type={'password'}
              name={'password'}
              placeholder={t('ENTER_YOUR_PASSWORD')}
              value={values.password}
              onChange={handleChange}
              error={touched.password && errors.password}
              ignoreLabelHeight
            />
          </Grid>
          <Grid xs={12} sm={6} lg={12}>
            <InputControl
              label={t('CONFIRM_PASSWORD')}
              type={'password'}
              name={'confirmPassword'}
              placeholder={t('CONFIRM_PASSWORD')}
              value={values.confirmPassword ?? ''}
              onChange={handleChange}
              error={touched.confirmPassword && errors.confirmPassword}
              ignoreLabelHeight
            />
          </Grid>
        </Grid>
        <Notification type={'warning'} sx={{ mt: { xs: 36, sm: 48, lg: 24 } }}>
          {t('PLEASE_CREATE_A_STRONG_PASSWORD_')}
        </Notification>
        <Button
          fullWidth
          form={'activation_form'}
          variant={'contained'}
          color={'secondary'}
          type={'submit'}
          disabled={isSubmitting}
          sx={{
            mb: { xs: 12, lg: 24 },
            mt: {
              xs: 48,
              sm: 96,
              lg: 48,
            },
          }}
        >
          {t('REGISTER_NOW')}
        </Button>
        <CookiesNote btnText={t('REGISTER_NOW')} />
      </Stack>
      <RecaptchaNote />
    </Stack>
  );
};
