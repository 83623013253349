import { useMediaQuery, useTheme } from '@mui/material';

/**
 * @deprecated use ui-toolkit/useBreakpoints |
 * mobile - xsOnly |
 * tablet - smOnly + mdOnly |
 * desktop - lg
 */
export const useMQuery = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletPortrait = useMediaQuery(theme.breakpoints.only('sm'));
  const tabletLandscape = useMediaQuery(theme.breakpoints.only('md'));
  const tablet = tabletLandscape || tabletPortrait;
  const tabletWorkaround = useMediaQuery(theme.breakpoints.down('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const definitions = {
    mobile, // xsOnly or down(sm)
    tablet: tabletWorkaround && !mobile, // smOnly, mdOnly or between(sm, lg)
    tabletPortrait,
    tabletLandscape,
    desktop: !mobile && !tablet, // lg
    xl,
  };
  return definitions;
};
