import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/store';
import { ModuleType } from '@/api/__generated__/webApi';
import { companiesActions as actions } from '@/store/companies/slice';
import { UUID } from '@/types';

const allModuleTypes = Object.values(ModuleType);
const order = {
  [ModuleType.LIS]: 1,
  [ModuleType.PROFESSIONAL]: 2,
  [ModuleType.PASSPORT]: 0,
};
const getAllPossibleModuleOptions = (hideTypes: ModuleType[]) => {
  return allModuleTypes
    .sort((a, b) => {
      return order[a] > order[b] ? 1 : order[a] < order[b] ? -1 : 0;
    })
    .map((t) => ({ type: t, disabled: false, hidden: hideTypes.includes(t) }));
};
const getModulesWithState = (
  alreadyAddedCompanyModules: ModuleType[] | undefined,
  hideTypes: ModuleType[]
) => {
  if (!alreadyAddedCompanyModules) {
    return;
  }
  const availableModuleOptions = getAllPossibleModuleOptions(hideTypes);
  for (const type of alreadyAddedCompanyModules) {
    availableModuleOptions[allModuleTypes.indexOf(type)].disabled = true;
  }
  return availableModuleOptions;
};

//
//
//
//
//
//
//
//

export const useCreateModule = (companyId: UUID | undefined) => {
  const { companies, createModule, modules } = useAppSelector(
    (s) => s.companies
  );
  const company = companyId ? companies?.byID?.[companyId] : undefined;
  const companyModules = companyId ? modules?.[companyId] : undefined;

  const dispatch = useAppDispatch();
  const callbacks = useMemo(() => {
    return {
      openDialog: () => {
        dispatch(actions.dialogCreateModuleSetOpen({ open: true }));
      },
      closeDialog: () => {
        dispatch(actions.dialogCreateModuleSetOpen({ open: false }));
      },
      createModule: (moduleType: ModuleType) => {
        if (companyId) {
          dispatch(
            actions.requestCreateModule({
              companyId,
              moduleType,
            })
          );
        }
      },
      closeLisNotification: () => {
        dispatch(
          actions.dialogCreateModuleLisNotificationSetOpen({ open: false })
        );
      },
    };
  }, [companyId, dispatch]);

  const modulesOptions = useMemo(() => {
    if (!company || !companyModules) {
      return [];
    }

    const existingCompanyTypes = companyModules.allIDs.reduce<ModuleType[]>(
      (acc, moduleId) => {
        const { type } = companyModules.byID[moduleId] ?? {};
        if (!type) return acc;
        acc.push(type);
        return acc;
      },
      []
    );

    return getModulesWithState(
      existingCompanyTypes,
      company?.npi ? [] : [ModuleType.LIS]
    );
  }, [company, companyModules]);

  return {
    ...callbacks,
    dialogOpen: createModule.dialogOpen,
    fetching: createModule.fetching,
    modulesOptions,
    allModulesEnabled: company?.npi
      ? companyModules?.allIDs?.length === allModuleTypes.length
      : companyModules?.allIDs?.length === allModuleTypes.length - 1, // because companies without npi can not have lis module
    alertOpen: createModule.notificationLisOpen,
  };
};
