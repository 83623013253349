import { envs } from '@/constants/envs';
import {
  allRequestInterceptors,
  allResponseInterceptors,
} from './interceptors';
import { Api } from '@/api/__generated__/webApi';
import { Api as IdentityApi } from '@/api/__generated__/identityApi';

/// ///////// api clients //////////////////////////////////

const baseOptions = {
  withCredentials: true,
  baseURL: envs.PASSPORT_BACKEND,
  paramsSerializer: {
    indexes: null,
  },
};

export const client = new Api({ ...baseOptions });
for (const interceptors of allResponseInterceptors) {
  client.instance.interceptors.response.use(...interceptors);
}
for (const interceptors of allRequestInterceptors) {
  client.instance.interceptors.request.use(...interceptors);
}
export const apiClient = client.instance;

const identityBaseOptions = {
  withCredentials: true,
  baseURL: envs.PASSPORT_IDENTITY_API_V1,
};

export const identityClient = new IdentityApi({ ...identityBaseOptions });
export const identityApiClient = identityClient.instance;
