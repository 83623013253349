import { useParams } from '@/routes/appRoutes';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { UUID } from '@/types';
import { companiesActions as actions } from '../slice';
import { useCompaniesState } from './useCompaniesState';
import { USER_LIST_PER_PAGE_MAX } from '../saga/moduleUsersTableSagas';

export const useUsers = () => {
  const dispatch = useDispatch();
  const { companyId, roleId, moduleId } = useParams();
  const state = useCompaniesState();
  const moduleUsersState =
    moduleId && roleId
      ? state.moduleUsersByRoles[moduleId]?.[roleId] ?? null
      : null;
  const companyUsersState =
    companyId && roleId
      ? state.companyUsersByExclusionRoles[companyId]?.[roleId] ?? null
      : null;

  useEffect(() => {
    if (!moduleId || !companyId || !roleId) {
      return;
    }
    dispatch(actions.resetErrors());
    dispatch(
      actions.requestRoleUsers({
        companyId,
        type: 'company',
        roleId,
        excludeRoleId: roleId,
        filter: '',
        pageIndex: 0,
        replace: true,
      })
    );
    dispatch(
      actions.requestRoleUsers({
        moduleId,
        companyId,
        type: 'module',
        roleId,
        filter: '',
        pageIndex: 0,
        replace: true,
      })
    );
  }, [dispatch, companyId, moduleId, roleId]);
  const filterRef = useRef<{
    filter: string;
    type?: 'module' | 'company';
  } | null>(null);

  const callbacks = useMemo(() => {
    return {
      search: (filter: string, type?: 'module' | 'company') => {
        filterRef.current = { filter, type };
        dispatch(actions.setError(null));
        if (!moduleId || !companyId || !roleId) {
          console.error(
            '[useModuleRoleUsers -> #search] One of required params is not set',
            { moduleId, companyId, roleId }
          );
          return;
        }
        dispatch(actions.resetErrors());
        if (!type) {
          dispatch(
            actions.requestRoleUsers({
              companyId,
              type: 'company',
              roleId,
              filter,
              pageIndex: 0,
              excludeRoleId: roleId,
              replace: true,
            })
          );
          dispatch(
            actions.requestRoleUsers({
              companyId,
              type: 'module',
              roleId,
              filter,
              pageIndex: 0,
              moduleId,
              replace: true,
            })
          );
        } else {
          dispatch(
            actions.requestRoleUsers({
              companyId,
              type,
              roleId,
              filter,
              pageIndex: 0,
              ...(type === 'company'
                ? { excludeRoleId: roleId }
                : { moduleId }),
              replace: true,
            })
          );
        }
      },
      loadMoreCompanyUsers: () => {
        const filter =
          !filterRef.current?.type || filterRef.current?.type === 'module'
            ? ''
            : filterRef.current?.filter;
        dispatch(actions.resetErrors());

        if (!companyUsersState?.hasMore) {
          return;
        }

        if (!companyId || !roleId) {
          console.error(
            '[useModuleRoleUsers -> #loadMoreCompanyUsers] One of required params is not set',
            { companyId, roleId }
          );
          return;
        }
        dispatch(
          actions.requestRoleUsers({
            type: 'company',
            companyId,
            roleId,
            excludeRoleId: roleId,
            filter,
            pageIndex: companyUsersState?.items.allIDs.length
              ? Math.round(
                  companyUsersState.items.allIDs.length / USER_LIST_PER_PAGE_MAX
                )
              : 0,
            replace: false,
          })
        );
      },
      loadMoreModuleUsers: () => {
        const filter =
          !filterRef.current?.type || filterRef.current?.type === 'company'
            ? ''
            : filterRef.current?.filter;
        dispatch(actions.resetErrors());

        if (!moduleUsersState?.hasMore) {
          return;
        }

        if (!moduleId || !roleId || !companyId) {
          console.error(
            '[useModuleRoleUsers -> #loadMoreModuleUsers] One of required params is not set',
            { moduleId, roleId, companyId }
          );
          return;
        }
        dispatch(
          actions.requestRoleUsers({
            type: 'module',
            moduleId,
            companyId,
            roleId,
            filter,
            pageIndex: moduleUsersState?.items.allIDs.length
              ? Math.round(
                  moduleUsersState?.items.allIDs.length / USER_LIST_PER_PAGE_MAX
                )
              : 0,
            replace: false,
          })
        );
      },
      removeUser: (userId: UUID) => {
        if (!moduleId || !roleId || !companyId) {
          console.error(
            '[useModuleRoleUsers -> #removeUser] One of required params is not set',
            { moduleId, roleId, companyId }
          );
          return;
        }
        dispatch(
          actions.requestRemoveUserFromRole({
            userId,
            companyId,
            moduleId,
            roleId,
          })
        );
        dispatch(actions.resetErrors());
      },
      addUser: (id: UUID) => {
        if (!moduleId || !roleId || !companyId) {
          console.error(
            '[useModuleRoleUsers -> #addUser] One of required params is not set',
            { moduleId, roleId, companyId }
          );
          return;
        }
        dispatch(
          actions.requestAddUserToModuleRole({
            userId: id,
            companyId,
            moduleId,
            roleId,
          })
        );
        dispatch(actions.resetErrors());
      },
      resetError: () => {
        dispatch(actions.setError(null));
      },
    };
  }, [
    companyId,
    companyUsersState?.hasMore,
    companyUsersState?.items.allIDs.length,
    dispatch,
    moduleId,
    moduleUsersState?.hasMore,
    moduleUsersState?.items.allIDs.length,
    roleId,
  ]);

  return {
    moduleUsers: moduleUsersState?.items,
    companyUsers: companyUsersState?.items,
    fetching: state.fetching,
    error: state.error,
    errors: state.errors,
    ...callbacks,
  };
};
