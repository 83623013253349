import {
  NormalizedState,
  createNormalizedState,
  updateNormalizedState,
  deleteByIdFromNormalizedState,
} from '../common/normalized';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { globalStateResetAction } from '@/store/common/actions';
import { UUID } from '@/types';
import { ReactNode } from 'react';

export enum NotificationType {
  Error = 'error',
  Success = 'success',
}
export interface Notification {
  type: NotificationType;
  id: string;
  text: ReactNode;
}
export interface NotificationsState {
  notifications: NormalizedState<Notification> | null;
  dismissalQueue: UUID[] | null;
}
export const initialState: NotificationsState = {
  notifications: null,
  dismissalQueue: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<Notification[]>) {
      if (!state.notifications) {
        state.notifications = createNormalizedState([]);
      }
      updateNormalizedState(state.notifications, payload);
      if (!state.dismissalQueue) {
        return;
      }
      for (const { id } of payload) {
        if (state.dismissalQueue.includes(id)) {
          state.dismissalQueue.splice(state.dismissalQueue.indexOf(id), 1);
        }
      }
    },
    removeNotifications(state, { payload }: PayloadAction<UUID[]>) {
      if (state.notifications) {
        deleteByIdFromNormalizedState(state.notifications, payload);
      }
    },
    setDismissalQueue(state, { payload }: PayloadAction<UUID[] | null>) {
      state.dismissalQueue = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      toast.dismiss();
      return initialState;
    });
  },
});

export const notifyStateName = slice.name;
export const notifyReducer = slice.reducer;
export const notifyActions = slice.actions;
