import { ButtonBase, styled } from '@mui/material';

export const ProfileMenuButtonStyled = styled(ButtonBase)(({ theme: t }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0 0 10px',
  gap: '42px',
  fontSize: '16px',
  fontWeight: 500,
  position: 'relative',
  minWidth: '140px',
  justifyContent: 'end',
  borderRadius: '20px',
  '&:hover,\n  &:active': {
    '.sandwich-menu-open &': { backgroundColor: 'transparent' },
  },
  [t.breakpoints.between('xs', 'md')]: {
    minWidth: 'auto',
    padding: '0',
    borderRadius: '50%',
    margin: 0,
  },
}));
