export const envs = {
  get PASSPORT_BACKEND(): string {
    return (
      window.env?.REACT_APP_PASSPORT_BACKEND ??
      process.env.REACT_APP_PASSPORT_BACKEND ??
      ''
    );
  },
  get PASSPORT_IDENTITY_API_V1(): string {
    return (
      window.env?.REACT_APP_PASSPORT_IDENTITY_API_V1 ??
      process.env.REACT_APP_PASSPORT_IDENTITY_API_V1 ??
      ''
    );
  },
  get GOOGLE_API_KEY(): string {
    return (
      window.env?.GOOGLE_API_KEY ?? process.env.REACT_APP_GOOGLE_API_KEY ?? ''
    );
  },
  get GOOGLE_RECAPTCHA_KEY(): string {
    return (
      window.env?.REACT_APP_GOOGLE_RECAPTCHA_KEY ??
      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ??
      ''
    );
  },
  get GOOGLE_TAG_MANAGER_ID(): string {
    return (
      window.env?.GOOGLE_TAG_MANAGER_ID ??
      process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ??
      ''
    );
  },
  get PATIENT_PORTAL_URL(): string {
    return (
      window.env?.REACT_APP_PATIENT_PORTAL_URL ??
      process.env.REACT_APP_PATIENT_PORTAL_URL ??
      ''
    );
  },
  get HELP_URL(): string {
    return (
      window.env?.REACT_APP_HELP_URL ?? process.env.REACT_APP_HELP_URL ?? ''
    );
  },
  get CONTACT_SUPPORT_URL(): string {
    return (
      window.env?.REACT_APP_CONTACT_SUPPORT_URL ??
      process.env.REACT_APP_CONTACT_SUPPORT_URL ??
      ''
    );
  },
};
