import { forwardRef } from 'react';
import { ReactComponent as IconQuestionMark } from '@/images/icons/icon_question_mark_round_outlined.svg';
import { IconButton } from '@mui/material';

export const QuestionMarkButton = forwardRef<
  HTMLButtonElement,
  { onClick: React.MouseEventHandler }
>((props, ref) => {
  return (
    <IconButton
      ref={ref}
      sx={{
        svg: { marginTop: 1 },
      }}
      {...props}
    >
      <IconQuestionMark />
    </IconButton>
  );
});
QuestionMarkButton.displayName = 'Bt';
