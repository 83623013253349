/** @jsxImportSource @emotion/react */
import { ISODateString } from '@/types';
import { useTranslate } from '@/i18n/useTranslate';
import { css as _ } from '@mui/material';
import { StatusColor, StatusText } from '@/components/status';
import { AlertSign } from '@/components/AlertSign';
import React from 'react';
import { dateIsExpired } from '@/utils/dateIsExpired';
import { formatDate } from '@/components/DateFormat';
import { AccountCompanyStatus } from '@/api/__generated__/webApi';
import { EMPTY } from '@/constants';

export const accountCompanyStatusColorMap: Record<
  AccountCompanyStatus,
  StatusColor
> = {
  [AccountCompanyStatus.ACTIVE]: StatusColor.Success,
  [AccountCompanyStatus.INVITED]: StatusColor.Warning,
  [AccountCompanyStatus.DELETED]: StatusColor.Error,
};
export const accountCompanyStatusLabelMap = {
  [AccountCompanyStatus.ACTIVE]: 'STATUS_ACTIVE',
  [AccountCompanyStatus.INVITED]: 'STATUS_INVITED',
  [AccountCompanyStatus.DELETED]: 'STATUS_DELETED',
} as const;
export const CompanyAccountStatus = ({
  status,
  inviteExpiredTime,
}: {
  status: AccountCompanyStatus | undefined;
  inviteExpiredTime?: ISODateString | undefined;
}) => {
  const { t } = useTranslate('companies');
  if (!status) {
    return <>{EMPTY}</>;
  }
  const expired = dateIsExpired(inviteExpiredTime);

  return (
    <div css={_`display: flex; align-items: center; gap: 10px;`}>
      <StatusText color={accountCompanyStatusColorMap[status]}>
        {t(accountCompanyStatusLabelMap[status])}
      </StatusText>
      {expired && status === AccountCompanyStatus.INVITED && (
        <AlertSign
          title={t('INVITATION_EXPIRED', {
            date: formatDate(inviteExpiredTime),
          })}
        />
      )}
    </div>
  );
};
