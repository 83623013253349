/** @jsxImportSource @emotion/react */
import { H3 } from '@/components/Text';
import { FC, PropsWithChildren, ReactNode } from 'react';
import {
  Box,
  css as _,
  styled,
  SxProps,
  Theme,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { spreadSx } from '@/utils/spreadSx';

const ButtonContainer = styled('div')`
  display: flex;
  gap: 24px;
  justify-content: end;
  flex-direction: row-reverse;
  .mobile-layout & {
    width: 100%;
    justify-content: space-between;
    button {
      width: 100%;
    }
  }
  .tablet-layout & {
    justify-content: space-between;
    flex: 1 1;
  }
`;

export const BoxFooter: FC<
  PropsWithChildren<{
    text?: ReactNode;
    className?: string;
    spaceBetween?: boolean;
    sx?: SxProps<Theme>;
  }>
> = ({ children, text, className, spaceBetween = true, sx }) => {
  return (
    <Box
      mt={{ xs: 42, sm: 36 }}
      className={className}
      sx={[
        {
          display: 'flex',
          gap: 24,
          alignItems: 'center',
          justifyContent: 'end',
        },
        ...spreadSx(sx),
      ]}
    >
      {text && (
        <div
          css={[
            _`flex: 100 1 auto; display: flex; align-items: center; .mobile-layout & {display: none;}`,
          ]}
        >
          {text && <Typography variant={'16_20_500'}>{text}</Typography>}
        </div>
      )}
      <ButtonContainer
        className="bf-buttons"
        css={
          spaceBetween &&
          !text &&
          _`justify-content: space-between; width: 100%`
        }
      >
        {children}
      </ButtonContainer>
    </Box>
  );
};
export const BoxBody = styled(Box)`
  flex: 1 1 auto;
`;

/**
 * @deprecated - use BoxHeader
 */
export const BoxHeaderDeprecated = styled(H3)`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;
BoxHeaderDeprecated.defaultProps = {
  marginBottom: { xs: 36, sm: 64, lg: 36 },
};
export const BoxHeader: FC<
  PropsWithChildren<{
    sx?: SxProps<Theme>;
    mb?: TypographyTypeMap['props']['mb'];
  }>
> = ({ children, sx, mb }) => {
  return (
    <Typography
      mb={mb}
      component={'h2'}
      sx={[
        {
          fontSize: { xs: '20px', sm: '22px', lg: '24px' },
          lineHeight: { xs: '24px', sm: '30px', lg: '30px' },
          fontWeight: { xs: 500 },
          mb: mb ?? { xs: 24, sm: 36 },
        },
        ...spreadSx(sx),
      ]}
    >
      {children}
    </Typography>
  );
};

export const boxPaddings = (t: Theme): SystemStyleObject<Theme> => ({
  padding: { xs: 18, sm: 24, lg: 36 },
});
export const boxXPaddings = {
  xs: 18,
  sm: 24,
  lg: 36,
};

const sx = (t: Theme) => ({
  backgroundColor: t.colors.background,
  padding: { xs: 18, sm: 24, lg: 36 },
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${t.colors.borderLight}`,
  display: 'flex',
  flexDirection: 'column',
  [t.breakpoints.between('xs', 'lg')]: {
    height: 'auto',
  },
});
export const WhiteBox: FC<
  Parameters<typeof Box>[0] & { noXPaddings?: boolean }
> = ({ noXPaddings, ...props }) => {
  return (
    <Box
      {...props}
      sx={[
        sx,
        noXPaddings
          ? { paddingLeft: '0!important', paddingRight: '0!important' }
          : {},
        ...spreadSx(props.sx),
      ]}
    />
  );
};
