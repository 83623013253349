/** @jsxImportSource @emotion/react */
import { AppRoutes, generatePath } from '@/routes/appRoutes';
import { FormikConfig } from 'formik';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { companiesActions } from '@/store/companies/slice';
import { useNewRoleForm } from '@/store/companies/hooks';
import { RoleFormView } from '@/features/module/ModulePage/fragments/RoleFormView';

export interface RoleFormType {
  name: string;
  description: string;
}

interface RoleFormProps {
  back: () => void;
  className?: string;
}
export const CreateNewRoleForm: FC<RoleFormProps> = ({ back, className }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    resetRoleErrors,
    roleCreationErrors,
    newRoleData: role,
  } = useNewRoleForm();
  const handleSubmit: FormikConfig<RoleFormType>['onSubmit'] = useCallback(
    (v) => {
      if (params.moduleId) {
        dispatch(
          companiesActions.setRoles({
            moduleId: params.moduleId,
            roles: [{ ...v, id: 'new', editable: true }],
          })
        );
        navigate({
          pathname: generatePath(AppRoutes.COMPANY_MODULES_PERMISSIONS_EDIT, {
            companyId: params.companyId,
            module: params.module,
            moduleId: params.moduleId,
            roleId: 'new',
          }),
          search: window.location.search,
        });
      } else {
        console.error('[handleSubmit] moduleId not found');
      }
    },
    [dispatch, navigate, params.companyId, params.module, params.moduleId]
  );
  return (
    <>
      <RoleFormView
        onSubmit={handleSubmit}
        resetErrors={resetRoleErrors}
        cancel={back}
        values={role as RoleFormType}
        errors={roleCreationErrors}
        className={className}
      />
    </>
  );
};
