/** @jsxImportSource @emotion/react */
import React, { FC, PropsWithChildren } from 'react';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { spreadSx } from '@/utils/spreadSx';

const styles = {
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 36,
    mb: {
      sm: 65,
      xs: 36,
    },
  },
  header: {
    textAlign: 'end',
    flex: '1 1 auto',
    typography: { xs: '24_34_700', sm: '32_36_700' },
  },
};

export const AuthHeader: FC<
  PropsWithChildren<{ title: string; className?: string; sx?: SxProps<Theme> }>
> = ({ sx, title, children, className }) => {
  return (
    <Stack
      sx={[styles.container, ...spreadSx(sx)]}
      className={className}
      dir={'rtl'}
    >
      {children}
      <Typography sx={styles.header} component={'h2'}>
        <span dir={'ltr'}>{title}</span>
      </Typography>
    </Stack>
  );
};
