/** @jsxImportSource @emotion/react */
import { To } from 'react-router-dom';
import { memo } from 'react';
import {
  BORDER_WIDTH,
  IconView,
  liClassname,
  MenuListItem,
  MenuButton,
  selectedClassName,
  GAP_WIDTH,
} from './styles';
import cn from 'classnames';
import { Box, css as _, IconButton, Theme, Typography } from '@mui/material';
import { IconContainer } from '@/components/IconContainer';
import { ReactComponent as IconSVGArrowDown } from '@/images/icons/icon_arrow_down_thin.svg';
import { Nowrap } from '@/components';

const styles = {
  menuButtonLabelContainer: {
    minWidth: 100,
    display: 'flex',
    gap: GAP_WIDTH,
    alignItems: 'center',
  },
  menuButtonText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    minWidth: 100,
  },
  mbTextSubContainer: { display: 'flex', alignItems: 'center', gap: 20 },
  arrowButtonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: '100%',
  },
  icon: {
    path: {
      fill: (t: Theme) => t.palette.grey[800],
      '.active &, a:hover &': {
        fill: (t: Theme) => t.palette.secondary.main,
      },
    },
  },
  menuButton: (t: Theme) => ({
    justifyContent: 'space-between',
    backgroundColor: 'grey.100',
    [t.breakpoints.down('desktop')]: { borderTopWidth: BORDER_WIDTH },
  }),
};

interface MenuItemProps {
  title: string;
  subtitle?: string;
  selected?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
  to?: To;
  exact?: boolean;
  disabled?: boolean;
  header?: boolean;
  onCollapse?: (collapse?: boolean) => void;
  expanded?: boolean;
}

export const MenuItem = memo(
  ({
    title,
    selected,
    subtitle,
    icon,
    onClick,
    to,
    exact,
    disabled,
    onCollapse,
    expanded,
  }: MenuItemProps) => {
    return (
      <MenuListItem
        to={to ?? ''}
        header={true}
        disabled={disabled}
        end={exact}
        tabIndex={0}
        role={'button'}
        onClick={
          disabled
            ? () => {}
            : () => {
                onClick?.();
                onCollapse?.(false);
              }
        }
        className={cn(
          { [selectedClassName]: selected },
          liClassname,
          'menu-item'
        )}
      >
        <MenuButton header={true} disabled={disabled} sx={styles.menuButton}>
          <Box sx={styles.menuButtonLabelContainer}>
            {icon && <IconView>{icon}</IconView>}
            <Box sx={{ minWidth: 100 }}>
              <Box sx={styles.mbTextSubContainer}>
                <Typography component={'h2'} sx={styles.menuButtonText}>
                  <Nowrap>{title}</Nowrap>
                </Typography>
              </Box>
              {subtitle && <p>{subtitle}</p>}
            </Box>
          </Box>
          {Boolean(onCollapse) && (
            <Box sx={styles.arrowButtonContainer}>
              <IconButton
                component={'span'}
                onClick={() => onCollapse?.()}
                css={expanded && _`transform: rotate(180deg)`}
              >
                <IconContainer>
                  <Box component={IconSVGArrowDown} sx={styles.icon} />
                </IconContainer>
              </IconButton>
            </Box>
          )}
        </MenuButton>
      </MenuListItem>
    );
  }
);
MenuItem.displayName = 'MenuItem';
