/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Box, css as _, IconButton, Stack, styled } from '@mui/material';
import { ReactComponent as IconSVGArrowLeft } from '@/images/icons/icon_arrow_left.svg';
import { IconContainer } from '@/components/IconContainer';
import { SelectorType } from '../Calendar';
import { DateTime } from 'luxon';
import { Button } from '@/components/Button';
import { useDatePickerContext } from '@/components/DateControl/DateInput/useDatePickerContext';
const buttonSxStyles = {
  paddingX: 9,
  minWidth: 'initial',
  width: 'auto',
  '&:disabled': { color: 'white!important' },
};
export type HeaderActionType = 'back' | 'forward' | 'month' | 'year' | 'day';
interface YMSelectorProps {
  date: DateTime;
  className?: string;
  onClick?: (e: { type: HeaderActionType }) => void;
  type?: SelectorType;
  yearsRange: [number, number];
}

export const Header: FC<YMSelectorProps> = ({
  type,
  date,
  className,
  onClick,
  yearsRange,
}) => {
  const [minYear, maxYear] = yearsRange;
  const { disablePastDates, disableFutureDates } = useDatePickerContext();
  const isDisabledBack = () => {
    if (type === 'month' || type === 'year') {
      return date.year <= minYear;
    } else if (type === 'day') {
      if (disablePastDates) {
        return (
          date.minus({ month: 1 }).endOf('month') <
          DateTime.now().startOf('month')
        );
      } else {
        return (
          date.minus({ month: 1 }).endOf('month') <
          DateTime.now().set({ year: minYear, month: 1 }).startOf('month')
        );
      }
    }
  };
  const isDisabledForward = () => {
    if (type === 'month' || type === 'year') {
      return date.year >= maxYear;
    } else if (type === 'day') {
      if (disableFutureDates) {
        return (
          date.plus({ month: 1 }).startOf('month') >
          DateTime.now().endOf('month')
        );
      } else {
        return (
          date.plus({ month: 1 }).startOf('month') <
          DateTime.now().set({ year: minYear, month: 1 }).endOf('month')
        );
      }
    }
  };
  return (
    <SelectorContainer className={className}>
      <Stack direction={'row'} alignItems={'center'} minWidth={120}>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={buttonSxStyles}
          disabled={type === 'month'}
          onClick={() => {
            onClick?.({ type: 'month' });
          }}
        >
          {date.monthLong}
        </Button>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={buttonSxStyles}
          onClick={() => {
            onClick?.({ type: 'day' });
          }}
          disabled={type === 'day'}
        >
          {date.day}
        </Button>
      </Stack>
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={buttonSxStyles}
          onClick={() => {
            onClick?.({ type: 'year' });
          }}
          disabled={type === 'year'}
        >
          {date.year}
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        sx={{ 'svg path': { stroke: (t) => t.palette.secondary.main } }}
      >
        <NavigationButton
          disabled={isDisabledBack()}
          onClick={() => {
            onClick?.({ type: 'back' });
          }}
        >
          <IconContainer>
            <Box
              component={IconSVGArrowLeft}
              sx={{ path: { stroke: 'white' } }}
            />
          </IconContainer>
        </NavigationButton>
        <NavigationButton
          disabled={isDisabledForward()}
          onClick={() => {
            onClick?.({ type: 'forward' });
          }}
        >
          <IconContainer>
            <Box
              component={IconSVGArrowLeft}
              sx={{ transform: 'rotate(180deg)', path: { stroke: 'white' } }}
            />
          </IconContainer>
        </NavigationButton>
      </Stack>
    </SelectorContainer>
  );
};

const NavigationButton = styled(IconButton)(
  ({ theme: t }) => _`
  &:hover path {
    stroke: ${t.palette.secondary.light};
  };
  &:active path {
    stroke: ${t.palette.secondary.dark};
  }
  &:disabled path {
    stroke: ${t.palette.secondary.dark}
  }
`
);

const SelectorContainer = styled('div')(
  ({ theme: t }) => _`
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 52px;
  background-color: ${t.palette.grey[800]};
`
);
