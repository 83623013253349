import { Link } from '@/components';
import { EMPTY } from '@/constants';

export const getLatLongLink = (
  address:
    | {
        googlePlaceId?: string;
        latitude?: number;
        longitude?: number;
      }
    | undefined
) => {
  return (
    <>
      {address?.latitude && address?.longitude ? (
        <Link
          target={'_blank'}
          to={`https://www.google.com/maps/search/?api=1&query=${
            address.latitude
          }%2C${address.longitude}&query_place_id=${
            address.googlePlaceId ?? ''
          }`}
        >{`${address.latitude}/${address.longitude}`}</Link>
      ) : (
        EMPTY
      )}
    </>
  );
};
