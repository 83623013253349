import {
  Theme,
  styled,
  css as _,
  Interpolation,
  InputBase,
} from '@mui/material';
import { PropsWithChildren } from 'react';

export const styles = {
  container: () => _`
    width: 100%;
    position: relative;
    cursor: pointer;
    `,

  iconContainer: _`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    pointer-events: none;
    `,
};

const inputStyles: Interpolation<{ theme?: Theme; error?: boolean }> = ({
  theme,
  error,
}) => _`
  height: 100%;
  width: 100%;
  input {
    height: 100%;
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: inherit;
    box-sizing: border-box;
    cursor: pointer;
    &::placeholder {
      color: ${theme?.palette.grey[500] ?? ''}
    }
    outline: none;
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
    }
  }
`;

export const StyledInput = styled(InputBase)(inputStyles);
StyledInput.defaultProps = {
  type: 'text',
};

export const CalendarContainer = styled('div')<{ twoColumns?: boolean }>(
  ({ theme, twoColumns }) => _`
    --width: 292px;
    color: white;
    background-color: ${theme.colors.all.grey2};
    width: 100%;
    max-width: ${twoColumns ? 'calc(var(--width) * 2)' : 'var(--width)'};
    min-height: 336px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
`
);
interface CircleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  currentDay: boolean;
  selected: boolean;
}
export const Circle = styled(
  ({
    children,
    currentDay: _,
    ...restProps
  }: PropsWithChildren<CircleProps>) => {
    return <button {...restProps}>{children}</button>;
  }
)<{ currentDay: boolean; selected: boolean }>(
  ({ theme, currentDay, selected, disabled }) => _`
  position: absolute;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${
    selected
      ? theme.palette.secondary.main
      : currentDay
      ? theme.palette.grey[600]
      : 'transparent'
  };
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  border: none;
  cursor: pointer;
  color: ${
    disabled
      ? selected || currentDay
        ? 'white'
        : theme.colors.all.grey4
      : 'white'
  };
  @media (hover: hover) {
    &:hover {
      color: ${!disabled && !selected && !currentDay && theme.palette.grey[400]}
    }
    &:active {
      color: white;
    }
  }
  &:disabled {
    &:hover {
      cursor: inherit
    }
    &:active {
      background-color: transparent;
      transition: none;
    }
  }
  &:active {
    background-color: ${theme.palette.secondary.main};
    transition: none;
  }
`
);
Circle.defaultProps = {
  type: 'button',
};
export const DatesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
export const Cell = styled('div')`
  border: none;
  background-color: transparent;
  position: relative;
  width: calc(100% / 7);
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: ' ';
    margin-top: 100%;
  }
`;
