import { useCallback, useEffect, useRef, useState } from 'react';

export const useSearch = (cb: (val: string, name?: string) => void) => {
  const cbRef = useRef(cb);
  const initRef = useRef(false);
  cbRef.current = cb;
  const [input, setValue] = useState({ value: '', name: '' });
  const handleChange: React.ChangeEventHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      initRef.current = true;
      setValue({ name: e.target.name, value: e.target.value });
    },
    []
  );

  useEffect(() => {
    if (!initRef.current) {
      return;
    }
    const tmt = window.setTimeout(() => {
      cbRef.current?.(input.value, input.name);
    }, 600);
    return () => {
      window.clearTimeout(tmt);
    };
  }, [input]);

  return {
    input,
    handleChange,
  };
};
