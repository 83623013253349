import { FC } from 'react';

export const SecureTextDisplay: FC<{
  children: string;
  first: number;
  last: number;
}> = ({ children, first, last }) => {
  const start = children.substring(0, first);
  const end = children.substring(children.length - last, children.length);
  if ((start + end).length > children.length) {
    return <>{children}</>;
  }
  const stars = [...Array(children.length - (start + end).length)]
    .fill('*')
    .join('');
  return <>{`${start}${stars}${end}`}</>;
};
