export interface ValidationError<T = string> {
  field: T;
  error: ValidationErrorType;
}

export enum ValidationErrorType {
  ACCOUNT_NPI_EXISTS = 'ACCOUNT_NPI_EXISTS',
  ACCOUNT_INVITE_EXISTS = 'ACCOUNT_INVITE_EXISTS',
  ACCOUNT_PHONE_EXISTS = 'ACCOUNT_PHONE_EXISTS',
  COMPANY_NPI_EXISTS = 'COMPANY_NPI_EXISTS',
  NPI_VALIDATION_ERROR = 'NPI_VALIDATION_ERROR',
  PHONE_VALIDATION_ERROR = 'PHONE_VALIDATION_ERROR',
  ROLE_COMPANY_MODULE_EXISTS = 'ROLE_COMPANY_MODULE_EXISTS',
  BIRTHDATE_VALIDATION_ERROR = 'BIRTHDATE_VALIDATION_ERROR',
  NPI_VERIFICATION_EXISTS = 'NPI_VERIFICATION_EXISTS',
  CODE_VALIDATION_ERROR = 'CODE_VALIDATION_ERROR',
  RECAPTCHA_VALIDATION_ERROR = 'RECAPTCHA_VALIDATION_ERROR',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  OWNER_ROLE_NOT_FOUND = 'OWNER_ROLE_NOT_FOUND',
  DEFAULT_ROLE_NOT_FOUND = 'DEFAULT_ROLE_NOT_FOUND',
  MODULE_NOT_FOUND = 'MODULE_NOT_FOUND',
  COMPANY_MODULE_NOT_FOUND = 'COMPANY_MODULE_NOT_FOUND',
  PERMISSIONS_NOT_FOUND = 'PERMISSIONS_NOT_FOUND',
  CURRENT_PASSWORD_INCORRECT = 'CURRENT_PASSWORD_INCORRECT',
  NEW_PASSWORD_EQUALS_CURRENT_PASSWORD = 'NEW_PASSWORD_EQUALS_CURRENT_PASSWORD',
  MODIFICATION_FORBIDDEN = 'MODIFICATION_FORBIDDEN',
  PHONE_NOT_FOUND = 'PHONE_NOT_FOUND',
  MOBILE_PHONE_REQUIRED = 'MOBILE_PHONE_REQUIRED',
  REMOVE_MAIN_PHONE = 'REMOVE_MAIN_PHONE',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_CONFIRMATION_TOKEN_ERROR = 'EMAIL_CONFIRMATION_TOKEN_ERROR',
  REMOVE_MAIN_EMAIL = 'REMOVE_MAIN_EMAIL',
  NPI_TYPE_INCORRECT = 'NPI_TYPE_INCORRECT',
  NPI_TYPE_INCORRECT_INDIVIDUAL = 'NPI_TYPE_INCORRECT_INDIVIDUAL',
  NPI_TYPE_INCORRECT_ORG = 'NPI_TYPE_INCORRECT_ORG',
  NPI_NOT_FOUND = 'NPI_NOT_FOUND',
  NPI_NOT_ACTIVE = 'NPI_NOT_ACTIVE',
  PARENT_NPI_REQUIRED = 'PARENT_NPI_REQUIRED',
  TOO_MANY_NPI_REGISTRATIONS = 'TOO_MANY_NPI_REGISTRATIONS',
  NOT_NULL = 'NOT_NULL',
  NOT_BLANK = 'NOT_BLANK',
  NOT_EMPTY = 'NOT_EMPTY',
  EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR',
  INVALID_FORMAT_ERROR = 'INVALID_FORMAT_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NPI_REGISTRY_ERROR = 'NPI_REGISTRY_ERROR',
  NOT_POSITIVE = 'NOT_POSITIVE',
  NOT_DIGIT_10_0 = 'NOT_DIGIT_10_0',
  MIN_10_MAX_10 = 'MIN_10_MAX_10',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  COMPANY_MODULE_ALREADY_EXISTS = 'COMPANY_MODULE_ALREADY_EXISTS',
  INSUFFICIENT_ACCESS_RIGHTS = 'INSUFFICIENT_ACCESS_RIGHTS',
  ACCOUNT_COMPANY_MODULE_ROLE_EXISTS = 'ACCOUNT_COMPANY_MODULE_ROLE_EXISTS',
  ACCOUNT_EMAIL_EXISTS = 'ACCOUNT_EMAIL_EXISTS',
  ACCOUNT_COMPANY_EXISTS = 'ACCOUNT_COMPANY_EXISTS',
  INVALID_DOMAIN = 'INVALID_DOMAIN',
  ACCOUNT_COMPANY_INVITE_EXISTS = 'ACCOUNT_COMPANY_INVITE_EXISTS',
  ZIP_CODE_NUMBER_VALIDATION_ERROR = 'ZIP_CODE_NUMBER_VALIDATION_ERROR',
  NOT_URL = 'NOT_URL',
  OPENID_ENDPOINT_UNAVAILABLE = 'OPENID_ENDPOINT_UNAVAILABLE',
  OPENID_ENDPOINT_READ_ERROR = 'OPENID_ENDPOINT_READ_ERROR',
  OPENID_ENDPOINT_METADATA_INVALID = 'OPENID_ENDPOINT_METADATA_INVALID',
  OPENID_ENDPOINT_ERROR = 'OPENID_ENDPOINT_ERROR',
  RESEND_CODE_TIMEOUT_EXCEEDED = 'RESEND_CODE_TIMEOUT_EXCEEDED',
  ACCOUNT_INVITE_AUTHORIZED = 'ACCOUNT_INVITE_AUTHORIZED',
  ACCOUNT_INVITE_NOT_FOUND = 'ACCOUNT_INVITE_NOT_FOUND',
  PHONE_EXISTS = 'PHONE_EXISTS',
  ACCOUNT_COMPANY_NOT_FOUND = 'ACCOUNT_COMPANY_NOT_FOUND',
  DOMAIN_EMAIL_NOT_EXISTS = 'DOMAIN_EMAIL_NOT_EXISTS',
  OPENID_SETTINGS_NOT_FOUND = 'OPENID_SETTINGS_NOT_FOUND',
  OPENID_SETTINGS_DISABLED = 'OPENID_SETTINGS_DISABLED',
  CONTACT_PHONE_NOT_FOUND = 'CONTACT_PHONE_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  COMPANY_DOMAIN_EXISTS = 'COMPANY_DOMAIN_EXISTS',
  COMPANY_DOMAIN_VALIDATION_ERROR = 'COMPANY_DOMAIN_VALIDATION_ERROR',
  MAX_SIZE_80 = 'MAX_SIZE_80',
  MAX_SIZE_100 = 'MAX_SIZE_100',
  MAX_SIZE_255 = 'MAX_SIZE_255',
  MAX_SIZE_256 = 'MAX_SIZE_256',
  MAX_SIZE_2048 = 'MAX_SIZE_2048',
  MAX_UPLOAD_SIZE_EXCEEDED = 'MAX_UPLOAD_SIZE_EXCEEDED',
  CONTACT_VALIDATION_ERROR = 'CONTACT_VALIDATION_ERROR',
  CONTACT_HAS_SIMILAR = 'CONTACT_HAS_SIMILAR',
  MAX_SIZE = 'MAX_SIZE',
  Error_500 = 'UNKNOWN_SERVER_ERROR_500',
  AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE = 'AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE',
  NAME_IS_TOO_SHORT = 'NAME_IS_TOO_SHORT',
}
