import { call, fork, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { RootState } from './store';
import { authSaga } from './auth/sagas';
import { querySaga } from './query/sagas';
import { phonesSaga } from '@/store/phones/sagas';
import { npiSaga } from './npi-individual/sagas';
import { companyNpiSaga } from '@/store/npi-org/sagas';
import { companiesSaga } from './companies/saga/saga';
import { usersSaga } from './users/saga';
import { emailsSaga } from './emails/sagas';
import { errorNotificationsSaga } from './notifications/sagas';
import { profileSaga } from './profile/sagas';
import { permissionSagas } from './permissions/saga';
import { insuranceSaga } from '@/store/insurance/saga';
import { settingsSaga } from '@/store/companies-settings/saga';

export function* rootSaga() {
  yield fork(authSaga);
  yield fork(querySaga);
  yield fork(phonesSaga);
  yield fork(npiSaga);
  yield fork(companyNpiSaga);
  yield fork(companiesSaga);
  yield fork(settingsSaga);
  yield fork(usersSaga);
  yield fork(emailsSaga);
  yield fork(errorNotificationsSaga);
  yield fork(profileSaga);
  yield fork(permissionSagas);
  yield fork(insuranceSaga);
}

/**
 *
 * @param saga
 * @param options
 */
export function tryCatchSaga<A, K extends keyof RootState>(
  saga: (a: A, s?: RootState[K]) => void,
  options?: {
    withAuthentication?: boolean;
    withProgress?: boolean;
    withStore?: K;
    updateProgressAction?: (payload: boolean) => AnyAction;
    // eslint-disable-next-line
    handleError?: (error: any | null, a: A) => AnyAction[];
    cleanState?: () => AnyAction;
  }
) {
  return function* (a: A) {
    try {
      if (options?.withProgress && options?.updateProgressAction) {
        yield put(options.updateProgressAction(true));
      }
      yield call(saga, a);
    } catch (error) {
      if (options?.handleError) {
        const errorActions = options.handleError(error, a);
        for (const errorAction of errorActions) {
          yield put(errorAction);
        }
      }
    } finally {
      if (options?.withProgress && options?.updateProgressAction) {
        yield put(options.updateProgressAction(false));
      }

      if (options?.cleanState) {
        yield put(options.cleanState());
      }
    }
  };
}
