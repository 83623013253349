/** @jsxImportSource @emotion/react */
import { FC, PropsWithChildren, useMemo } from 'react';
import { DateTime, Info, Settings } from 'luxon';
import styled from '@emotion/styled';
import { css as _, Stack } from '@mui/material';
import { useTranslate } from '@/i18n/useTranslate';
import {
  Cell,
  Circle,
  DatesContainer,
} from '@/components/DateControl/DateInput/styles';
import { DateRange } from '@/components/DateControl/DateInput/CalendarPicker';
import { useDatePickerContext } from '@/components/DateControl/DateInput/useDatePickerContext';

const startOfWeek = (dateTime: DateTime) => {
  if (/-us/i.test(Settings.defaultLocale)) {
    if (dateTime.weekday === 7) {
      return dateTime.plus({ day: 1 }).startOf('week').minus({ day: 1 });
    }
    return dateTime.startOf('week').minus({ day: 1 });
  }
  return dateTime.startOf('week');
};
const endOfWeek = (dateTime: DateTime) => {
  if (/-us/i.test(Settings.defaultLocale)) {
    if (dateTime.weekday === 7) {
      return dateTime.plus({ day: 1 }).endOf('week').minus({ day: 1 });
    }
    return dateTime.endOf('week').minus({ day: 1 });
  }
  return dateTime.endOf('week');
};
const weekdays = () => {
  const weekdays = Info.weekdays('short');
  if (/-us/i.test(Settings.defaultLocale)) {
    const last = weekdays.pop() as string;
    weekdays.unshift(last);
  }
  return weekdays;
};

export const DatesArea: FC<{
  onChange: (d: DateTime) => void;
  month: DateTime;
  selected?: DateTime;
  range?: DateRange;
}> = ({ month, selected, onChange, range }) => {
  const { disablePastDates, disableFutureDates } = useDatePickerContext();
  const weekDays = weekdays();
  const now = DateTime.now();
  const t = useTranslate('common');
  const monthDates: DateTime[] = useMemo(() => {
    const startDay = startOfWeek(month.startOf('month'));
    let endDay = endOfWeek(month.endOf('month'));
    const diff = endDay.diff(startDay, 'days');
    // calendar should always have no more than 6 rows - it is enough for all cases
    // but - there are cases when all dates can be shown within 5 rows, so, in order to make view of different months look same
    // it is required to add 6th row for "5 rowed" months
    if (Math.round(diff.days / 7) < 6) {
      endDay = endOfWeek(endDay.plus({ day: 1 }));
    }
    let cursor = startDay;
    const dates = [];
    while (cursor < endDay) {
      dates.push(cursor);
      cursor = cursor.plus({ day: 1 });
    }
    return dates;
    // month dates need to be updated every lng switch because it relies on week start / end - and it can be different dates depending on locale
    // ex: en-US - week starts from Sun. Also, it is different for some arabic countries (not handled right now)
    /* eslint-disable-next-line */
  }, [month, t]);
  const startOfRange = (d: DateTime) => {
    return range?.[0] ? d.hasSame(range[0], 'day') : false;
  };
  const endOfRange = (d: DateTime) => {
    return range?.[1] ? d.hasSame(range[1], 'day') : false;
  };

  const withinRange = (d: DateTime) => {
    if (!range?.[0] || !range?.[1] || range?.[0]?.hasSame(range?.[1], 'day')) {
      return false;
    }
    return (
      range?.[0] &&
      range?.[1] &&
      range[0]?.startOf('day') <= d &&
      d <= range[1]?.endOf('day')
    );
  };

  const disablePast = (date: DateTime) =>
    disablePastDates ? date < now.startOf('day') : false;
  const disableFuture = (date: DateTime) =>
    disableFutureDates ? date > now.endOf('day') : false;
  return (
    <Stack width={'100%'} height={'100%'}>
      <WeekDaysRow>
        {weekDays.map((day, i) => {
          return <Cell key={i}>{day}</Cell>;
        })}
      </WeekDaysRow>
      <DatesContainer>
        {monthDates.map((date, i) => {
          const rangeEnd = endOfRange(date);
          const rangeStart = startOfRange(date);
          return (
            <Cell key={i}>
              {withinRange(date) && (
                <RangeMark end={rangeEnd} start={rangeStart} />
              )}
              <Circle
                onClick={() => onChange(date)}
                disabled={
                  !date.hasSame(month, 'month') ||
                  disablePast(date) ||
                  disableFuture(date)
                }
                currentDay={date.hasSame(now, 'day')}
                selected={
                  (selected && date.hasSame(selected, 'day')) ||
                  rangeEnd ||
                  rangeStart
                }
              >
                {date.day}
              </Circle>
            </Cell>
          );
        })}
      </DatesContainer>
    </Stack>
  );
};
const WeekDaysRow = styled.div`
  display: flex;
  div {
    width: calc(100% / 7);
    min-width: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
`;
const RangeMark = styled(
  ({
    end: _,
    start: __,
    ...restProps
  }: PropsWithChildren<{ end: boolean; start: boolean }>) => (
    <div {...restProps} />
  )
)<{ end: boolean; start: boolean }>(
  ({ end, start, theme }) => _`
  position: absolute;
  width: 100%;
  height: 34px;
  background-color: ${theme.palette.secondary.dark};
  border-radius: ${end ? '0 50% 50% 0' : start ? '50% 0 0 50%' : 'none'};
`
);
