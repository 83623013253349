import { DateTime } from 'luxon';

export class Validator {
  disableFuture = false;
  disablePast = false;
  fn = (value: string) => {
    const [month, day, year] = value.split('/');
    const monthParsed = Number(month);
    const dayParsed = Number(day);
    const yearParsed = Number(year);
    let mm = month;
    let dd = day;
    let yyyy = year;
    let yearIsValid: boolean | undefined;
    let monthIsValid: boolean | undefined;
    let dayIsValid: boolean | undefined;
    if (!isNaN(monthParsed)) {
      if (monthParsed > 12) {
        mm = 'mm';
        monthIsValid = false;
      } else {
        monthIsValid = true;
      }
    }
    if (!isNaN(dayParsed)) {
      if (dayParsed > 31) {
        dd = 'dd';
        dayIsValid = false;
      } else {
        dayIsValid = true;
      }
    }
    const now = DateTime.now();

    if (!isNaN(yearParsed)) {
      if (
        (this?.disableFuture && yearParsed > now.year) ||
        (this?.disablePast && yearParsed < now.year)
      ) {
        yyyy = 'yyyy';
        yearIsValid = false;
      } else {
        yearIsValid = true;
      }
    }

    if (yearIsValid && dayIsValid && monthIsValid) {
      const date = DateTime.fromObject({
        month: monthParsed,
        year: yearParsed,
        day: dayParsed,
      });
      if (
        date.isValid &&
        ((date > now && this.disableFuture) || (date < now && this.disablePast))
      ) {
        mm = 'mm';
        dd = 'dd';
        yyyy = 'yyyy';
        dayIsValid = false;
        monthIsValid = false;
      }
    }

    return {
      value: `${mm}/${dd}/${yyyy}`,
      year: yearIsValid,
      month: monthIsValid,
      day: dayIsValid,
    };
  };
}
