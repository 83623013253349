export const styles = {
  nothing: {
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
    mb: {
      xs: 36,
      lg: 48,
    },
  },
};
