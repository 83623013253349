import { Form, Formik, FormikConfig, FormikValues, FormikProps } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { PartialRecord } from '@/types';
import { useConnectServerValidationErrors } from '@/hooks/useConnectServerValidationErrors';
import { ValidationErrorType } from '@/types/ValidationError';
interface ServerErrors<T> {
  serverErrors: PartialRecord<keyof T, ValidationErrorType>;
  resetServerErrors: () => void;
}
type Props<T> = FormikConfig<T> &
  ServerErrors<T> & {
    id?: string;
    values?: T | null;
    innerRef?: (p: FormikProps<T> | null) => void;
    children: FormikConfig<T>['children'];
    className?: string;
    noForm?: boolean;
  };
export const FormikRedux = <T extends FormikValues>({
  children,
  serverErrors,
  resetServerErrors,
  id,
  className,
  innerRef: innerORef,
  noForm,
  onSubmit,
  ...restProps
}: Props<T>) => {
  const connectLink = useConnectServerValidationErrors<T>(
    serverErrors,
    resetServerErrors
  );
  const { innerRef } = connectLink;
  useEffect(() => {
    innerRef.current?.resetForm(restProps.initialValues);
    if (restProps.values) {
      void innerRef.current?.setValues(restProps.values, false);
      void innerRef.current?.setTouched(restProps.values, false);
    }
  }, [innerRef, restProps.initialValues, restProps.values]);

  const cbRef = useRef(onSubmit);
  cbRef.current = onSubmit;
  const onSubmitInternal: FormikConfig<T>['onSubmit'] = useCallback(
    (...args) => {
      innerRef.current?.setSubmitting(true);
      return cbRef.current(...args);
    },
    [innerRef]
  );

  return (
    <Formik<T>
      onSubmit={onSubmitInternal}
      {...connectLink}
      {...restProps}
      innerRef={(r) => {
        innerORef?.(r);
        innerRef.current = r;
      }}
    >
      {children instanceof Function ? (
        (f) => {
          return (
            <>
              {noForm ? (
                children(f)
              ) : (
                <Form id={id} className={className}>
                  {children(f)}
                </Form>
              )}
            </>
          );
        }
      ) : (
        <Form id={id} className={className}>
          {children}
        </Form>
      )}
    </Formik>
  );
};
