import {
  PhoneInputControl as KitPhoneInputControl,
  PhoneInputControlProps,
} from 'ui-kit';
import { useCurrentLocationInfo } from '@/components/Google/shared/useCurrentLocationInfo';
import { FC, memo } from 'react';
import { Country } from 'react-phone-number-input';

export const PhoneInputControl: FC<PhoneInputControlProps> = memo((props) => {
  const location = useCurrentLocationInfo();
  return (
    <KitPhoneInputControl
      {...props}
      defaultCountry={location?.country?.short_name as Country}
      key={location?.country?.short_name}
    />
  );
});
PhoneInputControl.displayName = 'PhoneInputControl';
