/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC } from 'react';
import {
  useNPIFormIndividual,
  useNPIStateIndividual,
} from '@/store/npi-individual/hooks';
import { useNPIStateOrg } from '@/store/npi-org/hooks';
import { NPIFormView } from '../shared/NPIFormView';
export const NPIFormContainer: FC<{
  narrowView: boolean;
  disabled?: boolean;
}> = ({ narrowView }) => {
  const { t } = useTranslate('profile');
  const { handleChange, handleSubmit, error, fetching, dirty } =
    useNPIFormIndividual();
  const { step: stepInd } = useNPIStateIndividual();
  const { step } = useNPIStateOrg();
  const indNpiProcessIsActive = stepInd === 'registration';
  const orgNpiProcessIsActive = step !== 'init';

  return (
    <NPIFormView
      narrowView={narrowView}
      value={''}
      disabled={indNpiProcessIsActive || orgNpiProcessIsActive}
      dirty={dirty}
      error={error}
      fetching={fetching}
      header={t('HEALTHCARE_PROVIDER_REGISTRATION')}
      description={t('YOU_CAN_REGISTER_AS_HEALTHCARE_PROVIDER_IF_')}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
    />
  );
};
