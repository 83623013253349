import { Interpolation } from '@emotion/react';
import { Theme } from '@mui/material';
import { alpha } from '@/theme/utils';

export const globalStyles: Interpolation<Theme> = (t: Theme) => {
  return {
    body: {
      fontFamily: 'Raleway, RalewayLocal, sans-serif',
      padding: 0,
      margin: 0,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    [`${t.breakpoints.up('lg')}`]: {
      '::-webkit-scrollbar': {
        width: 10,
        height: 10,
        backgroundColor: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(t.palette.secondary.main, 14),
      },
    },
    '.grecaptcha-badge': {
      visibility: 'hidden',
    },
  } as unknown as Interpolation<Theme>;
};
