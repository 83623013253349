import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { calcInsert } from './utils/calcInsert';

const validDate = (value: string | undefined, mask: string) => {
  if (!value || value.length !== mask.length) {
    return false;
  }
  if (/[ymd]/i.test(value)) {
    return false;
  }
  return true;
};
export const useDateInputMask = ({
  mask: initialMask,
  value: externalValue = initialMask,
  validate,
  onChange,
}: {
  value?: string;
  mask: string;
  onChange?: (value: string) => void;
  validate?: (str: string) => {
    value: string;
    year: boolean | undefined;
    month: boolean | undefined;
    day: boolean | undefined;
  };
}) => {
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  );
  const [value, setValue] = useState(externalValue);
  const selection = useRef<
    Array<{
      selectionStart: number | null | undefined;
      selectionEnd: number | null | undefined;
    }>
  >([]);
  useEffect(() => {
    if (externalValue !== value) {
      setValue(externalValue);
    }
    // eslint-disable-next-line
  }, [externalValue]);
  const externalRefs = useRef({ validate, onChange });
  externalRefs.current.validate = validate;
  externalRefs.current.onChange = onChange;

  const handleSetSelection = useCallback(() => {
    selection.current.push({
      selectionStart: inputElement?.selectionStart,
      selectionEnd: inputElement?.selectionEnd,
    });
    selection.current = selection.current.slice(-3);
  }, [inputElement?.selectionEnd, inputElement?.selectionStart]);

  const onChangeCapture = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const params = {
        maskedValueAfter: e.target.value,
        maskedValueBefore: value,
        initialMask,
        cursorPositionAfter: e.target?.selectionStart,
        selectionStartBefore: selection.current.at(-1)?.selectionStart ?? 0,
        selectionEndBefore: selection.current?.at(-1)?.selectionEnd ?? 0,
      };
      const cursorPositionAndValue = calcInsert(params);

      if (!cursorPositionAndValue) {
        return;
      }
      let newValue = cursorPositionAndValue.maskedValue;
      let newPosition = cursorPositionAndValue.cursorPosition;
      if (externalRefs.current.validate) {
        const { value, year, day, month } = externalRefs.current.validate(
          cursorPositionAndValue.maskedValue
        );
        newValue = value;

        if (
          typeof day !== 'undefined' &&
          typeof month !== 'undefined' &&
          !day &&
          !month
        ) {
          const iD = initialMask.indexOf('d');
          const iM = initialMask.indexOf('m');
          newPosition = Math.min(iD, iM);
        } else if (typeof day !== 'undefined' && !day) {
          newPosition = initialMask.indexOf('d');
        } else if (typeof month !== 'undefined' && !month) {
          newPosition = initialMask.indexOf('m');
        } else if (typeof year !== 'undefined' && !year) {
          newPosition = initialMask.indexOf('y');
        }
      }
      setValue(newValue);
      if (validDate(newValue, initialMask)) {
        externalRefs.current.onChange?.(newValue);
      } else if (newValue === initialMask && newValue !== value) {
        externalRefs.current.onChange?.('');
      }
      window.requestAnimationFrame(() => {
        inputElement?.setSelectionRange(newPosition, newPosition);
      });
    },
    [initialMask, inputElement, value]
  );

  return {
    ref: (el: HTMLInputElement | null) => {
      setInputElement(el);
    },
    onChangeCapture,
    onKeyUp: handleSetSelection,
    onKeyDown: handleSetSelection,
    clearDate: () => {
      setValue(initialMask);
      onChange?.('');
    },
    value,
  };
};
