import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as AlertIcon } from './alert.svg';
import { styles } from './styles';
export const AlertText: FC<{ children: string | undefined }> = ({
  children,
}) => {
  return (
    <Stack direction={'row'} gap={10} alignItems={'center'}>
      <AlertIcon />
      <Typography sx={styles.text} color={'grey.500'}>
        {children}
      </Typography>
    </Stack>
  );
};
