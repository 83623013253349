import React, { FC } from 'react';
import { Field } from 'formik';
import { DateControlProps } from '@/components/DateControl/DateControl';
import { useFormikError } from '@/components/FormikRedux/useFormikError';
import { CheckboxControl } from '@/components/CheckboxControl';

export const CheckboxControlF: FC<
  Omit<DateControlProps, 'name'> & { name: string; submitCount: number }
> = ({ submitCount, ...restProps }) => {
  const error = useFormikError(restProps.name, submitCount);
  return <Field as={CheckboxControl} {...restProps} error={error} />;
};
