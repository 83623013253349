import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { Link } from '@/components';
import { ReactComponent as ArrowLeft } from '@/images/icons/icon_arrow_left.svg';

export const BackButton: FC<{ onClick: VoidFunction }> = ({ onClick }) => {
  const { t } = useTranslate('common');
  return (
    <Breadcrumbs
      sx={{
        mb: {
          xs: 16,
          sm: 24,
        },
      }}
    >
      <Link to={''} onClick={onClick}>
        <ArrowLeft /> {t('BACK')}
      </Link>
    </Breadcrumbs>
  );
};
