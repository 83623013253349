import { Link, LinkProps } from 'react-router-dom';
import { styled, css as _, Theme, SxProps } from '@mui/material';
import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';
import { FC, PropsWithChildren } from 'react';
export const UlContainer = styled('ul')(({ theme: t }) => ({
  zIndex: 1,
  position: 'absolute',
  padding: 0,
  margin: 0,
  listStyle: 'none',
  boxSizing: 'border-box',
  border: `1px solid ${t.colors.borderLight}`,
  width: 270,
  maxHeight: '80vh',
  overflow: 'hidden',
  backgroundColor: t.palette.common.white,
  overflowY: 'auto',
  boxShadow: t.boxShadows.dropdown,
  [t.breakpoints.between('xs', 'md')]: {
    right: '0 !important',
  },
  [t.breakpoints.only('xs')]: {
    width: '100%',
  },
}));

export const ListItem = styled('li')`
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: white;
`;

const linkStyles = ({
  theme,
  withIcon,
}: {
  withIcon?: boolean;
  theme: Theme;
}) => _`
    word-break: break-word;
    padding: 10px 42px 10px 16px;
    padding-right: ${withIcon ? 42 : 16}px !important; 
    min-height: 40px; 
    color: ${theme.colors.defaultTextColor};
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    >strong {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &:hover {
        background-color: ${theme.colors.secondary5};
        color: ${theme.colors.secondary};
        svg {
            path {
                fill: ${theme.colors.secondary}
            }
        }
    }
    &:active {
        background-color: ${theme.colors.secondary10};
        color: ${theme.colors.secondary};
        svg {
            path {
                fill: ${theme.colors.secondary}
            }
        }
    }
    svg {
        path {
            fill: ${theme.colors.defaultTextColor}
        }
    }
    ${theme.breakpoints.between('xs', 'md')} {
        padding-right: ${withIcon ? 60 : 24}px !important;
    }
`;

const MenuLink_ = styled(
  Link,
  shouldNotForwardProps(['withIcon'])
)<{ withIcon?: boolean }>(linkStyles);
const ExternalMenuLink = styled(
  'a',
  shouldNotForwardProps(['withIcon'])
)<{ withIcon?: boolean }>(linkStyles);

export const MenuLink: FC<
  PropsWithChildren<
    LinkProps & { href?: string; withIcon?: boolean; sx?: SxProps<Theme> }
  >
> = ({ href, ...restProps }) => {
  if (href) {
    return <ExternalMenuLink href={href} {...restProps} />;
  }

  return <MenuLink_ {...restProps} />;
};

export const MenuIcon = styled('span')`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  ${({ theme }) => theme.breakpoints.between('xs', 'md')} {
    padding: 0 24px;
  }
`;
