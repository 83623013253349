import React, { FC, PropsWithChildren } from 'react';
import { useField } from 'formik';
import { RadioGroup } from '@mui/material';

export const RadioGroupF: FC<
  PropsWithChildren<{ name: string; className?: string }>
> = (props) => {
  const [field] = useField(props);
  return <RadioGroup {...field} {...props} />;
};
