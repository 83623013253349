import { useParams } from '@/routes/appRoutes';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { companiesActions as actions } from '../slice';
import { useCompaniesState } from './useCompaniesState';

export const useRoles = () => {
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const { companyId, roleId, moduleId } = useParams();

  useEffect(() => {
    if (!companyId || state.error) {
      return;
    }
    if (moduleId) {
      dispatch(actions.requestRoles({ companyId, moduleId }));
    }
  }, [dispatch, companyId, moduleId, state.error]);

  useLayoutEffect(() => {
    dispatch(actions.setError(null));
  }, [dispatch, companyId]);

  const callbacks = useMemo(() => {
    return {
      refetch: () => {
        dispatch(actions.setError(null));
        if (moduleId && companyId) {
          dispatch(actions.requestRoles({ companyId, moduleId }));
        }
      },
      resetError: () => {
        dispatch(actions.setError(null));
      },
    };
  }, [dispatch, companyId, moduleId]);
  const customRolesIds = useMemo(() => {
    const ids = moduleId
      ? state.roles?.[moduleId]?.allIDs.filter(
          (id) => state.roles[moduleId]?.byID[id]?.editable && id !== 'new'
        )
      : null;
    return ids;
  }, [moduleId, state.roles]);
  return {
    roles: moduleId ? state.roles?.[moduleId] : null,
    customRolesIds,
    role: moduleId && roleId ? state.roles?.[moduleId]?.byID?.[roleId] : null,
    error: state.error,
    ...callbacks,
    fetching: state.fetching,
  };
};
