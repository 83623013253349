import { companiesApi } from '@/api';
import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { getError, getErrors } from '@/store/common/error-handlers';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { RootState } from '@/store/store';
import {
  ErrorTypes,
  SearchType,
  UsersState,
  usersStateActions as actions,
} from './slice';
import { NormalizedState } from '../common/normalized';
import { User } from '@/types';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { ValidationErrorType } from '@/types/ValidationError';

export function* usersSaga() {
  yield all([
    takeLeading(actions.requestDeleteUser, deleteUser),
    takeLeading(actions.requestSearchUsers, requestSearchUsers),
    takeLeading(
      actions.requestChangeEmailOfCompanyAccount,
      requestChangeEmailOfCompanyAccount
    ),
  ]);
}
type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;

const stateSelector = (state: RootState) => state.users;

function* requestChangeEmailOfCompanyAccount({
  payload,
}: Action<'requestChangeEmailOfCompanyAccount'>): SagaReturn {
  const users: NormalizedState<User> = yield select(
    ({ users }: RootState) => users.users[payload.companyId]?.items
  );
  const oldEmail = users.byID[payload.accountId]?.domainEmail;
  yield put(actions.updateUserEmail(payload));
  try {
    yield call(companiesApi.changeCompanyAccountEmail, {
      companyId: payload.companyId,
      accountId: payload.accountId,
      domainEmail: payload.email,
    });
  } catch (e) {
    const error = getError(e);
    yield put(
      actions.setErrors({ common: [{ field: ErrorTypes.Common, error }] })
    );
    yield put(
      actions.updateUserEmail({
        companyId: payload.companyId,
        accountId: payload.accountId,
        email: oldEmail,
      })
    );
  }
}
// eslint-disable-next-line
export type ReturnT<T> = T extends (...params: any[]) => any
  ? Awaited<ReturnType<T>>
  : never;
function* requestSearchUsers({
  payload,
}: Action<'requestSearchUsers'>): SagaReturn {
  try {
    yield put(actions.setIsFetching({ fetching: true }));
    const accounts: ReturnT<typeof companiesApi.accountsInviteSearch> =
      yield call(companiesApi.accountsInviteSearch, {
        companyId: payload.companyId,
        data: payload.data,
      });
    if (!accounts?.length) {
      yield put(
        actions.setFormErrors({
          errors: {
            common: ValidationErrorType.USER_NOT_FOUND,
          },
          form: 'inviteUser',
        })
      );
      return;
    }
    const foundBy =
      (payload.data.phone && 'phone') ??
      (payload.data.email && 'email') ??
      (payload.data.npi && 'npi');
    yield put(
      actions.setForm({
        forms: {
          inviteUser: {
            accounts,
            step: 'list',
            search: {
              type: foundBy as SearchType,
              value:
                payload.data?.phone ??
                payload.data.npi ??
                payload.data.email ??
                '',
            },
          },
        },
        merge: true,
      })
    );
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      showError500OrUnknownToast(e);
    }
    yield put(actions.setFormErrors({ errors, form: 'inviteUser' }));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}
function* deleteUser({ payload }: Action<'requestDeleteUser'>): SagaReturn {
  const state: UsersState = yield select(stateSelector);
  const users = state.users[payload.companyId]?.items;
  const account = users?.byID[payload.accountId];
  const index = account?.id ? users?.allIDs.indexOf(account?.id) : null;
  if (!account || index == null) {
    return;
  }
  yield put(
    actions.deleteUser({
      companyId: payload.companyId,
      accountId: payload.accountId,
    })
  );
  try {
    yield call(companiesApi.deleteAccount, {
      companyId: payload.companyId,
      accountId: payload.accountId,
    });
  } catch (e) {
    const error = getError(e);
    yield put(
      actions.setErrors({ common: [{ field: ErrorTypes.Common, error }] })
    );
    yield put(
      actions.insertUsers({
        companyId: payload.companyId,
        users: [account],
        insertionIndex: index,
      })
    );
  }
}
