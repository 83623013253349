import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { AppRoutes, generatePath } from '@/routes/appRoutes';
import { Cookies } from 'react-cookie';
import { browserHistory } from '@/store/store';
import { useCompanyPermissionsValidator } from '@/store/permissions/PermissionsKeeper';
import { CompanyPermissionName } from '@/types';
import { useCheckAcceptedCookies } from '@/features/cookies/redux/hooks';

export const isAuthorized = () =>
  !!new Cookies(window.document.cookie).get('access_token');
export const ProtectedRoute: FC<{
  unauthorizedOnly?: boolean;
  redirectPath: (typeof AppRoutes)[keyof typeof AppRoutes];
  name?: string;
}> = ({ unauthorizedOnly, redirectPath }) => {
  const isAuthenticated = isAuthorized();
  const userControlPage = useMatch(AppRoutes.COMPANY_USERS_CONTROL);
  const modulePage = useMatch(AppRoutes.COMPANY_MODULES);
  const navigate = useNavigate();
  const hasPermission = useCompanyPermissionsValidator(true);

  useCheckAcceptedCookies();

  useEffect(() => {
    if (userControlPage) {
      if (!hasPermission(CompanyPermissionName.CompanyViewAllUsers)) {
        navigate(
          generatePath(AppRoutes.COMPANY_DETAILS, {
            companyId: userControlPage.params.companyId,
          })
        );
      }
    }
    if (modulePage) {
      if (
        !(
          hasPermission(CompanyPermissionName.CompanyViewAllRoles) &&
          (hasPermission(CompanyPermissionName.CompanyAssignRole) ||
            hasPermission(CompanyPermissionName.CompanyRevokeRole) ||
            hasPermission(CompanyPermissionName.CompanyViewAllUsers) ||
            hasPermission(CompanyPermissionName.ModuleCreateCustomRole))
        )
      ) {
        navigate(
          generatePath(AppRoutes.COMPANY_DETAILS, {
            companyId: modulePage.params.companyId,
          })
        );
      }
    }
  }, [hasPermission, modulePage, navigate, userControlPage]);

  const accessAllowed = unauthorizedOnly ? !isAuthenticated : isAuthenticated;
  if (!accessAllowed) {
    console.group?.(
      `[Protected Route handling] forced navigation from ${browserHistory.location.pathname} to ${redirectPath}`
    );
    console.table?.({
      unauthorizedOnly,
      redirectPath,
      isAuthenticated,
      accessAllowed,
    });
    console.groupEnd?.();
  }
  return accessAllowed ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: redirectPath, search: window.location.search }} />
  );
};
