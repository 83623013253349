import React, { ChangeEvent, FC } from 'react';
import { DateTimeFormat, DEFAULT_DATE_FORMAT } from '@/components/DateFormat';
import { HeadlessDatePicker } from '@/components/DateControl/DateInput/HeadlessDatePicker';
import { DateInput } from '@/components/DateControl/DateInput/DateInput';
import { FieldSize } from '@/components/common/FieldSize';
import {
  ChangeEventHandler,
  useInternalControlled,
} from '@/hooks/useInternalControlled';
import { DateTime } from 'luxon';

export const PARSING_FORMAT = DateTimeFormat.ISODate;
export type PARSING_FORMAT_TYPE = string; // in format of PARSING_FORMAT;
export interface DateInputProps {
  value?: PARSING_FORMAT_TYPE | undefined;
  format?: string;
  name: string;
  onChange?: ChangeEventHandler;
  placeholder?: string;
  id?: string;
  error?: boolean;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  size?: FieldSize;
  disabled?: boolean;
}
export const DatePickerInput: FC<DateInputProps> = (props) => {
  const {
    disabled,
    name,
    size = 'md',
    placeholder,
    error,
    id,
    onChange,
    value,
  } = props;
  const { handleChange, internalValue } = useInternalControlled<string>({
    onChange,
    value,
  });

  return (
    <HeadlessDatePicker
      {...props}
      onChange={handleChange as ChangeEventHandler<[string, string] | string>}
      value={internalValue ?? ''}
      autofocus={false}
      type={'calendar'}
    >
      {({ value }) => {
        const date = value
          ? DateTime.fromFormat(value, DEFAULT_DATE_FORMAT)
          : undefined;
        return (
          <DateInput
            disableFutureDates={props.disableFutureDates}
            disablePastDates={props.disablePastDates}
            disabled={disabled}
            size={size}
            error={error}
            id={id}
            value={date?.isValid ? date.toFormat(PARSING_FORMAT) : undefined}
            name={name}
            placeholder={placeholder}
            onChange={(e) => {
              handleChange?.(e as ChangeEvent<HTMLInputElement>);
            }}
          />
        );
      }}
    </HeadlessDatePicker>
  );
};
