import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { InfiniteScrollContainer } from '@/components/InfiniteScrollContainer';

const TableContext = createContext<any>({ onSort: (id: string) => {} });
export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}
interface TableContextType<T> {
  onSort?: ((params: { [name: string]: Sort }) => void) | undefined;
  sortState?: Record<string, Sort | undefined>;
}

export interface SortParams<T> {
  id: keyof T;
  order: Sort | undefined;
}

export interface TableHeaderItem<T> {
  id: keyof T;
  sortable: boolean;
  label: string;
}

export const useTableContext = <T,>() => {
  return useContext<TableContextType<T>>(TableContext);
};
interface TableProps<T> {
  onSort?: TableContextType<T>['onSort'];
  className?: string;
  sortState?: TableContextType<T>['sortState'];
  sx?: SxProps<Theme>;
}

export const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

export const DSTable = <T,>({
  children,
  className,
  onSort,
  sortState,
  sx,
}: PropsWithChildren<TableProps<T>>) => {
  const ref = useRef<TableContextType<T>>({
    onSort,
  });
  ref.current = { onSort };
  const ctx: TableContextType<T> = useMemo(() => {
    return { onSort: ref.current.onSort, sortState };
  }, [sortState]);

  return (
    <TableContext.Provider value={ctx}>
      <StyledTable className={className} sx={sx}>
        {children}
      </StyledTable>
    </TableContext.Provider>
  );
};

type ISTableProps = Omit<TableProps<any>, 'setScrollRef' | 'scrollbarCss'> & {
  onLoad?: () => void;
  threshold?: number;
  sx?: SxProps<Theme>;
};
export const Table = (props: PropsWithChildren<ISTableProps>) => {
  return (
    <InfiniteScrollContainer onLoad={props.onLoad}>
      <DSTable {...props} />
    </InfiniteScrollContainer>
  );
};
