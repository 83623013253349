import { createContext, useContext } from 'react';

export const DatePickerContext = createContext<{
  disablePastDates: boolean | undefined;
  disableFutureDates: boolean | undefined;
}>({
  disablePastDates: false,
  disableFutureDates: false,
});
export const useDatePickerContext = () => {
  return useContext(DatePickerContext);
};
