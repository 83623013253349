/** @jsxImportSource @emotion/react */
import {
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Switch as MuiSwitch,
  SxProps,
  Theme,
} from '@mui/material';
import React, { FC } from 'react';
import { useField } from 'formik';

export const Switch = styled(MuiSwitch)`
  padding: 15px 12px;
  width: 65px;
  .MuiSwitch {
    &-thumb {
      width: 22px;
      height: 22px;
    }
    &-switchBase {
      padding: 8px;
    }
  }
  .Mui-checked {
    transform: translateX(26px) !important;
  }
`;

Switch.defaultProps = {
  color: 'secondary',
};

type SwitchControlProps = Omit<FormControlLabelProps, 'control'>;
export const SwitchControl: FC<SwitchControlProps> = ({ ...restProps }) => {
  return (
    <FormControlLabel
      sx={{ ml: 0, gap: 12, 'MuiTypography-root': { fontSize: '14px' } }}
      control={<Switch />}
      labelPlacement={'start'}
      {...restProps}
    />
  );
};

export const SwitchControlF: FC<{
  label: string;
  name: string;
  className?: string;
  sx?: SxProps<Theme>;
}> = (props) => {
  const { label: _, ...restProps } = props;
  const [field] = useField({ type: 'checkbox', ...restProps });
  return <SwitchControl {...field} {...props} />;
};
