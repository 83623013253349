export const rad = (deg: number) => deg * (Math.PI / 180);
export const deg = (rad: number) => {
  return (rad * 180) / Math.PI;
};
export const rotateAndCrop = (
  img: HTMLImageElement,
  {
    rotate = { deg: 0 },
    cropArea,
    quality = 2,
  }: {
    quality?: number;
    rotate?: {
      deg: number;
      offset?: { x: number; y: number };
    };
    cropArea: {
      x: number;
      y: number;
      size: number;
    };
  }
) => {
  // quality multiplier
  const qm = quality;
  const size = cropArea.size * qm;
  const x = cropArea.x * qm;
  const y = cropArea.y * qm;
  const rCanvas = document.createElement('canvas');
  const rCtx = rCanvas.getContext('2d') as CanvasRenderingContext2D;
  const w = img.offsetWidth * qm;
  const h = img.offsetHeight * qm;
  rCanvas.width = w;
  rCanvas.height = h;
  const radians = rad(rotate.deg);
  // transformOriginCoords
  const to = {
    x: w / 2,
    y: h / 2,
  };
  const offsetX = Number(rotate.offset?.x ?? '0') * qm;
  const offsetY = Number(rotate.offset?.y ?? '0') * qm;
  rCtx.translate(to.x + offsetX, to.y + offsetY);
  rCtx.rotate(radians);
  rCtx.drawImage(img, -to.x, -to.y, w, h);
  const croppingCanvas = document.createElement('canvas');
  const cCtx = croppingCanvas.getContext('2d') as CanvasRenderingContext2D;
  croppingCanvas.width = size;
  croppingCanvas.height = size;
  cCtx.drawImage(rCanvas, -x, -y, w, h);
  return new Promise<File>((resolve) => {
    croppingCanvas.toBlob(
      (blob) => {
        resolve(blob as File);
      },
      'image/jpeg',
      0.7
    );
  });
};

export const getBisector = (
  a: { x: number; y: number },
  b: { x: number; y: number }
) => {
  const W = a.x > b.x ? a.x - b.x : b.x - a.x;
  const H = a.y > b.y ? a.y - b.y : b.y - a.y;
  const R = Math.sqrt(Math.pow(W, 2) + Math.pow(H, 2));
  return { bisect: R, H, W };
};
export const getPointOnCircle = (angle: number, R: number) => {
  return {
    x: Math.sin(rad(angle)) * R,
    y: Math.cos(rad(angle)) * R,
  };
};
