import { ValidationErrorType } from '@/types/ValidationError';
import { MFAType } from '@/api/__generated__/identityApi';

export interface RegistrationParams {
  phone: string;
  firstName: string;
  lastName: string;
  recaptcha: string;
  password: string;
}
export type LoginParams = Omit<RegistrationParams, 'firstName' | 'lastName'>;

export interface SendCodeParams {
  code: string; // recaptcha
  phone?: string;
  email?: string;
  inviteCode?: string;
  mfaType?: MFAType;
}
export enum Space {
  Login = 'login',
  Mfa = 'mfa',
  Registration = 'registration',
  Recovery = 'recovery',
  Activation = 'activation',
}
export interface FieldValidationError {
  field: string;
  error: ValidationErrorType;
}
export interface RecoveryCheckParams {
  recaptcha: string;
  email?: string;
  phone?: string;
}
export interface RecoveryConfirmParams {
  code: string; // recaptcha
  phone: string;
}
export interface RecoveryNewPasswordParams {
  phone: string;
  password: string;
  recaptcha: string;
}

export type Step =
  | 'privacy-policy'
  | 'index'
  | 'pin-code'
  | 'mfa-code'
  | 'pin-code-attempts-expired'
  | 'mfa-type-required'
  | 'success'
  | 'error'
  | 'new-password'
  | 'account-not-found'
  | 'init-account-activation';
export enum Forms {
  RecoveryCheckAccountForm = 'recovery-check-account-form',
  RecoveryNewPasswordForm = 'recovery-set-new-password-form',
  RegistrationForm = 'registration-form',
  LoginForm = 'login-form',
  ActivationForm = 'activation-form',
}
export const spacesToFormsMap = {
  [Space.Login]: Forms.LoginForm,
  [Space.Mfa]: Forms.LoginForm,
  [Space.Registration]: Forms.RegistrationForm,
  [Space.Recovery]: Forms.RecoveryCheckAccountForm,
  [Space.Activation]: Forms.ActivationForm,
} as const;
