import React, { FC } from 'react';
import { ContactAddress } from '@/api/__generated__/webApi';
import { useTranslate } from '@/i18n/useTranslate';
import { BoxHeader, WhiteBox } from '@/components';
import { Box, Stack } from '@mui/material';
import { styles } from './styles';
import { Dl } from '@/components/Dl';
import { EMPTY } from '@/constants';
import { GoogleMap } from '@/components/Google/Map';
import { AlertText } from '../AlertText';
import { Button } from 'ui-kit';
import { getLatLongLink } from '@/features/common/utils/getLatLongLink';

export const GeoValidatedAddressView: FC<{
  address: ContactAddress;
  onDelete: () => void;
  onChange: () => void;
}> = ({ address, onDelete, onChange }) => {
  const { t } = useTranslate('profile');
  return (
    <WhiteBox>
      <Stack sx={styles.locationContainer}>
        <Stack sx={styles.column}>
          <BoxHeader>{t('CONTACT_ADDRESS')}</BoxHeader>
          <Dl>
            <dt>{t('ADDRESS_1')}</dt>
            <dd>{address?.main || EMPTY}</dd>
          </Dl>
          <Dl>
            <dt>{t('ADDRESS_2')}</dt>
            <dd>{address?.additional || EMPTY}</dd>
          </Dl>
          <Dl>
            <dt>{t('CITY')}</dt>
            <dd>{address?.city || EMPTY}</dd>
          </Dl>
          <Dl>
            <dt>{t('STATE')}</dt>
            <dd>{address?.state || EMPTY}</dd>
          </Dl>
          <Dl>
            <dt>{t('POSTAL_CODE')}</dt>
            <dd>{address?.postalCode || EMPTY}</dd>
          </Dl>
          <Dl>
            <dt>{t('LOCATION')}</dt>
            <dd>{getLatLongLink(address)}</dd>
          </Dl>
        </Stack>
        <Stack sx={styles.mapLayout}>
          <GoogleMap placeId={address?.googlePlaceId} hideTooltip>
            {(mapRef) => {
              return <Box ref={mapRef} sx={styles.mapContentContainer} />;
            }}
          </GoogleMap>
        </Stack>
      </Stack>
      <Stack
        gap={24}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Stack sx={styles.alertContainer}>
          <AlertText>{t('YOU_CAN_HAVE_ONLY_ONE_ADDRESS')}</AlertText>
        </Stack>
        <Stack direction={'row'} gap={12}>
          <Button fullWidth onClick={onChange}>
            {t('EDIT')}
          </Button>
          <Button fullWidth variant={'outlined'} onClick={onDelete}>
            {t('DELETE')}
          </Button>
        </Stack>
      </Stack>
    </WhiteBox>
  );
};
