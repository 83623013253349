import { useLayoutEffect, useEffect } from 'react';

export const hideLoader = () => {
  const element = window.document.getElementById('preloader');
  if (element) {
    element.classList.add('fade');
    window.setTimeout(() => {
      element.classList.add('hide');
      element.classList.remove('animate');
    }, 1000);
  }
};
export const showLoader = () => {
  const element = window.document.getElementById('preloader');
  if (element) {
    element.classList.remove('fade', 'hide');
    element.classList.add('animate');
  }
};

export const useInitVivicaLoader = (ready: boolean) => {
  useLayoutEffect(() => {
    if (!ready) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [ready]);
  useEffect(() => {
    if (ready) {
      hideLoader();
    }
  }, [ready]);
};
