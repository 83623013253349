/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { DnDFileInput, FileChangeHandler } from './DnDFileInput';
import { DnDZoneViewPhotoUpload } from './DndZoneViewPhotoUpload';
import { FileContextWrapper } from '@/components/FileInput/FileContextWrapper';
import { Stack } from '@mui/material';

/**
 * Same as DnDFileInput -
 * meaning it is DnDFileInput using different view for drop zone and having one more parameter - src
 * @param src
 * @param props
 */
export const PhotoUpload: FC<{
  src: string | undefined;
  round?: boolean;
  onChange: FileChangeHandler;
}> = ({ src, round = true, onChange }) => {
  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <FileContextWrapper onChange={onChange}>
        <DnDFileInput
          dropZoneView={<DnDZoneViewPhotoUpload src={src} round={round} />}
          multiple={false}
          accept={'image/*'}
        />
      </FileContextWrapper>
    </Stack>
  );
};
