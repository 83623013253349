import { useTranslate } from '@/i18n/useTranslate';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

export const LabelOptional = ({
  optional,
  className,
  disabled,
  sx,
}: {
  optional?: boolean;
  className?: string;
  disabled: boolean;
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslate('common');
  return (
    <Box height={18} className={className} sx={sx}>
      {optional && (
        <Typography
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={400}
          fontStyle={'italic'}
          color={disabled ? 'grey.400' : 'grey.500'}
        >
          {t('OPTIONAL')}
        </Typography>
      )}
    </Box>
  );
};
