import { NormalizedState } from '../common/normalized';
import { AccountDetails, Nullable, PartialRecord } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createNormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import { FormModel, FormState } from '../companies/slice';
import { globalStateResetAction } from '../common/actions';
import { InvitationDetails } from '@/api';
import { envs } from '@/constants/envs';
import { pathJoin } from '@/utils/pathJoin';
import { Contact, ContactAddress } from '@/api/__generated__/webApi';
import { ValidationErrorType } from '@/types/ValidationError';

export enum Fetching {
  Common = 'common',
}

export const avatarUrl = pathJoin(envs.PASSPORT_BACKEND, 'api/account/avatar');

interface UploadFlow extends FormModel {}

export interface ProfileState {
  addresses: NormalizedState<ContactAddress> | null;
  errors: PartialRecord<string, ValidationErrorType> | null;
  fetching: PartialRecord<'common', boolean> | null;
  redirect: string | null;
  forms: Partial<{
    supportForm: FormModel;
    uploadAvatarFlow: UploadFlow;
    editProfile: FormModel;
  }>;
  notification?: { type: 'INVITE_SUCCESSFUL'; params?: InvitationDetails };
  aboutSystemShown?: boolean;
  accountDetails?: AccountDetails;
}

type FormNames = keyof ProfileState['forms'];
const initialState: ProfileState = {
  addresses: null,
  errors: null,
  fetching: null,
  redirect: null,
  forms: {},
};
const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    requestAccountDetails() {},
    requestSaveAccountDetails(
      _,
      _action: PayloadAction<{ contact: Nullable<Contact> }>
    ) {},
    requestUploadAvatar(_, _action: PayloadAction<{ data: FormData }>) {},
    requestAddressess() {},
    requestCheckBackUrl(_state, _action: PayloadAction<{ backUrl: string }>) {},
    requestConfirmInvitation(_state, _action: PayloadAction<string>) {},
    requestSendSupportMessage(
      _state,
      _action: PayloadAction<{ message: string; subject: string }>
    ) {},
    setDisplayInfoAboutSystem(state, { payload }: PayloadAction<boolean>) {
      state.aboutSystemShown = payload;
    },
    setAddresses(
      state,
      { payload }: PayloadAction<{ items: ContactAddress[]; replace?: boolean }>
    ) {
      if (payload.replace || !state.addresses?.allIDs.length) {
        state.addresses = createNormalizedState(payload.items);
      }
      updateNormalizedState(state.addresses, payload.items);
    },
    setErrors(
      state,
      action: PayloadAction<PartialRecord<string, ValidationErrorType>>
    ) {
      state.errors = action.payload;
    },
    setNotification(
      state,
      { payload }: PayloadAction<ProfileState['notification'] | undefined>
    ) {
      if (payload) {
        const { type, params } = payload;
        state.notification = { type, params };
      } else {
        state.notification = undefined;
      }
    },
    setFormErrors(
      state,
      {
        payload,
      }: PayloadAction<{
        form: FormNames;
        errors: PartialRecord<string, ValidationErrorType>;
      }>
    ) {
      (state.forms[payload.form] = state.forms[payload.form] ?? {}).errors =
        payload.errors;
    },
    setFormState(
      state,
      { payload }: PayloadAction<{ formName: FormNames; formState: FormState }>
    ) {
      (state.forms[payload.formName] =
        state.forms[payload.formName] ?? {}).state = payload.formState;
    },
    setForm(
      state,
      {
        payload,
      }: PayloadAction<{ forms: ProfileState['forms']; merge: boolean }>
    ) {
      if (!payload.forms) {
        state.forms = {};
        return;
      }
      for (const formName of Object.keys(payload.forms)) {
        if (state.forms?.[formName as FormNames] && payload?.merge) {
          state.forms[formName as FormNames] = {
            ...state.forms[formName as FormNames],
            ...payload.forms[formName as FormNames],
          };
        } else {
          (state.forms = state.forms ?? {})[formName as FormNames] =
            payload.forms[formName as FormNames];
        }
      }
    },
    resetErrors(state) {
      state.errors = null;
      if (state.forms.supportForm?.errors) {
        state.forms.supportForm.errors = null;
      }
      if (state.forms.editProfile?.errors) {
        state.forms.editProfile.errors = null;
      }
    },
    setIsFetching(
      state,
      { payload }: PayloadAction<{ type?: Fetching; fetching: boolean }>
    ) {
      (state.fetching = state.fetching ?? {})[payload.type ?? Fetching.Common] =
        payload.fetching;
    },
    updateAvatarUrl(state) {
      if (!state.accountDetails) {
        console.error(
          '[setAvatarUrl] Unable to set avatarUrl. Account Details not found'
        );
        return;
      }
      state.accountDetails.avatarUrl =
        state.accountDetails.avatarUrl + `?ts=${Date.now()}`;
    },
    redirect(state, { payload }: PayloadAction<string | null>) {
      state.redirect = payload;
    },
    setAccountDetails(state, { payload }: PayloadAction<AccountDetails>) {
      const avatarUrl = payload.avatarUrl
        ? `${payload.avatarUrl}?ts=${Date.now()}`
        : state.accountDetails?.avatarUrl;
      state.accountDetails = { ...payload, avatarUrl };
    },
    setContact(state, { payload }: PayloadAction<Contact>) {
      if (state.accountDetails) {
        state.accountDetails.contact = payload;
      } else {
        console.error(
          '[setContact] AccountDetails not found',
          state.accountDetails
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const profileStateName = slice.name;
export const profileReducer = slice.reducer;
export const profileActions = slice.actions;
