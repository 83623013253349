import React, { FC, useLayoutEffect } from 'react';

import { ErrorFragment } from '../common/fragments/ErrorFragment/ErrorFragment';
import { PinCodeFragment } from '../common/fragments/PinCodeFragment';
import { PinCodeExpiredFragment } from '../common/fragments/PinCodeExpiredFragment/PinCodeExpiredFragment';
import { Forms, Space } from '../common/types';
import { useAuthState } from '@/store/auth/hooks';
import { authActions } from '@/store/auth/slice';
import { useDispatch } from 'react-redux';
import { NewPasswordFragment } from './fragments/NewPasswordFragment';
import { SuccessFragment } from '../common/fragments/SuccessFragment/SuccessFragment';
import { CheckAccountFragment } from './fragments/CheckAccountFragment';

const RecoveryPage: FC = () => {
  const state = useAuthState();
  const recoveryForm = state.forms[Forms.RecoveryCheckAccountForm];
  const dispatch = useDispatch();
  let component = <></>;

  useLayoutEffect(() => {
    dispatch(authActions.goToStep('index'));
  }, [dispatch]);

  switch (state.step) {
    case 'error':
      component = <ErrorFragment space={Space.Recovery} />;
      break;
    case 'pin-code':
      component = (
        <PinCodeFragment
          space={Space.Recovery}
          sendBy={recoveryForm?.selected}
        />
      );
      break;
    case 'pin-code-attempts-expired':
      component = <PinCodeExpiredFragment space={Space.Recovery} />;
      break;
    case 'success':
      component = <SuccessFragment space={Space.Recovery} />;
      break;
    case 'new-password':
      component = <NewPasswordFragment />;
      break;
    default:
      component = (
        <CheckAccountFragment
          notFound={state.step === 'account-not-found'}
          reset={() => {
            dispatch(authActions.reset());
          }}
        />
      );
  }

  return component;
};
export default RecoveryPage;
