/** @jsxImportSource @emotion/react */
import { css } from '@mui/material';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes';
import { Button, H2, Text } from '@/components';
import { DialogAlert } from '@/components/Dialog';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { useCompaniesState } from '@/store/companies/hooks';
import { companiesActions } from '@/store/companies/slice';
import { UUID } from '@/types';
import { ReactComponent as IconSVGLongArrow } from '@/images/icons/icon_long_arrow_right.svg';
import { FC } from 'react';
import { ModuleType } from '@/api/__generated__/webApi';
import { useMQuery } from '@/hooks/useMQuery';

export const pageHeaders: Record<ModuleType, TKeys<'companies'>> = {
  [ModuleType.LIS]: 'LIS',
  [ModuleType.PASSPORT]: 'PASSPORT',
  [ModuleType.PROFESSIONAL]: 'PROFESSIONAL',
};
export const pageDescriptions: Record<ModuleType, TKeys<'companies'>> = {
  [ModuleType.LIS]:
    'THE_LAB_SERVICE_HELPS_YOU_PERFORM_REGULAR_ANALYSIS_CHECKS_COLLATES',
  [ModuleType.PASSPORT]: 'PASSPORT_PAGE_DESCRIPTION',
  [ModuleType.PROFESSIONAL]: 'PROFESSIONAL_PAGE_DESCRIPTION',
};

export const useModuleName = () => {
  const { module } = useParams();
  const { t } = useTranslate('companies');
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  return {
    description: moduleType && t(pageDescriptions[moduleType]),
    name: moduleType && t(pageHeaders[moduleType]),
    moduleType,
  };
};
export const ModuleHeader: FC<{ className?: string }> = ({ className }) => {
  const { moduleType, name, description } = useModuleName();
  const { mobile, tablet } = useMQuery();
  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          gap: 24px;
        `}
      >
        <div
          css={css`
            flex: 1 1 auto;
            .mobile-layout & {
              display: flex;
              align-items: center;
            }
          `}
        >
          <H2
            mb={12}
            css={css`
              .mobile-layout &,
              .tablet-layout & {
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
                margin: 0;
              }
            `}
          >
            {name}
          </H2>
          {!mobile && !tablet && <Text fz={14}>{description}</Text>}
        </div>
        <div>
          <EnterToModule type={moduleType} />
        </div>
      </div>
      {(mobile || tablet) && (
        <Text fz={14} mt={20}>
          {description}
        </Text>
      )}
    </div>
  );
};

interface EnterToModuleProps {
  type: ModuleType | null;
  companyId?: UUID;
  children?: (p: { onClick: () => void; fetching: boolean }) => JSX.Element;
}
const EnterToModule: FC<EnterToModuleProps> = ({
  type,
  companyId: propCompanyId,
  children,
}) => {
  const { t } = useTranslate('companies');
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const { mobile } = useMQuery();
  const handleClick = () =>
    type &&
    companyId &&
    dispatch(
      companiesActions.requestEnterModule({
        moduleType: type,
        companyId: propCompanyId ?? companyId,
      })
    );
  if (!type || type === ModuleType.PASSPORT) {
    return null;
  }
  return (
    <>
      {children ? (
        children({ onClick: handleClick, fetching: state.fetching })
      ) : (
        <Button
          color="secondary"
          onClick={handleClick}
          disabled={state.fetching}
          sx={{ '.mobile-layout': { padding: 0, width: 40 } }}
          endIcon={<IconSVGLongArrow />}
        >
          {!mobile && t('ENTER')}
        </Button>
      )}
      <DialogAlert
        onClose={() => dispatch(companiesActions.resetErrors())}
        title={t('ERROR')}
        open={!!state.errors.enterModule}
      >
        {t(state.errors.enterModule)}
      </DialogAlert>
    </>
  );
};
