import { MobilePhone } from '@/api/__generated__/webApi';

export const groupPhones = (phones: Partial<MobilePhone[]> | undefined) => {
  const groupedPhones = (phones ?? []).reduce<{
    main: MobilePhone | null;
    confirmed: Partial<MobilePhone[]>;
    unconfirmed: Partial<MobilePhone[]>;
  }>(
    (acc, item) => {
      if (item?.main) {
        acc.main = item;
      } else if (item?.ownershipConfirmed) {
        acc.confirmed.push(item);
      } else {
        acc.unconfirmed.push(item);
      }

      return acc;
    },
    {
      confirmed: [],
      main: null,
      unconfirmed: [],
    }
  );
  return {
    ...groupedPhones,
    firstExistingByPriority:
      groupedPhones?.main ??
      groupedPhones.confirmed[0] ??
      groupedPhones.unconfirmed[0],
  };
};
