import React, { createContext, FC, PropsWithChildren } from 'react';
import { useFileOperations } from '@/components/FileInput/useFileOperations';
import { FileChangeHandler } from '@/components/FileInput/DnDFileInput';

export interface ContextActions {
  handleChange: React.ChangeEventHandler<Element>;
  handleDelete: (filename: string) => void;
  files: File[];
  handleAddFiles: (files: FileList | null | undefined) => void;
}
export const FileContext = createContext<ContextActions>({
  files: [],
  handleDelete: () => {},
  handleChange: () => {},
  handleAddFiles: () => {},
});
export const FileContextWrapper: FC<
  PropsWithChildren<{
    onChange?: FileChangeHandler;
    multiple?: boolean;
    name?: string;
  }>
> = ({ onChange, multiple, name, children }) => {
  const { files, handleDelete, handleAddFiles, handleChange } =
    useFileOperations({
      onChange,
      name,
      multiple,
    });
  return (
    <FileContext.Provider
      value={{
        handleChange,
        handleDelete,
        files,
        handleAddFiles,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
